import React from 'react';
import { createUseStyles } from 'react-jss';
import imgLogo from './images/logo.png';

const useStyles = createUseStyles({
    copyright_container_element_text: {
      fontSize: 12,
      margin: '0 0 10px 10px',
      '& a': {
        color: 'rgb(47, 47, 47)'
      }
    }
});

const Copyright = props => {
    const classes = useStyles();
    return (
        <>
            <div className="copyright-container-element-image">
                <a href="https://roomtodo.com/#index" target="_blank">
                    <img className="copyright-image-logotype" src={imgLogo} />
                </a>
            </div>
            <div className={classes.copyright_container_element_text}><a href="//roomtodo.com" target="_blank">2021 © Roomtodo</a></div>
        </>
    )
}

export default Copyright;