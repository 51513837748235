import React, { useEffect, useRef, useState } from 'react';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { planner } from '../../../../../init';
import { selectMetrics } from '../../../../../redux/features/metrics/metricsSLice';

const useStyle = createUseStyles({
    text:{
        fontSize: 13,
        color: 'rgb(68, 68, 68)',
    },
    input:{
        outline: 'none',
        border: 'none',
        padding: '3px 5px',
        color: 'rgb(68, 68, 68)',
        margin: '0 8px 0 4px',
        '&::-webkit-inner-spin-button':{
            display: 'none'
        },
        '&:hover':{
            background: 'rgb(244, 244, 244)'
        },
        '&:focus':{
            color: 'rgb(47, 47, 47)',
            outline: 'rgb(54, 54, 54) solid 1px',
            backgroundColor: 'rgb(244, 244, 244)',
            cursor: 'auto'
        }
    },
    arrows:{
        display: 'grid'
    },
    arrowUp:{
        transform: 'rotate(90deg)',
        cursor: 'pointer',
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& path':{
                fill: '#FFCC00'
            }
        }
    },
    arrowDown:{
        transform: 'rotate(-90deg)',
        cursor: 'pointer',
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& path':{
                fill: '#FFCC00'
            }
        }
    },
    changerInputs:{
        display: "flex",
        textAlign: 'center',
        alignItems: "center"
    },
    changerMain: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }
});

let key = null;

const ChangeSize = props => {
    const classes = useStyle();
    const inputEl = useRef(null);

    let metrics = useSelector(selectMetrics);

    let [value, setValue] = useState(planner.units.valueToString(+props.value));

    let onBlurInput = e => {
        let convertedValue = planner.units.stringToValue(e.target.value);
        let newValue = planner.units.valueToString(convertedValue);
        newValue = isNaN(newValue) ? props.value : newValue;
        setValue(newValue);
        e.target.style.width = ((newValue.length+1) * 7) + 5 + 'px';
        props.valueChangeType ? props.changeValue({status: false, action: null}, planner.units.stringToValue(newValue), props.valueChangeType) : props.changeValue({status: false, action: null}, planner.units.stringToValue(newValue));
        document.removeEventListener("keydown", onEnterClick);
    }
    
    let onChangeSize = (action, mouseDownStatus) => {
        let changedValue = 0;
        let input = inputEl.current;
        switch(action) {
            case "change":
                setValue(input.value);
                input.style.width = ((value.length+1) * 8) + 5 + 'px';
                break;
            case "incr":
                changedValue = +props.value + planner.units.getStep();
                props.valueChangeType ? props.changeValue({status: mouseDownStatus, action}, changedValue, props.valueChangeType) : props.changeValue({status: mouseDownStatus, action}, changedValue);
                break;
            case "decr":
                changedValue = props.value - planner.units.getStep();
                changedValue = changedValue <= 0 ? 0 : changedValue;
                props.valueChangeType ? props.changeValue({status: mouseDownStatus, action}, changedValue, props.valueChangeType) : props.changeValue({status: mouseDownStatus, action}, changedValue);
                break;
        }
    }

    let time = null;
    let mouseDownArrows = action => {
        time = Date.now()
        key = setTimeout(() => {
            onChangeSize(action, true);
        }, 200)
    }

    let mouseUpArrows = action => {
        clearTimeout(key);
        onChangeSize(action, false);
    }

    let onFocusInput = e => document.addEventListener("keydown", onEnterClick);

    let onEnterClick = e => e.code == "Enter" ? inputEl.current && inputEl.current.blur() : null;

    let windowMouseUp = e => props.mouseDown.status == true ? mouseUpArrows(props.mouseDown.action, false) : null;

    useEffect(() => {
        document.addEventListener("mouseup", windowMouseUp);

        if(props.mouseDown.status == true && props.mouseDown.type == props.valueChangeType) {
            key = setTimeout(() => {
                onChangeSize(props.mouseDown.action, true);
            }, 100)
        }
        
        setValue(planner.units.valueToString(+props.value));
        inputEl.current.style.width = ((inputEl.current.value.length+1) * 7) + 5 + 'px';

        return () => document.removeEventListener("mouseup", windowMouseUp);
        
    }, [props.value, metrics]);

    useEffect(() => inputEl.current.style.width = ((inputEl.current.value.length+1) * 7) + 5 + 'px');

    let inputClasses = props.addClass ? classes.input + " " + props.addClass : classes.input;
    
    return (
        <div className={classes.changerMain}>
            <div className={classes.text} >{props.label}</div>
            <div className={classes.changerInputs}>
                <input className={inputClasses} ref={ inputEl } onFocus={onFocusInput} onBlur={onBlurInput} onChange={e => onChangeSize("change")} value={value} type="text"/>
                <div className={classes.arrows} >
                    <svg className={classes.arrowUp} onMouseUp={e => mouseUpArrows("incr")} onMouseDown={e => mouseDownArrows("incr")} width="9" height="15" viewBox="0 0 9 15" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.97282 0.679673L8.27496 0L0.5625 7.54323L1.26036 8.2229L1.26511 8.21825L8.30758 15L9 14.3151L1.96151 7.53713L8.97282 0.679673Z" fill="#626262"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.97282 0.679673L8.27496 0L0.5625 7.54323L1.26036 8.2229L1.26511 8.21825L8.30758 15L9 14.3151L1.96151 7.53713L8.97282 0.679673Z" fill="#626262"/>
                    </svg>
                    <svg className={classes.arrowDown} onMouseUp={e => mouseUpArrows("decr")} onMouseDown={e => mouseDownArrows("decr")} width="9" height="15" viewBox="0 0 9 15" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.97282 0.679673L8.27496 0L0.5625 7.54323L1.26036 8.2229L1.26511 8.21825L8.30758 15L9 14.3151L1.96151 7.53713L8.97282 0.679673Z" fill="#626262"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.97282 0.679673L8.27496 0L0.5625 7.54323L1.26036 8.2229L1.26511 8.21825L8.30758 15L9 14.3151L1.96151 7.53713L8.97282 0.679673Z" fill="#626262"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default ChangeSize;