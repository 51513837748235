import React, { useEffect, useState } from 'react';
import {createUseStyles} from "react-jss";
import ChangeSize from "../../../../../../common/changeSize/index.jsx";
import { planner, user } from '../../../../../../../../../init.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranslation } from '../../../../../../../../../redux/features/language/languageSlice.js';
import { selectMetrics } from '../../../../../../../../../redux/features/metrics/metricsSLice.js';
import { historyAdd, selectActiveViewModeData, setActiveViewModeState } from '../../../../../../../../../redux/features/viewMode/viewModeSlice.js';
import { hideQuickPanelAll } from '../../../../../../../../../redux/features/quickPanels/quickPanelsSlice.js';
import { changeUndoRedo } from '../../../../../../../../../redux/features/topPanel/topPanelSlice.js';
import EditMaterialsItem from '../../../../../../common/editMaterialItem/index.jsx';
import CatalogMaterials from '../../../general/catalog/CatalogMaterials.jsx';
import Catalog from '../../../general/catalog/Catalog.jsx';

const useStyle = createUseStyles({
    planerWallsCont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planerWallsWrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',

    },
    title:{
        fontSize: 13,
        color: '#444444',
        borderBottom: '1px solid rgb(186, 186, 186)',
        padding: '7px 10px',
    },
    cont:{
        padding: '20px'
    },
    planerChangeSizesWrap:{
        display: 'grid',
        gridTemplateColumns: 'auto 27px',
        gridGap: 24,
        marginBottom: 20,
        paddingRight: 10
    },
    planerChangeSizes:{

    },
    groupWr:{
        height: 100,
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 10,
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #d9d9d9',
        cursor: 'pointer',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        marginBottom: 20,
        '&:hover':{
            background: '#FFCC00'
        }
    },
    groupIcon:{

    },
    groupText:{
        fontSize: 13,
        color: '#444444',
        width: 80,
    },

    changeSizesLockWr:{
        position: 'relative',
        height: 94,
        display: 'grid',
        paddingTop: 13
    },
    changeSizesLockLine1:{
        width: '100%',
        height: 11,
        borderTop: '1px solid',
        borderRight: '1px solid'
    },
    changeSizesLockLine2:{
        borderTop: '1px solid',
        width: 13,
    },
    changeSizesLockLine3:{
        width: '100%',
        height: 11,
        borderBottom: '1px solid #444444',
        borderRight: '1px solid #444444'
    },
    zamokLock:{
        position: 'absolute',
        top: 31,
        right: '-7px',
        width: 16,
        cursor: 'pointer',
        opacity: ".5",
    },
    materialsGrid: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        "& > div":{
            marginBottom: 20
        }
    }
});
const StateSelectedGroup = () => {
    
    const classes = useStyle();
    
    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataType = currentStateHistory[currentStateHistory.length - 1].type;

    let elevation = currentStateHistory[0].data.elevation;
    let width = currentStateHistory[0].data.width;
    let depth = currentStateHistory[0].data.depth;
    let height = currentStateHistory[0].data.height;

    let currentGroup = planner.mih()._scene.currentGroup;
    
    let [objectWidth, setObjectWidth] = useState(Math.round(width * 10) / 10);
    let [objectHeight, setObjectHeight] = useState(Math.round(height * 10) / 10);
    let [objectDepth, setObjectDepth] = useState(Math.round(depth * 10) / 10);
    let [objectElevation, setObjectElevation] = useState(elevation);

    let [isMerged, setIsMerged] = useState(planner.scene.currentGroupIsMerged());

    let [mouseDown, setMouseDown] = useState({status: false, action: null, type: null});

    let metrics = useSelector(selectMetrics);

    let ratioWvH = objectWidth / objectHeight;
    let ratioWvD = objectWidth / objectDepth;

    let currentObjects = currentGroup.getObjViews().map(i => i.getSceneObject());

    let currentObjectsMaterialsIds = currentObjects.map(i => i.getMaterials()).map(i => i.map(y => y.current));

    let currentObjectsMaterialsIdsConcat = currentObjectsMaterialsIds.reduce((previousValue, currentValue) => previousValue.concat(currentValue))

    let [currentObjectsMatIdsRepeat, setCurrentObjectsMatIdsRepeat] = useState(new Set(currentObjectsMaterialsIdsConcat.filter((item, index) => currentObjectsMaterialsIdsConcat.indexOf(item) !== index)));

    let onCopyMaterial = id => R2D.CopyPaste.copyMaterial(id);
    
    let onPasteMaterial = prevMatId => {
        let mat = R2D.CopyPaste.pasteMaterial();
        if (mat) {
            currentObjects.forEach((i, index) => {
                currentObjectsMaterialsIds[index].forEach((y, indexY) => {
                    if(prevMatId == y) {
                        i.setMaterialAt(indexY, mat);
                        i.update();
                    }
                })
            })
            
            planner.mih()._scene.history.saveState();
            setCurrentObjectsMatIdsRepeat(prevValue => {
                let newValue = prevValue;
                newValue.delete(prevMatId);
                newValue.add(mat)
                return newValue;
            });
            dispatch(changeUndoRedo());
        }
    }

    let previewMaterials = [];

    for(let i of currentObjectsMatIdsRepeat) {
        previewMaterials.push(
            <EditMaterialsItem matId={i} key={i} onCopyPaste={{copy: onCopyMaterial, paste: onPasteMaterial, index: i}} onClick={e => onClickPreview(i)} />
        )
    }

    let [activeMatId, setActiveMatId] = useState(null);

    let onClickPreview = async matId => {
        let catalogMaterials = await user.loadRightPanelData(R2D.URL.CATALOG_MATERIALS_TREE_FOR_MODELS);
        dispatch(historyAdd({added: true, data: catalogMaterials.materials, type: "catalog"}));
        setActiveMatId(matId);
    }

    let onChangeSize = (mouseDown, value, type) => {
        setMouseDown({...mouseDown, type});
        switch(type) {
            case "width":
                currentGroup.setWidth(value);
                currentGroup.setHeight(value / ratioWvH);
                currentGroup.setDepth(value / ratioWvD);
                break;
            case "height":
                currentGroup.setHeight(value);
                currentGroup.setWidth(value * ratioWvH);
                currentGroup.setDepth(value * ratioWvH / ratioWvD);
                break;
            case "depth":
                currentGroup.setDepth(value);
                currentGroup.setWidth(value * ratioWvD);
                currentGroup.setHeight(value * ratioWvD / ratioWvH);
                break;
            case "elevation":
                currentGroup.y = value;
                break;
        }
        planner.mih()._ruler3d.findRules(planner.mih()._scene.currentGroup);
        currentGroup.update();
        
        if(mouseDown.status == false) {
            planner.mih()._scene.history.saveState();
            dispatch(changeUndoRedo());
        }
        setObjectWidth(Math.round(currentGroup.getWidth() * 10) / 10);
        setObjectHeight(Math.round(currentGroup.getHeight() * 10) / 10);
        setObjectDepth(Math.round(currentGroup.getDepth() * 10) / 10);
        setObjectElevation(currentGroup.y);
    }

    let onDelClick = e => {
        if(e.code == "Delete") {
            dispatch(setActiveViewModeState({state: "stateEditing"}));
            dispatch(hideQuickPanelAll());
            document.removeEventListener("keydown", onDelClick);
        }
    }

    let onMaterialSelect = id => {
        currentObjects.forEach((i, index) => {
            currentObjectsMaterialsIds[index].forEach((y, indexY) => {
                if(activeMatId == y) {
                    i.setMaterialAt(indexY, id);
                    i.update();
                }
            })
        })
        
        planner.mih()._scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    let onChangePreview = id => {
        setCurrentObjectsMatIdsRepeat(prevValue => {
            let newValue = prevValue;
            newValue.delete(activeMatId);
            newValue.add(id)
            return newValue;
        });
    };

    useEffect(() => {
        setObjectElevation(elevation);
        setObjectWidth(Math.round(width * 10) / 10);
        setObjectDepth(Math.round(depth * 10) / 10);
        setCurrentObjectsMatIdsRepeat(new Set(currentObjectsMaterialsIdsConcat.filter((item, index) => currentObjectsMaterialsIdsConcat.indexOf(item) !== index)))
        document.addEventListener("keydown", onDelClick);
        
        return () => document.removeEventListener("keydown", onDelClick);
    }, [elevation, width, depth, currentStateHistory[0].data])

    let onClickMergeButton = e => {
        if(isMerged) {
            planner.scene.unmergeCurrentGroup();
            dispatch(setActiveViewModeState({state: "stateEditing"}));
            dispatch(hideQuickPanelAll());
        } else {
            planner.scene.mergeCurrentGroup();
        }
        setIsMerged(!isMerged);
    }

    return (
        <>
        {
            currentDataType == "main" ?
            <div className={classes.planerWallsCont}>
                <div className={classes.planerWallsWrap}>
                    <div className={classes.title}>Group</div>
                    <div className={classes.cont}>
                    {
                        isMerged ?
                        <div className={classes.groupWr} onClick={onClickMergeButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="95" height="58" viewBox="0 0 95 58" fill="none">
                                <path d="M33 11L45 6V21L33 26V11Z" fill="#CBCBCB"/>
                                <path d="M63 11L75 6V21L63 26V11Z" fill="#CBCBCB"/>
                                <path d="M21 5L32.5 0L44 5L32.5 10L21 5Z" fill="#CBCBCB"/>
                                <path d="M51 5L62.5 0L74 5L62.5 10L51 5Z" fill="#CBCBCB"/>
                                <path d="M32 11L20 6V21L32 26V11Z" fill="#CBCBCB"/>
                                <path d="M62 11L50 6V21L62 26V11Z" fill="#CBCBCB"/>
                                <path d="M48 22L60 17V32L48 37V22Z" fill="#A1A1A1"/>
                                <path d="M36 16L47.5 11L59 16L47.5 21L36 16Z" fill="#A1A1A1"/>
                                <path d="M47 22L35 17V32L47 37V22Z" fill="#A1A1A1"/>
                                <path d="M9.5 8.5L18 13L16.5 14L8 9.5L5 11.5L0 4L13 6L9.5 8.5Z" fill="#444444"/>
                                <path d="M85.5 8.5L77 13L78.5 14L87 9.5L90 11.5L95 4L82 6L85.5 8.5Z" fill="#444444"/>
                                <path d="M46.4166 47.9701L46.4166 39.9702L48.4169 39.9701L48.417 47.9702L52.9168 47.9701L47.4172 57.4701L42.417 47.9702L46.4166 47.9701Z" fill="#444444"/>
                            </svg>
                            <div className={classes.groupText}>{translation["TEXT_UNGROUP"]}</div>
                        </div>
                        :
                        <div className={classes.groupWr} onClick={onClickMergeButton}>
                            <svg className={classes.groupIcon} width="95" height="58" viewBox="0 0 95 58" fill="none">
                                <path d="M33 11L45 6V21L33 26V11Z" fill="#CBCBCB"/>
                                <path d="M63 11L75 6V21L63 26V11Z" fill="#CBCBCB"/>
                                <path d="M21 5L32.5 0L44 5L32.5 10L21 5Z" fill="#CBCBCB"/>
                                <path d="M51 5L62.5 0L74 5L62.5 10L51 5Z" fill="#CBCBCB"/>
                                <path d="M32 11L20 6V21L32 26V11Z" fill="#CBCBCB"/>
                                <path d="M62 11L50 6V21L62 26V11Z" fill="#CBCBCB"/>
                                <path d="M48 22L60 17V32L48 37V22Z" fill="#A1A1A1"/>
                                <path d="M36 16L47.5 11L59 16L47.5 21L36 16Z" fill="#A1A1A1"/>
                                <path d="M47 22L35 17V32L47 37V22Z" fill="#A1A1A1"/>
                                <path d="M8.5 9.5L0 5L1.5 4L10 8.5L13 6.5L18 14L5 12L8.5 9.5Z" fill="#444444"/>
                                <path d="M86.5 9.5L95 5L93.5 4L85 8.5L82 6.5L77 14L90 12L86.5 9.5Z" fill="#444444"/>
                                <path d="M46.4166 48.5462L46.4166 56.5461L48.4169 56.5462L48.417 48.5461L52.9168 48.5462L47.4172 39.0462L42.417 48.5461L46.4166 48.5462Z" fill="#444444"/>
                            </svg>
                            <div className={classes.groupText}>{translation["TEXT_GROUP"]}</div>
                        </div>
                    }

                        <div className={classes.planerChangeSizesWrap}>
                            <div className={classes.planerChangeSizes}>
                                <ChangeSize mouseDown={mouseDown} valueChangeType={"width"} changeValue={onChangeSize} value={objectWidth} label={translation["WIDTH"]} />
                                <ChangeSize mouseDown={mouseDown} valueChangeType={"height"} changeValue={onChangeSize} value={objectHeight} label={translation["HEIGHT"]} />
                                <ChangeSize mouseDown={mouseDown} valueChangeType={"depth"} changeValue={onChangeSize} value={objectDepth} label={translation["DEPTH"]} />
                                <ChangeSize mouseDown={mouseDown} valueChangeType={"elevation"} changeValue={onChangeSize} value={objectElevation} label={translation["ELEVATION"]}/>
                            </div>
                            <div>
                                <div className={classes.changeSizesLockWr}>
                                    <div className={classes.changeSizesLockLine1}></div>
                                    <div className={classes.changeSizesLockLine2}></div>
                                    <div className={classes.changeSizesLockLine3}></div>


                                    <svg className={classes.zamokLock} viewBox="0 0 15 21" fill="none">
                                        <rect y="9" width="15" height="12" rx="2" fill="#444444"></rect>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M5.3006 5.47402L5.3006 9H2.00103V5.4898C2.00103 5.48978 2.00103 5.48981 2.00103 5.4898C2.00103 5.46359 2.00083 5.43378 2.0006 5.40073C1.99721 4.90361 1.98872 3.66059 2.58544 2.51478C2.92146 1.86955 3.46531 1.21546 4.32773 0.731498C5.19054 0.24732 6.25363 0 7.50001 0C8.74638 0 9.80947 0.24732 10.6723 0.731502C11.5347 1.21546 12.0785 1.86956 12.4146 2.51479C13.0112 3.66046 13.0028 4.90338 12.9994 5.4005C12.9992 5.43363 12.999 5.46344 12.999 5.4897V9H9.69941V5.47374C9.69942 4.93876 9.69943 4.2453 9.404 3.67801C9.27262 3.42573 9.10162 3.24215 8.87115 3.11282C8.64107 2.98371 8.23322 2.84211 7.50001 2.84211C6.7668 2.84211 6.35894 2.98371 6.12885 3.11282C5.89837 3.24216 5.72736 3.42575 5.59598 3.67802C5.30049 4.24542 5.30055 4.9391 5.3006 5.47402Z"
                                            fill="#444444"></path>
                                    </svg>

                                </div>
                            </div>
                        </div>

                        {
                            previewMaterials.length > 0 ?
                            <div className={classes.materialsGrid}>
                                { previewMaterials }
                            </div> :
                            null
                        }
                    </div>



                </div>
            </div>
            :
            currentDataType == "catalog" ? <Catalog /> : 
            currentDataType == "colorpicker" ? <ColorPicker matId={activeMatId} materialSelect={onMaterialSelect} changePreviewId={onChangePreview}/> :
            currentDataType == "material" ? <CatalogMaterials materialSelect={onMaterialSelect} changePreviewId={onChangePreview} matId={activeMatId} /> : null
        }
        </>
    );
    
};

export default StateSelectedGroup;