import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectQuickPanels } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import {createUseStyles} from "react-jss";
import { ClearRef } from './components/Clear';
import { RotateRef } from './components/Rotate';

const useStyle = createUseStyles({
    info_model_pop_wrap:{
        position: 'absolute',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)',
        '& img':{
            userSelect: "none",
            pointerEvents: "none"
        },

    },

    info_model_height:{
        display: 'none',
        position: 'absolute',
        background: '#ffffff',
        fontSize: 20,
        border: '1px solid #000',
        transform: 'translate(-50%, -50%)',
        padding: 5
    }
});


const HelperPlinth = props => {
    let quickPanels = useSelector(selectQuickPanels);
    let position = quickPanels.helper_plinth.position;

    let btnClear = useRef(null);
    let btnRotate = useRef(null);
    let textFieldInfo = useRef(null);

    let [panelPosition, setPanelPosition] = useState(position);

    let hideButtonsRotate = () => {
        btnClear.current.style.display = "none";
        textFieldInfo.current.style.display = 'block';
    }
    let showButtonsRotate = () => {
        textFieldInfo.current.style.display = "none";
        btnClear.current.style.display = "flex";
    }

    const classes = useStyle();

    return (
        <>
            <div className={classes.info_model_pop_wrap} style={{left: panelPosition.x, top: panelPosition.y}}>
                <ClearRef positionBtn={{x: 0, y: 0}} ref={btnClear}/>
                <div className={classes.info_model_height} ref={textFieldInfo}></div>
                <RotateRef radius={50} ref={btnRotate} position={panelPosition} textField={textFieldInfo} hide={hideButtonsRotate} show={showButtonsRotate} positionBtn={{y: 0, x: 50}}/>
            </div>
        </>
        
    )
}

export default HelperPlinth;