import { createSlice } from '@reduxjs/toolkit'

export const constructorSlice = createSlice({
  name: 'constr',
  initialState: { wallsHeight: 280, selectedWallSegmentHeight: 0, selectedCeilingHeight: 0, minWallsHeight: 0, maxWallsHeight: 400, isImagePlanUploaded: null },
  reducers: {
    changeWallsHeight: (state, action) => {
      state.wallsHeight = action.payload;
    },
    changeWallSegmentHeight: (state, action) => {
      state.selectedWallSegmentHeight = action.payload;
    },
    changeCeilingHeight: (state, action) => {
      state.selectedCeilingHeight = action.payload;
    },
    changeImagePlanUpload: (state, action) => {
      state.isImagePlanUploaded = action.payload;
    },
  },
})

export const selectWallsHeight = state => state.constr.wallsHeight;
export const selectMinWallsHeight = state => state.constr.minWallsHeight;
export const selectMaxWallsHeight = state => state.constr.maxWallsHeight;
export const selectWallSegmentHeight = state => state.constr.selectedWallSegmentHeight;
export const selectCeilingHeight = state => state.constr.selectedCeilingHeight;
export const selectIsImagePlanUploaded = state => state.constr.isImagePlanUploaded;

export const { changeWallsHeight, changeWallSegmentHeight, changeCeilingHeight, changeImagePlanUpload } = constructorSlice.actions; 

export default constructorSlice.reducer;