import React from 'react';
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    copyright_container_element_text: {
      fontSize: 12,
      margin: '0 0 10px 10px',
      '& a': {
        color: 'rgb(47, 47, 47)'
      }
    }
});

const Terms = props => {
    const classes = useStyles();
    let translation = useSelector(selectTranslation);
    return (
        <div className={classes.copyright_container_element_text}><a href="https://roomtodo.com/terms_of_service" target="_blank">{translation["BUTTON_TERMS_OF_USE"]}</a></div>
    )
}

export default Terms;
