import React, { useState, useRef, useEffect } from 'react';
import {createUseStyles} from "react-jss";
import { Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage, styleFitContainer  } from 'react-compare-slider';
import { useDispatch } from 'react-redux';
import { closeActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';

const useStyle = createUseStyles({
    compare_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        background: 'rgba(30, 28, 28, 0.9)'
    },
    compare_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    compare_popup_wr:{
        color: '#000',
        width: '100%',
        height: '100%'
    },
    compare_popup_cont:{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative'
    },
    compare_popup_login_wrap:{

    },
    compare_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    compare_popup_close_icon:{
        width: '100%'
    },
    swiper_slider_wr:{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        background: 'grey'
    },
    swiper_slider:{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    paginationWrap:{
        position: 'absolute',
        left: 0,
        bottom: 30,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 21px',
        zIndex: 1
    },
    paginationWr:{
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    pagination:{
        margin: '0 16px',
        '& span':{
            width: 20,
            height: 20,
            opacity: 1,
            background: '#fff',
            '&.$swiper-pagination-bullet-active':{
                background: '#9DD900',
            }
        }
    },
    prevBtn:{
        cursor: 'pointer',
        '&.$swiper-button-disabled':{
            opacity: '0.5',
            pointerEvents: 'none'
        },
        '&:hover path':{
            fill: '#ffd119'
        }
    },
    prevBtnArrow:{
        transform: 'scale(-1)',
        '& path':{
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        }
    },
    nextBtn:{
        cursor: 'pointer',
        '&.$swiper-button-disabled':{
            opacity: '0.5',
            pointerEvents: 'none'
        },
        '&:hover path':{
            fill: '#ffd119'
        }
    },
    nextBtnArrow:{
        '& path':{
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        }
    },
    shadowBot:{
        position: 'absolute',
        width: '100%',
        left: 0,
        bottom: '-30px',
        pointerEvents: 'none',
    },
    renderTypeWr:{

    },
    renderTypeText:{
        color: '#fff',
        fontSize: 38,
        '-webkit-text-stroke-width': '1px',
        '-webkit-text-stroke-color': 'rgba(0, 0, 0, 0.8)'
    },
    renderTypeSize:{
        color: '#fff',
        fontSize: 16,
        textAlign: 'right',
        letterSpacing: '-1px',
    },
    compare_drag_line:{
        width: '5px',
        height: '100%',
        background: '#9dd900',
        border: '1px solid #fff',
    },
    compare_drag_icon:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        cursor: 'ew-resize'
    },
    slider_img_text_1:{
        position: "absolute",
        left: 30,
        bottom: 50,
        fontSize: 30,
        fontWeight: "bold",

    },
    slider_img_text_2:{
        position: "absolute",
        right: 30,
        bottom: 50,
        fontSize: 30,
        fontWeight: "bold",
    }
});

const RenderExample = props => {
    const classes = useStyle();
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    let translation = useSelector(selectTranslation);

    let dispatch = useDispatch();

    const useSwiperRef = () => {
        const [wrapper, setWrapper] = useState(null);
        const ref = useRef(null);

        useEffect(() => {
            setWrapper(ref.current);
        }, []);

        return [
            wrapper,
            ref
        ]
    };
    const [paginationEl, paginationRef] = useSwiperRef();
    const MyCustomHandle = () => {
        return(
            <div className={classes.compare_drag_line}>
                <img className={classes.compare_drag_icon} src="/src_designer/images/icon_drag.svg"/>
            </div>
        )
    };

    let slider_items = [];

    switch_image.forEach((i, index) => {
        slider_items.push(
            <SwiperSlide key={index}>
                <ReactCompareSlider
                    style={{
                        ...styleFitContainer({
                            objectFit: 'contain',
                            objectPosition: 'center',
                        })
                    }}
                    handle={<MyCustomHandle />}
                    onlyHandleDraggable={true}
                    itemOne={
                        <>
                            <ReactCompareSliderImage src={R2D.URL.DOMAIN + i.img_1}  alt="Image one" />
                            <div className={classes.slider_img_text_1}>
                                <p className={classes.renderTypeText}>{ translation["RENDER_EXAMPLE_SCREENSHOT"] }</p>
                            </div>
                        </>
                        
                    }
                    itemTwo={
                        <>
                            <ReactCompareSliderImage src={R2D.URL.DOMAIN + i.img_2}  alt="Image two" />
                            <div className={classes.slider_img_text_2}>
                                <p className={classes.renderTypeText}>{ translation["RENDER_EXAMPLE_2K"] }</p>
                                <span className={classes.renderTypeSize}>1920 x 1080 px.</span>
                            </div>
                        </>
                    }
                />
            </SwiperSlide>
        )
    })

    return (
        <div className={classes.compare_popup_wrap +' '+ classes.compare_popup_wrap_show}>
            <div className={classes.compare_popup_wr}>
                <div className={classes.compare_popup_cont}>
                    <div className={classes.compare_popup_close} onClick={ e => dispatch(closeActivePopup({name: "renderExample"})) }>
                        <svg className={classes.compare_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                            <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                        </svg>
                    </div>
                    <div className={classes.swiper_slider_wr}>
                        <Swiper className={classes.swiper_slider}
                            modules={[Pagination, Navigation]}
                            spaceBetween={0}
                            slidesPerView={1}
                                allowTouchMove={false}
                                simulateTouch={false}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current;
                                swiper.params.navigation.nextEl = navigationNextRef.current;
                            }}
                            pagination={{
                                clickable: true,
                                el: paginationEl
                            }}>
                            { slider_items }

                            <div className={classes.paginationWrap}>

                                <img className={classes.shadowBot} src="/src_designer/images/shadow_compare.png"/>

                                <div className={classes.paginationWr}>
                                    <div className={classes.prevBtn} ref={navigationPrevRef}>
                                        <svg className={classes.prevBtnArrow} width="27" height="22" viewBox="0 0 27 22" fill="none">
                                            <path opacity="0.99" fillRule="evenodd" clipRule="evenodd" d="M15.0919 0L14.1045 0.966647L23.2476 9.95569H0.534842V11.3779H23.3426L14.0645 20.3591L15.0442 21.3333L25.009 11.6874L25.0165 11.6948L26.0039 10.7282L15.0919 0Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div className={classes.pagination} ref={paginationRef}></div>
                                    <div className={classes.nextBtn} ref={navigationNextRef}>
                                        <svg className={classes.nextBtnArrow} width="27" height="22" viewBox="0 0 27 22" fill="none">
                                            <path opacity="0.99" fillRule="evenodd" clipRule="evenodd" d="M15.0919 0L14.1045 0.966647L23.2476 9.95569H0.534842V11.3779H23.3426L14.0645 20.3591L15.0442 21.3333L25.009 11.6874L25.0165 11.6948L26.0039 10.7282L15.0919 0Z" fill="white"/>
                                        </svg>
                                    </div>

                                </div>

                            </div>

                        </Swiper>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default RenderExample;