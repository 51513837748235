import React, { useRef, useState } from 'react';
import {createUseStyles} from "react-jss";
import bgSky from './images/bg_sky.jpg'
import Lock from '../../../../../common/lock';
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../../../../../../../redux/features/language/languageSlice';
import { historyScrollSave, historyUpdate, selectActiveViewModeData, selectActiveViewModeState } from '../../../../../../../../redux/features/viewMode/viewModeSlice';
import { useEffect } from 'react';
import { planner, user } from '../../../../../../../../init';
import { selectUserProfileData  } from '../../../../../../../../redux/features/user/userSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import { setActivePopup } from '../../../../../../../../redux/features/popups/popupsSlice';
import { changeActivePano, selectActivePano, updateFreeIds } from '../../../../../../../../redux/features/common/commonSlice';

const useStyle = createUseStyles({
    cont:{
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd'
    },
    roll_out_left:{
        transform: "translateX(-100%)"
    },
    roll_out_right:{
        transform: "translateX(100%)"
    },
    wrap:{
        height: '100%',
        overflow: 'auto',
        background: '#fff',

    },
    wr:{
        padding: "15px 9px 15px 19px",
        display: 'grid',
        gridGap: 20
    },
    title: {
        width: '100%',
        height: '29px',
        backgroundColor: 'rgba(129, 129, 129, 0.9)',
        fontSize: '13px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '0 5px'
    },
    viewItemStandart:{
        width: '100%',
        height: 115,
        background: 'url('+bgSky+')',
        border: '1px solid #dddddd',
        position: 'relative',
        zIndex: 1,
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        gridGap: 7,
        cursor: 'pointer',
        '&:after':{
            content: "' '",
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(47, 47, 47, 0.7)',
            zIndex: '-1'
        },
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
    },
    viewItemIcon:{

    },
    planer_catalog_elem_overlay:{
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%'
    },
    help_bot_item:{
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        fontSize: 13,
        color: '#fff',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '& svg': {
            marginLeft: 10
        }
    },
    viewItem:{
        width: '100%',
        height: 115,
        border: '1px solid #dddddd',
        position: 'relative',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            border: '1px solid #ffd119'
        }
    },
    viewItemImg:{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        position: "absolute",
        left: 0,
        top: 0
    },
    viewItemLock:{
        border: '1px solid #ca0707',
    },
    activePano:{
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(47, 47, 47, 0.7)",
        '&:hover':{
            '& path:not($leftMouse)':{
                fill: '#ffd119'
            },
            '& $help_bot_item':{
                color: '#ffd119'
            },
            '& p':{
                color: '#ffd119'
            }
        }
    },
    leftMouse:{

    },
    preloadContainer:{
        width: '100%',
        height: '100%',
        position: "absolute",
        left: 0,
        top: 0,
        background: "rgba(255, 255, 255, .5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    rotateText:{
        position: "absolute",
        top: 20,
        color: "#fff"
    }
});

const StateEditing = () => {
    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);
    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;
    let savedScroll = currentStateHistory[currentStateHistory.length - 1].lastSavedScroll;
    let activePano = useSelector(selectActivePano);
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let userData = useSelector(selectUserProfileData);

    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    let activeId = null;

    let [activePanoId, setActivePanoId] = useState(planner.mih()._scene.skyboxId);

    let [rotation, setRotaion] = useState(activePano.rotation);

    let startRotation = 0;
    let startX = 0;
    var sensitivity = 0.5;
    let positionX = 0;

    let panorams = [];

    let [panoArray, setPanoArray] = useState();

    const classes = useStyle();

    let isPro = userData.plan == "pro" ? true : false;

    let onSelectPano = async ({e, id, rotation, previewImgSrc}) => {
        document.querySelector(".product_hover") && document.querySelector(".product_hover").remove();
        document.querySelector("." + classes.preloadContainer) && Array.from(document.querySelectorAll("." + classes.preloadContainer)).forEach(i => i.remove());
        activeId = id;
        let preloadContainer = document.createElement("div");
        preloadContainer.classList.add(classes.preloadContainer)
        let preload = document.createElement("div");
        preload.classList.add("preloader-cube");
        preloadContainer.append(preload);
        e.currentTarget.append(preloadContainer)
        await R2D.scene.setSkyboxMat(activeId);
        R2D.scene.setSkyboxRotation(rotation);
        R2D.scene.history.saveState();
        preloadContainer.remove();
        if(activeId == id) {
            dispatch(changeActivePano({ id, previewImgSrc: previewImgSrc, rotation}));
            setActivePanoId(activeId);
            setRotaion(0);
        }
        
    }

    let mouseEnter = (event, data) => {
        document.querySelector(".product_hover") && document.querySelector(".product_hover").remove();
        let product = event.target;
        let hoverWindow = document.createElement("div");
        hoverWindow.classList.add("product_hover");
        hoverWindow.innerHTML = data.name;
        document.querySelector("body").append(hoverWindow);
        hoverWindow.style.left = (product.getBoundingClientRect().left - hoverWindow.offsetWidth + 20) + "px";
        hoverWindow.style.top = (product.getBoundingClientRect().top + ((product.offsetHeight - hoverWindow.offsetHeight) / 2)) + "px";
    }

    let mouseLeave = () => document.querySelector(".product_hover") && document.querySelector(".product_hover").remove();

    let [freePanoIds, setFreePanoIds] = useState([]);
    
    let rightPanelData = useRef(null);

    let activeAnimationClass = isAdded ? classes.roll_out_right : classes.roll_out_left;

    function startRotatePano(e)
    {
        startX = e.clientX;
        startRotation = rotation;
        document.addEventListener("mousemove", rotatePano);
        document.addEventListener("mouseup", endRotatePano);
    }

    function rotatePano(e)
    {
        positionX = e.clientX;
        let distance = startX - positionX;

        let newRotation = Math.round(startRotation + distance * sensitivity + 360) % 360;
        R2D.scene.setSkyboxRotation(newRotation);
        setRotaion(newRotation);
        dispatch(changeActivePano({...activePano, rotation: newRotation}));
    }
    
    function endRotatePano(e)
    {
        document.removeEventListener("mousemove", rotatePano);
        document.removeEventListener("mouseup", endRotatePano);
        R2D.scene.history.saveState();
    }

    let mainDiv = useRef(null);

    useEffect(() => {
        setTimeout(async () => {
            let panorams = await user.loadPano();
            rightPanelData.current && rightPanelData.current.classList.remove(activeAnimationClass);
            dispatch(historyUpdate(panorams));
        }, 100);
        
    }, []);

    useEffect(() => {
        if(currentDataPanel && currentDataPanel.products) {
            currentDataPanel.products.forEach(elem => {
                if(elem.tags.includes("free")) setFreePanoIds(prev => [...prev, elem]);
                if(elem.tags.includes("skybox_default")) planner.scene.setDefaultPanoId(elem.id);
                panorams.push(
                    <div key={elem.id} className={classes.viewItemStandart} onMouseLeave={mouseLeave} onMouseEnter={e => mouseEnter(e, elem)}>
                        <LazyLoadImage src={R2D.URL.DOMAIN + elem.source.images.preview} className={classes.viewItemImg} placeholder={<div className='preloader-cube'></div>}/>
                        {
                            elem.tags.includes("free") || elem.tags.includes("skybox_default") || isPro || activeViewModeState == "stateRenderMake" ?
                                activePanoId == elem.id || activePanoId == 0 && elem.tags.includes("skybox_default") ?
                                    <>
                                        <div className={classes.activePano} onMouseDown={startRotatePano}>
                                            <p className={classes.rotateText}> { rotation }° </p>
                                            <svg width="67" height="42" viewBox="0 0 67 42" fill="none" className={classes.viewItemIcon}>
                                                <path d="M0 21.0735C0 16.5687 5.09113 12.5608 13 10V14.2263C12.4303 14.4314 11.8813 14.6444 11.3543 14.8647C8.6132 16.0102 6.65554 17.2723 5.44397 18.4694C4.24683 19.6523 4 20.5296 4 21.0735C4 21.6174 4.24683 22.4947 5.44397 23.6776C6.65554 24.8746 8.6132 26.1368 11.3543 27.2823C15.873 29.1707 22.0067 30.5282 29 30.9417V34.9483C12.6264 34.0296 0 28.1678 0 21.0735Z" fill="#FCFFFF"/>
                                                <path d="M46 34.0664C58.3101 31.996 67 26.9589 67 21.0735C67 16.5687 61.9089 12.5608 54 10V14.2263C54.5697 14.4314 55.1187 14.6444 55.6457 14.8647C58.3868 16.0102 60.3445 17.2723 61.556 18.4694C62.7532 19.6523 63 20.5296 63 21.0735C63 21.6174 62.7532 22.4947 61.556 23.6776C60.3445 24.8746 58.3868 26.1368 55.6457 27.2823C52.9513 28.4083 49.6827 29.3456 46 30.0065V34.0664Z" fill="#FCFFFF"/>
                                                <path d="M29 23L39.5 32.5L29 42V23Z" fill="#FCFFFF"/>
                                            </svg>
                                            <div className={classes.help_bot_item}>
                                                <div>{ translation["TEXT_ROTATE_PANORAMA"] }</div>
                                                <svg width="19" height="26" viewBox="0 0 19 26" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#fff" d="M4.39176 0.5H14.6071C16.7746 0.5 18.5 2.18908 18.5 4.23597V21.764C18.5 23.8099 16.7745 25.5 14.6071 25.5H4.39176C2.22554 25.5 0.5 23.81 0.5 21.764V4.23597C0.5 2.189 2.22546 0.5 4.39176 0.5Z" stroke="#828282"/>
                                                    <rect x="9" width="1" height="11" fill="#828282"/>
                                                    <rect x="17" y="11" width="1" height="15" transform="rotate(90 17 11)" fill="#828282"/>
                                                    <path fillRule="evenodd" className={classes.leftMouse} clipRule="evenodd" d="M4.68837 2H9C9.01951 3.67815 9 10.9992 9 10.9992C7.05106 11.0301 4.59176 11.004 1.99998 10.9992V4.51191C1.99998 3.05816 3.20298 2 4.68837 2Z" fill="#2F2F2F"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className={classes.planer_catalog_elem_overlay} onClick={e => onSelectPano({e, id: elem.id, rotation: 0, previewImgSrc: elem.source.images.preview})}></div> 
                            :
                            <>
                                <div className={classes.planer_catalog_elem_overlay} onClick={e => dispatch(setActivePopup({name: "payPro"}))}></div>
                                <Lock/>
                            </>
                        }
                    </div>
                )
            });
            mainDiv.current.scrollTop = savedScroll;
            mainDiv.current.addEventListener("scroll", onScroll);
            setPanoArray(panorams);
            return () => mainDiv.current && mainDiv.current.removeEventListener("scroll", onScroll);
        }
    }, [currentDataPanel, activePanoId, rotation, isPro]);

    useEffect(() => isPro == false && dispatch(changeActivePano({id: 0, rotation: 0, previewImgSrc: ""})), [isPro]);

    useEffect(() => dispatch(updateFreeIds(freePanoIds)), [freePanoIds]);

    useEffect(() => {
        setRotaion(activePano.rotation);
        setActivePanoId(activePano.id);
    }, [activePano]);

    let scrollTimer = -1;

    let onScroll = e => {
        if (scrollTimer != -1) clearTimeout(scrollTimer);
        scrollTimer = setTimeout(() => scrollFinished(), 500);
    }

    let scrollFinished = () => dispatch(historyScrollSave(mainDiv.current ? mainDiv.current.scrollTop : 0));

    return (
        <>      
            <div className={classes.cont + ' ' + activeAnimationClass} ref={ rightPanelData }>
                {
                    currentDataPanel ?
                    <div className={classes.wrap} ref={ mainDiv }>
                        <div className={classes.title}>{ translation["PANO_TITLE"] }</div>
                        <div className={classes.wr}>
                            { panoArray }
                        </div>
                    </div> :
                    null
                }
            </div> 
        </>
    );
};

export default StateEditing;