import React from 'react';
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles({
    blueButton:{
        background: '#66acf4',
        color: '#fff',
        fontSize: 13,
        padding: '5px 9px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: 'pointer',
        '&:hover':{
            background: '#FFCC00'
        }
    }
});

const BlueButton = props => {
    const classes = useStyle();
    return (
        <div onClick={e => props.onClickData ? props.onClick(props.onClickData) : props.onClick()} className={classes.blueButton}>{props.label}</div>
    );
};

export default BlueButton;