import React from 'react';
import {createUseStyles} from "react-jss";
import Lock from "../lock/index.jsx";

const useStyle = createUseStyles({
    editMatItemWr:{
        width: 100,
        height: 100,
        position: 'relative',
        outline: 'rgb(217, 217, 217) solid 2px',
        '&:hover': {
            outline: 'rgb(255, 204, 0) solid 2px',
        }
    },
    editMatItemWrLock:{
        width: 100,
        height: 100,
        position: 'relative',
        border: '1px solid #ca0707',
        '&:hover':{
            border: '1px solid #cdcdcd',
        }
    },
    
    
    editMatItem:{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all ease .2s',
        boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.23)',
        '&:hover':{
            '& $editMat':{
                transform: 'translateY(0%)'
            }
        }
    },
    editMat:{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 30,
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        background: 'rgba(68, 68, 68, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateY(100%)',

    },
    editMatImg:{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        
    },
    editMatEditIcon:{
        width: 16,
        height: 16,
        cursor: 'pointer',
        marginRight: 10,
        '& rect':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& rect':{
                fill: '#ffd119'
            }
        }
    },
});

const EditPlinthItem = props => {
    const classes = useStyle();

    return (
        <div className={
            props.lock? classes.editMatItemWrLock : classes.editMatItemWr
        } onClick={props.onClick}>
            <div className={classes.editMatItem}>
                <div className={classes.editMatImg} style={{background: `url(${props.src})`, backgroundSize: "contain"}}></div>
            </div>
            {props.lock && <Lock/>}
        </div>
    );
};

export default EditPlinthItem;