import React from 'react';
import {createUseStyles} from "react-jss";
import iconZamok from './../lock/images/icon_zamok_red_lock.svg'

const useStyle = createUseStyles({
    zamok: {
        top: '-4px',
        right: '-4px',
        width: 20,
        position: 'absolute',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
    },
});

const Lock = () => {
    const classes = useStyle();
    return (
        <img className={classes.zamok} src={iconZamok}/>
    );
};

export default Lock;