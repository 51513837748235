import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveViewMode } from '../../../../../redux/features/viewMode/viewModeSlice';
import { createUseStyles } from "react-jss";
import { selectTranslation } from '../../../../../redux/features/language/languageSlice';
import { useEffect } from 'react';
import { useRef } from 'react';

const useStyles = createUseStyles({
    left_bar_btn:{
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        height: '29px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        marginBottom: '1px',
        position: "relative",
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            '& path, & circle, & rect':{
                fill: '#ffd119',
            }
        }
    },
    left_bar_btn_active:{
        background: '#BAE51F',
        position: "relative",
        '& path, & circle, & rect':{
            fill: '#424242',
        },
        height: '29px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        marginBottom: '1px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
    },
    left_bar_btn_hover:{
        position: "absolute",
        background: "#81e7ff",
        transition: "all 0s ease-in-out 1s",
        display: "none",
        fontSize: 12,
        width: "max-content",
        zIndex: 9,
        padding: "2px 7px",
        transition: "all .1s ease",
        userSelect: "none"
    },
    left_bar_btn_overlay:{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0
    }
});

const LeftButton = props => {
    const classes = useStyles();
    let activeViewMode = useSelector(selectActiveViewMode);
    let translation = useSelector(selectTranslation);
    let timeoutId = null;
    let mouseDown = false;

    let mouseEnter = e => {
        if(!mouseDown) {
            let hoverDiv = e.target.closest("." + classes.left_bar_btn) ? e.target.closest("." + classes.left_bar_btn).querySelector("." + classes.left_bar_btn_hover) : e.target.closest("." + classes.left_bar_btn_active).querySelector("." + classes.left_bar_btn_hover);
            hoverDiv.style.left = e.clientX + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
            timeoutId = setTimeout(() => hoverDiv.style.display = "block", 1000);
        }
    }

    let mouseMove = e => {
        if(!mouseDown) {
            let hoverDiv = e.target.closest("." + classes.left_bar_btn) ? e.target.closest("." + classes.left_bar_btn).querySelector("." + classes.left_bar_btn_hover) : e.target.closest("." + classes.left_bar_btn_active).querySelector("." + classes.left_bar_btn_hover);
            hoverDiv.style.left = e.clientX + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
        }
    }

    let mouseLeave = e => {
        if(!mouseDown) {
            clearTimeout(timeoutId);
            let hoverDiv = e.target.closest("." + classes.left_bar_btn) ? e.target.closest("." + classes.left_bar_btn).querySelector("." + classes.left_bar_btn_hover) : e.target.closest("." + classes.left_bar_btn_active).querySelector("." + classes.left_bar_btn_hover);
            hoverDiv.removeAttribute("style");
        }
        
    }

    let mouseUpBtn = e => props.onClick();

    let mouseUpDocument = e => {
        mouseDown = false;
        document.removeEventListener("mouseup", mouseUpDocument);
    }

    let mouseDownBtn = e => {
        mouseLeave(e);
        document.addEventListener("mouseup", mouseUpDocument);
        mouseDown = true;
    }

    useEffect(() => {
        setTimeout(() => {
            if(divOverlay.current) {
                divOverlay.current.addEventListener("mouseenter", mouseEnter);
                divOverlay.current.addEventListener("mousemove", mouseMove);
                divOverlay.current.addEventListener("mouseup", mouseUpBtn);
                divOverlay.current.addEventListener("mousedown", mouseDownBtn);
                divOverlay.current.addEventListener("mouseleave", mouseLeave);
            }
        }, 200)
    }, [])

    let divOverlay = useRef(null);

    return(
        <div className={activeViewMode==props.viewMode ? classes.left_bar_btn_active : classes.left_bar_btn} >
            { props.img }
            <div className={classes.left_bar_btn_hover}>{translation[props.textHover]}</div>
            <div className={classes.left_bar_btn_overlay} ref={divOverlay}></div>
        </div>
    )
}

export default LeftButton;