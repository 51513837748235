import React, { useRef } from 'react';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { changeLockState } from '../../../../../redux/features/common/commonSlice';
import { selectTranslation } from '../../../../../redux/features/language/languageSlice';

const useStyle = createUseStyles({
    lock_main:{
        position: 'absolute',
        cursor: 'pointer',
        '& svg': {
            width: 16,
        }
        
    },
    lock_main_hover:{
        position: "absolute",
        background: "#81e7ff",
        transition: "all 0s ease-in-out 1s",
        display: "none",
        fontSize: 12,
        width: "max-content",
        padding: "2px 7px",
        transition: "all .1s ease",
        userSelect: "none"
    },
    lock_main_overlay:{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0
    }
});

const LockSize = props => {
    const classes = useStyle();
    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);

    let timeoutId = null;

    let mouseEnter = e => {
        let hoverDiv = mainDiv.current.querySelector("." + classes.lock_main_hover);
        hoverDiv.style.right = "calc(100% + " + (mainDiv.current.getBoundingClientRect().left - e.clientX + 20 + "px") + ")";
        hoverDiv.style.top = e.clientY - mainDiv.current.getBoundingClientRect().top  + 10 + "px";
        timeoutId = setTimeout(() => hoverDiv.style.display = "block", 1000);
    }

    let mouseMove = e => {
        let hoverDiv = mainDiv.current.querySelector("." + classes.lock_main_hover);
        hoverDiv.style.right = "calc(100% + " + ( mainDiv.current.getBoundingClientRect().left - e.clientX + 20 + "px") + ")";
        hoverDiv.style.top = e.clientY - mainDiv.current.getBoundingClientRect().top  + 10 + "px";
    }

    let mouseLeave = e => {
        clearTimeout(timeoutId);
        mainDiv.current.querySelector("." + classes.lock_main_hover).removeAttribute("style");
    }

    let mainDiv = useRef(null);

    let mouseUpLock = e => {
        dispatch(changeLockState(!props.lockState));
        mouseLeave(e);
    }

    return (
        <div className={classes.lock_main} ref={mainDiv} style={{right: props.right, top: props.top}} >
            {
                props.lockState? 
                <svg viewBox="0 0 15 21" fill="none">
                    <rect y="9" width="15" height="12" rx="2" fill="#444444"></rect>
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M5.3006 5.47402L5.3006 9H2.00103V5.4898C2.00103 5.48978 2.00103 5.48981 2.00103 5.4898C2.00103 5.46359 2.00083 5.43378 2.0006 5.40073C1.99721 4.90361 1.98872 3.66059 2.58544 2.51478C2.92146 1.86955 3.46531 1.21546 4.32773 0.731498C5.19054 0.24732 6.25363 0 7.50001 0C8.74638 0 9.80947 0.24732 10.6723 0.731502C11.5347 1.21546 12.0785 1.86956 12.4146 2.51479C13.0112 3.66046 13.0028 4.90338 12.9994 5.4005C12.9992 5.43363 12.999 5.46344 12.999 5.4897V9H9.69941V5.47374C9.69942 4.93876 9.69943 4.2453 9.404 3.67801C9.27262 3.42573 9.10162 3.24215 8.87115 3.11282C8.64107 2.98371 8.23322 2.84211 7.50001 2.84211C6.7668 2.84211 6.35894 2.98371 6.12885 3.11282C5.89837 3.24216 5.72736 3.42575 5.59598 3.67802C5.30049 4.24542 5.30055 4.9391 5.3006 5.47402Z"
                        fill="#444444"></path>
                </svg> :
                <svg viewBox="0 0 15 22" fill="none">
                    <rect y="10" width="15" height="12" rx="2" fill="#444444"></rect>
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M5.3006 5.47402L5.3006 7H2.00103V5.4898C2.00103 5.46359 2.00083 5.43378 2.0006 5.40073C1.99721 4.90361 1.98872 3.66059 2.58544 2.51478C2.92146 1.86955 3.46531 1.21546 4.32773 0.731498C5.19054 0.24732 6.25363 0 7.50001 0C8.74638 0 9.80947 0.24732 10.6723 0.731502C11.5347 1.21546 12.0785 1.86956 12.4146 2.51479C13.0112 3.66047 13.0028 4.90338 12.9994 5.4005C12.9992 5.43363 12.999 5.46344 12.999 5.4897V10H9.69941V5.47374C9.69942 4.93876 9.69943 4.2453 9.404 3.67801C9.27262 3.42573 9.10162 3.24215 8.87115 3.11282C8.64107 2.9837 8.23322 2.84211 7.50001 2.84211C6.7668 2.84211 6.35894 2.98371 6.12885 3.11282C5.89837 3.24216 5.72736 3.42575 5.59598 3.67802C5.30049 4.24542 5.30055 4.9391 5.3006 5.47402Z"
                        fill="#444444"></path>
                </svg>
            }
            <div className={classes.lock_main_hover}>{translation["HINT_LOCK_DIMENSIONS"]}</div>
            <div className={classes.lock_main_overlay} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onMouseMove={mouseMove} onMouseUp={mouseUpLock}></div>
            
        </div>
    );
};

export default LockSize;