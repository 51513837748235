import React, { useEffect, useState } from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup, selectPopups, setPopupData } from '../../../../redux/features/popups/popupsSlice';
import cx from 'classnames';
import { useRef } from 'react';
import { selectUserProfileData, userProfileDataLoad } from '../../../../redux/features/user/userSlice';
import { selectLanguages, selectTranslation } from '../../../../redux/features/language/languageSlice';
import { user } from '../../../../init';
import { setActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice';

const useStyle = createUseStyles({
    done_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(30, 28, 28, 0.9)'
    },
    done_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    done_popup_wr:{
        color: '#000'
    },
    done_popup_cont:{
        color: '#000',
        backgroundColor: '#fff',
        border: '1px solid #000',
        overflow: 'hidden',
        position: 'relative',
        width: 755
    },
    done_popup_login_wrap:{
        width: '100%'
    },
    done_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    done_popup_close_icon:{
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    done_popup_top:{
        background: '#72C4FF',
        height: 210,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #000'
    },
    done_popup_top_icon:{

    },
    done_popup_bot:{
        padding: '17px 10px 30px',
        textAlign: 'center',
        background: '#D9D9D9'
    },
    done_popup_title:{
        color: '#373737',
        fontSize: 32,
        fontWeight: 300,
        textAlign: 'center',
        letterSpacing: '-2px',
        marginBottom: 38,
    },
    done_popup_items:{
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 111px)',
        gridGap: 11,
        justifyContent: 'center',
        marginBottom: 24
    },
    done_popup_item:{
        background: '#fff',
        boxShadow: '0px 4px 4px rgba(255, 255, 255, 0.25)',
        width: 111,
        height: 194,
        display: 'grid',
        alignItems: 'center',
        padding: "12px 10px",
        position: 'relative',
        cursor: 'pointer',
        border: '1px solid #fff',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '&:hover':{
            border: '1px solid #9dd900'
        }
    },
    done_popup_item_active:{
        '&:before':{
            content: '" "',
            position: 'absolute',
            top: '-2%',
            left: '-2%',
            width: '104%',
            height: '104%',
            border: '1px solid #fff',
            boxShadow: 'inset 0px 0px 0px 4px #9dd900'
        }
    },

    done_popup_item_top:{

    },
    done_popup_item_credits:{
        color: '#515151',
        fontSize: 38,
        lineHeight: '0.9',
        marginBottom: 10
    },
    done_popup_item_input:{
        width: '100%',
        border: '1px solid #D2E5EF',
        height: 39,
        padding: '0 4px',
        textAlign: 'center',
        fontSize: 38,
        outline: 'none',
        color: '#515151',
        '-moz-appearance': 'textfield',
        '&::-webkit-input-placeholder':{
            color: '#D7D7D7'
        },
        '&::-webkit-outer-spin-button':{
            '-webkit-appearance': 'none',
            margin: 0
        },
        '&::-webkit-inner-spin-button':{
            '-webkit-appearance': 'none',
            margin: 0
        },

    },
    done_popup_item_input_text:{
        position: 'absolute',
        bottom: 'calc(100% + 8px)',
        left: 0,
        fontSize: 12,
        color: '#485258'
    },
    done_popup_item_credits_text:{
        color: '#373737',
        fontSize: 13
    },
    done_popup_item_line:{
        opacity: '0.1',
        background: '#000',
        height: 1
    },
    done_popup_item_bot:{

    },
    done_popup_item_price:{
        fontSize: 18,
        color: '#2D2D2D',
        marginBottom: 10,
        '& span':{
            fontSize: 29,
            transform: 'translateY(3px)',
            display: 'inline-block',
            '& sup':{
                fontSize: 11,
                transform: 'translateY(-4px)',
                display: 'inline-block',
                marginLeft: 3
            }
        }
    },
    done_popup_item_price_text:{
        fontSize: 13,
        color: '#4F4F4F',
    },

    done_popup_btn:{
        color: '#2B2B2B',
        fontSize: 16,
        padding: '10px 30px',
        background: '#9DD900',
        textTransform: 'uppercase',
        cursor: 'pointer',
        display: 'inline-block',
        marginBottom: 12,
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '&:hover':{
            background: '#ffd119'
        }
    },
    done_popup_btn_terms:{
        display: 'inline-block',
        color: '#66ACF4',
        fontSize: 13,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover':{
            textDecoration: 'none',
        }
    },
    done_iframe:{
        border: 'none',
        display: 'inherit'
    }

});
const BuyCredits = props => {
    const classes = useStyle();
    let dispatch = useDispatch();

    let popups = useSelector(selectPopups);
    let userData = useSelector(selectUserProfileData);
    let languages = useSelector(selectLanguages);

    let translation = useSelector(selectTranslation);

    let currLanguage = languages.find(i => i.current == 1);

    let iframeSrc = popups.buyCredits.data;

    let [itemsArray, setItemsArray] = useState([]);
    let [itemsToShow, setItemsToShow] = useState([]);
    let [itemsToShowComponents, setItemsToShowComponents] = useState([]);
    let [selectedPlan, setSelectedPlan] = useState(null);

    let [customNumOfCredits, setCustomNumOfCredits] = useState(0);

    let selectItem = e => {
        if(e.currentTarget.dataset.planId == "custom") e.currentTarget.querySelector("input").focus();
        if(e.currentTarget.dataset.planId == selectedPlan) return;
        document.querySelector("." + classes.done_popup_item).classList.remove(classes.done_popup_item_active);
        setSelectedPlan(e.currentTarget.dataset.planId);
    }

    let [popupHeight, setPopupHeight] = useState(0);

    let [customCreditsPrice, setCustomCreditsPrice] = useState("0.00");
    let [customCreditsPricePerCredit, setCustomCreditsPricePerCredit] = useState("0.00");

    let onBuyCredits = e => {
        let numberOfCredits = 0;
        if(selectedPlan == "custom") numberOfCredits = customCredits.current.value;
        else numberOfCredits = itemsArray.find(i => i.id == selectedPlan).numOfCredits;
        setPopupHeight(buyCreditsRef.current.offsetHeight);
        let token = R2D.Storage.load('r2d_token');
        dispatch(setPopupData({name: "buyCredits", data: `https://dev.roomtodo.com/api/credits/get_form?credits=${numberOfCredits}&lang=${currLanguage.code}&token=${token}`}));
        window.addEventListener("message", messageListener, false);
    }

    function messageListener(event)
    {
        let dataObj;

        if ( event.data.source && event.data.source.startsWith("react") || event.data && event.data.startsWith('/*framebus*/')) return;

        try
        {
            dataObj = JSON.parse(event.data);
        } catch ( error ) {
            console.error("Error parse JSON string!");
            console.log(event.data);
            return;
        }
        if (dataObj.action == 'credits_buy_finish')
        {
            window.removeEventListener('message', messageListener);

            messageFinishListener();   
        }
    }

    function messageFinishListener() {

        dispatch(setPopupData({name: "buyCredits", data: `/src_designer/html/pay_notification/wait_${currLanguage.code}.html`}))

        window.addEventListener("message", receiveMessage);

        let checkInterval = setInterval(checkUserCredits, 2000);

        let checkNum = 0;

        async function checkUserCredits()
        {
            try {
                let response = await user.checkIsLogged();
                if(response.logged == true) {
                    if (user.credits() != userData.credits) {
                        clearInterval(checkInterval);
                        dispatch(setPopupData({name: "buyCredits", data: `/src_designer/html/pay_notification/ok_${currLanguage.code}.html`}));
                        dispatch(userProfileDataLoad());
                    }
                    else {
                        checkNum += 1;
                        if (checkNum > 5) {
                            clearInterval(checkInterval);
                            dispatch(setPopupData({name: "buyCredits", data: `/src_designer/html/pay_notification/error_${currLanguage.code}.html`}))
                        }
                    }
                }
            } catch(e) {
                console.log("Error while user data load: ", e)
                clearInterval(checkInterval);
                dispatch(setPopupData({name: "buyCredits", data: `/src_designer/html/pay_notification/error_${currLanguage.code}.html`}))
            }
        }

        function receiveMessage(event)
        {
            let dataObj;

            if (event.data && (event.data.source == "react-devtools-bridge" || event.data.startsWith('/*framebus*/'))) return;

            try
            {
                dataObj = JSON.parse(event.data);
            } catch ( error ) {
                console.error("Error parse JSON string!");
                console.log(event.data);
                return;
            }

            if (dataObj.action == 'close')
            {
                clearInterval(checkInterval);
                window.removeEventListener("message", receiveMessage, false);
                dispatch(closeActivePopup({name: "buyCredits"}))
            }
        }  
    };

    let onChangeCustomCredits = e => {
        let credits = +e.target.value;
        if(credits == 0) {
            setCustomNumOfCredits(credits);
            setCustomCreditsPricePerCredit("0.00")
            setCustomCreditsPrice("0.00");
            return;
        }
        let pricePerCreditNum = itemsArray.find(i => credits > 0 && credits < 6 && i.numOfCredits == 5 || 
            credits >= 45 && credits < 50 && i.numOfCredits == 45 || 
            credits > 101 && credits < 200 && i.numOfCredits == 101 || 
            credits >= 200 && credits < 500 && i.numOfCredits == 200 || 
            credits >= 500 && i.numOfCredits == 500 || 
            i.numOfCredits == credits).pricePerCredit;

        let priceTotal = (Math.round(credits * pricePerCreditNum * 1000) / 1000).toFixed(2) + "";
        setCustomCreditsPricePerCredit(pricePerCreditNum + "")
        setCustomCreditsPrice(priceTotal);
        setCustomNumOfCredits(credits);
    }
    
    let loadPricesEventHandler = e => {
        let prices = JSON.parse(e.data.data);
        let items = [];
        let itemsToShow = [];
        let idNum = 1;
        for(let i in prices) {
            let totalPrice = prices[i] * i + "";
            if(totalPrice.split(".")[1] && totalPrice.split(".")[1].length < 2) totalPrice = (+totalPrice).toFixed(2);
            let newItem = {
                id: idNum,
                numOfCredits: i,
                priceTotal: totalPrice,
                pricePerCredit: prices[i]
            };
            items.push(newItem);
            if(newItem.numOfCredits == 5 || newItem.numOfCredits == 20 || newItem.numOfCredits == 50 || newItem.numOfCredits == 100) itemsToShow.push(newItem);
            idNum++;
        }
        setItemsArray(items);
        setItemsToShow(itemsToShow);

        let itemsToShowArray = [];

        itemsToShow.forEach(i => {
            itemsToShowArray.push(
                <div className={cx(classes.done_popup_item, {[classes.done_popup_item_active]:selectedPlan == i.id})} data-plan-id={i.id} key={i.id} onClick={selectItem}>
                    <div className={classes.done_popup_item_top}>
                        <div className={classes.done_popup_item_credits}>{ i.numOfCredits }</div>
                        <div className={classes.done_popup_item_credits_text}>{ translation["RENDER_BUY_CREDITS"] }</div>
                    </div>
                    <div className={classes.done_popup_item_line}></div>
                    <div className={classes.done_popup_item_bot}>
                        <div className={classes.done_popup_item_price}>
                            $ <span>{ i.priceTotal.split(".")[0] }<sup>{i.priceTotal.split(".")[1] }</sup></span>
                        </div>
                        <div className={classes.done_popup_item_price_text}>${ i.pricePerCredit } / { translation["RENDER_CREDIT"] }</div>
                    </div>
                </div>
            )
        });

        setItemsToShowComponents(itemsToShowArray);
    }

    useEffect(() => {
        let itemsToShowArray = [];

        itemsToShow.length > 0 && itemsToShow.forEach(i => {
            itemsToShowArray.push(
                <div className={cx(classes.done_popup_item, {[classes.done_popup_item_active]:selectedPlan == i.id})} data-plan-id={i.id} key={i.id} onClick={selectItem}>
                    <div className={classes.done_popup_item_top}>
                        <div className={classes.done_popup_item_credits}>{ i.numOfCredits }</div>
                        <div className={classes.done_popup_item_credits_text}>{ translation["RENDER_BUY_CREDITS"] }</div>
                    </div>
                    <div className={classes.done_popup_item_line}></div>
                    <div className={classes.done_popup_item_bot}>
                        <div className={classes.done_popup_item_price}>
                            $ <span>{ i.priceTotal.split(".")[0] }<sup>{i.priceTotal.split(".")[1] }</sup></span>
                        </div>
                        <div className={classes.done_popup_item_price_text}>${ i.pricePerCredit } / { translation["RENDER_CREDIT"] }</div>
                    </div>
                </div>
            )
        });

        setItemsToShowComponents(itemsToShowArray);
    }, [selectedPlan])

    useEffect(() => {
        let url = R2D.makeURL(R2D.URL.DOMAIN, "/api/credits/packets");
        let loader = new R2D.XHRLoader();

        loader.addEventListener(Event.COMPLETE, loadPricesEventHandler);
        loader.load(url, null, 'POST', null, null, true);
    }, []);

    let buyCreditsRef = useRef(null);
    let customCredits = useRef(null);

    return (
        <div className={classes.done_popup_wrap +' '+ classes.done_popup_wrap_show}>
            <div className={classes.done_popup_wr}>
                <div className={classes.done_popup_cont}>
                    {
                        iframeSrc ?
                        <div>
                            <div className={classes.done_popup_close} onClick={e => dispatch(setPopupData({name: "buyCredits", data: ""}))} id="js_close_popup">
                                <svg className={classes.done_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                                    <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                                </svg>
                            </div>
                            <iframe src={iframeSrc} className={classes.done_iframe} datatype="window-iframe" id="dialog-iframe" width={"100%"} height={popupHeight + "px"}></iframe>
                        </div>
                        
                        :
                        <div ref={buyCreditsRef} className={classes.done_popup_login_wrap}>
                            <svg className={classes.shadow_planer_pop_top_left} width="147" height="144" viewBox="0 0 147 144" fill="none">
                                <path opacity="0.8" d="M0 0H147L0 144V0Z" fill="url(#paint0_linear_723_3148)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_723_3148" x1="-7.00001" y1="133" x2="121.908" y2="7.90513" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#67B6EE"/>
                                        <stop offset="1" stopColor="#2B81BD" stopOpacity="0"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                            <div className={classes.done_popup_close} onClick={e => dispatch(closeActivePopup({name: "buyCredits"}))} id="js_close_popup">
                                <svg className={classes.done_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                                    <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                                </svg>
                            </div>
                            <div className={classes.done_popup_login_wr}>
                                <div className={classes.done_popup_top}>
                                    <img className={classes.done_popup_top_icon} src="/src_designer/images/icon_buy_credits.svg"/>
                                </div>
                                <div className={classes.done_popup_bot}>
                                    <div className={classes.done_popup_title}>
                                        { translation["RENDER_BUY_SELECT"] }
                                    </div>

                                    <div className={classes.done_popup_items}>

                                        { itemsToShowComponents }

                                        <div className={cx(classes.done_popup_item, {[classes.done_popup_item_custom]: true}, {[classes.done_popup_item_active]:selectedPlan == "custom"})} data-plan-id={"custom"} onClick={selectItem} >
                                            <div className={classes.done_popup_item_input_text}>{ translation["RENDER_BUY_VARIANT"] }:</div>
                                            <div className={classes.done_popup_item_top}>
                                                <div className={classes.done_popup_item_credits}>
                                                    <input type="text" ref={customCredits} className={classes.done_popup_item_input} placeholder="XX" onChange={onChangeCustomCredits} value={customNumOfCredits ? customNumOfCredits : ""}/>
                                                </div>
                                                <div className={classes.done_popup_item_credits_text}>{ translation["RENDER_BUY_CREDITS"] }</div>
                                            </div>
                                            <div className={classes.done_popup_item_line}></div>
                                            <div className={classes.done_popup_item_bot}>
                                                <div className={classes.done_popup_item_price}>
                                                    $ <span>{ customCreditsPrice.split(".")[0] }<sup>{customCreditsPrice.split(".")[1] }</sup></span>
                                                </div>
                                                <div className={classes.done_popup_item_price_text}>$ { customCreditsPricePerCredit } / { translation["RENDER_CREDIT"] }</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={classes.done_popup_btn} onClick={onBuyCredits}>{ translation["RENDER_BUY_BUY"] }</div>
                                    {/* <div>
                                        <div className={classes.done_popup_btn_terms} onClick={e => window.open('https://roomtodo.com/en/terms_of_service/', '_blank')}>{ translation["RENDER_BUY_TERMS"] }</div>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default BuyCredits;