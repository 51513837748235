import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveViewMode, setActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice.js';
import Ruler from './addToolsButtons/Ruler.jsx';
import ToCenter from './addToolsButtons/ToCenter.jsx';
import ZoomInOut from './addToolsButtons/ZoomInOut.jsx';
import View2D from './viewModesButtons/View2D.jsx';
import View3D from './viewModesButtons/View3D.jsx';
import ViewConstr from './viewModesButtons/ViewConstr.jsx';
import ViewWalk from './viewModesButtons/ViewWalk.jsx';
// import FullScreen from './addToolsButtons/FullScreen.jsx';
import {createUseStyles} from 'react-jss'
import { selectUserProfileData } from '../../../../redux/features/user/userSlice.js';
import { selectTips } from '../../../../redux/features/tips/tipsSlice.js';
import Tip from '../tips/Tip.jsx';

const useStyles = createUseStyles({
    left_bar_wrap:{
        position: 'absolute',
        left: '0',
        zIndex: '1',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        pointerEvents: 'none'
    },
    left_bar_wr:{
        pointerEvents: 'auto',
        display: 'grid',
        gridGap: '24px'
    },
    viewModes:{
        position: "relative"
    }
});

const LeftPanel = props => {

    let activeViewMode = useSelector(selectActiveViewMode);
    let userProfileData = useSelector(selectUserProfileData);
    let tips = useSelector(selectTips);
    let activeViewModeState = useSelector(setActiveViewModeState);

    const classes = useStyles();
    return (
        <>
            <div className={classes.left_bar_wrap}>
                {
                    activeViewMode == "tileConfig" ?
                    <div className={classes.left_bar_wr}>
                        <ZoomInOut /> 
                    </div>
                    :
                    <div className={classes.left_bar_wr}>
                        <div className={classes.viewModes}>
                            <ViewConstr />
                            <View2D />
                            <View3D />
                            <ViewWalk />
                            {
                                activeViewModeState != "constr" && userProfileData.tips && userProfileData.tips != 0 && !tips.constructorMode.showed ? <Tip name={"constructorMode"} showTime={1000} arrow={{position: "top", direction: "left"}} text={"TUT_CONSTRUCTION_MODE"} position={{x: 40, y: 60, from: "left"}}/> : null
                            }
                        </div>
                        {
                            activeViewMode == "view2d" || activeViewMode == "constr" ?
                            <>
                                <ZoomInOut /> 
                                <ToCenter />
                                <Ruler />
                            </>
                            : null
                        }
                        {
                            activeViewMode == "view3d" ?
                            <>
                                <ZoomInOut /> 
                                <ToCenter />
                            </> 
                            : null
                        }
                    </div>
                }
            </div>

            {/* <FullScreen/> */}

        </>
        
    )
}

export default LeftPanel;