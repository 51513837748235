import React from 'react';
import ListCatalog from './lists/ListCatalog.jsx';
import ListDragCatalog from './lists/ListModels.jsx';
import ListDragAddCatalog from './lists/ListMaterials.jsx';
import { useSelector } from 'react-redux';
import { selectActiveViewModeData } from '../../../../../../../../../redux/features/viewMode/viewModeSlice.js';
import ColorPicker from '../../../general/colorPicker/ColorPicker.jsx';

const StateEditing = props => {

    let activeViewModeData = useSelector(selectActiveViewModeData);
    
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;

    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1];
    
    return (
        <>
            {
                currentDataPanel ?
                    currentDataPanel.type == "catalog" ? <ListCatalog /> : 
                    currentDataPanel.type == "model" ? <ListDragCatalog /> :
                    currentDataPanel.type == "material" ? <ListDragAddCatalog /> :
                    currentDataPanel.type == "colorpicker" ? <ColorPicker onDragColor={true}/> 
                    : null
                : null
            }
        </>
    )
}

export default StateEditing;