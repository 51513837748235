import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyUpdate, selectActiveViewMode, selectActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice';
import { planner, user } from '../../../../init';
import { setActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice';
import { changeUndoRedoConstr, changeUndoRedoScene, changeUndoRedoTileConfig, selectIsUndoRedoConstr, selectIsUndoRedoScene, selectIsUndoRedoTileConfig } from '../../../../redux/features/topPanel/topPanelSlice';
import { setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { hideQuickPanelAll, selectQuickPanels, showQuickPanel } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import { useEffect, useRef } from 'react';
import { selectTips } from '../../../../redux/features/tips/tipsSlice';
import { selectUserProfileData } from '../../../../redux/features/user/userSlice';

const CenterPanel = props => {

    let activeViewMode = useSelector(selectActiveViewMode);
    let dispatch = useDispatch();
    let wallConfigurator = planner.scene.getConfigurator();
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let tips = useSelector(selectTips);
    let userProfileData = useSelector(selectUserProfileData);

    let isUndoRedoConstr = useSelector(selectIsUndoRedoConstr);
    let isUndoRedoScene = useSelector(selectIsUndoRedoScene);
    let isUndoRedoTileConfig = useSelector(selectIsUndoRedoTileConfig);
    let quickPanels = useSelector(selectQuickPanels);

    const onEvent = e => {

        if(e.target.tagName != "CANVAS") return;

        if(e.type == 'mouseup' && activeViewModeState == "stateRenderMake") return;

        if(activeViewMode == "constr") {
            
            let result = planner.constr[e.type](e.nativeEvent);
            
            if(result) {

                if(result.state == "stateSelectedCover") {
                    dispatch(setActiveViewModeState({state: result.state, data: {added: true, type: "main"}}));
                }
                else if(result.state == "stateSetWallHeight") {
                    dispatch(setActivePopup({name: "setWallHeight"}));
                }
                else if(result.state == "stateSelectedRoom") {
                    dispatch(showQuickPanel({name: "delete", position: result.quickPanel}));
                }
                else if(result.state == "stateSelectedArea") {
                    dispatch(setActiveViewModeState({state: result.state, data: {added: true, type: "main"}}));
                }
                else if(result.state == "stateSelectedRuler") {
                    dispatch(showQuickPanel({name: "delete", position: result.quickPanel}));
                }
                else if(result.state == "stateAlignDrawing") {
                    if(result.action == "alignerWidthSet") dispatch(setActivePopup({name: "alignerWidthSet"}));
                }
                else if(result.state == "stateSelectedPoint") {
                    dispatch(showQuickPanel({name: "delete", position: result.quickPanel}));
                    dispatch(setActiveViewModeState({state: "stateEditing"}));
                }
                else if(result.state == "stateEditing" || result.state == "stateMergeCovers") {
                    if(result.state == "stateMergeCovers") dispatch(setActivePopup({name: "confirm", type: "merge_covers"}));
                    dispatch(hideQuickPanelAll());
                    dispatch(setActiveViewModeState({state: "stateEditing"}));
                    document.removeEventListener("keydown", props.onKeyDownConstr);
                }

                if(e.type != "mousemove") dispatch(changeUndoRedoConstr({canUndo: planner.constr.canUndo(), canRedo: planner.constr.canRedo()}));
            }
        }
        else {
            let result = planner.scene[e.type](e.nativeEvent);

            if(result) {
                if(result.state == "stateSelectedConstr") {
                    let currObj = planner.mih()._scene.currentView3DObject;
                    let constructionData = currObj.getConstructorElementData();
                    let id = R2D.ObjectConstructor3D.__checkMaterialId(currObj.type, constructionData.getMaterials());

                    if(Array.isArray(id)) id = id[0];

                    dispatch(setActiveViewModeState({state: result.state, data: {added: true, type: "main", matId: id}}));
                    dispatch(showQuickPanel({name: result.type == "topFrame" ? "helper_plinth" : "helper", position: result.quickPanel}));
                }

                if(result.state == "stateSelectedWall") {
                    let currObj = planner.mih()._scene.currentView3DObject;
                    let constructionData = currObj.getConstructorElementData();
                    let topPlinth = constructionData.topPlinth;
                    let bottomPlinth = constructionData.bottomPlinth;

                    let wallMatId = R2D.ObjectConstructor3D.__checkMaterialId(currObj.type, planner.scene.getCurrentConstrMaterial());
                    let moldMatId = R2D.ObjectConstructor3D.__checkMaterialId('topPlinth', topPlinth.getMaterials()[0]);
                    let plinthMatId = R2D.ObjectConstructor3D.__checkMaterialId('bottomPlinth', bottomPlinth.getMaterials()[0]);

                    dispatch(setActiveViewModeState({state: result.state, data: {added: true, type: "main", matId: {wallMatId, moldMatId, plinthMatId}}}));
                    dispatch(showQuickPanel({name: "helper", position: result.quickPanel}));
                }

                if(result.state == "stateDraggingProduct") {
                    dispatch(hideQuickPanelAll());
                }

                if(result.state == "stateSelectedProduct") {
                    setTimeout(() => {
                        dispatch(setActiveViewModeState({state: result.state, data: {added: true, type: "main"}}));

                        if(quickPanels.helper_model.status) dispatch(hideQuickPanelAll());

                        else {
                            result.quickPanel.y -= 60;
                            dispatch(showQuickPanel({name: "helper_model", position: result.quickPanel})); 
                        }
                    }, 0)
                }

                if(result.state == "stateSelectedGroup") {
                    
                    let currGroup = planner.mih()._scene.currentGroup;

                    if(currGroup) {
                        if(activeViewModeState == "stateSelectedGroup") {
                        dispatch(historyUpdate({
                            elevation: planner.scene.getCurrentGroupElevation(),
                            width: currGroup.getWidth(),
                            height: currGroup.getHeight(),
                            depth: currGroup.getDepth(),
                    }))
                    } else {
                        dispatch(setActiveViewModeState({state: result.state, data: {added: true, type: "main", data: {
                            elevation: planner.scene.getCurrentGroupElevation(),
                            width: currGroup.getWidth(),
                            height: currGroup.getHeight(),
                            depth: currGroup.getDepth(),
                        
                    }}}));
                    }
                    if(currGroup.merged) {
                            if(quickPanels.helper_model.status) {
                                if(!e.ctrlKey) dispatch(hideQuickPanelAll());
                            }
                            else {
                                result.quickPanel.y -= 60;
                                dispatch(showQuickPanel({name: "helper_model", position: result.quickPanel})); 
                            }
                    } else {
                        dispatch(hideQuickPanelAll());
                    }
                    }
                }
                
                if(result.state == "stateSelectedPlinth") {

                    let currObj = planner.mih()._scene.currentView3DObject;
                    let constructionData = currObj.getConstructorElementData();

                    let matId = R2D.ObjectConstructor3D.__checkMaterialId(currObj.type, constructionData.getMaterial());

                    dispatch(setActiveViewModeState({state: result.state, data: {added: true, type: "main", matId}}));
                    dispatch(showQuickPanel({name: "helper_plinth", position: result.quickPanel}));
                }

                if(result.state == "stateEditing") {
                    dispatch(setActiveViewModeState({state: result.state}));
                    dispatch(hideQuickPanelAll());
                }
                
                if(e.type != "mousemove") dispatch(changeUndoRedoScene({canUndo: planner.scene.canUndo(), canRedo: planner.scene.canRedo()}));
            }
        }
    }

    let onEventTileConfig = e => {

        let tileEditor = wallConfigurator.getTileEditor();

        let result = tileEditor[e.type](e.nativeEvent);
        
        if(result) {
            if(result.state == "stateSelectedDelimetr") {
                dispatch(showQuickPanel({name: "delete", position: result.quickPanel}));
            }

            if(e.type != "mousemove") dispatch(changeUndoRedoTileConfig({canUndo: tileEditor.history.canUndo(), canRedo: tileEditor.history.canRedo()}));
        }
        
    }

    let tileConfig = useRef(null);

    let plannerDiv = useRef(null);
    
    useEffect(() => {
        if(activeViewMode == 'tileConfig') {
            if(tileConfig.current.hasChildNodes()) tileConfig.current.children[0].remove(); 
            tileConfig.current.append(wallConfigurator.getDomElement()) 
        }
    }, [activeViewMode]);

    return (
        <>
            {
                activeViewMode == 'tileConfig' ?
                <>
                    <div id="tileConfig" ref={tileConfig} onMouseDown={onEventTileConfig} onMouseMove={onEventTileConfig} onMouseUp={onEventTileConfig}></div>
                    <div id="planner" style={{display: 'none'}}></div>
                </> :
                <>
                    <div id="tileConfig" style={{display: 'none'}}></div>
                    <div id="planner" onMouseUp={onEvent} onMouseMove={onEvent} onMouseDown={onEvent} ref={plannerDiv}></div>
                </>
                
            }
        </>
    )
}

export default CenterPanel;