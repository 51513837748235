import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveViewModeData, historyAdd, historyScrollSave } from '../../../../../../../../../../redux/features/viewMode/viewModeSlice';
import {createUseStyles} from "react-jss";
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const useStyle = createUseStyles({
    planer_catalog_cont:{
        borderLeft: '1px solid #cdcdcd',
        background: '#F1F1F1',
        height: '100%',
        display: 'grid',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '270px',
        overflow:'auto',
        transition: 'all 0.2s cubic-bezier(0.19, 1, 0.22, 1)',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planer_cat_item:{
        display: 'grid',
        gridTemplateColumns: '90px auto',
        alignItems: 'center',
        borderBottom: '1px solid #cdcdcd',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: 'pointer',
        minHeight: 38,
        '&:hover':{
            background: '#fff'
        },
        '@media screen and (max-width: 1280px)':{
            fontSize: 12
        }
    },
    planer_cat_item_img_wr:{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
});
const ListCatalog = props => {

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;
    let savedScroll = currentStateHistory[currentStateHistory.length - 1].lastSavedScroll;

    let arrayList = [];

    let dispatch = useDispatch();

    let catalogClick = async data => {
        if(data.type == "product" && data.privateMaterialType != 6) {
            dispatch(historyAdd({type: data.privateMaterialType == 1 ? "material" : "model", urlList: data.urlList, categoryId: data.id, privateMaterialType: data.privateMaterialType, data: null, added: true, lastSavedScroll: 0}));

        } else if(data.privateMaterialType == 6) {
            dispatch(historyAdd({added: true, type: "colorpicker", name: data.name, categoryId: data.id, privateMaterialType: data.privateMaterialType}));
        }
        else dispatch(historyAdd({type: "catalog", data: data.nodes, added: true, lastSavedScroll: 0}))
    }

    const classes = useStyle();

    if(currentDataPanel) {
        currentDataPanel.forEach(e => {
            arrayList.push(
                <div className={classes.planer_cat_item} key={e.id} onClick={ event => catalogClick(e) }>
                    <div className={classes.planer_cat_item_img_wr}>
                        <img src={R2D.URL.DOMAIN + e.icon} className={classes.planer_cat_item_img}/>
                    </div>
                    <div className={classes.planer_cat_item_title} dangerouslySetInnerHTML={{__html: e.name}}></div>
                </div>
            )
        });
    }

    const variants = {
        visible: { transform:  "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }

    let scrollTimer = -1;

    function onScroll() {
      if (scrollTimer != -1) clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => scrollFinished(), 1000);
    }

    let scrollFinished = () => dispatch(historyScrollSave(mainDiv.current ? mainDiv.current.scrollTop : 0));

    let mainDiv = useRef(null);

    useEffect(() => {
        mainDiv.current.scrollTop = savedScroll;
        mainDiv.current.addEventListener("scroll", onScroll);
    }, [currentDataPanel]);

    return (
        <>
            {
                currentDataPanel ?
                <motion.div transition={{ ease: "easeOut", duration: .1 }} key={currentStateHistory.length} initial="hidden" animate="visible" exit={"exit"} variants={variants} ref={mainDiv} className={classes.planer_catalog_cont} >
                    { arrayList }
                </motion.div>
                : null
            }
        </>
    )
}

export default ListCatalog;