import React, { useRef, useState } from 'react';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { hideQuickPanelAll, selectQuickPanels } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import { planner } from '../../../../init';
import { RotateRef } from './components/Rotate';
import { useDispatch } from 'react-redux';
import { selectActiveViewModeData, setActiveViewModeState, updateCurrentProductSize } from '../../../../redux/features/viewMode/viewModeSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';

const useStyle = createUseStyles({
    info_model_pop_wrap:{
        position: 'absolute',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)',
        '& img':{
            userSelect: "none",
            pointerEvents: "none",
            userDrag: 'none'  
        },

    },
    info_model_pop_move:{
        height: 32,
    },
    info_model_pop_item_cont:{
        display: 'flex',
    },
    info_model_pop_item_wr:{
        width: 32,
        height:32,
        background: '#fff',
        position: 'relative',
        '&:last-child':{
            '& $info_model_pop_item':{
                borderRight: '1px solid #000'
            }
        },
        '&:hover':{
            '& $info_model_more_wr':{
                display: "flex",
                transform: 'translateY(0%)',
                boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)',
            }
        }
    },
    info_model_pop_item:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        borderRight: '1px solid rgb(217, 217, 217)',
        transition: 'transform 0.9s cubic-bezier(.19,1,.22,1)',
        position: "relative",
        '& img':{
            maxWidth: '70%',
            maxHeight: '70%',
        },
        '&:hover':{
            background: '#ffd119'
        }
    },
    icon_info_more:{
        position: 'absolute',
        width: 10,
        right: 0,
        bottom: 0,
    },
    info_model_more_wrap:{
        position: 'absolute',
        zIndex: '-1',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        //overflow: 'hidden',
    },
    info_model_more_wr:{
        background: '#fff',
        border: '1px solid #000',
        display: 'none',
        transform: 'translateY(-110%)',
        boxShadow: '0 0 0px 0px rgba(0, 0, 0, 0.25)',
        //transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
    },
    info_model_more:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: "relative",
        width: 32,
        height: 32,
        borderRight: '1px solid rgb(217, 217, 217);',
        //transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '& img':{
            maxWidth: '70%',
            maxHeight: '70%',
        },
        '&:last-child':{
            borderRight: 0
        },
        '&:hover':{
            background: '#ffd119'
        }
    },
    info_model_btn:{
        position: 'absolute',
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #000',
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)',
        userSelect: 'none',
        //transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '&:hover':{
            background: '#ffd119'
        },
        '& img':{

        },
        
    },
    info_model_bottom:{
        display: 'flex',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    info_model_height:{
        display: 'none',
        position: 'absolute',
        background: '#ffffff',
        fontSize: 20,
        border: '1px solid #000',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        padding: 5,
        width: "max-content"
    },
    info_model_btn_hover:{
        position: "absolute",
        background: "#81e7ff",
        display: "none",
        zIndex: 9,
        fontSize: 12,
        width: "max-content",
        padding: "2px 7px"
    },
    info_model_btn_overlay:{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0
    }
});

const HelperModel = () => {
    let dispatch = useDispatch();
    let quickPanels = useSelector(selectQuickPanels);
    let position = quickPanels.helper_model.position;

    let info_bottom = useRef(null);

    let translation = useSelector(selectTranslation);

    var pointPrev = null;

    let mih = planner.mih();
    let currentObjectView3d = mih._scene.currentGroup ? mih._scene.currentGroup : mih._scene.currentView3DObject;

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;

    let elemSize = currentStateHistory[0].data;

    let btnMoveY = useRef(null);
    let textFieldInfo = useRef(null);
    let btnRotate = useRef(null);
    let mainDiv = useRef(null);
    let btnDragPanel = useRef(null);
    let [mouseDown, setMouseDown] = useState(false);

    let [panelPosition, setPanelPosition] = useState(position);

    let hideButtonsElevate = () => info_bottom.current.style.display = btnRotate.current.style.display = 'none';

    let showButtonsElevate = () => info_bottom.current.style.display = btnRotate.current.style.display ='flex';

    let hideButtonsRotate = () => {
        info_bottom.current.style.display = btnMoveY.current.style.display = 'none';
        textFieldInfo.current.style.display = 'block';
    }

    let showButtonsRotate = () => {
        info_bottom.current.style.display = btnMoveY.current.style.display ='flex';
        textFieldInfo.current.style.display = "none";
    }

    let timeoutId = null;
    let pointClick = 0;

    let startElevateModel = event => {
        setMouseDown(true);
        clearTimeout(timeoutId);
        event.target.closest("." + classes.info_model_main).querySelector("." + classes.info_model_btn_hover).removeAttribute("style");
        pointPrev = {
            x:event.clientX,
            y:event.clientY
        };

        document.addEventListener('mousemove', onElevateModel);
        document.addEventListener('mouseup', stopElevateModel);

        hideButtonsElevate();
        textFieldInfo.current.style.display = 'block';
        
        textFieldInfo.current.textContent = planner.units.valueToString(currentObjectView3d.y);

        var viewportOffset = btnMoveY.current.getBoundingClientRect();
        var top = viewportOffset.top;

        pointClick = pointPrev.y - top;
    }

    let onElevateModel = event => {
        var dy = event.clientY - pointPrev.y;
        var py = Math.round(currentObjectView3d.y - dy);

        if ( py < 0 ) py = 0;
        if ( currentObjectView3d.y == py ) return;

        mainDiv.current.style.top = event.clientY + (btnMoveY.current.offsetHeight - pointClick) + 50 + 16 - btnMoveY.current.offsetHeight + 'px';
        textFieldInfo.current.textContent = planner.units.valueToString(py);
        

        currentObjectView3d.y = py;
        currentObjectView3d.update();

        dispatch(updateCurrentProductSize({...elemSize, elevation: py}));

        pointPrev = {
            x:event.clientX,
            y:event.clientY
        };

        if (mih._scene.currentView3DObject && mih._scene.currentView3DObject.objectType == "product") {
            mih._ruler3d.update(mih._productHelper.getView3DObjects(), mih._scene.currentView3DObject);
            mih._ruler3d.appendFromConstructor(mih._constructor.getLines());
        }
        mih._ruler3d.findRules(mih._scene.currentGroup || mih._scene.currentView3DObject);
    }

    let stopElevateModel = event => {
        pointPrev = null;

        showButtonsElevate();
        setMouseDown(false);
        
        textFieldInfo.current.style.display = 'none';

        document.removeEventListener('mousemove', onElevateModel);
        document.removeEventListener('mouseup', stopElevateModel);

        if (mih._scene.currentView3DObject && mih._scene.currentView3DObject.type == R2D.ProductType.MODEL && mih._scene.currentView3DObject.getForWall() ) {
            mih._constructor.pickElement(mih._scene.currentView3DObject.sceneObject.objectId);
            mih._constructor.dropElement(R2D.Scene.getObjectDataForWallElement(mih._scene.currentView3DObject.sceneObject), 10);
        }
        mih._scene.history.saveState();

        setPanelPosition({x: mainDiv.current.style.left.replace('px', ''), y: mainDiv.current.style.top.replace('px', '')});
        
    }

    let onDeleteModel = e => {
        planner.scene.removeCurrentModel();
        dispatch(hideQuickPanelAll());
        dispatch(setActiveViewModeState({state: "stateEditing"}))
    }

    let restBtn = null;

    function onDragPanelStart(e)
    {

        document.addEventListener('mousemove', onDragPanelMove);
        document.addEventListener('mouseup', onDragPanelStopMove);

        var viewportOffset = btnDragPanel.current.getBoundingClientRect();
        var top = viewportOffset.top;
        restBtn = (btnDragPanel.current.offsetHeight - (e.clientY - top)) - 16;

    }

    function onDragPanelMove(event)
    {
        mainDiv.current.style.left = event.clientX + 32 + 16 + "px"; 
        mainDiv.current.style.top = event.clientY + restBtn + 'px';
    }

    function onDragPanelStopMove(event)
    {
        document.removeEventListener('mousemove', onDragPanelMove);
        document.removeEventListener('mouseup', onDragPanelStopMove);
        setPanelPosition({x: mainDiv.current.style.left.replace('px', ''), y: mainDiv.current.style.top.replace('px', '')});
    }

    

    let mouseEnter = e => {
        if(!mouseDown) {
            let hoverDiv = e.target.closest("." + classes.info_model_main).querySelector("." + classes.info_model_btn_hover);
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
            timeoutId = setTimeout(() => hoverDiv.style.display = "block", 1000);
        }
    }

    let mouseMove = e => {
        if(!mouseDown) {
            let hoverDiv = e.target.closest("." + classes.info_model_main).querySelector("." + classes.info_model_btn_hover);
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
        }
        
    }

    let mouseLeave = e => {
        clearTimeout(timeoutId);
        e.target.closest("." + classes.info_model_main).querySelector("." + classes.info_model_btn_hover).removeAttribute("style")
    };



    const classes = useStyle();
    return (
        <div className={classes.info_model_pop_wrap} ref={mainDiv} style={{left: position.x, top: position.y}}>

            <RotateRef ref={btnRotate} radius={75} textField={textFieldInfo} hide={hideButtonsRotate} show={showButtonsRotate} position={panelPosition} positionBtn={{x: 75, y: 0}}/>
            
            <div className={classes.info_model_bottom} ref={info_bottom}>
                <div className={classes.info_model_pop_move} onMouseDown={onDragPanelStart} ref={btnDragPanel}>
                    <img src="/src_designer/images/icon_info_model_move.svg" />
                </div>
                <div className={classes.info_model_pop_item_cont}>
                    <div className={classes.info_model_pop_item_wr}>
                        <div className={classes.info_model_pop_item} >
                            <img src="/src_designer/images/icon_flip_1.svg"/>
                        </div>
                        <img src="/src_designer/images/icon_info_more.svg" className={classes.icon_info_more}/>
                        <div className={classes.info_model_more_wrap} >
                            <div className={classes.info_model_more_wr} >
                                <div className={classes.info_model_more + " " + classes.info_model_main} >
                                    <img src="/src_designer/images/icon_flip_1.svg"/>
                                    <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_FLIP_X"]}</div>
                                    <div className={classes.info_model_btn_overlay} onClick={R2D.scene.currentGroup ? planner.scene.flipCurrentGroupX : planner.scene.flipCurrentModelX} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
                                </div>
                                <div className={classes.info_model_more + " " + classes.info_model_main}>
                                    <img style={{'width' : '21px'}} src="/src_designer/images/icon_flip_2_new.svg"/>
                                    <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_FLIP_Z"]}</div>
                                    <div className={classes.info_model_btn_overlay} onClick={R2D.scene.currentGroup ? planner.scene.flipCurrentGroupZ : planner.scene.flipCurrentModelZ} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.info_model_pop_item_wr}>
                        <div className={classes.info_model_pop_item + " " + classes.info_model_main}>
                            <img src="/src_designer/images/icon_coppy.svg"/>
                            <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_DUPLICATE"]}</div>
                            <div className={classes.info_model_btn_overlay} onClick={R2D.scene.currentGroup ? planner.scene.duplicateCurrentGroup : planner.scene.duplicateCurrentModel} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
                        </div>
                    </div>

                    <div className={classes.info_model_pop_item_wr} >
                        <div className={classes.info_model_pop_item + " " + classes.info_model_main}>
                            <img src="/src_designer/images/icon_trash.svg"/>
                            <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_DELETE"]}</div>
                            <div className={classes.info_model_btn_overlay} onClick={onDeleteModel} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.info_model_height} ref={textFieldInfo}></div>

            <div className={classes.info_model_btn + " " + classes.info_model_main} ref={btnMoveY} style={{left: 0, top: -50}}>
                <img src="/src_designer/images/icon_move_y.svg"/>
                <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_ELEVATION"]}</div>
                <div className={classes.info_model_btn_overlay} onMouseDown={startElevateModel} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
            </div>

        </div>
    );
};

export default HelperModel;