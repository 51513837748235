import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../../../../../../../../../redux/features/language/languageSlice';
import { planner, user } from '../../../../../../../../../../init';
import { historyScrollSave, historyUpdate, selectActiveViewModeData } from '../../../../../../../../../../redux/features/viewMode/viewModeSlice';
import { createUseStyles } from "react-jss";
import { useDispatch } from 'react-redux';
import { setActivePopup } from '../../../../../../../../../../redux/features/popups/popupsSlice';
import cx from 'classnames';
import { selectIsLogged, selectUserProfileData, userProfileDataLoad } from '../../../../../../../../../../redux/features/user/userSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { motion } from 'framer-motion';
import { selectTips } from '../../../../../../../../../../redux/features/tips/tipsSlice';
import Tip from '../../../../../../../tips/Tip';

const useStyle = createUseStyles({
    planer_catalog_cont:{
        borderLeft: '1px solid #cdcdcd',
        background: '#F1F1F1',
        height: '100%',
        display: 'grid',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '270px',
        overflow:'auto',
        transition: 'all 0.2s cubic-bezier(0.19, 1, 0.22, 1)',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planer_add_elem_lock:{
        border: '1px solid #ca0707',
        '&:hover':{
            border: '1px solid #cdcdcd',
        },
    },
    planer_catalog_elem:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '108px',
        width: '108px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        userSelect: 'none',
        marginTop: 10,
        '& img:not($planer_catalog_prem_zamok, $planer_catalog_prem_add_img)':{
            maxWidth: '100%',
            maxHeight: '100%'
        },
        '@media screen and (max-width: 1280px)':{
            height: 80
        }
    },
    planer_catalog_elem_add: {
        outline: '1px solid #d9d9d9',
        cursor: 'pointer',
        '&:hover':{
            background: '#fcc749',
            '& $planer_catalog_prem_add_text':{
                opacity: 1
            }
        },
    },
    planer_catalog_elem_overlay:{
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%'
    },
    planer_catalog_title:{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '29px',
        backgroundColor: 'rgba(129, 129, 129, 0.9)',
        zIndex: '2',
        fontSize: '13px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '0 5px'
    },
    planer_catalog_elem_wrap:{
        paddingTop: '29px',
        height: '100%',
        overflow: 'auto',
        background: '#fff'
    },
    planer_catalog_elem_wr:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '10px'
    },
    planer_catalog_prem_add_text:{
        fontSize: 10,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        opacity:0,
    },
    planer_catalog_prem_add_img:{
        maxWidth: '60%',
        maxHeight: '60%'
    },
    planer_catalog_prem_zamok:{
        width: 16,
        height: 16,
        position: 'absolute',
        top: '-3px',
        right: '-6px',
        cursor: 'pointer',
        '& rect':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& rect':{
                fill: '#ffd119'
            }
        }
    },
    planer_catalog_elem_upload:{
        borderBottom: "1px solid rgb(178, 178, 178)",
        paddingBottom: 10,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: "wrap"
    },
    planer_catalog_elem_custom:{
        "&:hover $planer_catalog_elem_custom_delete": {
            opacity: 1
        }
    },
    planer_catalog_elem_custom_delete:{
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
        opacity: 0,
        zIndex: 9
    }
});

const ListMaterials = props => {

    let dispatch = useDispatch();

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;
    let savedScroll = currentStateHistory[currentStateHistory.length - 1].lastSavedScroll;

    let tips = useSelector(selectTips);
    let userProfileData = useSelector(selectUserProfileData);

    let translation = useSelector(selectTranslation);
    
    var timeStart = useMemo(() => Date.now(), []);

    let [materialsListState, setMaterialsListState] = useState(null);
    let [customMaterialsListState, setCustomMaterialsListState] = useState(null);

    let isLogged = useSelector(selectIsLogged);

    let mouseEnter = (event, data) => {
        document.querySelector(".product_hover") ? document.querySelector(".product_hover").remove() : null;
        let product = event.target;
        let hoverWindow = document.createElement("div");
        hoverWindow.classList.add("product_hover");
        hoverWindow.innerHTML = data.name;
        document.querySelector("body").append(hoverWindow);
        hoverWindow.style.left = (product.getBoundingClientRect().left - hoverWindow.offsetWidth + 20) + "px";
        hoverWindow.style.top = (product.getBoundingClientRect().top + ((product.offsetHeight - hoverWindow.offsetHeight) / 2)) + "px";
    }

    let mouseLeave = () => document.querySelector(".product_hover") ? document.querySelector(".product_hover").remove() : null;

    const classes = useStyle();

    let onCustomMatAdd = e => user.isPro() ? dispatch(setActivePopup({name: "uploadCustom"})) : dispatch(setActivePopup({name: "payPro"}));

    let timer = null;
    let dragTimeout = null;

    let onMouseDownProduct = (e, id) => {
        timer = Date.now();
        dragTimeout = setTimeout(() => e.nativeEvent.button == 0 ? planner.scene.dragProductFromCatalog(id) : null, 100)   
    }

    let materialMouseUp = e => Date.now() - timer < 100 && clearTimeout(dragTimeout);

    useEffect(async () => {
        mainDiv.current.scrollTop = savedScroll;
        mainDiv.current.addEventListener("scroll", onScroll);
        let loadedData = await user.loadRightPanelData(currentStateHistory[currentStateHistory.length - 1].urlList, R2D.controller.getCountry());
        dispatch(historyUpdate(loadedData));
        setTimeout(planner.apiScene.dispatchEvent(new Event(planner.apiScene.TIPS_UPLOAD_CUSTOM_MAT, {})), 1500);
    }, []);

    useEffect(() => {
        let materialsList = [];
        let customMaterialsList = [];

        currentDataPanel && currentDataPanel.products.forEach(elem => {
            if(elem.material_owner) {
                customMaterialsList.push(
                    <div className={classes.planer_catalog_elem + " " + classes.planer_catalog_elem_custom} key={elem.id} >
                        <LazyLoadImage src={R2D.URL.DOMAIN + elem.source.images.preview} placeholder={<div className='preloader-cube'></div>}/>
                        <LazyLoadImage src="/src_designer/images/icon_trash.svg" className={classes.planer_catalog_elem_custom_delete} onClick={e => dispatch(setActivePopup({name: "confirm", type: "delete_custom_material", data: elem.id}))} placeholder={<div className='preloader-cube'></div>}/>
                        <div className={classes.planer_catalog_elem_overlay} onMouseUp={materialMouseUp} onMouseDown={e => onMouseDownProduct(e, elem.id)}></div>
                    </div>
                )
            } else {
                materialsList.push(
                    <div className={classes.planer_catalog_elem} key={elem.id} onMouseLeave={mouseLeave} onMouseEnter={event => mouseEnter(event, elem)}>
                        <LazyLoadImage src={R2D.URL.DOMAIN + elem.source.images.preview} placeholder={<div className='preloader-cube'></div>}/>
                        <div className={classes.planer_catalog_elem_overlay} onMouseUp={materialMouseUp} onMouseDown={e => onMouseDownProduct(e, elem.id)}></div>
                    </div>
                )
            }
        });
        setMaterialsListState(materialsList);
        setCustomMaterialsListState(customMaterialsList);
    }, [currentDataPanel]);

    const variants = {
        visible: { transform:  "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }

    let scrollTimer = -1;

    let onScroll = e => {
        if (scrollTimer != -1) clearTimeout(scrollTimer);
        scrollTimer = setTimeout(() => scrollFinished(), 500);
    }

    let scrollFinished = () => dispatch(historyScrollSave(mainDiv.current ? mainDiv.current.scrollTop : 0));

    let mainDiv = useRef(null);

    return (

        <motion.div transition={{ ease: "easeOut", duration: .1 }} initial="hidden" animate="visible" exit={"exit"} variants={variants} className={classes.planer_catalog_cont} >
            <div className={classes.planer_catalog_title}>{ currentDataPanel && currentDataPanel.data && currentDataPanel.data.name }</div>
            <div className={classes.planer_catalog_elem_wrap} ref={mainDiv}>
                <div className={classes.planer_catalog_elem_wr}>
                    {
                        currentDataPanel ?
                        customMaterialsListState && customMaterialsListState.length > 0 ?
                            <>
                                <div className={classes.planer_catalog_elem_upload}>
                                    <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                                        <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                                        { user.data.plan != "pro" ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                                        <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                                    </div>
                                    { customMaterialsListState }
                                </div> 
                                { materialsListState }
                            </>
                            :
                            <>
                                <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                                    <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                                    { user.data.plan != "pro" ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                                    <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                                </div>
                                { materialsListState }
                            </>
                        :
                        <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                            <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                            { user.data.plan != "pro" ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                            <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                        </div>

                    }
                </div>
            </div>

        </motion.div>
    )
}

export default ListMaterials;