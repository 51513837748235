import { createSlice } from '@reduxjs/toolkit'

export const quickPanelsSlice = createSlice({
  name: 'quickPanels',
  initialState: { 
    helper: {status: false, position: {}},
    delete: {status: false, position: {}},
    helper_model: {status: false, position: {}},
    helper_plinth: {status: false, position: {}},
  },
  reducers: {
    showQuickPanel: (state, action) => {
        state[action.payload.name].status = true;
        state[action.payload.name].position = action.payload.position;
    },
    hideQuickPanel: (state, action) => {
      state[action.payload.name].status = false;
      state[action.payload.name].position = {};
    },
    hideQuickPanelAll: (state, action) => {
      for(let panel in state) {
        state[panel].status = false;
        state[panel].position = {};
      }
    },

  },
})

export const selectQuickPanels = state => state.quickPanels;

export const { showQuickPanel, hideQuickPanel, hideQuickPanelAll } = quickPanelsSlice.actions; 

export default quickPanelsSlice.reducer;