import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup, selectPopups, setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { selectUserProjectActive, setActiveProjectName, saveLoggedStatus, userLogout } from '../../../../redux/features/user/userSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import { setActiveViewModeState, selectActiveViewModeData, historyUpdate, setActiveViewMode, selectActiveViewModeState, historyRemove } from '../../../../redux/features/viewMode/viewModeSlice';
import {createUseStyles} from "react-jss";
import { user, planner } from '../../../../init';
import { changeImagePlanUpload } from '../../../../redux/features/constructor/constructorSlice';
import { hideQuickPanelAll } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import { updateCurrentProjectHash } from '../../../../redux/features/common/commonSlice';

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative'
    },
    planer_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    text:{
        color: 'rgb(47, 47, 47)',
        margin: '0 10px 15px',
        fontSize: 15,
        textAlign: 'center'
    },
    planer_popup_bottom:{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'center'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },

});

const ConfirmPopup = props => {

    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);
    let popups = useSelector(selectPopups);
    let data = popups.confirm.data;
    let activeProjectName = useSelector(selectUserProjectActive);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;

    let popupType = popups.confirm.type;

    let confirmText = "";

    let onClickOk = null;

    let onClickCancel = null;

    if(popupType == "logout") {
        confirmText = translation["TEXT_CONFIRM_SIGN_OUT"];
        onClickOk = async e => {
            if(planner.scene.wasSaved()) {
                dispatch(setActiveViewModeState({state: "stateEditing"}));
                dispatch(hideQuickPanelAll());
            };
            dispatch(userLogout());
            
            dispatch(closeActivePopup({name: "confirm"}));
            
            if(activeViewModeState == "stateRendersShow" || activeViewModeState == "stateProjectsShow") dispatch(setActiveViewModeState({state: "stateEditing"}));

            if(currentStateHistory[currentStateHistory.length - 1].type == "changePlinth") dispatch(historyRemove());
            
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
    }

    if(popupType == "project_save_before_rename") {
        confirmText = translation["TEXT_SAVE_BEFORE_RENAME_SCENE"];
        onClickOk = e => {
            dispatch(closeActivePopup({name: "confirm"}));

            user.isLogged() ? 
            dispatch(setActivePopup({name: "setProjectName", type: "create"})) :
            dispatch(setActivePopup({ name: "login" }))
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
    }

    if(popupType == "project_delete") {
        confirmText = translation["TEXT_DELETE_SCENE"];
        onClickOk = e => {
            dispatch(closeActivePopup({name: "confirm"}));
            return new Promise((res, rej) => {
                res(user.deleteProject(data.id));
            }).then(async res => {
                let response = await user.loadProjects();
        
                response.type == "error" ? 
                dispatch(setActivePopup({name: "alert", type: response.message, data: response.data})) :
                dispatch(historyUpdate(response.list.reverse()));

                if(activeProjectName.name == data.name) {
                    dispatch(hideQuickPanelAll());
                    dispatch(setActiveProjectName(translation["DEFAULT_SCENE_NAME"]));
                    
                    dispatch(updateCurrentProjectHash(""));
                    window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH);
                }
            })
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
    }

    if(popupType == "project_copied_open") {
        confirmText = translation["TEXT_LOAD_COPIED_PROJECT"];
        onClickOk = async e => {
            dispatch(closeActivePopup({name: "confirm"}));

            if(!planner.scene.wasSaved() || planner.scene.wasChanged())
                dispatch(setActivePopup({name: "saveBefore", type: "open", data}));

            else {
                dispatch(hideQuickPanelAll());
                let loadedProjectViewType = await planner.scene.loadProject(data.id);

                dispatch(setActiveProjectName(data.name))
                
                if(loadedProjectViewType == "2d" || loadedProjectViewType == "constr") dispatch(setActiveViewMode("view2d"));
                if(loadedProjectViewType == "3d") dispatch(setActiveViewMode("view3d"));
                if(loadedProjectViewType == "walk") dispatch(setActiveViewMode("viewWalk"));

                dispatch(changeImagePlanUpload(R2D.commonSceneHelper.drawingHelper.state != null ? R2D.commonSceneHelper.drawingHelper.state : null));

                let response = await user.projectHashLoad(R2D.controller.getProjectId());
                if(response.type == "error") dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
                else {
                    dispatch(updateCurrentProjectHash(response));
                    window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH + '/project/' + response);
                }
            }
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
    }

    if(popupType == "render_open") {
        confirmText = translation["TEXT_IMAGE_WILL_OPENED"];
        onClickOk = async e => {
            let renderImgUrl = await user.openRender(data);
            dispatch(closeActivePopup({name: "confirm"}));
            window.open(`${R2D.URL.DOMAIN}${renderImgUrl}`);
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
    }
    
    if(popupType == "render_delete") {
        confirmText = translation["TEXT_RENDER_DELETE_CONFIRM"];
        onClickOk = e => {
            dispatch(closeActivePopup({name: "confirm"}));

            async function loaderHandler(event) {
                
    
                if ( event.type == Event.ERROR ) {
                    dispatch(setActivePopup({name: "alert", data: "TEXT_ERROR_LOAD_DATA"}));
                    return;
                }
    
                if(activeViewModeState == "stateRendersShow") {
                    let response = await user.loadRenders();
                    dispatch(historyUpdate(response));
                }
            }
            var url = `${R2D.URL.DOMAIN}${R2D.URL.URL_RENDER_DELETE}?project_id=${data.projectId}&render_id=${data.id}`;
            var loader = new R2D.XHRLoader();

            loader.addEventListener(Event.COMPLETE, loaderHandler);
            loader.addEventListener(Event.ERROR, loaderHandler);
            loader.load(url, null, 'get', null, 'json', true);
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
        
    }

    if(popupType == "project_clear") {
        confirmText = translation["TEXT_CLEAR_SCENE"];
        onClickOk = e => {
            planner.scene.clear();
            dispatch(hideQuickPanelAll());
            dispatch(closeActivePopup({name: "confirm"}));
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
    }

    if(popupType == "merge_covers") {
        confirmText = translation["TEXT_CONFIRM_MERGE_COVERS"];
        onClickOk = e => {
            planner.constr.mergeCovers();
            dispatch(closeActivePopup({name: "confirm"}));
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));
    }

    if(popupType == "image_plan_delete") {
        confirmText = translation["TEXT_DELETE_PLAN"];
        onClickOk = e => {
            dispatch(closeActivePopup({name: "confirm"}));
            dispatch(changeImagePlanUpload(null));
            dispatch(setActiveViewModeState({state: "stateEditing"}));
            WC.WallsEditor._instance.delImageDrawing();
            WC.WallsEditor._instance.changeState(WC.WallsEditor._instance.stateEditing);
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));   
    }

    if(popupType == "delete_custom_material") {
        let privateMaterialType = currentStateHistory[currentStateHistory.length - 1].privateMaterialType;
        if(privateMaterialType == 1) confirmText = translation["TEXT_DELETE_PLAN"];
        else confirmText = translation["TEXT_DELETE_PRODUCT"];

        onClickOk = async e => {
            var params = R2D.XHRLoader.makeParamsString({
                entity_id:data
            });

            let urlFull = R2D.makeURL(R2D.URL.DOMAIN, R2D.URL.URL_DELETE_PRIVATE);
            
            let loader = R2D.XHRLoader.getPostLoader(urlFull, params, null, null, true);

            let loaderEventHandler = async e => {
                let loadedData = await user.loadRightPanelData(currentStateHistory[currentStateHistory.length - 1].urlList);
                dispatch(historyUpdate(loadedData));
                dispatch(closeActivePopup({name: "confirm"}));
            }
            
            loader.addEventListener(Event.COMPLETE, loaderEventHandler);
            loader.addEventListener(Event.ERROR, loaderEventHandler);
            
        }
        onClickCancel = e => dispatch(closeActivePopup({name: "confirm"}));   
    }
    
    const classes = useStyle();
    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <div className="planer_popup_login_wrap">
                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                        
                        <div className={classes.text}>
                            <p dangerouslySetInnerHTML={{__html: confirmText}}></p>
                        </div>
                        <div className={classes.planer_popup_bottom}>
                            <span className={classes.planer_btn_done} onClick={onClickOk}>{ translation["BUTTON_OK"] }</span>
                            <span className={classes.planer_btn_done} onClick={onClickCancel}>{ translation["BUTTON_CANCEL"] }</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPopup;