import React, { useState } from 'react';
import {createUseStyles} from "react-jss";
import ChangeSize from "../../../../../common/changeSize/index.jsx";
import { user, planner } from '../../../../../../../../init.js';
import { useSelector, useDispatch } from 'react-redux';
import { selectTranslation } from '../../../../../../../../redux/features/language/languageSlice.js';
import { selectMetrics } from '../../../../../../../../redux/features/metrics/metricsSLice.js';
import { changeUndoRedo } from '../../../../../../../../redux/features/topPanel/topPanelSlice.js';
import cx from 'classnames';
import { selectActiveViewModeData } from '../../../../../../../../redux/features/viewMode/viewModeSlice.js';
import { motion } from 'framer-motion';

const useStyle = createUseStyles({
    planerWallsCont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planerWallsWrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',
        padding: 20,
    },
    planerWallsTitle:{
        fontSize: 13,
        textAlign: 'center',
        marginBottom: 15,
        color: '#505050',
    },
    planerChangeSizes:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: 20
    },
    planerWallsCurentMatWr:{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },

    divideWall:{
        height: 100,
        border: '1px solid #d9d9d9',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 31,
        padding: '10px 25px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        marginBottom: 20,
        '&:hover':{
            background: '#FFCC00',
            border: '1px solid #FFCC00',
        }
    },
    divideWallImg:{

    },
    divideWallText:{
        color: '#444444',
        fontSize: 13
    },
    divideWallGrid:{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 14,
        marginBottom: 20
    },
    divide_checkbox_wr:{
        marginBottom: 15
    },

    editMatItemWr:{
        width: 100,
        height: 100,
        position: 'relative',
        outline: 'rgb(217, 217, 217) solid 3px',
        '&:hover':{
            outline: 'rgb(255, 204, 0) solid 3px',
        }
    },

    active:{
        outline: 'rgb(183, 215, 67) solid 3px',
    },
    
    
    editMatItem:{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.23)',
        '&:hover':{
            '& $editMat':{
                transform: 'translateY(0%)'
            }
        }
    },
    editMat:{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 30,
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        background: 'rgba(68, 68, 68, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateY(100%)',

    },
    editMatImg:{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        backgroundSize: 'cover'
    },
    
});

const ChangePlinth = () => {
    const classes = useStyle();
    let isPro = user.isPro();
    let currObj = planner.mih()._scene.currentView3DObject;
    let constructionData = currObj.getConstructorElementData();

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentPlinthType = currentStateHistory[currentStateHistory.length - 1].data;
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    constructionData = currentPlinthType ? currentPlinthType == 'molding' ? constructionData.topPlinth : constructionData.bottomPlinth : constructionData;

    let [mouseDown, setMouseDown] = useState({status: false, action: null, type: null});

    let metrics = useSelector(selectMetrics);

    let translation = useSelector(selectTranslation);
    let dispatch = useDispatch();

    let imagesSrc = constructionData.type == 'bottomPlinth' ? '/plinth/b' : '/molding/t';

    let [plinthHeight, setPlinthHeight] = useState(constructionData.h);
    let [plinthDepth, setPlinthDepth] = useState(constructionData.d);
    

    let onChangeSize = (mouseDown, value, type) => {
        setMouseDown({...mouseDown, type});
        constructionData[type] = value;
        constructionData.dispatchUpdate();
        planner.scene.rebuildAllPlinths();
        type == "h" ? setPlinthHeight(value) : setPlinthDepth(value);

        if(mouseDown.status == false) {
            planner.mih()._scene.history.saveState();
            dispatch(changeUndoRedo());
        }
    }

    let onChangePlinthType = (i, e) => {
        if(document.querySelector('.' + classes.editMatItemWr)) {
            Array.from(document.querySelectorAll('.' + classes.editMatItemWr)).forEach(item => item.classList.remove(classes.active));
        }
        
        e.currentTarget.classList.add(classes.active);
        constructionData.shapeNum = i;
        constructionData.h = WC.PLINTH_B_SHAPES[i].defHeight;
        constructionData.d = WC.PLINTH_B_SHAPES[i].defDepth;
        constructionData.dispatchUpdate();
        planner.scene.rebuildAllPlinths();
        setPlinthHeight(constructionData.h)
        setPlinthDepth(constructionData.d);
        R2D.scene.history.saveState();
        
        currObj = planner.mih()._scene.currentView3DObject;
        constructionData = currObj.getConstructorElementData();
        constructionData = currentPlinthType ? currentPlinthType == 'molding' ? constructionData.topPlinth : constructionData.bottomPlinth : constructionData;
        setPlinthDepth(constructionData.d);
        setPlinthHeight(constructionData.h);
    }

    let plinths = [];

    for(let i = 0; i < 8; i++) {
        plinths.push(
            <div key={i} className={cx(classes.editMatItemWr, {
                [classes.active]:i==constructionData.shapeNum
            })} onClick={e => onChangePlinthType(i, e)}>
                <div className={classes.editMatItem}>
                    <div className={classes.editMatImg} style={{background: `url('/src_designer/images${imagesSrc + i}.png')`}}></div>
                </div>
            </div>
        )
    }

    const variants = {
        visible: { transform:  "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }
    
    return (
        <>
            <motion.div transition={{ ease: "linear", duration: .1 }} initial="hidden" animate="visible" exit={"exit"} variants={variants} className={classes.planerWallsCont}>
                <div className={classes.planerWallsWrap}>
                    <div className={classes.planerWallsTitle}>{`${translation["LENGTH"]} ${planner.units.valueToString(constructionData.length)} ${R2D.DimensionSystem.getName()}`}</div>

                    <div className={classes.planerChangeSizes}>
                        <ChangeSize mouseDown={mouseDown} valueChangeType={"h"} changeValue={onChangeSize} value={plinthHeight} label={translation["HEIGHT"]}/>
                        <ChangeSize mouseDown={mouseDown} valueChangeType={"d"} changeValue={onChangeSize} value={plinthDepth} label={translation["DEPTH"]}/>
                    </div>

                    <div className={classes.divideWallGrid}>
                        { plinths }
                    </div>

                </div>
            </motion.div>
        </>
        
    );
};

export default ChangePlinth;