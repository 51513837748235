import React, { useRef, useState } from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch, useSelector } from 'react-redux';
import { planner, user } from '../../../../../../../../../init.js';
import { historyAdd, selectActiveViewMode, selectActiveViewModeData, updateMaterialId } from '../../../../../../../../../redux/features/viewMode/viewModeSlice.js';
import Catalog from '../../../general/catalog/Catalog.jsx'
import CatalogMaterials from '../../../general/catalog/CatalogMaterials.jsx';

import EditMaterialsItem from "../../../../../../common/editMaterialItem/index.jsx";
import { selectTranslation } from '../../../../../../../../../redux/features/language/languageSlice.js';
import ColorPicker from '../../../general/colorPicker/ColorPicker.jsx';
import { changeUndoRedo } from '../../../../../../../../../redux/features/topPanel/topPanelSlice.js';
import { useEffect } from 'react';

const useStyle = createUseStyles({
    planerWallsCont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planerWallsWrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',
        padding: 20,
    },
    planerWallsTitle:{
        fontSize: 13,
        textAlign: 'center',
        marginBottom: 15,
        color: '#505050',
    },
    planerWallsCurentMatWr:{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
    showItemWr:{
        display: 'flex',
        alignItems: 'center'
    },
    showItemIconWr:{

    },
    showItemIcon:{
        width: 20,
        cursor: 'pointer',
    },
    showItemInput:{
        color: '#444444',
        border: 'none',
        margin: '0 8px 0 4px',
        outline: 'none',
        padding: '3px 5px',
        maxWidth: 'calc(100% - 12px)',
        '&:hover':{
            background: 'rgb(244, 244, 244)',
        }
    }


});

const StateSelectedConstr = () => {
    const classes = useStyle();
    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataType = currentStateHistory[currentStateHistory.length - 1].type;

    let activeViewMode = useSelector(selectActiveViewMode);

    let currObj = planner.mih()._scene.currentView3DObject;
    let type = currObj.getType();
    let constructionData = currObj.getConstructorElementData();

    let roomName = useRef();

    let previewMaterialId = currentStateHistory[0].matId;

    let [roomTitleText, setRoomTitleText] = useState(constructionData.title && type == "cover" ? constructionData.title.text : null);

    let area = planner.units.squareToString(constructionData.area);

    let [isRoomNameVisible, setRoomNameVisible] = useState(constructionData.title ? constructionData.title.visible : null);

    let onClickMaterials = async e => {
        let catalogMaterials = await user.loadRightPanelData(R2D.URL.CATALOG_MATERIALS_TREE_FOR_MODELS);
        dispatch(historyAdd({added: true, data: catalogMaterials.materials, type: "catalog"}));
    }

    let onEnterClick = e => e.code == "Enter" ? roomName.current && roomName.current.blur() : null;

    let onChangeMaterialId = id => dispatch(updateMaterialId(id));

    let onChangeRoomName = e => {
        document.addEventListener("keydown", onEnterClick);
        setRoomTitleText(e.target.value);
    }

    let onBlurRoomName = e => {
        constructionData.title.text = e.target.value;
        if(activeViewMode == "view2d") planner.mih()._titlesTool.updateValues(constructionData.id);
        document.removeEventListener("keydown", onEnterClick);
    }

    let onChangeRoomNameTool = value => setRoomTitleText(value.data);

    let onHideRoomName = e => {
        constructionData.title.visible = !isRoomNameVisible;
        constructionData.dispatchUpdate();
        planner.mih()._titlesTool.updateValues(constructionData.id);
        setRoomNameVisible(constructionData.title.visible);
    }

    let onCopyMaterial = id => R2D.CopyPaste.copyMaterial(id);

    let onPasteMaterial = () => {
        let mat = R2D.CopyPaste.pasteMaterial();
        if (mat) {
            constructionData.setMaterial(mat);
            constructionData.dispatchUpdate();
            dispatch(updateMaterialId(mat));
            R2D.scene.history.saveState();
            dispatch(changeUndoRedo());
        }   
    }

    let onMaterialSelect = id => {
        planner.scene.setMaterialOnCurrentConstr(id);
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    useEffect(() => {
        planner.apiScene.addEventListener(planner.scene.CHANGED_TITLE_TEXT, onChangeRoomNameTool);
        return () => planner.apiScene.removeEventListener(planner.scene.CHANGED_TITLE_TEXT, onChangeRoomNameTool);
    }, [])

    

    return (
        <>
            {
            currentDataType == "main" ?
            <div className={classes.planerWallsCont}>
                <div className={classes.planerWallsWrap}>
                    <div className={classes.planerWallsTitle}>{ translation["TEXT_CONSTRUCTION_ELEMENT_AREA"] + " " + area}</div>
                    <div className={classes.planerWallsCurentMatWr}>
                        <EditMaterialsItem matId={previewMaterialId} onCopyPaste={{copy: onCopyMaterial, paste: onPasteMaterial}} onClick={onClickMaterials}  lock={false}/>
                    </div>
                    {
                        type == "cover" ? 
                        <div className={classes.showItemWr}>
                            {
                                activeViewMode == "view2d" ?
                                <div className={classes.showItemIconWr} onClick={onHideRoomName}>
                                    {
                                        isRoomNameVisible ? 
                                        <svg className={classes.showItemIcon} viewBox="0 0 12 7" fill="none">
                                            <path d="M6.40078 6.5C4.0451 6.5 2.09142 5.22145 1.34074 3.5C2.09142 1.77855 4.0451 0.5 6.40078 0.5C8.75646 0.5 10.7101 1.77855 11.4608 3.5C10.7101 5.22145 8.75646 6.5 6.40078 6.5Z" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M6.5 5C7.32843 5 8 4.32843 8 3.5C8 2.67157 7.32843 2 6.5 2C5.67157 2 5 2.67157 5 3.5C5 4.32843 5.67157 5 6.5 5Z" fill="#444444"/>
                                        </svg>
                                        :
                                        <svg className={classes.showItemIcon} viewBox="0 0 13 5" fill="none">
                                            <g opacity="0.99">
                                                <path d="M0.800515 0C1.57504 2.03156 3.78747 3.5 6.40052 3.5C9.01356 3.5 11.226 2.03156 12.0005 0H10.9077C10.1839 1.41856 8.48832 2.5 6.40052 2.5C4.31271 2.5 2.61709 1.41856 1.89336 0H0.800515Z" fill="#444444"/>
                                                <path d="M2.00088 1.00026L2.79692 1.60551L1.56948 2.99657L0.773438 2.39132L2.00088 1.00026Z" fill="#444444"/>
                                                <path d="M3.6706 2.55596L4.67465 2.84356L3.96832 4.79687L2.96426 4.50928L3.6706 2.55596Z" fill="#444444"/>
                                                <path d="M8.49154 2.56038L7.47354 2.81562L8.1144 4.78832L9.13239 4.53308L8.49154 2.56038Z" fill="#444444"/>
                                                <path d="M10.7964 1.00026L10.0004 1.60551L11.2716 3.05407L12.0676 2.44883L10.7964 1.00026Z" fill="#444444"/>
                                            </g>
                                        </svg>
                                    }
                                </div>
                                :
                                null
                            }
                            <input className={classes.showItemInput} type="text" ref={roomName} onChange={onChangeRoomName} onBlur={onBlurRoomName} value={ roomTitleText }/>
                        </div> :
                        null
                    }
                </div>
            </div>
            :
            null
        }
        {currentDataType == "catalog" ? <Catalog /> : null}
        {currentDataType == "colorpicker" ? <ColorPicker matId={previewMaterialId} materialSelect={onMaterialSelect} changePreviewId={onChangeMaterialId}/> : null}
        {currentDataType == "material" ? <CatalogMaterials matId={previewMaterialId} materialSelect={onMaterialSelect} changePreviewId={onChangeMaterialId}/> : null}
        </>
    );
};

export default StateSelectedConstr;