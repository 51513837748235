import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userFacebookLogin, saveLoggedStatus } from '../../../../redux/features/user/userSlice';
import { setActivePopup, closeActivePopup, selectPopups, setPopupData } from '../../../../redux/features/popups/popupsSlice';
import {createUseStyles} from "react-jss";
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import { user } from '../../../../init';

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative'
    },
    planer_popup_login_wrap:{

    },
    planer_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    planner_popup_facebook:{

    },
    planner_popup_facebook_text:{
        fontSize: '15px',
        color: 'rgb(47, 47, 47)',
        margin: '15px 10px',
        textAlign: 'center'
    },
    planner_popup_facebook_icon:{
        width: '23px',
        margin: 'auto',
        cursor: 'pointer',
        '& img':{
            display: 'block'
        }
    },
    planner_popup_facebook_line:{
        background: 'rgb(217, 217, 217)',
        height: '1px',
        margin: '15px 40px'
    },
    planer_popup_login_wr:{
        marginBottom: '20px'
    },
    input_wrap:{
        padding: '0 65px',
        display: 'grid',
        gridGap: 20,
        border: 'none',
        gridTemplateColumns: 'auto auto',
        alignItems: 'center'
    },
    input_text:{
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        color: 'rgb(68, 68, 68)',
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    input_wr:{
        color: '#fff',
        width: 225,
        '&:last-child': {
            marginBottom: '0'
        }
    },
    formInput:{
        paddingLeft: '5px',
        fontSize: '16px',
        color: '#000',
        width: '100%',
        border: '1px solid #e8dede',
        height: '30px',
        background: '#ffffff',
        outline: 'none',
        resize: 'none',
        transition: 'all 0.4s ease-out'
    },
    important_input:{
        '& $formInput':{
            border: '1px solid #e84343 !important'
        }
    },
    planer_popup_report:{
        color: 'red',
        fontSize: '12px',
        marginBottom: '10px'
    },
    planer_btn_done_wrap:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },
    planer_popup_bottom:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    planer_popup_bottom_link:{
        fontSize: 13,
        color: 'rgb(68, 68, 68)',
        cursor: 'pointer',
        '&:hover':{
            textDecoration: 'underline',
            textDecorationStyle: 'dashed',
            textUnderlineOffset: '4px'
        }
    }

});
const LoginPopup = props => {

    let dispatch = useDispatch();

    let translation = useSelector(selectTranslation);

    let popups = useSelector(selectPopups);

    let popupData = popups.login.data;
    let popupType = popups.login.type;
    

    const onSubmit = async e => {
        e.preventDefault();

        let form = e.target;

        let email = form.elements.email;
        let password = form.elements.password;
            
        let responce = await user.tryLogin(email.value, password.value);
        if(responce.type == "error") {
            dispatch(setActivePopup({name: "alert", type: responce.message, data: responce.data}))
        }
        else {
            dispatch(saveLoggedStatus(responce.logged));
            dispatch(closeActivePopup({name: "login"}));

            if(popupType == "buy_plan")  {
                !user.isPro() ? dispatch(setPopupData({name: "payPro", data: popupData})) :
                dispatch(closeActivePopup({name: "payPro"}));
            }
        }
        
    }

    const openNewPopup = name => {
        dispatch(closeActivePopup({ name: "login" }));
        dispatch(setActivePopup({ name }));
    }

    let onFacebookLogin = async e => {
        
        let response = await user.facebookLogin();
        
        if(response.type == "error") dispatch(setActivePopup({name: "alert", type: response.message, data: response.data}))
        else {
            dispatch(saveLoggedStatus(response.logged));
            dispatch(closeActivePopup({name: "login"}));

            if(popupType == "buy_plan")  {
                !user.isPro() ? dispatch(setPopupData({name: "payPro", data: popupData})) :
                dispatch(closeActivePopup({name: "payPro"}));
            }
        }
    }

    const classes = useStyle();
    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show} style={{zIndex: popups.login.zIndex}}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <div className={classes.planer_popup_login_wrap}>
                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                        <div className={classes.planner_popup_facebook}>
                            <div className={classes.planner_popup_facebook_text}>{ translation["TEXT_SIGN_IN_WITH"] }</div>
                            <div className={classes.planner_popup_facebook_icon} onClick={onFacebookLogin}>
                                <img src="/src_designer/images/icon_facebook.svg" />
                            </div>
                            <div className={classes.planner_popup_facebook_line}></div>
                            <div className={classes.planner_popup_facebook_text}>{ translation["TEXT_SIGN_IN_OR"] }</div>
                        </div>
                        <div className={classes.planer_popup_close} id="js_close_popup" onClick={e => dispatch(closeActivePopup({name: "login"}))}>
                            <svg className={classes.planer_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                                <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                            </svg>
                        </div>
                        <div className={classes.planer_popup_login_wr}>
                            <form onSubmit={onSubmit}>
                                <fieldset className={classes.input_wrap}>
                                    <div className={classes.input_text}>{translation["INPUT_TEXT_LOGIN"]}</div>
                                    <div className={classes.input_wr}>
                                        <input id="formEmail" className={classes.formInput} type="tel" name="email" required placeholder="" />
                                    </div>
                                    <div className={classes.input_text}>{translation["INPUT_TEXT_PASSWORD"]}</div>
                                    <div className={classes.input_wr}>
                                        <input id="formPassword" className={classes.formInput} type="password" name="password" required placeholder="" />
                                    </div>
                                    <div className={classes.planer_popup_report} id="loginReport"></div>
                                </fieldset>
                                <fieldset className={classes.planer_btn_done_wrap}>
                                    <input type="submit" className={classes.planer_btn_done} id="login_sent" value="Ok" />
                                </fieldset>
                            </form>
                        </div>
                        <div className={classes.planer_popup_bottom}>
                            <span className={classes.planer_popup_bottom_link} onClick={e => openNewPopup("registration") }>{translation["TEXT_HEAD_REGISTRATION"]}</span>
                            <span className={classes.planer_popup_bottom_link} onClick={e => openNewPopup("forgotPassword") }>{ translation["TEXT_FORGOT_PASSWORD"] }</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPopup;