import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveViewModeData } from '../../../../../../../redux/features/viewMode/viewModeSlice.js';
import ListRenders from '../general/stateRendersShow/ListRenders.jsx';
import ListProjects from '../general/stateProjectsShow/ListProjects.jsx';
import RenderMake from '../general/stateRenderMake/RenderMake.jsx';
import StateEditing from './stateEditing/StateEditing.jsx';

const PanoMain = props => {

    let activeViewModeData = useSelector(selectActiveViewModeData);

    return (
        <>
            {   
                activeViewModeData.activeState == "stateEditing" ? 
                <>
                    <StateEditing /> 
                    
                </>
                
                : 
                activeViewModeData.activeState == "stateRendersShow" ? <ListRenders /> :
                activeViewModeData.activeState == "stateRenderMake" ? <RenderMake /> : 
                activeViewModeData.activeState == "stateProjectsShow" ? <ListProjects /> : null
            }
        </>
    )
}

export default PanoMain;