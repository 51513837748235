import React, {useRef, useEffect} from 'react';
import { selectActiveViewModeData } from '../../../../../../../../redux/features/viewMode/viewModeSlice';
import { planner } from '../../../../../../../../init';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';

const useStyle = createUseStyles({
    cont:{
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    wrap:{
        height: '100%',
        overflow: 'auto',
        background: '#fff',

    },
    wr:{
        padding: '20px 20px 20px 10px',
    },
    title: {
        width: '100%',
        height: '29px',
        backgroundColor: 'rgba(129, 129, 129, 0.9)',
        fontSize: '13px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '0 5px'
    },
    color_picker_palette: {
        display: "block",
        margin: "0",
        padding: "0",
        outline: "solid 1px #aaaaaa",
        position: "relative"
    },

    color_picker_slider: {
        display: 'block',
        margin: '0',
        padding: '0',
        position: 'relative'
    },
    
    color_picker_canvas: {
        display: 'block',
        margin: 0,
        padding: 0,
    },
    color_picker_pointer: {
        pointerEvents: 'none',
        display: 'block',
        margin: 0,
        padding: 0,
        outline: '2px solid #000000',
        outlineOffset: '-1px',
        position: "absolute",
        "& color_picker_pointer": {
            outline: 'rgb(255, 255, 255) solid 2px',
            outlineOffset: '-2px'
        }
    },
    top: {
        textAlign: 'center',
        marginBottom: 20
    },
    bottom: {
        display: 'flex',
        justifyContent: "space-between",
        marginBottom: 20
    }
});

const ColorPicker = props => {
    const classes = useStyle();

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let title = currentStateHistory[currentStateHistory.length - 1].name;

    let palWidth = 200;
    let palHeight = 150;
    let sliderWidth = 20;
    let intend = 10;
    let palPointerSize = 12;
    let sliderPointerSize = 8;
    var prevSize = 120;

    let valH = 0;
    let valS = 0;
    let valB = 100;

    let palPosX = 0;
    let palPosY = 0;
    let sliderPos = 1;
    let mouseDown = false;
    let mouseDownOnPrev = false;
    let downX = 0;
    let downY = 0;

    let sliderCtx = null;
    let palCtx = null;
    let prevContext = null;

    let palPointer = useRef(null);
    let sliderPointer = useRef(null);
    let sliderCanvas = useRef(null);
    let palCanvas = useRef(null);
    let text = useRef(null);
    let colorPrev = useRef(null);

    let downOnPrevListener = e => {
        var color = getHex();
        addMaterialToPool(color);
        planner.scene.dragProductFromCatalog(color);
    }

    let updateSelectedObj = () => {
        if(props.materialSelect && props.changePreviewId) {
            props.materialSelect(getHex());
            props.changePreviewId(getHex());
        }
    }

    function drawColorPrev(color)
    {
        prevContext.fillStyle = color;
        prevContext.fillRect(0, 0, prevSize, prevSize);
    }

    let addMaterialToPool = color => {
        R2D.PoolMaterials.create(color);
        R2D.Pool.addProductData({id: color, productId:color, type: R2D.ProductType.MATERIAL, name: color, source:{body:{}}});
    };

    let colorTextChangeListener = () => {
        if (setHex(text.current.value))
        {
            drawColorPrev(getHex());
            addMaterialToPool(getHex());
            updateSelectedObj();
        }
        else
        {
            updateText()
        }
    }

    let sliderDownListener = e =>
    {
        e.preventDefault();

        e = e.nativeEvent;

        mouseDown = true;
        //setMouseDown(true)
        valB = (1 - e.offsetY / palHeight) * 100;
        placePointers();

        //let rgb = me.getRGB();
        //body.style.borderColor = 'rgb(' + Math.round(rgb[0]) + ',' + Math.round(rgb[1]) + ',' + Math.round(rgb[2]) + ')';
        updateText();
        drawColorPrev(getHex());
    }

    let palDownListener = e => {
        
        
        e.preventDefault();

        e = e.nativeEvent;

        mouseDown = true;
        valH = e.offsetX / palWidth * 360;
        valS = (1 - e.offsetY / palHeight) * 100;
        drawSlider(sliderCtx, sliderWidth, palHeight);
        placePointers();

        //let rgb = me.getRGB();
        //body.style.borderColor = 'rgb(' + Math.round(rgb[0]) + ',' + Math.round(rgb[1]) + ',' + Math.round(rgb[2]) + ')';
        updateText();
        drawColorPrev(getHex());
    }

    let sliderMoveListener = e =>
    {
        
        e = e.nativeEvent;
        if (mouseDown)
        {
            valB = (1 - e.offsetY / palHeight) * 100;
            placePointers();
            updateText();
            drawColorPrev(getHex());
        }
    }

    let palMoveListener = e => {
        e = e.nativeEvent;

        if (mouseDown)
        {
            valH = e.offsetX / palWidth * 360;
            valS = (1 - e.offsetY / palHeight) * 100;
            drawSlider(sliderCtx, sliderWidth, palHeight);
            placePointers();
            updateText();
            drawColorPrev(getHex());
        }
    }

    let palUpListener = e => {
        if(e.type == "mouseleave" && !mouseDown) return;
        updateText();
        addMaterialToPool(getHex());
        updateSelectedObj()
    }

    let sliderUpListener = e => {
        if(e.type == "mouseleave" && !mouseDown) return;
        updateText();
        addMaterialToPool(getHex());
        updateSelectedObj();
    }

    let mouseUpListener = () => {
        mouseDown = false;
    }

    let placePointers = () => {
        palPointer.current.style.left = Math.floor(valH / 360 * palWidth - Math.floor(palPointerSize / 2)) + 'px';
        palPointer.current.style.top = Math.floor((1 - valS / 100) * palHeight - Math.floor(palPointerSize / 2)) + 'px';
        sliderPointer.current.style.top = Math.floor((1 - valB / 100) * palHeight - Math.floor(sliderPointerSize / 2)) + 'px';
    }

    let drawSlider = (ctx, w, h) => {
        let rgb = HSVtoRGB(valH, valS, 100);
        let color1 = 'rgb(' +
            Math.round(rgb[0]) + ',' +
            Math.round(rgb[1]) + ',' +
            Math.round(rgb[2]) + ')';
        let color2 = '#000';


        ctx.clearRect(0, 0, w, h);

        let grad = ctx.createLinearGradient(0, 0, 0, h);
        grad.addColorStop(0, color1);
        grad.addColorStop(1, color2);

        ctx.fillStyle = grad;
        ctx.fillRect(0, 0, w, h);
    }

    let drawPalette = (ctx, w, h) => {
        let hGrad = ctx.createLinearGradient(0, 0, palWidth, 0);
        hGrad.addColorStop(0 / 6, '#F00');
        hGrad.addColorStop(1 / 6, '#FF0');
        hGrad.addColorStop(2 / 6, '#0F0');
        hGrad.addColorStop(3 / 6, '#0FF');
        hGrad.addColorStop(4 / 6, '#00F');
        hGrad.addColorStop(5 / 6, '#F0F');
        hGrad.addColorStop(6 / 6, '#F00');

        ctx.fillStyle = hGrad;
        ctx.fillRect(0, 0, w, h);

        let type = 's';
        let vGrad = ctx.createLinearGradient(0, 0, 0, h);
        switch (type.toLowerCase()) {
            case 's':
                vGrad.addColorStop(0, 'rgba(255,255,255,0)');
                vGrad.addColorStop(1, 'rgba(255,255,255,1)');
                break;
            case 'v':
                vGrad.addColorStop(0, 'rgba(0,0,0,0)');
                vGrad.addColorStop(1, 'rgba(0,0,0,1)');
                break;
        }
        ctx.fillStyle = vGrad;
        ctx.fillRect(0, 0, w, h);
    }

    let HSVtoRGB = (h, s, v) => {
        let u = 255 * (v / 100);

        if (h === null) {
            return [ u, u, u ];
        }

        h /= 60;
        s /= 100;

        let i = Math.floor(h);
        let f = i%2 ? h-i : 1-(h-i);
        let m = u * (1 - s);
        let n = u * (1 - s * f);
        switch (i) {
            case 6:
            case 0: return [Math.round(u), Math.round(n), Math.round(m)];
            case 1: return [Math.round(n), Math.round(u), Math.round(m)];
            case 2: return [Math.round(m), Math.round(u), Math.round(n)];
            case 3: return [Math.round(m), Math.round(n), Math.round(u)];
            case 4: return [Math.round(n), Math.round(m), Math.round(u)];
            case 5: return [Math.round(u), Math.round(m), Math.round(n)];
        }
    }

    let RGBtoHSV = (r, g, b) => {
        r /= 255;
        g /= 255;
        b /= 255;
        let n = Math.min(Math.min(r,g),b);
        let v = Math.max(Math.max(r,g),b);
        let m = v - n;
        if (m === 0) { return [ 0, 0, 100 * v ]; }
        let h = r===n ? 3+(b-g)/m : (g===n ? 5+(r-b)/m : 1+(g-r)/m);
        return [
            Math.round(60 * (h===6?0:h)),
            Math.round(100 * (m/v)),
            Math.round(100 * v)
        ];
    }

    let componentToHex = c => {
        let hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    let RGBToHex = (r, g, b) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    let hexToRGB = hex =>
    {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    }

    let getHex = () => {
        let rgb = HSVtoRGB(valH, valS, valB);
        return RGBToHex(rgb[0], rgb[1], rgb[2]);
    };
    
    let setHex = hex => {
        let rgb = hexToRGB(hex);
        if (! rgb) return false;
        setRGB(rgb[0], rgb[1], rgb[2]);
        updateText();
        drawColorPrev(hex)
        return true;
    };

    let updateText = () => text.current.value = getHex();

    let getRGB = () => HSVtoRGB(valH, valS, valB);

    let setRGB = (r, g, b) => {
        let hsb = RGBtoHSV(r, g, b);
        valH = hsb[0];
        valS = hsb[1];
        valB = hsb[2];

        drawSlider(sliderCtx, sliderWidth, palHeight);
        placePointers();
        updateText();
    };

    useEffect(() => {
        sliderCtx = sliderCanvas.current.getContext('2d');
        drawSlider(sliderCtx, sliderWidth, palHeight);

        palCtx = palCanvas.current.getContext('2d');
        drawPalette(palCtx, palWidth, palHeight);

        prevContext = colorPrev.current.getContext('2d');

        setHex(props.matId && ~props.matId.indexOf("#") ? props.matId : "#aaaaaa");

        document.addEventListener('mouseup', mouseUpListener);

        return () => {
            document.removeEventListener('mouseup', mouseUpListener);
        }
    })

    return (
        
        <div className={classes.cont}>
        
        
            <div className={classes.wrap}>
                    <div className={classes.title}>{ title }</div>
                    <div className={classes.wr}>
                        <div>
                            <div></div>
                            <div className={classes.top}>
                                <canvas ref={colorPrev} onMouseDown={props.onDragColor ? downOnPrevListener : null} width={120} height={120}></canvas>
                            </div>
                            
                            <div className={classes.bottom}>
                                <div className={classes.color_picker_palette}>
                                    <canvas ref={palCanvas} onMouseLeave={palUpListener} onMouseUp={palUpListener} onMouseMove={palMoveListener} onMouseDown={palDownListener} className={classes.color_picker_canvas} width={200} height={150}></canvas>
                                    <div className={classes.color_picker_pointer} ref={palPointer} style={{width: "12px", height: "12px"}}>
                                        <div className={classes.color_picker_pointer} style={{width: "12px", height: "12px"}}></div>
                                    </div>
                                </div>
                                <div className={classes.color_picker_slider}>
                                    <canvas ref={sliderCanvas} onMouseLeave={sliderUpListener} onMouseUp={sliderUpListener} onMouseMove={sliderMoveListener} onMouseDown={sliderDownListener} className={classes.color_picker_canvas} width={20} height={150}></canvas>
                                    <div className={classes.color_picker_pointer} ref={sliderPointer} style={{width: "28px", height: "8px", left: "-4px"}}>
                                        <div className={classes.color_picker_pointer} style={{width: "28px", height: "8px"}}></div>
                                    </div>
                                </div>
                            </div>
                            <input type="text" name="color" onBlur={colorTextChangeListener} className='text_field_value_change_number' ref={text}></input>
                        </div>  
                    </div>
            </div>
        </div>
    )
}

export default ColorPicker;