import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup, setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import {createUseStyles} from "react-jss";
import Checkbox from "../common/checkbox/index.jsx";
import { user } from '../../../../init';


const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative'
    },
    planer_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    planer_popup_back:{
        top: 10,
        left: 10,
        width: 23,
        cursor: 'pointer',
        height: 22,
        zIndex: 2,
        position: 'absolute',
        transform: 'rotate(180deg)',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_back_icon:{
        width: '100%',
        height: '100%',
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    planer_popup_login_wrap:{

    },
    planer_popup_login_wr: {
        marginBottom: 20
    },
    title:{
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: 'rgb(68, 68, 68)',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        margin: '15px 10px 40px'
    },
    input_wrap:{
        padding: '0 65px',
        display: 'grid',
        gridGap: 20,
        border: 'none',
        gridTemplateColumns: 'auto auto',
        alignItems: 'center'
    },
    input_text:{
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        color: 'rgb(68, 68, 68)',
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    input_wr:{
        color: '#fff',
        width: 250,
        '&:last-child': {
            marginBottom: '0'
        }
    },
    formInput:{
        paddingLeft: '5px',
        fontSize: '16px',
        color: '#000',
        width: '100%',
        border: '1px solid #e8dede',
        height: '30px',
        background: '#ffffff',
        outline: 'none',
        resize: 'none',
        transition: 'all 0.4s ease-out'
    },
    important_input:{
        '& $formInput':{
            border: '1px solid #e84343 !important'
        }
    },
    planer_popup_report:{
        color: 'red',
        fontSize: '12px',
        marginBottom: '10px'
    },
    planer_btn_done_wrap:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },
    checkbox_wrap:{
        marginBottom: 15
    }
});

const RegistrationPopup = props => {

    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);

    const onSubmit = async e => {
        e.preventDefault();

        let form = e.target;

        let email = form.elements.email;
        let password = form.elements.password;
        let password_repeat = form.elements.passwordRepeat;
        let checkGetNews = form.elements.news;

        let dataObj = {
            email: email.value,
            name: email.value,
            password: password.value,
            password_repeat: password_repeat.value,
            subscription_news: '0'
        };

        if (checkGetNews.checked) dataObj.subscription_news = '1';

        if (! /^[^@]+@[^@]+$/.test(dataObj.email))
        {
            dispatch(setActivePopup({name: "alert", type: "error_email", data: "TEXT_REG_INVALID_EMAIL"}));
            return;
        }
        else if (dataObj.password.length < 6)
        {
            dispatch(setActivePopup({name: "alert", type: "error_password_short", data: "TEXT_SHORT_PASSWORD"}));
            return;
        }
        else if (dataObj.password != dataObj.password_repeat)
        {
            dispatch(setActivePopup({name: "alert", type: "error_password_not_match", data: "TEXT_PASSWORDS_NOT_MATCH"}));
            return;
        }

        let response = await user.registration(dataObj);
        if(response.type == "error") dispatch(setActivePopup({name: "alert", type: response.message, data: response.data}))
        else if(response.type == "load_thanks"){
            dispatch(setActivePopup({name: "alert", type: response.type, data: response.data}));
            dispatch(closeActivePopup({name: "registration"}));
        }
    }

    let onClickBack = e => {
        dispatch(closeActivePopup({name: "registration"}));
        dispatch(setActivePopup({name: "login"}));
    }

    const classes = useStyle();
    return (
        <div className={classes.planer_popup_wrap +' ' + classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <h2 className={classes.title}>Registration</h2>
                    <div className={classes.planer_popup_login_wrap}>
                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />

                        <div className={classes.planer_popup_back} onClick={onClickBack}>
                            <svg className={classes.planer_popup_back_icon} width="9" height="15" viewBox="0 0 9 15" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.0271826 0.679673L0.72504 0L8.4375 7.54323L7.73964 8.2229L7.73489 8.21825L0.692425 15L-2.86102e-06 14.3151L7.03849 7.53713L0.0271826 0.679673Z" fill="#444444"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.0271826 0.679673L0.72504 0L8.4375 7.54323L7.73964 8.2229L7.73489 8.21825L0.692425 15L-2.86102e-06 14.3151L7.03849 7.53713L0.0271826 0.679673Z" fill="#444444"></path>
                            </svg>
                        </div>

                        <div className={classes.planer_popup_close}  onClick={() => dispatch(closeActivePopup({name: "registration"})) }>
                            <svg className={classes.planer_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                                <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                            </svg>
                        </div>
                        <div className={classes.planer_popup_login_wr}>
                            <form onSubmit={onSubmit}>
                                <fieldset className={classes.input_wrap}>
                                    <div className={classes.input_text}>{ translation["TEXT_EMAIL"] }</div>
                                    <div className={classes.input_wr}>
                                        <input className={classes.formInput} type="tel" name="email" required placeholder="" />
                                    </div>
                                    <div className={classes.input_text}>{ translation["INPUT_TEXT_PASSWORD"] }</div>
                                    <div className={classes.input_wr}>
                                        <input className={classes.formInput} type="password" name="password" required placeholder="" />
                                    </div>
                                    <div className={classes.input_text}>{ translation["TEXT_VERIFY_PASSWORD"] }</div>
                                    <div className={classes.input_wr}>
                                        <input className={classes.formInput} type="password" name="passwordRepeat"  required placeholder="" />
                                    </div>
                                    <div></div>
                                    <div className={classes.checkbox_wrap}>
                                        <Checkbox name={"news"} label={ translation["TEXT_GET_NEWS"] } checked={null}/>
                                    </div>
                                </fieldset>
                                <div className={classes.planer_popup_report} id="loginReport"></div>
                                <fieldset className={classes.planer_btn_done_wrap}>
                                    <input type="submit" className={classes.planer_btn_done} id="login_sent" value={translation["BUTTON_OK"]} />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationPopup;