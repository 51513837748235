import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup, selectPopups, setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import {createUseStyles} from "react-jss";
import { planner, user } from '../../../../init';
import { selectUserProjectActive, setActiveProjectName } from '../../../../redux/features/user/userSlice';
import { historyUpdate, selectActiveViewMode, selectActiveViewModeState, setActiveViewMode, setActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice';
import { hideQuickPanelAll } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import { changeImagePlanUpload } from '../../../../redux/features/constructor/constructorSlice';
import { changeActivePano, updateCurrentProjectHash } from '../../../../redux/features/common/commonSlice';

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    text:{
        color: 'rgb(47, 47, 47)',
        margin: '0 10px 15px',
        fontSize: 15,
        textAlign: 'center'
    },
    planer_btn_done_wrap:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },
    input_wr:{
        color: '#fff',
        width: '100%',
        margin: 'auto',
        marginBottom: 15,
    },
    formInput:{
        paddingLeft: '5px',
        fontSize: '15px',
        color: '#000',
        width: '100%',
        border: '1px solid #e8dede',
        height: '30px',
        background: '#ffffff',
        outline: 'none',
        resize: 'none',
        transition: 'all 0.4s ease-out'
    },

});

const SetProjectNamePopup = props => {

    let popups = useSelector(selectPopups);
    let translation = useSelector(selectTranslation);
    let dispatch = useDispatch();
    let projectActiveName = useSelector(selectUserProjectActive);
    let popupType = popups.setProjectName.type;
    let projectToOpen = popups.setProjectName.data;
    let activeViewMode = useSelector(selectActiveViewMode);
    let activeViewModeState = useSelector(selectActiveViewModeState);

    if(popupType == "copy") projectActiveName = projectToOpen;

    let onSubmit = e => {
        e.preventDefault();

        let projectName = e.target.elements.name.value;
        dispatch(closeActivePopup({name: 'setProjectName'}));

        return new Promise(async (resolve, rejecj) => {
            if(popupType == "create" || popupType == "createBeforeOpen" || popupType == "saveBeforeCreate") {
                let project = {name: projectName, createNew: false};
                if(R2D.controller.getProjectUserKey() != user.getKey()) {
                    project.createNew = true;
                }
                await planner.scene.saveProject(project);
            }
            if(popupType == "rename") planner.scene.renameProject(projectName);
            if(popupType == "copy") {
                let copiedProjectId = await user.copyProject({id: projectToOpen.id, name: projectToOpen.name, newName: projectName});
                dispatch(setActivePopup({name: "confirm", type: "project_copied_open", data: {id: copiedProjectId, name: projectName}}));
            }
            resolve();
        }).then(async result => {
            if(popupType == "create") {
                let response = await user.projectHashLoad(R2D.controller.getProjectId());
                if(response.type == "error") dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
                else {
                    dispatch(updateCurrentProjectHash(response));
                    window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH + '/project/' + response);
                    dispatch(setActivePopup({name: "completeMessage", positionX: "left", data: "NOTIFY_SAVE_COMPLETE"}));
                }
            }
            if(popupType == "saveBeforeCreate") {
                dispatch(hideQuickPanelAll());
                dispatch(setActiveViewModeState({state: "stateEditing"}));
                R2D.controller.createNewScene(true);
                projectName = translation["DEFAULT_SCENE_NAME"];
                dispatch(changeImagePlanUpload(null));
                dispatch(changeActivePano({ id: 0, previewImgSrc: "", rotation: 0}));
                
                dispatch(updateCurrentProjectHash(""));
                window.history.replaceState(null, null, '/');
                dispatch(setActivePopup({name: "completeMessage", positionX: "left", data: "NOTIFY_SAVE_COMPLETE"}));
                
            }
            if(popupType != "copy") dispatch(setActiveProjectName(projectName));
            if(popupType == "createBeforeOpen") {
                dispatch(hideQuickPanelAll());
                let loadedProjectViewType = await planner.scene.loadProject(projectToOpen.id);
                dispatch(setActiveProjectName(projectToOpen.name));
                if(loadedProjectViewType == "2d" || loadedProjectViewType == "constr") dispatch(setActiveViewMode("view2d"));
                if(loadedProjectViewType == "3d") dispatch(setActiveViewMode("view3d"));
                if(loadedProjectViewType == "walk") dispatch(setActiveViewMode("viewWalk"));
                dispatch(changeImagePlanUpload(R2D.commonSceneHelper.drawingHelper.state != null ? R2D.commonSceneHelper.drawingHelper.state : null));
                let response = await user.projectHashLoad(R2D.controller.getProjectId());
                if(response.type == "error") dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
                else {
                    dispatch(updateCurrentProjectHash(response));
                    window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH + '/project/' + response);
                    dispatch(setActivePopup({name: "completeMessage", positionX: "left", data: "NOTIFY_SAVE_COMPLETE"}));
                }
            }

            if(activeViewModeState == "stateProjectsShow") {
                let response = await user.loadProjects();
                response.type == "error" ?
                dispatch(setActivePopup({name: "alert", type: response.message, data: response.data})) :
                dispatch(historyUpdate(response.list.reverse()));
            }
        })  
    }

    const classes = useStyle();
    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                    <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                    <form onSubmit={onSubmit}>
                        <div className={classes.text}> { translation["TEXT_ENTER_NAME_SCENE"] } </div>
                        <fieldset>
                            <div className={classes.input_wr}>
                                <input className={classes.formInput} type="text" name="name" defaultValue={ projectActiveName.name || translation["DEFAULT_SCENE_NAME"] }/>
                            </div>
                        </fieldset>
                        <fieldset className={classes.planer_btn_done_wrap}>
                            <button className={classes.planer_btn_done} type='submit'> { translation["BUTTON_OK"] } </button>
                            <button className={classes.planer_btn_done} onClick={e => dispatch(closeActivePopup({ name: 'setProjectName' }))}> { translation["BUTTON_CANCEL"] } </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SetProjectNamePopup;