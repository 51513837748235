import React, { useEffect, useRef } from 'react';
import Checkbox from "../../../../../../common/checkbox/index";
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../../../../../../../../redux/features/language/languageSlice.js';
import { setActiveViewModeState } from '../../../../../../../../../redux/features/viewMode/viewModeSlice';
import { planner } from '../../../../../../../../../init';
import { useDispatch } from 'react-redux';

const useStyle = createUseStyles({
    planerEditFloorWr:{
        padding: 20,
        transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planerShowCeiling:{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15
    },
    planerDeleteFloor:{
        height: 120,
        border: '1px solid #d9d9d9',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover':{
            background: '#FFCC00'
        }
    },
    planerDeleteFloorIcon:{
        width: 26,
        height: 34
    },
    planerEditFloorInfo:{
        fontSize: 13,
        textAlign: 'center',
        marginTop: 15,
        color: '#505050',
    },
    roll_out_right:{
        transform: "translateX(100%)"
    },
});

const StateSelectedCover = () => {
    const classes = useStyle();
    let translation = useSelector(selectTranslation);
    let dispatch = useDispatch();

    let areaValue = planner.units.squareToString(planner.constr.selectedCovers()[0].getFillArea())

    let rightPanelData = useRef(null);

    useEffect(() => setTimeout(() => rightPanelData.current && rightPanelData.current.classList.remove(classes.roll_out_right), 100));

    let onSetCeilingVisible = checked => planner.constr.setCeilingVisible(checked);

    let onDeleteFloor = e => {
        planner.constr.deleteSelected();
        dispatch(setActiveViewModeState({state: "stateEditing"}));
    }

    return (
        <div className={classes.planerEditFloorWr + " " + classes.roll_out_right} ref={rightPanelData}>
            <div className={classes.planerShowCeiling}>
                <Checkbox onChange={onSetCeilingVisible} checked={planner.constr.selectedCovers()[0].getCeilingVisible() ? "checked" : null} name={"ceiling"} label={translation["TEXT_SHOW_CEIL"]} />
            </div>

            <div className={classes.planerDeleteFloor} onClick={onDeleteFloor}>
                <svg viewBox="0 0 25 34" className={classes.planerDeleteFloorIcon}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M2 7.00006H22H23V32.4201C23 33.2921 22.296 34.0001 21.429 34.0001H3.571C2.704 34.0001 2 33.2921 2 32.4201V7.00006C2 7.00006 2 6.12706 2 7.00006Z" fill="#626262"/>
                    <path className="path_not_anim" fillRule="evenodd" clipRule="evenodd" d="M18.5 9C19.328 9 20 9.672 20 10.5V27.5C20 28.328 19.328 29 18.5 29C17.672 29 17 28.328 17 27.5V10.5C17 9.672 17.672 9 18.5 9Z" fill="#fff"/>
                    <path className="path_not_anim" fillRule="evenodd" clipRule="evenodd" d="M12.5 9C13.328 9 14 9.672 14 10.5V27.5C14 28.328 13.328 29 12.5 29C11.672 29 11 28.328 11 27.5V10.5C11 9.672 11.672 9 12.5 9Z" fill="#fff"/>
                    <path className="path_not_anim" fillRule="evenodd" clipRule="evenodd" d="M6.5 9C7.328 9 8 9.672 8 10.5V27.5C8 28.328 7.328 29 6.5 29C5.672 29 5 28.328 5 27.5V10.5C5 9.672 5.672 9 6.5 9Z" fill="#fff"/>
                    <path d="M9 2L2 6H23L16 2L15.5528 1.10557C15.214 0.428005 14.5215 0 13.7639 0H11.2361C10.4785 0 9.786 0.428004 9.44721 1.10557L9 2Z" fill="#626262"/>
                    <line y1="6.5" x2="26" y2="6.5" stroke="#626262"/>
                    <line x1="10.5" y1="1.5" x2="14.5" y2="1.5" stroke="#626262" strokeLinecap="round"/>
                </svg>

            </div>
            <div className={classes.planerEditFloorInfo}>
                {translation["TEXT_CONSTRUCTION_ELEMENT_AREA"] + " " + areaValue} 
            </div>
        </div>
    );
};

export default StateSelectedCover;