import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectQuickPanels } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import {createUseStyles} from "react-jss";
import { RotateRef } from './components/Rotate';
import { MoveRef } from './components/Move';
import { useState } from 'react';
import { ClearRef } from './components/Clear';

const useStyle = createUseStyles({
    info_model_pop_wrap:{
        position: 'absolute',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        '& img':{
            userSelect: "none",
            pointerEvents: "none"
        },

    },
    info_model_height:{
        display: 'none',
        position: 'absolute',
        background: '#ffffff',
        fontSize: 20,
        border: '1px solid #000',
        transform: 'translate(-50%, -50%)',
        padding: 5
    }

});


const Helper = props => {

    let quickPanels = useSelector(selectQuickPanels);
    let position = quickPanels.helper.position;

    let btnMove = useRef(null);
    let textFieldInfo = useRef(null);
    let mainDiv = useRef(null);
    let btnClear = useRef(null);
    let btnRotate = useRef(null);

    let [panelPosition, setPanelPosition] = useState(position);

    let hideButtonsRotate = () => {
        btnClear.current.style.display = btnMove.current.style.display = 'none';
        textFieldInfo.current.style.display = 'block';
    }

    let showButtonsRotate = () => {
        btnClear.current.style.display = btnMove.current.style.display ='flex';
        textFieldInfo.current.style.display = "none";
    }

    let showButtonsMove = () => {
        btnClear.current.style.display = btnRotate.current.style.display = 'flex';
    }

    let hideButtonsMove = () => btnClear.current.style.display = btnRotate.current.style.display = 'none';

    const classes = useStyle();

    return (
        <div className={classes.info_model_pop_wrap} ref={mainDiv} style={{left: position.x, top: position.y}}> 
            <ClearRef positionBtn={{x: 0, y: 0}} ref={btnClear}/>
            <div className={classes.info_model_height} ref={textFieldInfo}></div>
            <RotateRef radius={55} ref={btnRotate} position={panelPosition} textField={textFieldInfo} hide={hideButtonsRotate} show={showButtonsRotate} positionBtn={{x: 55, y: 0}}/>
            <MoveRef ref={btnMove} hide={hideButtonsMove} changePosition={setPanelPosition} show={showButtonsMove} parent={mainDiv} positionBtn={{x: 0, y: -60}}/>
        </div>
    )
}

export default Helper;