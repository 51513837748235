import React, { forwardRef, useState } from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch, useSelector } from 'react-redux';
import { planner } from '../../../../../init';
import { selectTranslation } from '../../../../../redux/features/language/languageSlice';
import { changeUndoRedo } from '../../../../../redux/features/topPanel/topPanelSlice';
import { selectActiveViewModeData, updateCurrentProductSize } from '../../../../../redux/features/viewMode/viewModeSlice';

const useStyle = createUseStyles({
    info_model_btn:{
        position: 'absolute',
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #000',
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)',
        userSelect: 'none',
        //transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '&:hover':{
            background: '#ffd119'
        },
        '& img':{

        },
        
    },
    info_model_btn_hover:{
        position: "absolute",
        background: "#81e7ff",
        display: "none",
        zIndex: 9,
        fontSize: 12,
        width: "max-content",
        padding: "2px 7px"
    },
    info_model_btn_overlay:{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0
    }
});

export const RotateRef = forwardRef(( { radius, position, textField, hide, show, positionBtn }, ref) => <Rotate radius={radius} position={position} textField={textField} hide={hide} show={show} positionBtn={positionBtn} referense={ref} />);

const Rotate = props => {
    let position = props.position;
    let positionBtn = props.positionBtn;

    var buttonsRadius = props.radius;
    var angleButtonRotation = 0;
    var angleObject = 0;
    let translation = useSelector(selectTranslation);
    let [mouseDown, setMouseDown] = useState(false);

    let mih = planner.mih();
    let currentObjectView3d = R2D.scene.currentGroup ? R2D.scene.currentGroup : mih._scene.currentView3DObject;
    let currentPartNum = R2D.scene.currentPartNum;

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;

    let elemSize = currentStateHistory[0].data;

    let dispatch = useDispatch();
    
    let rotateBtnStartPosition = null;

    let startRotate = event => {
        props.hide();
        clearTimeout(timeoutId);
        setMouseDown(true);

        props.referense.current.querySelector("." + classes.info_model_btn_hover).removeAttribute("style");

        props.textField.current.style.display = 'block';
        
        rotateBtnStartPosition = {x: props.referense.current.offsetLeft, y: props.referense.current.offsetTop};

        document.addEventListener('mousemove', rotate);
        document.addEventListener('mouseup', stopRotate);

        switch ( currentObjectView3d.objectType ) {
            case "product":
                angleObject = GEOM.pacifyAngleRad(-GEOM.toRad(currentObjectView3d.sceneObject.rotationY));
                break;

            case "constructor":
                angleObject = currentObjectView3d.getMaterialRotation(currentPartNum);
                currentObjectView3d.startRotateMaterial();
                break;

            case "group":
                angleObject = -currentObjectView3d.rotation;
                break;
        }

        props.textField.current.textContent = Math.round(GEOM.toDeg(angleObject)) + "°";
    }

    let positionButtonRotation = () => {
        props.referense.current.style.top = Math.ceil(Math.sin(angleButtonRotation) * buttonsRadius ) + 'px';
        props.referense.current.style.left = Math.ceil(Math.cos(angleButtonRotation) * buttonsRadius ) + 'px';
    }

    let rotate = event => {
        var angleStep = G.toRad(45);
        var anglePrecision = G.toRad(3);
        var angleMouse = G.angleLine(position.x, position.y, event.clientX, event.clientY);
        var angle = angleMouse + angleObject;
        var angleNear = Math.round(angle / angleStep) * angleStep;
        var angleDelta = angleNear - angle;

        if ( Math.abs(angleDelta) < anglePrecision ) {
            angle += angleDelta;
            angleMouse += angleDelta;
        }

        angle = G.pacifyAngleRad(angle);
        angleMouse = G.pacifyAngleRad(angleMouse);
        angleButtonRotation = angleMouse;

        props.textField.current.textContent = Math.floor(GEOM.toDeg(angle)).toString() + "°";

        switch ( currentObjectView3d.objectType ) {
            case "product":
                currentObjectView3d.sceneObject.rotationY = -Math.floor(GEOM.toDeg(angle));
                currentObjectView3d.sceneObject.update();
                break;

            case "constructor":
                currentObjectView3d.rotateMaterial(angle, currentPartNum);
                currentObjectView3d.update();
                break;

            case "group":
                currentObjectView3d.setRotation(-angle);
                currentObjectView3d.update();
                dispatch(updateCurrentProductSize({...elemSize, width: currentObjectView3d.getWidth(), depth: currentObjectView3d.getDepth() }))
                break;
        }

        positionButtonRotation();

        if (mih._scene.currentView3DObject && mih._scene.currentView3DObject.objectType == "product") {
            mih._ruler3d.findRules(mih._scene.currentView3DObject);
        }
        else if (mih._scene.currentGroup) {
            mih._ruler3d.findRules(mih._scene.currentGroup);
        }
    }
    
    let stopRotate = event => {
        props.show();
        angleButtonRotation = 0;

        switch ( currentObjectView3d.objectType ) {
            case "product":
                break;

            case "constructor":
                currentObjectView3d.stopRotateMaterial();
                currentObjectView3d.update();
                break;

            case "group":
                break;
        }

        props.referense.current.style.left = rotateBtnStartPosition.x + 'px';
        props.referense.current.style.top = rotateBtnStartPosition.y + 'px';

        document.removeEventListener('mousemove', rotate);
        document.removeEventListener('mouseup', stopRotate);

        R2D.scene.history.saveState();

        dispatch(changeUndoRedo());

        setMouseDown(false);
    }

    let timeoutId = null;

    let mouseEnter = e => {
        if(!mouseDown) {
            let hoverDiv = props.referense.current.querySelector("." + classes.info_model_btn_hover);
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
            timeoutId = setTimeout(() => hoverDiv.style.display = "block", 1000);
        }
    }

    let mouseMove = e => {
        if(!mouseDown) {
            let hoverDiv = props.referense.current.querySelector("." + classes.info_model_btn_hover);
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
        }
    }

    let mouseLeave = e => {
        clearTimeout(timeoutId);
        props.referense.current.querySelector("." + classes.info_model_btn_hover).removeAttribute("style");
    }
    
    const classes = useStyle();
    return (
        <div className={classes.info_model_btn} style={{left: positionBtn.x, top: positionBtn.y}} ref={props.referense} >
            <img src="/src_designer/images/icon_rotate.svg"/>
            <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_ROTATION"]}</div>
            <div className={classes.info_model_btn_overlay} onMouseDown={startRotate} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
        </div>
    );
};

export default Rotate;