import React from 'react';
import { useDispatch } from 'react-redux';
import { setActivePopup, closeActivePopup, selectPopups, setPopupData } from '../../../../redux/features/popups/popupsSlice';
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.6)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000',
        background: '#63bbf0',
        // height: '86%',
        // width: '95%',
        margin: 'auto',

    },
    planer_popup_cont:{
        color: '#000',
        border: '1px solid #000',
        overflow: 'hidden',
        position: 'relative',
        width: 1257
    },
    modal_pro_wr:{
        background: '#63bbf0',
        width: '100%',
        height: '100%'
    },
    planer_popup_back:{
        position: 'absolute',
        left: 11,
        top: 12,
        cursor: 'pointer',
        zIndex: 1,
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close:{
        width: 14,
        height: 14,
        position: 'absolute',
        top: 0,
        right: 5,
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    done_popup_login_wrap:{
        width: '100%',
        padding: '4% 63px 4%  102px',
        position: 'relative'
    },
    shadow_planer_pop_top_left:{
        top: 0,
        left: 0,
        position: 'absolute',
        pointerEvents: 'none'
    },
    start_title:{
        color: '#282828',
        fontSize: 34,
        fontWeight: 300,
        marginBottom: 33,
        textAlign: 'center'
    },
    start_items:{
        display: 'grid',
        gridGap: '37px 23px',
        gridTemplateColumns: 'repeat(3, calc(334px))',
        justifyContent: 'center',
        overflow: 'auto',
        height: '52vh',
        padding: '4px 25px 4px 0',
    },
    start_item:{
        cursor: 'pointer',
        display: 'grid',
        position: 'relative',
        alignContent: 'center',
        '&:hover $start_item_img_wr:after':{
            opacity: 1
        }
    },
    start_item_img_wr:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 181,
        background: '#fff',
        marginBottom: 12,
        position: 'relative',
        border: '1px solid #fff',
        '&:after':{
            top: '-2%',
            left: '-1%',
            width: '102%',
            border: '1px solid #fff',
            height: '104%',
            content: '" "',
            position: 'absolute',
            boxShadow: 'inset 0px 0px 0px 4px #9dd900',
            opacity: 0,
        }
    },
    start_item_img:{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    start_item_title:{
        textAlign: 'center',
        fontSize: 15,
        color: '#373737',
        textTransform: 'uppercase'
    }

});

const StartPopupProjects = props => {
    const classes = useStyle();
    let dispatch = useDispatch();

    let backToStartPopup = e => {
        dispatch(closeActivePopup({name: "preConfigRoomsPopup"}))
        dispatch(setActivePopup({name: "startPopup"}))
    }
    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>


                    <svg className={classes.planer_popup_back} onClick={backToStartPopup} width="22" height="26" viewBox="0 0 22 26" fill="none" >
                        <path opacity="0.99" fill-rule="evenodd" clip-rule="evenodd" d="M13.0502 0L14.2311 1.15007L3.29643 11.8448H21.9996V13.5369H3.18054L14.2769 24.2224L13.1052 25.3814L1.18881 13.9061L1.18084 13.9139L0 12.7639L13.0502 0Z" fill="#232323"/>
                    </svg>


                    <div className={classes.planer_popup_close} id="js_close_popup" onClick={e => dispatch(closeActivePopup({name:"preConfigRoomsPopup"}))}>
                        <svg className={classes.planer_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                            <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                        </svg>
                    </div>


                    <div className={classes.done_popup_login_wrap}>

                        <svg className={classes.shadow_planer_pop_top_left} width="110" height="108"
                             viewBox="0 0 147 144" fill="none">
                            <path opacity="0.8" d="M0 0H147L0 144V0Z" fill="url(#paint0_linear_723_3148)"></path>
                            <defs>
                                <linearGradient id="paint0_linear_723_3148" x1="-7.00001" y1="133" x2="121.908"
                                                y2="7.90513" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#67B6EE"></stop>
                                    <stop offset="1" stopColor="#2B81BD" stopOpacity="0"></stop>
                                </linearGradient>
                            </defs>
                        </svg>


                        <div className={classes.start_title}>Preconfigurated rooms</div>
                        <div className={classes.start_items}>

                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/test_project_preview.jpg"/>
                                </div>
                                <div className={classes.start_item_title}>START FROM SCRATCH</div>
                            </div>
                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_2.svg"/>
                                </div>
                                <div className={classes.start_item_title}>UPLOAD A FLOORPLAN</div>
                            </div>
                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_3.svg"/>
                                </div>
                                <div className={classes.start_item_title}>MY PROJECTS</div>
                            </div>
                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_4.svg"/>
                                </div>
                                <div className={classes.start_item_title}>Preconfigurated rooms</div>
                            </div>

                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_1.svg"/>
                                </div>
                                <div className={classes.start_item_title}>START FROM SCRATCH</div>
                            </div>
                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_2.svg"/>
                                </div>
                                <div className={classes.start_item_title}>UPLOAD A FLOORPLAN</div>
                            </div>
                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_3.svg"/>
                                </div>
                                <div className={classes.start_item_title}>MY PROJECTS</div>
                            </div>
                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_4.svg"/>
                                </div>
                                <div className={classes.start_item_title}>Preconfigurated rooms</div>
                            </div>
                            <div className={classes.start_item}>
                                <div className={classes.start_item_img_wr}>
                                    <img className={classes.start_item_img} src="/src_designer/images/icon_start_4.svg"/>
                                </div>
                                <div className={classes.start_item_title}>Preconfigurated rooms</div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default StartPopupProjects;