import React from 'react';
import {createUseStyles} from "react-jss";
import { useEffect } from 'react';
import { useState } from 'react';

const useStyle = createUseStyles({
    editMatItemWr:{
        width: 100,
        height: 100,
        position: 'relative',
    },
    editMatItem:{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.23)',
        '&:hover':{
            '& $editMat':{
                opacity: 1
            }
        }
    },
    editMat:{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 30,
        transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
        background: 'rgba(68, 68, 68, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0
    },
    editMatImg:{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        backgroundSize: "cover"
    },
    editMatEditIcon:{
        width: 16,
        height: 16,
        cursor: 'pointer',
        margin: '0 5px',
        '& rect':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& rect':{
                fill: '#ffd119'
            }
        }
    },
});

const EditMaterialsItem = props => {
    const classes = useStyle();

    let [isLoaded, setIsLoaded] = useState({status: false, materialSrc: null, needUpdate: Math.random()});

    let mouseEnter = e => {
        let product = e.currentTarget;
        let hoverWindow = document.createElement("div");
        hoverWindow.classList.add("product_hover");
        hoverWindow.innerHTML = ~isLoaded.materialSrc.indexOf("#") ? isLoaded.materialSrc : R2D.Pool.getProductData(props.matId).name;
        document.querySelector("body").append(hoverWindow);
        hoverWindow.style.left = (product.getBoundingClientRect().left - hoverWindow.offsetWidth + 20) + "px";
        hoverWindow.style.top = (product.getBoundingClientRect().top + ((product.offsetHeight - hoverWindow.offsetHeight) / 2)) + "px";
    }
    
    let mouseLeave = () => document.querySelector(".product_hover") ? document.querySelector(".product_hover").remove() : null;

    let onPaste = e => props.onCopyPaste.index || !isNaN(props.onCopyPaste.index) ? props.onCopyPaste.paste(props.onCopyPaste.index) : props.onCopyPaste.paste();
    
    useEffect(() => {
        let timeoutId = null;
        if(props.matId == 0) setIsLoaded({...isLoaded, materialSrc: null, status: true});
        else if(~props.matId.indexOf("#")) setIsLoaded({...isLoaded, materialSrc: props.matId, status: true});
        else if(R2D.Pool.getProductData(props.matId)) setIsLoaded({...isLoaded, materialSrc: "url(" + R2D.URL.DOMAIN + R2D.Pool.getProductData(props.matId).source.images.preview + ")", status: true});
        else timeoutId = setTimeout(() => setIsLoaded({...isLoaded, needUpdate: Math.random()}), 500)
        return () => {
            clearTimeout(timeoutId);
            document.querySelector(".product_hover") ? document.querySelector(".product_hover").remove() : null
        }
    }, [isLoaded.needUpdate, props.matId])

    return (
        <>
            {
                props.matId != null ?
                <div className={classes.editMatItemWr}>
                    <div className={classes.editMatItem} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                        {
                            isLoaded.status ? 
                            <>
                                <div className={classes.editMatImg} style={{background: isLoaded.materialSrc, backgroundSize: 'cover'}} onClick={props.onClick}></div> 
                                <div className={classes.editMat}>
                                    <svg className={classes.editMatEditIcon} width="16" height="16" viewBox="0 0 16 16" fill="none" onClick={e => props.onCopyPaste.copy(props.matId)}>
                                        <rect x="3.5" y="0.5" width="12" height="12" stroke="white"/>
                                        <rect className={classes.rectHover2} y="3" width="2" height="1" fill="white"/>
                                        <rect className={classes.rectHover} y="15" width="13" height="1" fill="white"/>
                                        <rect className={classes.rectHover} y="15" width="11" height="1"
                                            transform="rotate(-90 0 15)" fill="white"/>
                                        <rect className={classes.rectHover} x="12" y="15" width="1" height="1"
                                            transform="rotate(-90 12 15)" fill="white"/>
                                    </svg>
                                    <svg className={classes.editMatEditIcon} width="15" height="16" viewBox="0 0 15 16" fill="none" onClick={onPaste}>
                                        <rect x="0.5" y="-0.5" width="11.1875" height="12" transform="matrix(1 0 0 -1 2.8125 15)" fill="white" stroke="white"/>
                                        <rect width="1.875" height="1" transform="matrix(1 0 0 -1 0 13)" fill="white"/>
                                        <rect width="12.1875" height="1" transform="matrix(1 0 0 -1 0 1)" fill="white"/>
                                        <rect width="11" height="0.9375" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 1)" fill="white"/>
                                        <rect width="1" height="0.9375" transform="matrix(-4.09794e-08 1 1 4.66255e-08 11.25 1)" fill="white"/>
                                    </svg>

                                </div>
                            </>
                            :
                            <div className={"preloader-cube"}></div>
                        }
                    </div>
                </div>
                :
                null
            }
        </>
    );
};

export default EditMaterialsItem;