import React, { useEffect, useRef } from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch, useSelector } from 'react-redux';
import { historyUpdate, selectActiveViewModeData } from '../../../../../../../../redux/features/viewMode/viewModeSlice';
import cx from 'classnames';
import { useState } from 'react';
import { user } from '../../../../../../../../init';
import { setActivePopup } from '../../../../../../../../redux/features/popups/popupsSlice';
import { selectTranslation } from '../../../../../../../../redux/features/language/languageSlice';
import { selectIsLogged } from '../../../../../../../../redux/features/user/userSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyle = createUseStyles({
    cont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all .2s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    wrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',
    },
    roll_out_left:{
        transform: "translateX(-100%)"
    },
    roll_out_right:{
        transform: "translateX(100%)"
    },
    title:{
        width: '100%',
        height: '29px',
        backgroundColor: 'rgba(129, 129, 129, 0.9)',
        fontSize: '13px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '0 5px'
    },
    planer_catalog_elem_wr:{
        width: "100%",
        display: "grid",
        gridGap: 10,
        gridTemplateColumns: "repeat(4, calc(25% - 5px))",
        padding: 15
    },
    planer_add_elem:{
        width: 46,
        height: 46,
        border: '1px solid #cdcdcd',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        userSelect: 'none',
        cursor: 'pointer',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '&:hover':{
            background: '#fcc749',
            '& $planer_catalog_prem_add_text':{
                opacity: 1
            }
        },
    },
    planer_add_elem_lock:{
        border: '1px solid #ca0707',
        '&:hover':{
            border: '1px solid #cdcdcd',
        },
    },
    planer_catalog_elem:{
        width: 46,
        height: 46,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        userSelect: 'none',
        cursor: 'pointer',
        boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.11)',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        overflow: 'hidden',
        '& img':{
            width: '100%',
            height: '100%',
        },
        '& $planer_catalog_prem_zamok': {
            width: 16,
            height: 16,
            position: 'absolute',
            top: '-3px',
            right: '-6px',
            cursor: 'pointer',
            '& rect':{
                transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
            },
            '&:hover':{
                '& rect':{
                    fill: '#ffd119'
                }
            }
        },
    },
    planer_catalog_prem_zamok: {

    },
    planer_catalog_elem_active:{
        boxShadow: '0 0 0 5px #bae51f',
    },
    planer_catalog_elem_add: {
        outline: '1px solid #d9d9d9',
        cursor: 'pointer',
        '&:hover':{
            background: '#fcc749',
            '& $planer_catalog_prem_add_text':{
                opacity: 1
            }
        },
    },
    planer_catalog_prem_add_text:{
        fontSize: 10,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        opacity:0,
    },
    planer_catalog_prem_add_img:{
        maxWidth: '60%',
        maxHeight: '60%'
    },
    
    planer_catalog_elem_upload:{
        borderBottom: "1px solid rgb(178, 178, 178)",
        paddingBottom: 15,
        width: "100%",
        display: "grid",
        gridGap: 10,
        gridTemplateColumns: "repeat(4, calc(25% - 5px))",
        "& img": {
            width: "auto"
        },
        "& $planer_catalog_elem_custom_delete":{
            height: "auto"
        }
    },
    planer_catalog_elem_custom:{
        "&:hover $planer_catalog_elem_custom_delete": {
            opacity: 1
        }
    },
    planer_catalog_elem_custom_delete:{
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
        opacity: 0,
        zIndex: 9,
    },
    planer_catalog_elem_list:{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, calc(25% - 5px))',
        gridGap: '10px',
        width: "100%",
        marginTop: 15
    },
    planer_catalog_elem_wr_flex:{
        padding: 15
    },
    planer_catalog_elem_overlay:{
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%'
    },
});

const CatalogMaterials = props => {
    
    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;
    let materialType = currentStateHistory[currentStateHistory.length - 1].materialType;
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    let translation = useSelector(selectTranslation);

    let isLogged = useSelector(selectIsLogged);

    let arrayList = [];
    let customMaterials = [];

    let dispatch = useDispatch();

    const classes = useStyle();

    let loaderCompleteListener = id => {
        props.materialSelect(id);
        props.changePreviewId(id)
    }

    let onMaterialSelect = id => {
        setActiveMaterial(id);
        if(!R2D.Pool.getProductData(id)) {
            let loader = R2D.Pool.loadProductData(id);
            loader.addEventListener(Event.COMPLETE, e => loaderCompleteListener(id));
            loader.addEventListener(Event.ERROR, e => loaderErrorListener(id));
        } else {
            props.materialSelect(id);
            props.changePreviewId(id)
        }
    }

    let mouseEnter = (event, data) => {
        document.querySelector(".product_hover") ? document.querySelector(".product_hover").remove() : null;
        let product = event.target;
        let hoverWindow = document.createElement("div");
        hoverWindow.classList.add("product_hover");
        hoverWindow.innerHTML = data.name;
        document.querySelector("body").append(hoverWindow);
        hoverWindow.style.left = (product.getBoundingClientRect().left - hoverWindow.offsetWidth + 5) + "px";
        hoverWindow.style.top = (product.getBoundingClientRect().top + ((product.offsetHeight - hoverWindow.offsetHeight) / 2)) + "px";
    }

    let mouseLeave = () => document.querySelector(".product_hover") ? document.querySelector(".product_hover").remove() : null;

    let onCustomMatAdd = e => user.isPro() ? dispatch(setActivePopup({name: "uploadCustom"})) : dispatch(setActivePopup({name: "payPro"}));

    let activeAnimationClass = isAdded ? classes.roll_out_right : classes.roll_out_left;

    let rightPanelData = useRef(null);

    let [activeMaterial, setActiveMaterial] = useState(props.matId);

    if(currentDataPanel && currentDataPanel.products) {
        currentDataPanel.products.forEach(i => {
            if(i.material_owner == true) {
                customMaterials.push(
                    <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_custom, {
                        [classes.planer_catalog_elem_active]:activeMaterial == i.id
                    })} key={i.id} >
                        <LazyLoadImage src={R2D.URL.DOMAIN + i.source.images.preview} placeholder={<div className='preloader-cube'></div>}/>
                        <LazyLoadImage src="/src_designer/images/icon_trash.svg" className={classes.planer_catalog_elem_custom_delete} onClick={e => dispatch(setActivePopup({name: "confirm", type: "delete_custom_material", data: i.id}))} placeholder={<div className='preloader-cube'></div>}/>
                        <div className={classes.planer_catalog_elem_overlay} onClick={e => onMaterialSelect(i.id)}></div>
                    </div>
                )
            } else {
                arrayList.push(
                    <div key={i.id} onMouseLeave={mouseLeave} onMouseEnter={e => mouseEnter(e, i)} onClick={e => onMaterialSelect(i.id)} className={cx(classes.planer_catalog_elem, {
                        [classes.planer_catalog_elem_active]:activeMaterial == i.id
                    })} >
                        <LazyLoadImage src={R2D.URL.DOMAIN + i.source.images.preview} placeholder={<div className='preloader-cube'></div>}/>
                    </div>
                )
            }
        });
    
    }
    
    useEffect(() => {
        setTimeout(async () => {
            let loadedData = await user.loadRightPanelData(currentStateHistory[currentStateHistory.length - 1].urlList);
            rightPanelData.current && rightPanelData.current.classList.remove(activeAnimationClass);
            dispatch(historyUpdate(loadedData));
        }, 100);
    }, []);

    useEffect(() => () => document.querySelector(".product_hover") ? document.querySelector(".product_hover").remove() : null);

    return (
        <div className={classes.cont + ' ' + activeAnimationClass} ref={ rightPanelData }>
            <div className={classes.wrap}>
                <div className={classes.wr}>
                    <div className={classes.title}>{ materialType || currentDataPanel && currentDataPanel.data && currentDataPanel.data.name || "" }</div>
                        {
                            currentDataPanel ?
                                customMaterials.length > 0 ?
                                <div className={classes.planer_catalog_elem_wr_flex}>
                                    <div className={classes.planer_catalog_elem_upload}>
                                        <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                                            <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                                            { !user.isPro() ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                                            <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                                        </div>
                                        { customMaterials }
                                    </div>
                                    <div className={classes.planer_catalog_elem_list}>
                                        { arrayList }
                                    </div>
                                </div>
                                :
                                
                                <div className={classes.planer_catalog_elem_wr}>
                                    {
                                        currentStateHistory[currentStateHistory.length - 1].matShowType == "bank" ?
                                        <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                                            <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                                            { user.data.plan != "pro" ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                                            <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                                        </div>
                                        :
                                        null
                                    }
                                    
                                    { arrayList }
                                </div>
                            :
                            currentStateHistory[currentStateHistory.length - 1].matShowType == "bank" ?
                                <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                                    <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                                    { user.data.plan != "pro" ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                                    <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                                </div>
                            :
                            null
                            
                        }
                </div>
            </div>
        </div>
    );
};

export default CatalogMaterials;