import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { hideQuickPanel, selectQuickPanels } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import { createUseStyles } from "react-jss";
import { planner } from '../../../../init';
import { useDispatch } from 'react-redux';
import { selectActiveViewMode } from '../../../../redux/features/viewMode/viewModeSlice';
import { changeUndoRedo } from '../../../../redux/features/topPanel/topPanelSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';

const useStyle = createUseStyles({
    info_model_pop_wrap:{
        position: 'absolute',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)'
    
    },
    info_model_pop_item_cont:{
        display: 'flex',
    },
    info_model_pop_item_wr:{
        width: 32,
        height:32,
        background: '#fff',
        position: 'relative',
        '&:last-child':{
            '& $info_model_pop_item':{
                borderRight: '1px solid #000'
            }
        },
    },
    info_model_pop_item:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        borderRight: '1px solid rgb(217, 217, 217)',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '& img':{
            maxWidth: '70%',
            maxHeight: '70%',
        },
        '&:hover':{
            background: '#ffd119'
        }
    },
    info_model_btn_hover:{
        position: "absolute",
        background: "#81e7ff",
        display: "none",
        zIndex: 9,
        fontSize: 12,
        width: "max-content",
        padding: "2px 7px"
    },
    info_model_btn_overlay:{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0
    }
});

const Delete = props => {
    let quickPanels = useSelector(selectQuickPanels);
    let position = quickPanels.delete.position;
    let dispatch = useDispatch();
    let activeViewMode = useSelector(selectActiveViewMode);
    let translation = useSelector(selectTranslation);
    let tileConfigurator = null;
    let tileEditor = null;
    let mouseDown = false;

    if(activeViewMode == "tileConfig") {
        tileConfigurator = planner.scene.getConfigurator();
        tileEditor = tileConfigurator.getTileEditor(); 
    }
    
    const classes = useStyle();

    let onMouseDown = e => mouseDown = true;

    let onDelete = e => {
        dispatch(hideQuickPanel({name: "delete"}));
        if(activeViewMode == "tileConfig") tileEditor.state.btnDeleteListener();
        else {
            if(planner.scene.getSelectedRuler()) planner.scene.delRuler(planner.scene.getSelectedRuler());
            else planner.constr.deleteSelected();
        }
        dispatch(changeUndoRedo());
        mouseDown = false;
    }

    let timeoutId = null;

    let mouseEnter = e => {
        let hoverDiv = mainDiv.current.querySelector("." + classes.info_model_btn_hover);
        if(!mouseDown) {
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
            timeoutId = setTimeout(() => hoverDiv.style.display = "block", 1000);
        }
    }

    let mouseMove = e => {
        let hoverDiv = mainDiv.current.querySelector("." + classes.info_model_btn_hover);
        if(!mouseDown) {
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
        }
    }

    let mouseLeave = e => {
        clearTimeout(timeoutId);
        mainDiv.current.querySelector("." + classes.info_model_btn_hover).removeAttribute("style");
    }

    let mainDiv = useRef(null);

    return (
        <>
            <div className={classes.info_model_pop_wrap} style={{left: position.x, top: position.y}}>
                <div className={classes.info_model_pop_item_cont}>
                    <div className={classes.info_model_pop_item_wr} ref={mainDiv} >
                        <div className={classes.info_model_pop_item}>
                            <img src="/src_designer/images/icon_trash.svg"/>
                            <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_DELETE"]}</div>
                            <div className={classes.info_model_btn_overlay} onMouseDown={onMouseDown} onMouseUp={onDelete} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Delete;