import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { user } from '../../../init'

export const languagesInit = createAsyncThunk(
  'language/languagesInit',
  async thunkAPI => {
    return await user.loadLanguages();
  }
)

export const translationInit = createAsyncThunk(
  'language/translationInit',
  async thunkAPI => {
    return await user.loadTranslation();
  }
);

export const languageChange = createAsyncThunk(
  'language/languageChange',
  async (code, thunkAPI) => {
    let languages = await user.setLanguageCode(thunkAPI.getState().language.languages, code);
    let translation = await user.loadTranslation();

    if(thunkAPI.getState().viewMode.active == "view2d" || thunkAPI.getState().viewMode.active == "view3d") {
      let start2D3DData = await user.loadRightPanelData(R2D.URL.CATALOG_TREE);
      thunkAPI.dispatch(historyClear({state: "stateEditing"}));
      thunkAPI.dispatch(setActiveViewModeState({state: "stateEditing", lastSavedScroll: 0, data: {type: "catalog", added: true, data: start2D3DData.tree}}));
    }

    return {languages, translation};
  }
);

export const languageSlice = createSlice({
  name: 'language',
  initialState: {translation: null, languages: []},
  extraReducers: builder => {
    builder.addCase(languagesInit.fulfilled, (state, action) => {
      state.languages = action.payload;
    });
    builder.addCase(translationInit.fulfilled, (state, action) => {
      state.translation = action.payload;
    });
    builder.addCase(languageChange.fulfilled, (state, action) => {
      return action.payload;
    });
  },
})

export const selectLanguages = state => state.language.languages;
export const selectTranslation = state => state.language.translation;

export default languageSlice.reducer;