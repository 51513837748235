import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import { setActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice';
import {createUseStyles} from "react-jss";
import { planner } from '../../../../init';
import { useEffect } from 'react';

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative'
    },
    planer_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    text:{
        color: 'rgb(47, 47, 47)',
        margin: '0 10px 15px',
        fontSize: 15,
        textAlign: 'center'
    },
    planer_popup_bottom:{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'center'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },
    popup_input: {
        padding: '0 10px',
        marginBottom: '20px',
        '& input': {
            width: '100%',
            height: '30px',
            padding: '0 5px',
            fontSize: '15px'
        }
    }

});

const SetWallHeightPopup = props => {

    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);

    let onKeyDown = e => {
        if(e.code == "Enter") onClickOk(e);
        else if(e.code == "Escape") onClose(e);
    }


    let onClickOk = e => {
        planner.constr.setAreaHeight(inputHeight.current.value);
        dispatch(setActiveViewModeState({state: "stateEditing"}));
        dispatch(closeActivePopup({name: "setWallHeight"}));
    };

    let onClose = e => {
        dispatch(closeActivePopup({name: "setWallHeight"}));
        dispatch(setActiveViewModeState({state: "stateEditing"}));
    }

    const classes = useStyle();

    let inputHeight = useRef(null);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => window.removeEventListener("keydown", onKeyDown);
    })

    return (
        <div className={classes.planer_popup_wrap + ' ' + classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <div className="planer_popup_login_wrap">
                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                        <div className={classes.text}>
                            <p>{ translation["TEXT_SET_HEIGHT_LOWER_CONTOUR"] }</p>
                        </div>
                        <div className={classes.popup_input}>
                            <input type="text" defaultValue={100} ref={inputHeight} name="wallHeight"/>
                        </div>
                        <div className={classes.planer_popup_bottom}>
                            <span className={classes.planer_btn_done} onClick={onClickOk}>{ translation["BUTTON_OK"] }</span>
                            <span className={classes.planer_btn_done} onClick={onClose}>{ translation["BUTTON_CANCEL"] }</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetWallHeightPopup;