import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveViewModeData } from '../../../../../../../redux/features/viewMode/viewModeSlice.js';
import StateEditing from './states/stateEditing/StateEditing.jsx';


const Main_TileConfig = props => {
    let activeViewModeData = useSelector(selectActiveViewModeData);

    return (
        <>
            {
                activeViewModeData.activeState == "stateEditing" ? <StateEditing /> : null
                
            }
        </>
    )
}

export default Main_TileConfig;