import React from 'react';
import Navigation from './navigation/Navigation.jsx';
import Content from './content/Content.jsx';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { selectRightPanelStatus } from '../../../../redux/features/common/commonSlice.js';

const useStyle = createUseStyles({
    planer_catalog_wrap:{
        position: 'absolute',
        top: '0',
        right: '0',
        width: '270px',
        height: '100%',
        zIndex: '1',
        userSelect: 'none',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planer_catalog_wrap__hidden:{
        position: 'absolute',
        top: '0',
        right: '0',
        width: 'auto',
        height: '100%',
        zIndex: '1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    }
});
const RightPanel = props => {
    let rightPanelStatus = useSelector(selectRightPanelStatus);

    const classes = useStyle();
    return (
        <div className={!rightPanelStatus ? classes.planer_catalog_wrap__hidden : classes.planer_catalog_wrap}>
            <Navigation />
            <Content onKeyDownConstr={props.onKeyDownConstr}/>
        </div>

    )
}

export default RightPanel;