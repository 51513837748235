import React, { forwardRef } from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { planner } from '../../../../../init';
import { selectTranslation } from '../../../../../redux/features/language/languageSlice';
import { changeUndoRedo } from '../../../../../redux/features/topPanel/topPanelSlice';
import { selectActiveViewModeData, selectActiveViewModeState, updateMaterialId } from '../../../../../redux/features/viewMode/viewModeSlice';

const useStyle = createUseStyles({
    info_model_btn:{
        position: 'absolute',
        border: '1px solid #000',
        width: 32,
        height: 32,
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)',
        userSelect: 'none',
        //transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '& img':{
            userSelect: "none",
            pointerEvents: "none"
        },
        '&:hover':{
            background: '#ffd119'
        }
    },
    info_model_btn_hover:{
        position: "absolute",
        background: "#81e7ff",
        display: "none",
        zIndex: 9,
        fontSize: 12,
        width: "max-content",
        padding: "2px 7px"
    },
    info_model_btn_overlay:{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0
    }
});

export const ClearRef = forwardRef(( { positionBtn }, ref) => <Clear positionBtn={positionBtn} referense={ref} />);

const Clear = props => {

    const classes = useStyle();
    let dispatch = useDispatch();

    let positionBtn = props.positionBtn;
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let currObj = planner.mih()._scene.currentView3DObject;
    let constructionData = currObj.getConstructorElementData();

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let translation = useSelector(selectTranslation);
    let mouseDown = false;
    let timeoutId = null;

    let onClear = e => {
        planner.scene.clearCurrentConstruction();
        if(activeViewModeState == "stateSelectedWall")
            dispatch(updateMaterialId({...currentStateHistory[0].matId, "wallMatId": R2D.ObjectConstructor3D.__checkMaterialId(currObj.type, constructionData.getMaterial())}));
        else if(activeViewModeState == "stateSelectedPlinth")
            dispatch(updateMaterialId(R2D.ObjectConstructor3D.__checkMaterialId(currObj.type, constructionData.getMaterial())));
        else if(activeViewModeState == "stateSelectedConstr")
            dispatch(updateMaterialId(R2D.ObjectConstructor3D.__checkMaterialId(currObj.type, constructionData.getMaterial())));
        
        dispatch(changeUndoRedo());
        mouseDown = false;
    }

    let mouseDownClear = e => {
        mouseDown = true;
        clearTimeout(timeoutId);
        props.referense.current.querySelector("." + classes.info_model_btn_hover).removeAttribute("style");
    }
    
    let mouseEnter = e => {
        let hoverDiv = props.referense.current.querySelector("." + classes.info_model_btn_hover);
        if(!mouseDown) {
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
            timeoutId = setTimeout(() => hoverDiv.style.display = "block", 1000);
        }
    }

    let mouseMove = e => {
        let hoverDiv = props.referense.current.querySelector("." + classes.info_model_btn_hover);
        if(!mouseDown) {
            hoverDiv.style.left = e.clientX - e.currentTarget.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - e.currentTarget.getBoundingClientRect().top + 10 + "px";
        }
    }

    let mouseLeave = e => {
        clearTimeout(timeoutId);
        props.referense.current.querySelector("." + classes.info_model_btn_hover).removeAttribute("style");
    }

    return (
        <div className={classes.info_model_btn} ref={props.referense} style={{left: positionBtn.x, top: positionBtn.y}}>
            <img src="/src_designer/images/icon_remove_pokrutya.svg"/>
            <div className={classes.info_model_btn_hover}>{translation["HINT_BUTTON_CLEAR_MATERIAL"]}</div>
            <div className={classes.info_model_btn_overlay} onMouseDown={mouseDownClear} onMouseUp={onClear} onMouseEnter={mouseEnter} onMouseMove={mouseMove} onMouseLeave={mouseLeave}></div>
        </div>
    )
}

export default Clear;