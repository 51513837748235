import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveViewModeData } from '../../../../../../../redux/features/viewMode/viewModeSlice.js';
import ListConstructor from './states/stateMain/ListConstructor.jsx';
import ListRenders from '../general/stateRendersShow/ListRenders.jsx';
import ListProjects from '../general/stateProjectsShow/ListProjects.jsx';
import RenderMake from '../general/stateRenderMake/RenderMake.jsx';
import StateSelectedCover from './states/stateSelectedCover/StateSelectedCover.jsx'
import StateSelectedArea from './states/stateSelectedArea/StateSelectedArea.jsx';
import StateAlignDrawing from './states/stateAlignDrawing/StateAlignDrawing.jsx';

const ConstructorMain = props => {

    let activeViewModeData = useSelector(selectActiveViewModeData);

    return (
        <>
            {
                activeViewModeData.activeState == "stateRendersShow" ? <ListRenders /> : 
                activeViewModeData.activeState == "stateProjectsShow" ? <ListProjects /> :
                activeViewModeData.activeState == "stateRenderMake" ? <RenderMake /> : 
                activeViewModeData.activeState == "stateSelectedCover" ? <StateSelectedCover /> :
                activeViewModeData.activeState == "stateSelectedArea" ? <StateSelectedArea /> : 
                activeViewModeData.activeState == "stateAlignDrawing" ? <StateAlignDrawing /> : <ListConstructor onKeyDownConstr={props.onKeyDownConstr}/>
            }
            
            
        </>
    )
}

export default ConstructorMain;