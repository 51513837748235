import React from 'react';
import { useSelector } from 'react-redux';
import { selectPopups } from '../../../../redux/features/popups/popupsSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '20px 30px',
        position: 'relative'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    text:{
        color: 'rgb(47, 47, 47)',
        fontSize: 15,
        textAlign: 'center'
    },
});

const LoadingMessagePopup = props => {

    let translation = useSelector(selectTranslation);
    let popups = useSelector(selectPopups);

    const classes = useStyle();

    let text = popups.loadingMessage.data;

    if(popups.loadingMessage.type != "scene_load_progress") text = translation[text];

    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <div className="planer_popup_login_wrap">
                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                        <div className={classes.text}>
                            <p dangerouslySetInnerHTML={{__html: text}}>{  }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingMessagePopup;