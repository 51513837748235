import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from 'react-redux';
import { planner, user } from '../../../../../../../../init';
import { changeActivePano, selectActivePano, selectFreeIds, selectRightPanelStatus, updateRightPanelStatus } from '../../../../../../../../redux/features/common/commonSlice';
import { selectWallsHeight } from '../../../../../../../../redux/features/constructor/constructorSlice';
import { selectTranslation } from '../../../../../../../../redux/features/language/languageSlice';
import { setActivePopup } from '../../../../../../../../redux/features/popups/popupsSlice';
import { selectUserProfileData, userCheckIsLogged, userProfileDataLoad } from '../../../../../../../../redux/features/user/userSlice';
import { historyAdd, selectActiveViewMode, selectActiveViewModeData, setActiveViewModeState } from '../../../../../../../../redux/features/viewMode/viewModeSlice';
import ChangeSize from '../../../../../../components/common/changeSize/index.jsx'
import StateEditing from '../../pano/stateEditing/StateEditing';
import bgSky from './images/bg_sky.jpg'
import { motion } from 'framer-motion';

let startRenderBtn = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    cursor: 'pointer',
    marginTop: 15,
    position: "relative",
    minHeight: 46
}

const useStyle = createUseStyles({
    planerWallsCont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planerWallsWrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',
        padding: 20,
        display: 'grid',
        gridGap: 30,
        alignContent: 'space-between',
    },
    title:{
        fontSize: '18px',
        color: '#373737',
        marginBottom: 13,
        fontWeight: 300,
        letterSpacing: '-1px',
    },

    viewItemStandart:{
        width: '100%',
        height: 115,
        border: '1px solid #dddddd',
        position: 'relative',
        zIndex: 1,
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        gridGap: 7,
        cursor: 'pointer',
        marginBottom: 34,
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            },
            '& $help_bot_item':{
                color: '#ffd119'
            }
        }
    },
    line:{
        background: '#C4C4C4',
        opacity: 0.3,
        height: 1,
        margin: '13px 0'
    },
    changeSizeWr:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 13,
        marginBottom: 11
    },
    text:{
        fontSize: 13,
        color: '#828282',
        '& span':{
           color: '#373737',
            fontWeight: 700
        }
    },
    input:{
        outline: 'none',
        border: 'none',
        padding: '3px 5px',
        color: '#444444',
        fontSize: 13,
        textAlign: 'right',
        margin: '0 4px 0 4px',
        '&::-webkit-inner-spin-button':{
            display: 'none'
        },
        '&:hover':{
            background: 'rgb(244, 244, 244)'
        }
    },
    arrows:{
        display: 'grid'
    },
    arrowUp:{
        transform: 'rotate(90deg)',
        cursor: 'pointer',
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& path':{
                fill: '#FFCC00'
            }
        }
    },
    arrowDown:{
        transform: 'rotate(-90deg)',
        cursor: 'pointer',
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& path':{
                fill: '#FFCC00'
            }
        }
    },
    changeHeightWr:{
        height: 120,
        position: 'relative'
    },
    changeHeightLine:{
        width: 1,
        background: '#C4C4C4',
        height: '100%',
        position: 'absolute',
        left: '50%',
        top: 0,
        '&:before':{
            content: '" "',
            width: 11,
            height: 1,
            background: '#C4C4C4',
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translateX(-50%)',
        }
    },
    changeHeightLineActive:{
        width: 1,
        background: '#9DD900',
        height: 85,
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
    changeHeightIconWr:{
        right: '50%',
        position: 'absolute',
        transform: 'translateX(12px) rotateY(180deg)',
        display: "flex"
    },
    changeHeightIcon:{
        cursor: 'pointer'
    },
    changeHeightIconArea:{
        position: 'absolute',
        left: 32,
        top: '50%',
        transform: 'translateY(-45%)',
    },
    changeHeightBot:{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:before':{
            content: '" "',
            width: '100%',
            height: 1,
            background: '#C4C4C4',
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
        }
    },
    changeHeightBotText:{
        fontSize: 13,
        color: '#828282',
        position: 'relative',
        background: '#fff',
        padding: 4
    },
    choiseRenderWr:{
        border: 'none',
        marginBottom: 40
    },
    choiseRenderBtn:{
        display: 'flex',
        justifyContent: 'space-between',
    },
    choiseRenderRadio:{
        position: 'relative'
    },
    choiseRenderRadioInput:{
        position: 'absolute',
        zIndex: '-1',
        opacity: 0,
        '&+label':{
            color: '#373737',
            fontWeight: '700',
            fontSize: 16,
            paddingLeft: 25,
            alignItems: 'center',
            userSelect: 'none',
            cursor: 'pointer',
            display: 'block',
            '& span':{
                color: '#828282',
                fontSize: 13,
                display: 'block',
            },
            '&:before':{
                content: '" "',
                transition: 'all .9s cubic-bezier(.19,1,.22,1)',
                width: 17,
                height: 17,
                borderRadius: '50%',
                background: '#E7E7E7',
                position: 'absolute',
                boxShadow: '0px 1px 0px 0px rgb(255 255 255 / 0%), inset 0px 1px 0px 0px rgb(191 191 191 / 85%)',
                left: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
                marginRight: 5
            },
            '&:after':{
                content: '" "',
                transition: 'all .9s cubic-bezier(.19,1,.22,1)',
                width: 9,
                height: 9,
                borderRadius: '50%',
                background: '#9DD900',
                border: '1px solid #9DD112',
                position: 'absolute',
                left: 3,
                top: 0,
                bottom: 0,
                margin: 'auto',
                marginRight: 5,
                opacity: 0
            },
        },
        '&:checked':{
            '&+label:after':{
                opacity: 1
            }
        }
    },
    choiseRenderPriceWr:{
        fontSize: 13,
        textAlign: 'right',
    },
    choiseRenderPrice:{
        '& span':{
            fontWeight: 700,
            display: 'inline-block',
            marginRight: 4
        }
    },
    choiseRenderExempleBtn:{
        color: '#66ACF4',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover':{
            textDecoration: 'none',
        }
    },
    textWrap:{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: "column",
        marginBottom: 6
    },
    metric:{
        marginLeft: 10
    },
    getMoreCredits:{
        color: '#66ACF4',
        fontSize: 13,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover':{
            textDecoration: 'none',
        }
    },
    zeroCredits:{
        fontSize: 13,
        color: '#EA1818'
    },
    startRenderBtn:{
        extend: startRenderBtn,
        background: '#66ACF4',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#fcc749'
        }
    },
    startRenderBtn_disabled:{
        extend: startRenderBtn,
        border: '1px solid #999999',
        backgroundColor: '#cccccc',
        color: '#666666',
        pointerEvents: 'none'
    },
    startRenderIcon:{
        width: 36,
        marginRight: 9,
    },
    startRenderText:{
        color: '#fff',
        textTransform: 'uppercase',
        fontSize: 20,
        cursor: 'pointer',
    },
    closeBtn:{
        position: "absolute",
        right: "calc(270px + 20px)",
        top: 20
    },
    planer_popup_close_icon:{
        width: "100%",
        cursor: "pointer",
        "& path":{
            transition: "all 0.9s cubic-bezier(.19,1,.22,1)"
        },
        "&:hover path":{
            fill: "#ffd119"
        }
    },
});

const RenderMake = () => {
    const classes = useStyle();

    let translation = useSelector(selectTranslation);
    
    let activeViewMode = useSelector(selectActiveViewMode);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataType = currentStateHistory[currentStateHistory.length - 1].type;
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;


    let activeIntId = planner.renders.activeIntId;

    let activePano = useSelector(selectActivePano);

    let userProfileData = useSelector(selectUserProfileData);
    let credits = (+userProfileData.credits).toFixed(0);

    let isPro = userProfileData.plan == "pro" ? true : false;
    let freeIds = useSelector(selectFreeIds);

    let dispatch = useDispatch();

    let [cameraHeight, setCameraHeight] = useState(null);
    let [cameraIconHeight, setCameraIconHeight] = useState(0);
    let [cameraRatio, setCameraRatio] = useState(null);

    let [activeCheckbox, setActiveCheckbox] = useState(null);

    let [preloadActive, setPreloadActive] = useState(false);

    let [mouseDown, setMouseDown] = useState({status: false, action: null});

    let rightPanelStatus = useSelector(selectRightPanelStatus);

    let onScreenshotMake = e => {
        let link = document.createElement("a");
        link.setAttribute("href", user.makeScreenShot());
        link.setAttribute("download", 'screenshot.jpg');
        link.click();
    }

    let changeCheckboxActive = e => setActiveCheckbox(mode_screenshot.current.checked ? 0 : mode_fullHD.current.checked ? 3 : mode_4k.current.checked ? 4 : null);

    let onClickRenderBtn = async () => {
        if(preloadActive) return;

        if(mode_screenshot.current.checked) {
            onScreenshotMake();
            return;
        }

        setPreloadActive(true);

        let activeInput = mode_fullHD.current.checked ? 3 : mode_4k.current.checked ? 4 : null;
        let newRenderId = await user.makeRender({type: activeInput, environment: null});
        
        dispatch(setActivePopup({name: "renderMade"}));

        new Promise((resolve, reject) => {
            resolve(dispatch(userCheckIsLogged()))
        }).then(result => {
            dispatch(userProfileDataLoad());
            setPreloadActive(false);
        });

        if(activeIntId) {
            clearInterval(activeIntId);
            planner.renders.activeIntId = null;
        }

        planner.renders.inProgressIds.push(newRenderId);

        let key = setInterval(async () => {
            let renders = await user.loadRenders();
            let inProgressIds = planner.renders.inProgressIds;
            if(renders) {
                renders.forEach(item => {
                    if(inProgressIds.length < 1) {
                        clearInterval(key);
                        planner.renders.activeIntId = null;
                    }
                    inProgressIds.forEach(renderId => {
                        if(item.render_id == renderId && item.status == "stored") {
                            planner.apiScene.dispatchEvent(new Event(planner.apiScene.RENDERS_UPDATE, renders));
                            planner.renders.inProgressIds = inProgressIds.filter(i => i != renderId);
                        }
                    })
                })
            }
        }, 15000)

        planner.renders.activeIntId = key;
    }

    let [cameraIconMaxTopOffset, setCameraIconMaxTopOffset] = useState(null);

    let onRenderClose = async e => {
        
        user.hideRenderFrame();

        let isActivePanoFree = freeIds.find(i => i.id == activePano.id);
        
        if(!isPro && !isActivePanoFree) {
            dispatch(changeActivePano({ id: 0, previewImgSrc: "", rotation: 0}));
            await R2D.scene.setSkyboxMat(0);
            R2D.scene.setSkyboxRotation(0);
            R2D.scene.history.saveState();
        }

        planner.scene.setRightPanelStatusBefore();
        dispatch(updateRightPanelStatus(R2D.Viewers._instance.rightPanelStatusBefore));
        
        dispatch(setActiveViewModeState({state: activeViewModeData.prevState}));
    }

    let maxCameraHeight = useSelector(selectWallsHeight);
    
    let mode_screenshot = useRef(null);
    let mode_fullHD = useRef(null);
    let mode_4k = useRef(null);

    const variants = {
        visible: { transform:  "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }

    useEffect(() => {
        if(activeViewMode != "viewWalk") planner["viewWalk"].activate();
        
        planner.mih()._currentViewer.changeIsRenderStateActive(true);
        planner.mih()._currentViewer.hideCursor();
        let newCameraHeight = R2D.Viewers.getCameraData().position.y;
        setCameraHeight(newCameraHeight);
        user.showRenderFrame();
        //planner.setSize(R2D.Viewers._instance.isRightPanelOpen ? window.innerWidth - 270 : window.innerWidth, window.innerHeight);
        
        let cameraIconMaxTopOffset = cameraIconDiv.current.offsetHeight - cameraIcon.current.offsetHeight;
        let cameraRatio = maxCameraHeight / (cameraIconMaxTopOffset);
        setCameraIconHeight(cameraIconMaxTopOffset - (newCameraHeight / cameraRatio).toFixed(0));
        setCameraRatio(cameraRatio);
        setCameraIconMaxTopOffset(cameraIconMaxTopOffset);
        
           
    }, [])

    let startDragPosition = 0;

    let dragCameraIcon = e => {
        startDragPosition = e.clientY;
        document.addEventListener("mousemove", onMoveCameraIcon);
        document.addEventListener("mouseup", onStopMoveCameraIcon);
    }

    let updateHeightValue = (mouseDown, value) => {
        setMouseDown(mouseDown);
        value = value < 1 ? 0 : value;
        value = value > maxCameraHeight ? maxCameraHeight : value;

        planner.mih()._currentViewer.cameraMoveY(value);
        let newCameraHeightValue  = ((value / cameraRatio) - cameraIconMaxTopOffset) / -1;
        setCameraHeight(value);
        setCameraIconHeight(newCameraHeightValue);
    }

    let onMoveCameraIcon = e => {
        let moveProgress = e.clientY - startDragPosition;
        let newCameraHeightValue = null;
        if(moveProgress < 0) {
            newCameraHeightValue = cameraIconHeight + moveProgress;
            newCameraHeightValue = newCameraHeightValue < 1 ? 0 : newCameraHeightValue;
        } else {
            newCameraHeightValue = cameraIconHeight + moveProgress;
            newCameraHeightValue = newCameraHeightValue > cameraIconMaxTopOffset ? cameraIconMaxTopOffset : newCameraHeightValue;
        }

        let newCameraHeight = (cameraIconMaxTopOffset - newCameraHeightValue) * cameraRatio;

        setCameraIconHeight(newCameraHeightValue);
        setCameraHeight(newCameraHeight);

        planner.mih()._currentViewer.cameraMoveY(newCameraHeight);
    }

    let onStopMoveCameraIcon = e => {
        document.removeEventListener("mousemove", onMoveCameraIcon);
        document.removeEventListener("mouseup", onStopMoveCameraIcon);
    }

    let cameraIcon = useRef(null);
    let cameraIconDiv = useRef(null);

    let onPanoChange = e => dispatch(historyAdd({type: "changePano"}));

    useEffect(() => {
        let closeBtn = document.createElement("div");
        closeBtn.classList.add(classes.closeBtn);
        closeBtn.innerHTML = `<svg class="${classes.planer_popup_close_icon}" viewBox='0 0 16.03 16.02' width='24px' height='24px'><path fill='#ffffff' d='M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z'></path></svg>`;
        document.querySelector("body").append(closeBtn);
        closeBtn.addEventListener("click", onRenderClose);
        return () => closeBtn.remove();
    }, [activePano, rightPanelStatus])

    return (
        <>
            {
                currentDataType == "changePano" ?
                <StateEditing />
                :
                <motion.div className={classes.planerWallsCont} transition={{ ease: "easeOut", duration: .1 }} initial="hidden" animate="visible" exit={"exit"} variants={variants}>
                    <div className={classes.planerWallsWrap}>
                        <div>
                            <div className={classes.title}>{ translation["RENDER_PANO_CHOOSE"] }</div>
                            <div className={classes.viewItemStandart} style={{background: `url('${activePano.previewImgSrc ? R2D.URL.DOMAIN + activePano.previewImgSrc : bgSky}')`}} onClick={onPanoChange}></div>
                            <div className={classes.line}></div>

                            <div className={classes.changeSizeWr}>
                                <ChangeSize mouseDown={mouseDown} changeValue={updateHeightValue} value={cameraHeight && cameraHeight.toFixed(0)} label={translation["RENDER_CAMERA_HEIGHT"]} />
                                <div className={classes.metric}>{planner.units.getName()}</div>
                            </div>

                            <div className={classes.changeHeightWr} ref={cameraIconDiv} onMouseDown={dragCameraIcon}>
                                <div className={classes.changeHeightLine}>
                                    <div className={classes.changeHeightLineActive}></div>
                                </div>
                                <div className={classes.changeHeightIconWr} ref={cameraIcon} style={{top: cameraIconHeight + "px"}}>
                                    <svg className={classes.changeHeightIcon} width="35" height="35" viewBox="0 0 35 35" fill="none">
                                        <rect x="7.61133" y="10.2478" width="6.9342" height="15.8496" rx="1" transform="rotate(1.24049 7.61133 10.2478)" fill="#2D8BDB"/>
                                        <rect x="14.9629" y="13.8752" width="1.4859" height="8.9154" rx="0.74295" transform="rotate(1.24049 14.9629 13.8752)" fill="#2D8BDB"/>
                                        <rect x="8.63477" y="8.78369" width="4.953" height="0.9906" rx="0.4953" transform="rotate(1.24049 8.63477 8.78369)" fill="#2D8BDB"/>
                                        <rect x="16.9551" y="13.4232" width="9.906" height="9.906" rx="1" transform="rotate(1.24049 16.9551 13.4232)" fill="#2D8BDB"/>
                                        <rect x="27.3633" y="13.1531" width="2.9718" height="10.8966" rx="1" transform="rotate(1.24049 27.3633 13.1531)" fill="#2D8BDB"/>
                                    </svg>
                                    <svg className={classes.changeHeightIconArea} width="98" height="101" viewBox="0 0 98 101" fill="none">
                                        <path opacity="0.2" d="M0.125 39L98 0V101L0 48.9343L0.125 39Z" fill="#C4C4C4"/>
                                    </svg>
                                </div>
                            </div>
                            <div className={classes.changeHeightBot}>
                                <div className={classes.changeHeightBotText}>{ translation["RENDER_FLOOR"] }</div>
                            </div>
                        </div>

                        <div>
                            <fieldset className={classes.choiseRenderWr}>
                                <div className={classes.choiseRenderBtn} >
                                    <div className={classes.choiseRenderRadio}>
                                        <input className={classes.choiseRenderRadioInput} onClick={changeCheckboxActive} ref={mode_screenshot} type="radio" id="mode_screenshot" name="color"/>
                                        <label htmlFor="mode_screenshot">
                                            { translation["RENDER_SCREENSHOT"] }
                                        </label>
                                    </div>
                                    <div className={classes.choiseRenderPriceWr}>
                                        <div className={classes.choiseRenderPrice}>{ translation["RENDER_FREE"] }</div>
                                    </div>
                                </div>
                                <div className={classes.line}></div>
                                <div className={classes.choiseRenderBtn}>
                                    <div className={classes.choiseRenderRadio}>
                                        <input className={classes.choiseRenderRadioInput} onClick={changeCheckboxActive} ref={mode_fullHD} type="radio" id="mode_fullHD" name="color" defaultChecked="checked" value="Так"/>
                                        <label htmlFor="mode_fullHD">
                                            { translation["RENDER_FULL_HD"] }
                                            <span>1920x1080</span>
                                        </label>
                                    </div>
                                    <div className={classes.choiseRenderPriceWr} onClick={e => dispatch(setActivePopup({name: "renderExample"}))}>
                                        <div className={classes.choiseRenderPrice}><span>1</span>{translation["RENDER_CREDIT_2K"]}</div>
                                        <div className={classes.choiseRenderExempleBtn}>{ translation["RENDER_EXAMPLE"] }</div>
                                    </div>
                                </div>
                                <div className={classes.line}></div>
                                <div className={classes.choiseRenderBtn} >
                                    <div className={classes.choiseRenderRadio}>
                                        <input className={classes.choiseRenderRadioInput} onClick={changeCheckboxActive} ref={mode_4k} type="radio" id="mode_4k" name="color" value="Так"/>
                                        <label htmlFor="mode_4k">
                                        { translation["RENDER_4K"] }
                                            <span>3840x2160</span>
                                        </label>
                                    </div>
                                    <div className={classes.choiseRenderPriceWr}>
                                        <div className={classes.choiseRenderPrice}><span>2</span>{translation["RENDER_CREDIT_4K"]}</div>
                                    </div>
                                </div>

                            </fieldset>

                            <div className={classes.textWrap}>
                                <div className={classes.text}>{translation["RENDER_CREDITS_AMOUNT"]} <span>{ (+userProfileData.credits).toFixed(0) }</span></div>
                                <div className={classes.getMoreCredits} onClick={e => dispatch(setActivePopup({name: "buyCredits"}))}>{translation["RENDER_GET_MORE"]}</div>
                            </div>

                            {
                                credits < 1 && (activeCheckbox == 3 || activeCheckbox == 4) ? 
                                    <div className={classes.zeroCredits}>{translation["RENDER_NOT_ENOUGH"]}</div>
                                :
                                    null
                            }

                            <div className={credits < 1 && (activeCheckbox == 3 || activeCheckbox == 4) || preloadActive ? classes.startRenderBtn_disabled : classes.startRenderBtn} onMouseUp={onClickRenderBtn}>
                                {
                                    preloadActive ?
                                    <div className='preloader-cube'></div>
                                    :
                                    <>
                                        <svg className={classes.startRenderIcon} width="35" height="26" viewBox="0 0 35 26" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M25.0601 3.5255H30.1606C31.5673 3.5255 32.3595 4.08467 33.3544 5.07988C34.3491 6.07509 34.908 7.42505 34.908 8.83271V20.4384V25.7455H29.6035H5.30441H0V20.4384V8.83271C0 7.42505 0.744521 6.07509 1.73921 5.07988C2.73416 4.08466 3.3407 3.52554 4.74737 3.52554H9.85879C9.89313 3.4259 9.95157 3.33624 10.0285 3.26413V3.26437L13.0019 0.480437C13.2753 0.223898 13.8064 0.0131617 14.1814 0.0131617L20.7416 1.8875e-06C21.1166 -0.000724082 21.6484 0.20806 21.9211 0.4646L24.8802 3.23413C24.9651 3.31331 25.0275 3.41392 25.0601 3.5255ZM28.4001 9.77932C29.1716 9.77932 29.7971 9.15384 29.7971 8.38227C29.7971 7.61071 29.1716 6.98523 28.4001 6.98523C27.6285 6.98523 27.003 7.61071 27.003 8.38227C27.003 9.15384 27.6285 9.77932 28.4001 9.77932ZM25.6059 13.9704C25.6059 18.5998 21.8531 22.3527 17.2237 22.3527C12.5943 22.3527 8.84141 18.5998 8.84141 13.9704C8.84141 9.34104 12.5943 5.58817 17.2237 5.58817C21.8531 5.58817 25.6059 9.34104 25.6059 13.9704ZM17.2236 19.5586C20.3098 19.5586 22.8117 17.0567 22.8117 13.9705C22.8117 10.8842 20.3098 8.38228 17.2236 8.38228C14.1373 8.38228 11.6354 10.8842 11.6354 13.9705C11.6354 17.0567 14.1373 19.5586 17.2236 19.5586Z" fill="white"/>
                                        </svg>
                                        <div className={classes.startRenderText}>{ translation["RENDER_START"] }</div>
                                    </>
                                }
                                
                            </div>
                        </div>

                    
                    </div>
                </motion.div>

            }
        
        </>
        
        
    );
};

export default RenderMake;