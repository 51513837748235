import { createSlice } from '@reduxjs/toolkit'
import { planner, user } from '../../../init';

export const commonSlice = createSlice({
  name: 'common',
  initialState: { rightPanelStatus: true, lockState: true, activePano: { id: 0, previewImgSrc: "", rotation: 0 }, freeIds: [], currentProjectHash: "", countryLoad: "" },
  reducers: {
    changeLockState: (state, action) => {
      state.lockState = action.payload;
    },
    changeActivePano: (state, action) => {
      state.activePano = { ...action.payload };
    },
    updateFreeIds: (state, action) => {
      state.freeIds = action.payload;
    },
    updateRightPanelStatus: (state, action) => {
      state.rightPanelStatus = action.payload;
    },
    updateCurrentProjectHash: (state, action) => {
      state.currentProjectHash = action.payload;
    },
    updateCountryLoad: (state, action) => {
      state.countryLoad = action.payload;
    }
  },
})

export const selectLockState = state => state.common.lockState;
export const selectActivePano = state => state.common.activePano;
export const selectFreeIds = state => state.common.freeIds;
export const selectRightPanelStatus = state => state.common.rightPanelStatus;
export const selectCurrentProjectHash = state => state.common.currentProjectHash;
export const selectCountryLoad = state => state.common.countryLoad;

export const { changeLockState, changeActivePano, updateFreeIds, updateRightPanelStatus, updateCurrentProjectHash, updateCountryLoad } = commonSlice.actions; 

export default commonSlice.reducer;