import { configureStore, combineReducers  } from '@reduxjs/toolkit';
import popupsReducer from "../features/popups/popupsSlice"
import viewModeReducer from "../features/viewMode/viewModeSlice"
import languageReducer from "../features/language/languageSlice"
import userReducer from '../features/user/userSlice';
import topPanelReducer from '../features/topPanel/topPanelSlice';
import constructorReducer from '../features/constructor/constructorSlice';
import quickPanelsReducer from '../features/quickPanels/quickPanelsSlice'
import metricsReducer from '../features/metrics/metricsSLice';
import tipsReducer from '../features/tips/tipsSlice';
import commonReducer from '../features/common/commonSlice';

export const store = configureStore({
  reducer: combineReducers({
    popups: popupsReducer, 
    viewMode: viewModeReducer, 
    language: languageReducer,
    user: userReducer,
    topPanel: topPanelReducer,
    constr: constructorReducer,
    quickPanels: quickPanelsReducer,
    metrics: metricsReducer,
    tips: tipsReducer,
    common: commonReducer
  })
});