import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyAdd, selectActiveViewModeData } from '../../../../../../redux/features/viewMode/viewModeSlice';

const HomeButton = props => {

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let lastElemHistory = null;
    let firstElemHistory = null;
    if(currentStateHistory) {
        lastElemHistory = currentStateHistory[currentStateHistory.length - 1];
        firstElemHistory = currentStateHistory[0];
    }

    let isHomeDisabled = (
        !currentStateHistory || lastElemHistory && firstElemHistory.data && lastElemHistory.data == firstElemHistory.data
        ) || 
        (
            lastElemHistory && firstElemHistory.type == "main" && lastElemHistory.type == "main"
        ) ||
        currentStateHistory.length < 2 || 
        ( lastElemHistory && firstElemHistory.matId && lastElemHistory.matId == firstElemHistory.matId);

    let dispatch = useDispatch();

    return (
        <div className={isHomeDisabled ? "planer_catalog_btn_not_active planer_catalog_btn" : "planer_catalog_btn"} onClick={e => dispatch(historyAdd(firstElemHistory))}>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 8.52667L0.724976 9.27105L8.03955 1.79238L15.2694 9.30016L16 8.56157L8.76611 1.04955L8.77112 1.04443L8.04614 0.300049L0 8.52667ZM14 8H13V14H11V11V10H5V11V14H3V8H2V15H3H5H6V11H10V15H11H13H14V8Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 8.52667L0.724976 9.27105L8.03955 1.79238L15.2694 9.30016L16 8.56157L8.76611 1.04955L8.77112 1.04443L8.04614 0.300049L0 8.52667ZM14 8H13V14H11V11V10H5V11V14H3V8H2V15H3H5H6V11H10V15H11H13H14V8Z" fill="white"/>
            </svg>
        </div>
    )
}

export default HomeButton;