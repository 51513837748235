import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup, setActivePopup } from '../../../../../redux/features/popups/popupsSlice';
import {createUseStyles} from "react-jss";
import { selectTranslation } from '../../../../../redux/features/language/languageSlice';
import { historyUpdate, selectActiveViewModeData, selectActiveViewModeState } from '../../../../../redux/features/viewMode/viewModeSlice';
import { useEffect } from 'react';
import MaterialSize from './components/MaterialSize';
import { user } from '../../../../../init';

const useStyle = createUseStyles({

    planerChangeSizesWrap:{
        display: 'grid',
        gridTemplateColumns: 'auto 27px',
        gridGap: 24,
        marginBottom: 20,
        paddingRight: 10
    },
    planerChangeSizes:{

    },
    changeSizesLockWr:{
        position: 'relative',
        display: 'grid',
        paddingTop: 13
    },
    lock_main:{
        position: 'absolute',
        top: 25,
        right: '-8px',
        '& svg': {
            width: 16,
            cursor: 'pointer'
        }
    },
    
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        width: 'auto',
        height: 'auto'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    planer_popup_top: {
        position: 'relative',
        zIndex: 9,
        width: 500,
        height: 220,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        background: '#fff',
    },

    planer_popup_top_text:{
        fontSize: '18px',
        color: '#444444',
        whiteSpace: 'nowrap'
    },

    planer_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        zIndex: 999,
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },

    planer_popup_bottom:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        fontSize: 13,
        color: 'rgb(47, 47, 47)',
    },
    planer_popup_bottom_size:{
        display: "flex",
        alignItems: "center"

    },
    planer_popup_bottom_size_btn:{
        marginRight: 40
    },
    green_outline: {
        outline: 'solid 2px #bae51f',
        outlineOffset: '-4px'
    },
    planer_popup_bottom_upload:{
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        height: 25,
        margin: '0 10px',
        display: 'flex',
        outline: 'rgb(217, 217, 217) solid 1px',
        padding: '0 20px',
        fontSize: 12,
        minWidth: 94,
        background: '#828282',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover':{
            background: '#ffd119',
        }
    }
});
const UploadCustomPopup = props => {

    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let activeViewModeDataState = useSelector(selectActiveViewModeState);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let privateMaterialType = currentStateHistory[currentStateHistory.length - 1].privateMaterialType;
    let categoryId = currentStateHistory[currentStateHistory.length - 1].categoryId;


    var defWidth = 800;
    var defHeight = 600;

    var defImageWidth = 60;
    var defCarpetWidth = 120;
    var uploadType = privateMaterialType;
    var uploadCategory = categoryId;
    var uploadToBank = activeViewModeDataState == "stateEditing" ? false : true;
    let callbackComplete = async data => {
        dispatch(closeActivePopup({name: "loadingMessage"}));
        dispatch(setActivePopup({name: "completeMessage", data: "NOTIFY_PRODUCT_ADDED", positionX: "right"}));
        let loadedData = await user.loadRightPanelData(currentStateHistory[currentStateHistory.length - 1].urlList);
        dispatch(historyUpdate(loadedData));

    };
    var imageUploaded = false;
    var panelHeight = 80;
    var imageRotated = false;
    var imgSrc = null;
    var startWidth = 500;
    var startHeight = 300;
    var panelHeight = 80;
    var width = startWidth;
    var height = startHeight;

    let [imageLoaded, setImageLoaded] = useState({});

    let [imageWidth, setImageWidth] = useState(defImageWidth);
    let [imageHeight, setImageHeight] = useState(defImageWidth * 2);

    let imgFile = null;

    const prev3d = useMemo(() => new Preview3d(defWidth, defHeight - panelHeight), []);

    function Preview3d(width, height) {
        let me = this;

        var camera, scene, renderer, controls;
        var wallWidth = 400;
        var wallHeight = 250;
        var floorDepth = 300;
        var manWidth = 75;
        var manHeight = 180;

        var posterWidth = 40;
        var posterHeight = 30;
        var carpetWidth = 80;
        var carpetHeight = 60;
        var type = 1;

        var carpetRotated = false;

        camera = new THREE.PerspectiveCamera(40, width / height, 1, 1000 );
        camera.position.z = 500;
        camera.position.x = 0;
        camera.position.y = 0;

        scene = new THREE.Scene();

        var light = new THREE.AmbientLight( 0xdddddd );
        scene.add( light );
        var dirLight1 = new THREE.DirectionalLight( 0xffffff, 0.4);
        scene.add( dirLight1 );
        dirLight1.position.set(0.6, 1.4, 1);
        var wallGeom1 = new THREE.PlaneGeometry(wallWidth, wallHeight);
        var wallMaterial = new THREE.MeshPhongMaterial({ color: '#cccccc'});
        var wallMesh1 = new THREE.Mesh(wallGeom1, wallMaterial);
        scene.add(wallMesh1);
        var wallGeom2 = new THREE.PlaneGeometry(floorDepth, wallHeight);
        wallMaterial = new THREE.MeshPhongMaterial({ color: '#cccccc'});
        var wallMesh2 = new THREE.Mesh(wallGeom2, wallMaterial);
        wallMesh2.rotateY(Math.PI / 2);
        wallMesh2.position.set(-wallWidth / 2, 0, floorDepth / 2);
        scene.add(wallMesh2);

        var floorGeom = new THREE.PlaneGeometry(wallWidth, floorDepth);
        var floorMaterial = new THREE.MeshPhongMaterial({ color: '#aaaaaa'});
        var floorMesh = new THREE.Mesh(floorGeom, floorMaterial);
        floorMesh.rotateX(-Math.PI / 2);
        floorMesh.position.set(0, -wallHeight / 2, floorDepth / 2);
        scene.add(floorMesh);

        var manGeom = new THREE.PlaneGeometry(manWidth, manHeight);
        var manMaterial = new THREE.MeshPhongMaterial({map: new THREE.ImageUtils.loadTexture('/src_designer/images/man.png'), transparent: true});
        var manMesh = new THREE.Mesh(manGeom, manMaterial);
        manMesh.position.set(-(wallWidth - manWidth) / 2 + 20, -(wallHeight - manHeight) / 2, 1);
        scene.add(manMesh);

        var squareGeom = new THREE.PlaneGeometry(60, 60);
        var squareMaterial = new THREE.MeshPhongMaterial({map: new THREE.ImageUtils.loadTexture('/src_designer/images/square.png'), transparent: true});
        var squareMesh = new THREE.Mesh(squareGeom, squareMaterial);
        squareMesh.position.set(-(wallWidth - manWidth) / 2 + 20, -(wallHeight - manHeight) / 2, 1);

        var posterGeom = new THREE.PlaneGeometry(posterWidth, posterHeight);
        var posterMaterial = new THREE.MeshPhongMaterial({ color: '#006622'});
        var posterMesh = new THREE.Mesh(posterGeom, posterMaterial);
        updatePosterGeom(posterWidth, posterHeight);

        var carpetGeom = new THREE.PlaneGeometry(carpetWidth, carpetHeight);
        var carpetMaterial = new THREE.MeshPhongMaterial({ color: '#444400'});
        var carpetMesh = new THREE.Mesh(carpetGeom, carpetMaterial);
        carpetMesh.rotateX(-Math.PI / 2);
        updateCarpetGeom(carpetWidth, carpetHeight);

        var boxGeom = new THREE.BoxGeometry(100, 100, 100);
        var boxMaterial = new THREE.MeshPhongMaterial({ color: '#444400'});
        var boxMesh = new THREE.Mesh(boxGeom, boxMaterial);
        boxMesh.position.set(-50, -wallHeight / 2 + 50, 50);

        renderer = new THREE.WebGLRenderer({antialias: true});
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setClearColor(0xffffff);
        renderer.setSize(width, height);

        controls = new THREE.OrbitControls(camera, renderer.domElement);
        controls.minPolarAngle = Math.PI * 0.1;
        controls.maxPolarAngle = Math.PI * 0.7;
        controls.minDistance = 100;
        controls.maxDistance = 600;

        controls.minAzimuthAngle = -Math.PI * 0.2;
        controls.maxAzimuthAngle = Math.PI * 0.2;


        function updateSquareGeom(w, h)
        {
            squareGeom = new THREE.PlaneGeometry(w, h);
            squareMesh.geometry = squareGeom;

            if (type == 1)
            {
                squareMesh.position.set(Math.ceil(manWidth / w) * w - (wallWidth - w) / 2,
                    -wallHeight / 2 + h / 2, 1);
                squareMesh.rotation.set(0, 0, 0);
            }
            else
            {
                squareMesh.position.set(-(wallWidth - w) / 2,
                    -wallHeight / 2 + 1, h / 2);


                squareMesh.rotation.set(0, 0, 0);
                squareMesh.rotateX(-Math.PI / 2);
            }
        }

        function updatePosterGeom(w, h)
        {
            posterWidth = w;
            posterHeight = h;

            posterGeom = new THREE.PlaneGeometry(posterWidth, posterHeight);
            posterMesh.geometry = posterGeom;
            posterMesh.position.set(-100 + posterWidth / 2, 30, 1);
        }

        function updateCarpetGeom(w, h)
        {
            carpetWidth = w;
            carpetHeight = h;

            carpetGeom = new THREE.PlaneGeometry(carpetWidth, carpetHeight);
            carpetMesh.geometry = carpetGeom;
            if (carpetRotated)
            {
                carpetMesh.position.set(-180 + carpetHeight / 2, -wallHeight / 2 + 1, 20 + carpetWidth / 2);
            }
            else
            {
                carpetMesh.position.set(-180 + carpetWidth / 2, -wallHeight / 2 + 1, 20 + carpetHeight / 2);
            }

        }

        function animate() {

            requestAnimationFrame( animate );

            controls.update();

            renderer.render( scene, camera );

        }

        animate();

        me.setImage = function(img, rotate)
        {
            carpetRotated = rotate;

            if (type == 5)
            {
                floorMaterial = new THREE.MeshPhongMaterial({map: createMap(img), shininess:1});
                floorMesh.material = floorMaterial;
                floorMaterial.map.wrapS = THREE.RepeatWrapping;
                floorMaterial.map.wrapT = THREE.RepeatWrapping;
                floorMaterial.map.repeat.set(width / 40, height / 30);
                floorMaterial.map.offset.set(20, 20);
                floorMaterial.needsUpdate = true;
            }
            else if (type == 1)
            {
                wallMaterial = new THREE.MeshPhongMaterial({map: createMap(img), shininess:1});
                wallMesh1.material = wallMaterial;
                wallMaterial.map.wrapS = THREE.RepeatWrapping;
                wallMaterial.map.wrapT = THREE.RepeatWrapping;
                wallMaterial.map.repeat.set(width / 40, height / 30);
                wallMaterial.needsUpdate = true;
            }
            else if (type == 3)
            {
                posterMaterial = new THREE.MeshPhongMaterial({map: createMap(img), shininess:1});
                posterMesh.material = posterMaterial;
            }
            else if (type == 4)
            {
                carpetMaterial = new THREE.MeshPhongMaterial({map: createMap(img), shininess:1});
                carpetMesh.material = carpetMaterial;

                carpetMesh.rotation.set(0, 0, 0);
                carpetMesh.rotateX(-Math.PI / 2);
                if (rotate) carpetMesh.rotateZ(Math.PI / 2);
            }
            else if (type == 7)
            {
                boxMaterial = new THREE.MeshPhongMaterial({map: createMap(img), shininess:1});
                boxMesh.material = boxMaterial;
                boxMaterial.map.wrapS = THREE.RepeatWrapping;
                boxMaterial.map.wrapT = THREE.RepeatWrapping;
                boxMaterial.needsUpdate = true;
            }

        };

        function createMap(img)
        {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0);
            var canvasMap = new THREE.Texture(canvas);
            canvasMap.needsUpdate = true;

            return canvasMap;
        }

        me.setImageSize = function(w, h)
        {
            if (type == 1)
            {
                wallMaterial.map.repeat.set(wallWidth / w, wallHeight / h);
                wallMaterial.needsUpdate = true;

                updateSquareGeom(w, h);
            }
            else if (type == 5)
            {
                floorMaterial.map.repeat.set(wallWidth / w, floorDepth / h);
                floorMaterial.map.offset.set(0, -floorDepth / h);
                floorMaterial.needsUpdate = true;

                updateSquareGeom(w, h);
            }
            else if (type == 3)
            {
                updatePosterGeom(w, h);
            }
            else if (type == 4)
            {
                updateCarpetGeom(w, h);
            }
            else if (type == 7)
            {
                boxMaterial.map.repeat.set(100 / w, 100 / h);
                floorMaterial.needsUpdate = true;
            }
        };

        me.setSize = function(w, h)
        {
            renderer.setSize(w, h);
            camera.aspect = w / h;
            camera.updateProjectionMatrix();
        };

        me.clear = function()
        {
            wallMaterial = new THREE.MeshPhongMaterial({color: '#cccccc'});
            wallMesh1.material = wallMaterial;
            floorMaterial = new THREE.MeshPhongMaterial({ color: '#aaaaaa'});
            floorMesh.material = floorMaterial;
            posterMaterial = new THREE.MeshPhongMaterial({color: '#882222'});
            posterMesh.material = posterMaterial;
            carpetMaterial = new THREE.MeshPhongMaterial({color: '#228822'});
            carpetMesh.material = carpetMaterial;

            if (posterMesh.parent) scene.remove(posterMesh);
            if (carpetMesh.parent) scene.remove(carpetMesh);
            if (squareMesh.parent) scene.remove(squareMesh);
            if (boxMesh.parent) scene.remove(boxMesh);

            if (type == 1)
            {
                camera.position.set(100, 100, 450);
                scene.add(squareMesh);
            }
            else if (type == 5)
            {
                camera.position.set(50, 200, 400);
                controls.target.set(0, -50, 50);
                scene.add(squareMesh);
            }
            else if (type == 3)
            {
                scene.add(posterMesh);

                camera.position.set(0, 50, 300);
                controls.target.set(-50, 50, 0);
            }
            else if (type == 4)
            {
                scene.add(carpetMesh);

                camera.position.set(0, 100, 150);
                controls.target.set(-100, -150, 0);
            }
            else if (type == 7)
            {
                scene.add(boxMesh);

                camera.position.set(50, 200, 400);
                controls.target.set(-50, -wallHeight / 2 + 50, 50);
            }
        };

        me.getRenderer = () => renderer;

        me.getWallMaterial = () => wallMaterial;

        me.setType = newType => type = newType;

    }

    function dragOverListener(e)
    {
        e.preventDefault();
        dropMatDiv.current.classList.add(classes.green_outline)
        
    }

    function dragEnterListener(e)
    {
        e.preventDefault();
    }

    function dragLeaveListener(e)
    {
        e.preventDefault();
        dropMatDiv.current.classList.remove(classes.green_outline);
    }

    function dropListener(e)
    {
        e.preventDefault();
        dropMatDiv.current.classList.remove(classes.green_outline);

        if (e.dataTransfer.files.length > 0)
        {
            imgFile = e.dataTransfer.files[0];
            openFile(imgFile);
        }
    }

    let onUploadFile = e => {
        
        var inputFile = document.createElement('input');
        inputFile.accept='image/*';

        function inputFileChangeEventHandler(event) {
            imgFile = event.currentTarget.files[0];

            inputFile.removeEventListener('change', inputFileChangeEventHandler);
            inputFile = null;

            openFile(imgFile);
        }

        inputFile.setAttribute('type', 'file');
        inputFile.addEventListener('change', inputFileChangeEventHandler);
        inputFile.click();

        
    }

    function openFile(file)
    {
        var loader = new R2D.ImagesLoader();

        FILE.loadFileAsImage(file, procImg);

        function procImg(imgSrc)
        {
            loader.addEventListener(Event.COMPLETE, loaderListener);
            loader.load([imgSrc]);
        }

        function loaderListener(e)
        {

            if (! e.data[0].width || ! e.data[0].height) return;

            imageUploaded = true;

            resizeListener();

            preview.current.append(prev3d.getRenderer().domElement);

            imageRotated = (e.data[0].height > e.data[0].width) && (uploadType == 4);
            var prodWidth = defImageWidth;
            if (uploadType == 4) prodWidth = defCarpetWidth;

            prev3d.setImage(e.data[0], imageRotated);

            let width = 0;
            let height = 0;

            if (imageRotated)
            {
                width = prodWidth;
                height = prodWidth / e.data[0].height * e.data[0].width;
            }
            else
            {
                width = prodWidth;
                height = prodWidth / e.data[0].width * e.data[0].height;
            }
            
            prev3d.setImageSize(width, height);

            loader.removeEventListener(Event.COMPLETE, loaderListener);
            loader.close();

            imgSrc = e.data[0].src;

            setImageWidth(width);
            setImageHeight(height);
            setImageLoaded({status: true, file, src: imgSrc});
        }
        function resizeListener()
        {
            if (imageUploaded)
            {
                defWidth = Math.max(document.body.clientWidth - 400, 500);
                defHeight = Math.max(document.body.clientHeight - 150, 300);

                width = defWidth;
                height = defHeight;
                prev3d.setSize(defWidth, defHeight - panelHeight);
            }
        }
    }

    let preview = useRef(null);

    let dropMatDiv = useRef(null);

    const classes = useStyle();

    let onImageSizeChage = (width, height) => {
        if (imageRotated)
        {
            imageHeight = width;
            imageWidth = height;
        }
        else
        {
            imageWidth = width;
            imageHeight = height;
        }
        setImageWidth(imageWidth);
        setImageHeight(imageHeight);
        prev3d.setImageSize(imageWidth, imageHeight);
    }

    let onApplyImage = e => {
        var uploader = new R2D.CustomUploader();

        dispatch(closeActivePopup({name: "uploadCustom"}));
        dispatch(setActivePopup({name: "loadingMessage", data: "TEXT_WAIT"}));

        if (uploadType == 3)
        {
            uploader.uploadPoster(imageLoaded.file, imageLoaded.src, imageWidth, imageHeight, uploadCategory, callbackComplete)
        }
        else if (uploadType == 4)
        {
            uploader.uploadCarpet(imageLoaded.file, imageLoaded.src, imageWidth, imageHeight, uploadCategory, callbackComplete)
        }
        else if (uploadType == 1 ||
                 uploadType == 5 ||
                 uploadType == 7)
        {
            uploader.uploadMaterial(imageLoaded.file, imageLoaded.src, imageWidth, imageHeight, uploadCategory, callbackComplete, uploadType, uploadToBank);
        }
    }

    useEffect(() => {
        prev3d.setType(privateMaterialType);
        prev3d.clear();
        
    }, [])

    return (
        <div className={classes.planer_popup_wrap + ' ' + classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    {
                        !imageLoaded.status ? 
                        <div className={classes.planer_popup_top} ref={dropMatDiv} onDrop={dropListener} onDragLeave={dragLeaveListener} onDragEnter={dragEnterListener} onDragOver={dragOverListener} >
                            <p className={classes.planer_popup_top_text} dangerouslySetInnerHTML={{__html: translation["SELECT_FILE_FOR_UPLOAD"]}}></p>
                        </div> : null
                        
                    }
                    <div ref={preview}></div>
                    <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                    <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                    <div className={classes.planer_popup_close} id="js_close_popup" onClick={e => dispatch(closeActivePopup({name: "uploadCustom"}))}>
                        <svg className={classes.planer_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                            <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"></path>
                        </svg>
                    </div>
                    <div className={classes.planer_popup_bottom}>
                        <div className={classes.planer_popup_bottom_upload} onClick={onUploadFile}>{ translation["SELECT_FILE"] }</div>
                        <div className={classes.planer_popup_bottom_size}>
                            {
                                imageLoaded.status ? 
                                <MaterialSize onApply={onApplyImage} changeSize={onImageSizeChage} imgWidth={imageWidth} imgHeight={imageHeight}/>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadCustomPopup;