import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfileData, userProfileDataLoad } from '../../../../redux/features/user/userSlice';
import { closeActivePopup, selectPopups, setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import {createUseStyles} from "react-jss";
import Checkbox from "../common/checkbox/index.jsx";
import { user } from '../../../../init';
import pro_round from './images/pro_round.png';

const useStyle = createUseStyles({
    planer_popup_wrap: {
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show: {
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr: {
        color: '#000'
    },
    planer_popup_cont: {
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative',
        width: 640
    },
    planer_popup_login_wrap: {},
    planer_popup_close: {
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover': {
            '& path': {
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon: {
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    title:{
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: 'rgb(68, 68, 68)',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        margin: '15px 10px 40px'
    },
    planner_popup_line:{
        background: 'rgb(217, 217, 217)',
        height: '1px',
        margin: '15px 40px'
    },
    input_wrap:{
        padding: '0 65px',
        display: 'grid',
        gridGap: 20,
        border: 'none',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        marginBottom: 40
    },
    input_text:{
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        color: 'rgb(68, 68, 68)',
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    input_email:{
        fontSize: 14,
        color: 'rgb(68, 68, 68)',
    },
    input_wr:{
        color: '#fff',
        width: 250,
        '&:last-child': {
            marginBottom: '0'
        }
    },
    formInput:{
        paddingLeft: '5px',
        fontSize: '16px',
        color: '#000',
        width: '100%',
        border: '1px solid #e8dede',
        height: '30px',
        background: '#ffffff',
        outline: 'none',
        resize: 'none',
        transition: 'all 0.4s ease-out'
    },
    important_input:{
        '& $formInput':{
            border: '1px solid #e84343 !important'
        }
    },
    planer_popup_report:{
        color: 'red',
        fontSize: '12px',
        marginBottom: '10px'
    },
    planer_btn_done_wrap:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },
    pro_status_wr:{
        padding: '0 65px',
        display: 'grid',
        gridGap: 20,
        border: 'none',
        gridTemplateColumns: '127px 1fr',
        alignItems: 'center',
        marginBottom: 13
    },
    pro_status:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'rgb(68, 68, 68)',
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        whiteSpace: 'nowrap'
    },
    pro_update:{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto auto',
        gridGap: 15
    },
    planer_btn_done_wr:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 60
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },
    pro_buy_credits:{
        color: '#66ACF4',
        fontSize: 13,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover':{
            textDecoration: 'none',
        }
    },
    pro_update_img:{
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: "pointer",
        '&:hover':{
            opacity: '0.5'
        }
    }
});

const ProfilePopup = props => {

    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);
    let userProfileData = useSelector(selectUserProfileData);
    let popups = useSelector(selectPopups);
    

    let onSubmit = async e => {
        e.preventDefault();
        let form = e.target;

        var pass1 = form.elements.new_password.value;
        var pass2 = form.elements.verify_password.value;

        let newsCheckbox = document.getElementById("news").checked ? '1' : '0';
        let tipsCheckbox = document.getElementById("tips").checked ? '1' : '0';

        if (pass1.length > 0 && pass1.length < 6)
        {
            dispatch(setActivePopup({name: "alert", type: "error_password_short", data: "TEXT_SHORT_PASSWORD"}));
            return;
        }
        else if (pass1 != pass2)
        {
            dispatch(setActivePopup({name: "alert", type: "error_password_not_match", data: "TEXT_PASSWORDS_NOT_MATCH"}));
            return;
        }
        else if (newsCheckbox == userProfileData.subscription_news && tipsCheckbox == userProfileData.tips && pass1.length == 0) {
            dispatch(closeActivePopup({ name: "profile" }));
            return;
        } 

        let dataObj = {
            user_name: document.getElementsByName("user_name")[0].value,
            subscription_news: newsCheckbox,
            tips: tipsCheckbox,
        };
        if (pass1.length > 0) dataObj.new_password = pass1;

        await user.setProfileData(dataObj);
        dispatch(userProfileDataLoad());
        dispatch(closeActivePopup({ name: "profile" }));
        dispatch(setActivePopup({name: "completeMessage", data: "NOTIFY_INFORMATION_SAVED", positionX: "left"}));
        

    }
    const classes = useStyle();
    return (
        <div datapopup={'popup-profile'} className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show} style={{zIndex: popups.login.zIndex}}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <div className={classes.planer_popup_login_wrap}>

                        <h2 className={classes.title}>{translation["TEXT_PROFILE"]}</h2>

                        <div className={classes.planner_popup_line}></div>

                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />

                        <div className={classes.planer_popup_close} onClick={e => dispatch(closeActivePopup({name: "profile"}))}>
                            <svg className={classes.planer_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                                <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                            </svg>
                        </div>

                        <form onSubmit={onSubmit}>
                            <fieldset className={classes.input_wrap}>
                                <div className={classes.input_text}>{ translation["TEXT_LOGIN"] }</div>
                                <span className={classes.input_email}>{ userProfileData.login }</span>

                                <div className={classes.input_text}>{ translation["TEXT_USER_NAME"] }</div>
                                <div className={classes.input_wr}>
                                    <input className={classes.formInput} type="text" name="user_name" defaultValue={ userProfileData.user_name }/>
                                </div>

                                <div className={classes.input_text}>{ translation["TEXT_NEW_PASSWORD"] }</div>
                                <div className={classes.input_wr}>
                                    <input className={classes.formInput} type="password" name="new_password" placeholder=''/>
                                </div>

                                <div className={classes.input_text}>{ translation["TEXT_VERIFY_PASSWORD"] }</div>
                                <div className={classes.input_wr}>
                                    <input className={classes.formInput} type="password" name="verify_password" placeholder=''/>
                                </div>
                                <div></div>

                                <Checkbox name={"news"} label={translation["TEXT_GET_NEWS"]} checked={userProfileData.subscription_news == 1 ? 'checked' : null}/>
                                

                                <div></div>
                                <Checkbox name={"tips"} label={translation["TEXT_SHOW_TIPS"]} checked={userProfileData.tips == 1 ? 'checked' : null}/>
                                
                            </fieldset>
                            <fieldset className={classes.pro_status_wr}>
                                <span className={classes.input_text}>{ translation["TEXT_R2D_VERSION"] }</span>

                                <div className={classes.pro_status}>
                                    <div>{ userProfileData.plan == "pro" ? "PRO" : "FREE" }</div>

                                    <div className={classes.pro_update}>
                                        {
                                            userProfileData.plan == "pro" ?
                                            <>
                                                <div>{ translation["TEXT_VALID_UNTIL"] }</div>
                                                <div>{ userProfileData.user_plan_expire }</div>
                                            </> :
                                            <>
                                                <div>{ translation["TEXT_UPDATE_TO"] }</div>
                                                <img className={classes.pro_update_img} src={pro_round} alt="" onClick={e => dispatch(setActivePopup({ name: "payPro" }))}/>
                                            </>

                                        }
                                    </div>
                                </div>
                            </fieldset>
                            <div className={classes.pro_status_wr}>
                                <span className={classes.input_text}>My credits </span>

                                <div className={classes.pro_status}>
                                    <div>{(+userProfileData.credits).toFixed(0)} credits</div>

                                    <div className={classes.pro_buy_credits} onClick={e => dispatch(setActivePopup({name: "buyCredits"}))}>
                                        Get more credits
                                    </div>
                                </div>
                            </div>
                            <div className={classes.planer_btn_done_wr}>
                                <input className={classes.planer_btn_done} type="submit" value="Ok"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilePopup;