import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup, selectPopups, setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import {createUseStyles} from "react-jss";
import { changeMetricsActive, selectMetrics } from '../../../../redux/features/metrics/metricsSLice';
import { user, planner } from '../../../../init';
import { useState } from 'react';

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    planer_popup_main:{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 50
    },
    text:{
        color: 'rgb(47, 47, 47)',
        marginRight: 70,
        fontSize: 15,
        textAlign: 'center'
    },
    planer_btn_done_wrap:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },
    bar_dropdown_wrap:{
        position: 'relative',
        height: '100%',
        minWidth: '36px',
        marginRight: '1px',
        '&:hover $bar_dropdown_option_wr':{
            transform: 'translateY(0%)'
        },
        '&:hover & path, & circle, & rect':{
            fill: '#ffd119',
        },
    },
    bar_dropdown_active:{
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '6px 11px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            color: '#ffd119'
        }
    },
    bar_dropdown_option_wrap:{
        position: 'absolute',
        top: '100%',
        left: '0',
        width: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
    },
    bar_dropdown_option_wr:{
        paddingTop: '1px',
        pointerEvents: 'auto',
        transform: 'translateY(-100%)',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    bar_dropdown_option:{
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        height: '27px',
        marginBottom: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        color: '#ffffff',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            color: '#ffd119'
        }
    },

});

const AlignerWidthSetMetricsPopup = props => {

    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);
    let popups = useSelector(selectPopups);
    let alignerWidth = popups.alignerWidthSetMetrics.data;

    let metricsGlobal = useSelector(selectMetrics);

    let [metrics, setMetrics] = useState({ active: metricsGlobal.active, dimensionsAll: ["cm", "mm", "m", "ft"] })

    let onClickOk = e => {
        dispatch(closeActivePopup({name: "alignerWidthSetMetrics"}));
        dispatch(changeMetricsActive(metrics.active));
        user.changeDimension(metrics.active);
        WC.WallsEditor._instance.state.setAlignerValue(planner.units.stringToValue(alignerWidth))
    }

    let onChangeDimension = dimension => {
        setMetrics({...metrics, active: dimension});
    }

    let dimensionsData = [];

    metrics.dimensionsAll.forEach(item => {
        dimensionsData.push(
            <div key={item} className={"bar_dropdown_option js_set_metric " + (metrics.active == item ? " bar_dropdown_option_active" : "")} onClick={e => onChangeDimension(item)}>{ item }</div>
        )
    })


    const classes = useStyle();
    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <div className="planer_popup_login_wrap">
                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                        
                        <div className={classes.planer_popup_main}>
                            <div className={classes.text}>
                                <p>{ translation["TEXT_CHOOSE_UNITS"] }</p>
                            </div>


                            <div className={classes.bar_dropdown_wrap}>
                                <div className={classes.bar_dropdown_active}>{metrics.active}</div>
                                <div className={classes.bar_dropdown_option_wrap}>
                                    <div className={classes.bar_dropdown_option_wr}>
                                        { dimensionsData }
                                    </div>
                                </div>
                            </div>
                        </div>
                        


                        <div className={classes.planer_btn_done_wrap}>
                            <div className={classes.planer_btn_done} onClick={onClickOk}>{ translation["BUTTON_OK"] }</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlignerWidthSetMetricsPopup;