import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveViewModeState } from '../../../../../../../redux/features/viewMode/viewModeSlice.js';
import StateEditing from './states/stateEditing/StateEditing.jsx';
import ListRenders from '../general/stateRendersShow/ListRenders.jsx';
import ListProjects from '../general/stateProjectsShow/ListProjects.jsx';
import RenderMake from '../general/stateRenderMake/RenderMake.jsx';
import StateSelectedConstr from './states/stateSelectedConstr/StateSelectedConstr.jsx';
import StateSelectedProduct from './states/stateSelectedProduct/StateSelectedProduct.jsx';
import StateSelectedWall from './states/stateSelectedWall/StateSelectedWall.jsx';
import StateSelectedPlinth from './states/stateSelectedPlinth/StateSelectedPlinth.jsx'
import StateSelectedGroup from './states/stateSelectedGroup/StateSelectedGroup.jsx';
import { planner } from '../../../../../../../init.js';
import { showQuickPanel } from '../../../../../../../redux/features/quickPanels/quickPanelsSlice.js';

const Main2D_3D = props => {
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let dispatch = useDispatch();

    let onRulerSelect = e => dispatch(showQuickPanel({name: "delete", position: {x: e.data.x, y: e.data.y}}));

    useEffect(() => {
        
        planner.scene.addEventListener(planner.scene.RULER_3D_SELECTED, onRulerSelect);
        return () => {
            
            planner.scene.removeEventListener(planner.scene.RULER_3D_SELECTED, onRulerSelect);
        }
    }, []);

    return (
        <>
            {
                activeViewModeState == "stateEditing" ? <StateEditing /> :
                activeViewModeState == "stateSelectedConstr" ? <StateSelectedConstr /> :
                activeViewModeState == "stateSelectedWall" ? <StateSelectedWall /> :
                activeViewModeState == "stateSelectedPlinth" ? <StateSelectedPlinth /> :
                activeViewModeState == "stateSelectedProduct" ? <StateSelectedProduct /> :
                activeViewModeState == "stateSelectedGroup" ? <StateSelectedGroup /> :
                activeViewModeState == "stateRendersShow" ? <ListRenders /> :
                activeViewModeState == "stateProjectsShow" ? <ListProjects /> :
                activeViewModeState == "stateRenderMake" ? <RenderMake /> : null
            }

        </>
    )
}

export default Main2D_3D;