import React, { useEffect, useState } from 'react';
import TopPanel from './components/top/TopPanel.jsx';
import LeftPanel from './components/left/LeftPanel.jsx';
import CenterPanel from './components/center/CenterPanel.jsx';
import RightPanel from './components/right/RightPanel.jsx';
import BottomPanel from './components/bottom/BottomPanel.jsx';
import Popups from './components/popups/Popups.jsx';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { historyClear, historyUpdate, selectActiveViewMode, selectActiveViewModeState, setActiveViewMode, setActiveViewModeState } from '../../redux/features/viewMode/viewModeSlice.js';
import QuickPanels from './components/quick/QuickPanels.jsx';
import { useDispatch } from 'react-redux';
import { selectIsLogged, selectUserProfileData, setActiveProjectName, userCheckIsLogged, userProfileDataLoad } from '../../redux/features/user/userSlice.js';
import { selectTips } from '../../redux/features/tips/tipsSlice.js';
import Tip from './components/tips/Tip.jsx';
import { planner, user } from '../../init.js';
import { closeActivePopup, setActivePopup } from '../../redux/features/popups/popupsSlice.js';
import { selectTranslation } from '../../redux/features/language/languageSlice.js';
import { changeActivePano, updateCurrentProjectHash } from '../../redux/features/common/commonSlice.js';
import { changeUndoRedo } from '../../redux/features/topPanel/topPanelSlice.js';
import { hideQuickPanelAll } from '../../redux/features/quickPanels/quickPanelsSlice.js';

const useStyle = createUseStyles({
    content:{
        fontFamily: 'Roboto, sans-serif',
        color: '#000',
        height: "100%",
        userSelect: "none"
    },
});

const Main = props => {
    const classes = useStyle();

    let activeViewMode = useSelector(selectActiveViewMode);
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let translation = useSelector(selectTranslation);
        
    let dispatch = useDispatch();

    let userProfileData = useSelector(selectUserProfileData);
    let tips = useSelector(selectTips);

    let isLogged = useSelector(selectIsLogged);

    let [showTipSelectGroup, setTipSelectGroup] = useState(false);
    let [showTipChangeWallHeight, setTipChangeWallHeight] = useState(false);
    let [showTipUploadCustomMat, setTipUploadCustomMat] = useState(false);

    let onKeyDownConstr = e => {
        if(e.code == "Escape") {
            dispatch(setActiveViewModeState({state: "stateEditing"}));
            document.removeEventListener("keydown", onKeyDownConstr);
        }
    }

    function changeMIHStateListener(e)
    {
        if(userProfileData.tips && userProfileData.tips != 0) {
            var mih = planner.mih();
            setTimeout(function() {
                if (!tips.selectGroup.showed && e.newState == mih.stateMain && e.oldState == mih.stateDraggingProdFromCatalog)
                {
                    var sceneObjs = R2D.scene.getSceneObjects();
                    var num = 0;
                    for (var i = 0; i < sceneObjs.length; i++)
                    {
                        if (R2D.ObjectViewer3DGroup.validSceneObject(sceneObjs[i])) num++;
                        if (num >= 3) break;
                    }

                    if (num >= 3) setTipSelectGroup(true);
                }

            }, 100);

            if (e.newState == mih.stateMain) planner.apiScene.dispatchEvent(new Event(planner.apiScene.TIPS_SAVE_PROJECT, {}));
        } 
    }

    function changeConstructorStateListener()
    {
        if(userProfileData.tips && userProfileData.tips != 0) {
            setTimeout(function() {
                if (WC.core.roomPoints.length > 0 && (R2D.viewConstructor.state == R2D.viewConstructor.stateEditing) &&
                    (R2D.viewConstructor.prevState == R2D.viewConstructor.stateMakingRoom ||
                    R2D.viewConstructor.prevState == R2D.viewConstructor.stateMakingWalls ||
                    R2D.viewConstructor.prevState == R2D.viewConstructor.stateMakingRect))
                {
                    setTipChangeWallHeight(true);
                }
            }, 200);
        }

        if (R2D.viewConstructor.state == R2D.viewConstructor.stateEditing) planner.apiScene.dispatchEvent(new Event(planner.apiScene.TIPS_SAVE_PROJECT, {}));
    }

    function customUploadListener(e) {
        if(userProfileData.tips && userProfileData.tips != 0) {
            setTimeout(function() {
                if (!tips.uploadCustomMat.showed) setTipUploadCustomMat(true);
            }, 100);
        } 
    }

    let onSceneLoadProgress = data => dispatch(setActivePopup({name: "loadingMessage", type: "scene_load_progress", data: translation["NOTIFY_LOADING_PRODUCTS"] + '<br>' + data.data.loaded + '/' + data.data.total}));

    let onSceneLoadComplete = data => dispatch(closeActivePopup({name: "loadingMessage"}));

    let onRendersUpdate = data => {
        dispatch(setActivePopup({name: "completeMessage", positionX: "left", data: "NOTIFY_RENDER_READY"}));
        activeViewModeState == "stateRendersShow" ? dispatch(historyUpdate(data.data)) : null;
    }

    let onProjectLoaded = async e => {
        if(e.data.status == "error") {
            dispatch(setActivePopup({name: "alert", data: "TEXT_ERROR_LOAD_DATA"}));
            return;
        }
        if(!e.data.id) {
            dispatch(changeActivePano({ id: 0, previewImgSrc: "", rotation: 0}));
        }
        else {
            let id = e.data.id;
            let rotation = e.data.rotation;
            let panorams = await user.loadPano();
            let panoPreviewSrc = panorams.products.find(i => i.id == id).source.images.preview;
            dispatch(changeActivePano({ id, previewImgSrc: panoPreviewSrc, rotation}));
        }
        if(e.data.viewState) {
            let newState = e.data.viewState.state == "2d" ? "view2d" : 
            e.data.viewState.state == "3d" ? "view3d" : 
            e.data.viewState.state == "walk" ? "viewWalk" : 
            e.data.viewState.state == "constructor" ? "constr" : null;
            dispatch(setActiveViewMode(newState));
        }
        if(e.data.hash) {
            dispatch(updateCurrentProjectHash(e.data.hash));
        }

        if(e.data.name) {
            dispatch(setActiveProjectName(e.data.name))
        }
    };

    let onHistoryUndoRedo = e => {
        if(e.data.removeQuickPanel) {
            dispatch(hideQuickPanelAll());
        }
        if(e.data.clearRightPanel) {
            dispatch(setActiveViewModeState({state: "stateEditing"}));
        }
        
        dispatch(changeUndoRedo());
    }

    let onWheelZoom = event => {
        let delta = event.data.delta > 0 ? -1 : 1;
        if(activeViewMode == "constr") {
            WC.WallsEditor._instance.zoom(WC.canvasWidth / 2, WC.canvasHeight / 2, -delta);
            R2D.ViewerScene2D._instance.cameraZoom(delta);
        } else if(activeViewMode == "view2d") {
            R2D.ViewerScene2D._instance.cameraZoom(-delta);
            WC.WallsEditor._instance.zoom(WC.canvasWidth / 2, WC.canvasHeight / 2, delta);
        } else {
            R2D.Viewers.cameraZoom(-delta);
        }
    }

    let onCameraMove = e => {
        if(activeViewMode == "constr") {
            R2D.ViewerScene2D._instance.cameraMove(e.data.x, e.data.y);
        } else {
            R2D.Viewers.cameraMove(e.data.x, e.data.y);
        }
    }

    async function messageListener(e) {
        if (!e.data || typeof e.data !== 'string' || e.data.startsWith('/*framebus*/')) return;
        
        try
        {
            var dataObj = JSON.parse(e.data);
        } catch ( error ) {
            console.error("Error parse JSON string!");
            return;
        }

        if (dataObj.action == 'load_project')
        {
            if (dataObj.hash)
            {
                R2D.controller.clearCurrentScene(true);
                R2D.controller.loadScene(dataObj.hash, true);

                dispatch(updateCurrentProjectHash(dataObj.hash));
                window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH + '/project/' + dataObj.hash);
            }
        }
        else if (dataObj.action == 'set_token')
        {
            if (dataObj.token)
            {
                user.saveToken(dataObj.token);
                dispatch(userCheckIsLogged());
            }
        }
        else if (dataObj.action == 'save_estimation')
        {
            R2D.controller.estimationToFile();
        }
        else if (dataObj.action == 'get_estimation')
        {
            var data = R2D.controller.getEstimation();
            R2D.postMessageToParent(JSON.stringify({
                action: "got_estimation",
                data
            }));
        }else if (dataObj.action == 'set_country')
        {
            if (dataObj.country)
            {
                R2D.controller.setCountry(dataObj.country);
                if(activeViewMode == "view2d" || activeViewMode == "view3d") {
                    let start2D3DData = await user.loadRightPanelData(R2D.URL.CATALOG_TREE);
                    dispatch(historyClear({state: "stateEditing"}));
                    dispatch(setActiveViewModeState({state: "stateEditing", lastSavedScroll: 0, data: {type: "catalog", added: true, data: start2D3DData.tree}}));
                  }
            }
        }
    }

    let onProjectSaveComplete = async e => R2D.scene.updateProjectUserKey(user.getKey());

    useEffect(() => {
        planner.mih().addEventListener(R2D.MouseInteractionHelper.STATE_CHANGED, changeMIHStateListener);
        R2D.viewConstructor.addEventListener(WC.EDITOR_CHANGE_STATE, changeConstructorStateListener);
        planner.scene.addEventListener(planner.scene.TIPS_UPLOAD_CUSTOM_MAT, customUploadListener);
        return () => {
            planner.mih().removeEventListener(R2D.MouseInteractionHelper.STATE_CHANGED, changeMIHStateListener);
            R2D.viewConstructor.removeEventListener(WC.EDITOR_CHANGE_STATE, changeConstructorStateListener);
            planner.scene.removeEventListener(planner.scene.TIPS_UPLOAD_CUSTOM_MAT, customUploadListener);
        }
    }, [userProfileData, tips]);

    useEffect(() => {
        planner.scene.addEventListener(planner.scene.SCENE_LOAD_PROGRESS, onSceneLoadProgress);
        planner.scene.addEventListener(planner.scene.SCENE_LOAD_COMPLETE, onSceneLoadComplete);
        planner.scene.addEventListener(planner.scene.PROJECT_LOADED, onProjectLoaded);
        planner.scene.addEventListener(planner.scene.PROJECT_SAVE_COMPLETE, onProjectSaveComplete);
        
        planner.apiScene.addEventListener(planner.apiScene.HISTORY_UNDO_REDO, onHistoryUndoRedo);
        
        let canvasWrapper = document.getElementById('planner');
        canvasWrapper.appendChild(planner.getDomElement());
        planner.setSize(R2D.Viewers._instance.isRightPanelOpen ? window.innerWidth - 270 : window.innerWidth, window.innerHeight);

        R2D.controller.checkProjectForLoad();
        R2D.controller.tryLoadProjectFromStorage();
        
        R2D.controller.clearStorage();

        let resizeListener = e => planner.setSize(R2D.Viewers._instance.isRightPanelOpen ? window.innerWidth - 270 : window.innerWidth, window.innerHeight);

        window.addEventListener('resize', resizeListener);

        setTimeout(() => document.querySelector(".preloader") && document.querySelector(".preloader").remove(), 300);

        R2D.postMessageToParent(JSON.stringify({action:"program_ready"}));
        
        return () => {
            planner.scene.removeEventListener(planner.scene.SCENE_LOAD_PROGRESS, onSceneLoadProgress);
            planner.scene.removeEventListener(planner.scene.SCENE_LOAD_COMPLETE, onSceneLoadComplete);
            planner.scene.removeEventListener(planner.scene.PROJECT_LOADED, onProjectLoaded);
            planner.apiScene.removeEventListener(planner.apiScene.HISTORY_UNDO_REDO, onHistoryUndoRedo);
        }
    }, [])

    useEffect(() => {
        planner.scene.addEventListener(planner.scene.RENDERS_UPDATE, onRendersUpdate);
        return () => planner.scene.removeEventListener(planner.scene.RENDERS_UPDATE, onRendersUpdate);
    }, [activeViewModeState])

    useEffect(() => {
        planner.apiScene.addEventListener(planner.apiScene.WHEEL_ZOOM, onWheelZoom);
        planner.apiConstr.addEventListener(planner.apiConstr.WHEEL_ZOOM, onWheelZoom);
        planner.apiConstr.addEventListener(planner.apiConstr.CAMERA_MOVE, onCameraMove);
        planner.apiScene.addEventListener(planner.apiScene.CAMERA_MOVE, onCameraMove);
        window.addEventListener("message", messageListener, false);

        return () => {
            planner.apiScene.removeEventListener(planner.apiScene.WHEEL_ZOOM, onWheelZoom);
            planner.apiConstr.removeEventListener(planner.apiConstr.WHEEL_ZOOM, onWheelZoom);
            planner.apiConstr.removeEventListener(planner.apiConstr.CAMERA_MOVE, onCameraMove);
            planner.apiScene.removeEventListener(planner.apiScene.CAMERA_MOVE, onCameraMove);
            window.removeEventListener("message", messageListener, false);
        }
    }, [activeViewMode])

    useEffect(async () => isLogged && !userProfileData.name && dispatch(userProfileDataLoad()), [isLogged]);

    return (
        <>
        
            {
                
                <div className={classes.content}>
                    {
                        activeViewModeState != "stateRenderMake" ?
                        <>
                            <TopPanel />
                            <LeftPanel />
                        </> : null
                    }

                    <CenterPanel onKeyDownConstr={onKeyDownConstr}/>
                    
                    <RightPanel onKeyDownConstr={onKeyDownConstr}/>

                    <BottomPanel />

                    <Popups />
                    <QuickPanels/>

                    {
                        showTipSelectGroup && !tips.selectGroup.showed ? <Tip name={"selectGroup"} showTime={1000} arrow={null} text={"TUT_CREATE_GROUP"} position={{x: "50%", y: "50%", from: "left"}}/> 
                        : null
                    }
                    {
                        showTipChangeWallHeight && !tips.changeWallHeight.showed ? <Tip name={"changeWallHeight"} showTime={1000} arrow={{position: "top", direction: "right"}} text={"TUT_CHANGE_WALLS_HEIGHT"} position={{x: window.innerWidth - 270, y: 98, from: "left"}}/> 
                        : null
                    }
                    {
                        showTipUploadCustomMat && !tips.uploadCustomMat.showed ? <Tip name={"uploadCustomMat"} showTime={1000} arrow={{position: "top", direction: "right"}} text={"TUT_UPLOAD_MATERIAL"} position={{x: 270, y: 175, from: "right"}}/> 
                        : null
                    }

                </div>
            }
        </>
    )
}

export default Main;