import React, { useState } from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch, useSelector } from 'react-redux';
import { selectTranslation } from '../../../../../../../../../redux/features/language/languageSlice.js';
import { historyAdd, selectActiveViewModeData, updateCurrentProductSize } from '../../../../../../../../../redux/features/viewMode/viewModeSlice.js';
import ChangeSize from '../../../../../../common/changeSize/index.jsx';
import EditMaterialsItem from '../../../../../../common/editMaterialItem/index.jsx';
import CatalogMaterials from '../../../general/catalog/CatalogMaterials.jsx';
import Catalog from '../../../general/catalog/Catalog.jsx';
import { user, planner } from '../../../../../../../../../init.js';
import { selectMetrics } from '../../../../../../../../../redux/features/metrics/metricsSLice.js';
import ColorPicker from '../../../general/colorPicker/ColorPicker.jsx';
import { changeUndoRedo } from '../../../../../../../../../redux/features/topPanel/topPanelSlice.js';
import { useEffect } from 'react';
import LockSize from '../../../../../../common/lockSize/index.jsx';
import { selectLockState } from '../../../../../../../../../redux/features/common/commonSlice.js';
import { motion } from 'framer-motion';

const useStyle = createUseStyles({
    cont:{
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        //transition: 'all 0.2s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    wrap:{
        height: '100%',
        overflow: 'auto',
        background: '#fff',
    },
    wr:{
        padding: 20,
    },
    title: {
        width: '100%',
        height: '29px',
        backgroundColor: 'rgba(129, 129, 129, 0.9)',
        fontSize: '13px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '0 5px'
    },
    planerChangeSizesWrap:{
        display: 'grid',
        gridTemplateColumns: 'auto 27px',
        gridGap: 24,
        marginBottom: 20,
        paddingRight: 10
    },
    planerChangeSizes:{

    },
    changeSizesLockWr:{
        position: 'relative',
        height: 94,
        display: 'grid',
        paddingTop: 13
    },
    changeSizesLockLine1:{
        width: '100%',
        height: 11,
        borderTop: '1px solid',
        borderRight: '1px solid'
    },
    changeSizesLockLine2:{
        borderTop: '1px solid',
        width: 13,
    },
    changeSizesLockLine3:{
        width: '100%',
        height: 11,
        borderBottom: '1px solid #444444',
        borderRight: '1px solid #444444'
    },
    materialsGrid:{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 14,
    }
});

const StateSelectedProduct = () => {
    const classes = useStyle();
    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataType = currentStateHistory[currentStateHistory.length - 1].type;

    let lockState = useSelector(selectLockState);
    
    let {width, height, depth, elevation} = planner.scene.getCurrentModelSizes() || { width: 0, height: 0, depth: 0, elevation: 0 };

    let [objectWidth, setObjectWidth] = useState(width);
    let [objectHeight, setObjectHeight] = useState(height);
    let [objectDepth, setObjectDepth] = useState(depth);
    let [objectElevation, setObjectElevation] = useState(elevation);

    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    let metrics = useSelector(selectMetrics);

    let [productName, setProductName] = useState(null);

    let [activeIndex, setActiveIndex] = useState(null);
    let [mouseDown, setMouseDown] = useState({status: false, action: null, type: null});

    let onCopyMaterial = id => R2D.CopyPaste.copyMaterial(id);
    
    let onPasteMaterial = index => {
        let mat = R2D.CopyPaste.pasteMaterial();
        if (mat) {
            planner.scene.setMaterialOnCurrentModel(index, mat);
            setPreviewMaterialsId([...planner.scene.getCurrentModelMaterials()]);
            dispatch(changeUndoRedo());
        }
    }

    let onClickPreview = async index => { 
        if(previewMaterialsId[index].source == "bank") {
            let catalogMaterials = await user.loadRightPanelData(R2D.URL.CATALOG_MATERIALS_TREE_FOR_MODELS);
            dispatch(historyAdd({added: true, data: catalogMaterials.materials, type: "catalog"}));
        } 
        else {
            dispatch(historyAdd({type: "material", urlList: R2D.URL.URL_GET_MATERIALS_SET + "&set_id=" + previewMaterialsId[index].setId, matShowType: "set", data: null, added: true}));
        }
        setActiveIndex(index);
    }

    let onChangeSize = (mouseDown, value, type) => {
        setMouseDown({...mouseDown, type});
        switch(type) {
            case "width":
                planner.scene.setCurrentModelWidth(value, lockState, mouseDown.status);
                break;
            case "height":
                planner.scene.setCurrentModelHeight(value, planner.mih()._scene.currentView3DObject.getType() == 4 ? false : lockState, mouseDown.status);
                break;
            case "depth":
                planner.scene.setCurrentModelDepth(value, planner.mih()._scene.currentView3DObject.getType() == 3 ? false : lockState, mouseDown.status);
                break;
            case "elevation":
                planner.scene.setCurrentModelElevation(value, mouseDown.status);
                break;
        }

        let {width, height, depth, elevation} = planner.scene.getCurrentModelSizes();

        setObjectWidth(width);
        setObjectHeight(height);
        setObjectDepth(depth);
        setObjectElevation(elevation);
        
        if(mouseDown.status == false) dispatch(changeUndoRedo());
    }

    let onMaterialSelect = id => {
        planner.scene.setMaterialOnCurrentModel(activeIndex, id);
        dispatch(changeUndoRedo());
    }

    let onChangePreview = id => setPreviewMaterialsId([...planner.scene.getCurrentModelMaterials()]);

    let [previewMaterials, setPreviewMaterials] = useState([]);

    let [previewMaterialsId, setPreviewMaterialsId] = useState([...planner.scene.getCurrentModelMaterials()]);

    const variants = {
        visible: { transform: "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }

    let onMoveObjectSmart = e => dispatch(updateCurrentProductSize({... currentStateHistory[0].data, elevation: e.data.elevation}));

    let onChange2dModelButtons = e => dispatch(updateCurrentProductSize({... currentStateHistory[0].data, width: e.data.size.width, depth: e.data.size.depth}));

    useEffect(() => {
        planner.scene.addEventListener(planner.scene.MODEL_CHANGE_SIZE, onChange2dModelButtons);
        planner.scene.addEventListener(planner.scene.OBJECT_SMART_MOVE, onMoveObjectSmart);
        
        return () => {
            planner.scene.removeEventListener(planner.scene.MODEL_CHANGE_SIZE, onChange2dModelButtons);
            planner.scene.removeEventListener(planner.scene.OBJECT_SMART_MOVE, onMoveObjectSmart);
        }
    }, [])

    useEffect(() => setObjectElevation(planner.scene.getCurrentModelSizes().elevation), [planner.scene.getCurrentModelSizes().elevation]);

    useEffect(() => setObjectWidth(planner.scene.getCurrentModelSizes().width), [planner.scene.getCurrentModelSizes().width]);

    useEffect(() => setObjectHeight(planner.scene.getCurrentModelSizes().height), [planner.scene.getCurrentModelSizes().height]);

    useEffect(() => setObjectDepth(planner.scene.getCurrentModelSizes().depth), [planner.scene.getCurrentModelSizes().depth]);

    useEffect(() => {
        let prevMatArray = [] 
        previewMaterialsId && previewMaterialsId.forEach((i, index) => {
            if(i.source && i.source != "none") {
                prevMatArray.push(<EditMaterialsItem key={index} matId={i.matId} onCopyPaste={{copy: onCopyMaterial, paste: onPasteMaterial, index}} onClick={e => onClickPreview(index)} lock={false}/>)
            }
        });
        
        setPreviewMaterials(prevMatArray);
    }, [previewMaterialsId]);

    useEffect(() => setProductName(planner.mih()._scene.currentView3DObject.getSceneObject().getName()), [planner.mih()._scene.currentView3DObject.getSceneObject().getName()]);

    return (
        <>
            {
                previewMaterialsId && previewMaterials && currentDataType == "main" ?
                    <motion.div transition={{ ease: "easeOut", duration: .2 }} initial="hidden" animate="visible" exit="exit" variants={variants} className={classes.cont}>
                        <div className={classes.wrap}>
                            <div className={classes.title}>{ productName }</div>
                            <div className={classes.wr}>

                                <div className={classes.planerChangeSizesWrap}>
                                    <div className={classes.planerChangeSizes}>
                                        <ChangeSize mouseDown={mouseDown} valueChangeType={"width"} changeValue={onChangeSize} value={objectWidth} label={translation["WIDTH"]} />
                                        <ChangeSize mouseDown={mouseDown} valueChangeType={"height"} changeValue={onChangeSize} value={objectHeight} label={translation["HEIGHT"]} />
                                        <ChangeSize mouseDown={mouseDown} valueChangeType={"depth"} changeValue={onChangeSize} value={objectDepth} label={translation["DEPTH"]} />
                                        <ChangeSize mouseDown={mouseDown} valueChangeType={"elevation"} changeValue={onChangeSize} value={objectElevation} label={translation["ELEVATION"]}/>
                                    </div>
                                    <div>
                                        <div className={classes.changeSizesLockWr}>
                                            <div className={classes.changeSizesLockLine1}></div>
                                            <div className={classes.changeSizesLockLine2}></div>
                                            <div className={classes.changeSizesLockLine3}></div>

                                            <LockSize lockState={lockState} right={'-7px'} top={"31px"}/>

                                        </div>
                                    </div>
                                </div>

                                <div className={classes.materialsGrid}>
                                    { previewMaterials || null}
                                </div>

                            </div>
                        </div>
                    </motion.div> 
                : null
            }
            {previewMaterialsId && previewMaterials && currentDataType == "catalog" ? <Catalog /> : null }
            {previewMaterialsId && previewMaterialsId[activeIndex] && previewMaterials && currentDataType == "colorpicker" ? <ColorPicker matId={previewMaterialsId[activeIndex].matId} materialSelect={onMaterialSelect} changePreviewId={onChangePreview}/> : null}
            {previewMaterialsId && previewMaterialsId[activeIndex] && previewMaterials && currentDataType == "material" ? <CatalogMaterials materialSelect={onMaterialSelect} changePreviewId={onChangePreview} matId={previewMaterialsId[activeIndex].matId} /> : null}
        
        </>
    );
};

export default StateSelectedProduct;