import React from 'react';
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import { selectActiveViewMode, selectActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice';
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles({
    help_bot_wrap:{
        position: 'absolute',
        bottom: 20,
        left: "50%",
        transform: "translateX(calc(-50% - 270px / 2))",
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '12px'
    },
    help_bot_wr:{
        display: 'flex',
        alignItems: 'center'
    },
    help_bot_item:{
        display: 'flex',
        alignItems: 'center',
        margin: '0 15px',
        '& img': {
            marginLeft: '5px'
        }
    },

});
const Tips = props => {
    let translation = useSelector(selectTranslation);
    let activeViewMode = useSelector(selectActiveViewMode);
    let activeViewModeState = useSelector(selectActiveViewModeState);

    const classes = useStyle();
    return (
        <div className={classes.help_bot_wrap}>
            <div className={classes.help_bot_wr}>
                {
                    activeViewModeState == "stateRenderMake" ?
                    null
                    :
                    activeViewMode == "view3d" ?
                        activeViewModeState == "stateEditing" ?
                            <>
                                <div className={classes.help_bot_item}>
                                    <div>{translation["TIP_ROTATE_CAMERA"]}</div>
                                    <img src="/src_designer/images/help/mouse_left_click.svg"/>
                                </div>
                                <div className={classes.help_bot_item}>
                                    <div>{translation["TEXT_DRAG_SCENE"]}</div>
                                    <img src="/src_designer/images/help/mouse_right_click.svg"/>
                                </div>
                            </>
                        :
                        activeViewModeState == "stateSelectedProduct" ?
                            <>
                                <div className={classes.help_bot_item}>
                                    <div>{translation["TIP_ROTATE_CAMERA"]}</div>
                                    <img src="/src_designer/images/help/keyboard_move_object.svg"/>
                                </div>
                                <div className={classes.help_bot_item}>
                                    <div>{translation["TIP_ROTATE_CAMERA"]}</div>
                                    <img src="/src_designer/images/help/ctrl_mouse_left.svg"/>
                                </div>
                            </>
                        :
                        activeViewModeState == "stateSelectedWall" ||
                        activeViewModeState == "stateSelectedConstr" ?
                        <div className={classes.help_bot_item}>
                            <div>{translation["TIP_ROTATE_CAMERA"]}</div>
                            <img src="/src_designer/images/help/keyboard_move_object.svg"/>
                        </div>
                        : null
                    :
                    activeViewMode == "view2d" || activeViewMode == "constr" ?
                    <div className={classes.help_bot_item}>
                        <div>{translation["TEXT_DRAG_SCENE"]}</div>
                        <img src="/src_designer/images/help/mouse_right_click.svg"/>
                    </div> 
                    :
                    activeViewMode == "viewWalk" ?
                    <>
                        <div className={classes.help_bot_item}>
                            <div>{translation["TIP_ROTATE_CAMERA"]}</div>
                            <img src="/src_designer/images/help/keyboard_move_object.svg"/>
                        </div>
                        <div className={classes.help_bot_item}>
                            <div>{translation["TIP_ROTATE_CAMERA"]}</div>
                            <img src="/src_designer/images/help/mouse_left_click.svg"/>
                        </div>
                    </>
                    : null
                }
            </div>
        </div>
    )
}

export default Tips;