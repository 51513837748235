import React from 'react';
import Main2D_3D from './panels/2D-3D/Main2D_3D.jsx';
import { useSelector } from 'react-redux';
import { selectActiveViewMode, selectActiveViewModeState } from '../../../../../redux/features/viewMode/viewModeSlice.js';
import ConstructorMain from './panels/constructor/ConstructorMain.jsx';
import {createUseStyles} from "react-jss";
import PanoMain from './panels/pano/PanoMain.jsx';
import Main_TileConfig from './panels/tileConfig/Main_TileConfig.jsx';
import { selectTips } from '../../../../../redux/features/tips/tipsSlice.js';
import { selectUserProfileData } from '../../../../../redux/features/user/userSlice.js';
import Tip from '../../tips/Tip.jsx';
import { selectRightPanelStatus } from '../../../../../redux/features/common/commonSlice.js';

const useStyle = createUseStyles({
    planer_catalog_wr:{
        height: 'calc(100% - 29px)',
        position: 'relative',
        overflow: 'hidden',
        borderLeft: '1px solid #cdcdcd',
        background: '#fff'
    }
});
const Content = props => {
    
    let activeViewMode = useSelector(selectActiveViewMode);
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let userProfileData = useSelector(selectUserProfileData);
    let tips = useSelector(selectTips);
    let rightPanelStatus = useSelector(selectRightPanelStatus);
    const classes = useStyle();
    return (
        <>
            {
                rightPanelStatus == false ? null :
                <div className={classes.planer_catalog_wr}>
                {
                    activeViewMode == "constr" ? <ConstructorMain onKeyDownConstr={props.onKeyDownConstr}/> : 
                    activeViewMode == "view2d" || activeViewMode == "view3d" ? <Main2D_3D /> : 
                    activeViewMode == "tileConfig" ? <Main_TileConfig /> : 
                    activeViewMode == "viewWalk" ? <PanoMain /> : null
                }
                </div>
                
                
            }
            {
                activeViewMode == "constr" && userProfileData.tips && userProfileData.tips != 0 && !tips.constructorButtons.showed ?
                <>
                    <Tip name={"constructorButtons"} showTime={500} arrow={{position: "top", direction: "right"}} text={"TUT_FROM_SCRATCH"} position={{x: "calc(100%)", y: 185, from: "right"}}/>
                    <Tip name={"constructorButtons"} showTime={1000} arrow={{position: "bottom", direction: "right"}} text={"TUT_UPLOAD_FLOORPLAN"} position={{x: "calc(100%)", y: 365, from: "right"}}/>
                </>
                :
                null
            }
            { activeViewModeState == "stateSelectedGroup" && userProfileData.tips && userProfileData.tips != 0 && !tips.mergeGroupButton.showed ? <Tip name={"mergeGroupButton"} showTime={1000} arrow={{position: "top", direction: "right"}} text={"TUT_MERGE_GROUP"} position={{x: "calc(-100% + 25px)", y: 170, from: "left"}}/> : null}
            { activeViewMode == "viewWalk" && activeViewModeState == "stateEditing" && userProfileData.tips && userProfileData.tips != 0 && !tips.panorams.showed ? <Tip name={"panorams"} showTime={1000} arrow={{position: "bottom", direction: "right"}} text={"TUT_CHANGE_PANORAMA"} position={{x: "calc(100%)", y: window.innerHeight / 2 - 60, from: "right"}}/> : null }
        </>
    )
}

export default Content;