import React from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch, useSelector } from 'react-redux';
import { selectRightPanelStatus, updateRightPanelStatus } from '../../../../../../redux/features/common/commonSlice';
import cx from 'classnames';
import { planner } from '../../../../../../init';

const useStyle = createUseStyles({
    planer_catalog_btn:{
        height: '100%',
        width: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover':{
            '& path, & circle, & rect':{
                fill: '#ffd119',
            }
        }
    },
    planer_catalog_btn__hidden:{
        transform: 'rotate(180deg)'
    }
});
const HideButton = props => {
    const classes = useStyle();
    let rightPanelStatus = useSelector(selectRightPanelStatus);
    let dispatch = useDispatch();

    let togglePanel = status => {
        dispatch(updateRightPanelStatus(status));
        planner.scene.toggleRightPanelStatus(status);
        planner.mih()._titlesTool.rightPanelToggle(status);
    }

    return (
        <>
            {
                <div className={cx(classes.planer_catalog_btn, {[classes.planer_catalog_btn__hidden]:rightPanelStatus == false})} onClick={e => togglePanel(!rightPanelStatus)}>
                    <svg width="9" height="15" viewBox="0 0 9 15" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.0271826 0.679673L0.72504 0L8.4375 7.54323L7.73964 8.2229L7.73489 8.21825L0.692425 15L-2.86102e-06 14.3151L7.03849 7.53713L0.0271826 0.679673Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.0271826 0.679673L0.72504 0L8.4375 7.54323L7.73964 8.2229L7.73489 8.21825L0.692425 15L-2.86102e-06 14.3151L7.03849 7.53713L0.0271826 0.679673Z" fill="white"/>
                    </svg>
                </div>
            }
        </>
        
        
    )
}

export default HideButton;