import React from 'react';
import { useSelector } from 'react-redux';
import { selectQuickPanels } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import Delete from './Delete';
import Helper from './Helper.jsx'
import HelperModel from './HelperModel';
import HelperPlinth from './HelperPlinth';

const QuickPanels = props => {

    let quickPanels = useSelector(selectQuickPanels);

    return (
        <>
            { quickPanels.helper.status == true ? <Helper /> : null }
            { quickPanels.delete.status == true ? <Delete /> : null }
            { quickPanels.helper_model.status == true ? <HelperModel /> : null }
            { quickPanels.helper_plinth.status == true ? <HelperPlinth /> : null }
        </>
        
    )
}

export default QuickPanels;