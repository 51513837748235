import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTranslation } from '../../../../../../../../redux/features/language/languageSlice';
import { historyUpdate, selectActiveViewModeData } from '../../../../../../../../redux/features/viewMode/viewModeSlice';
import { setActivePopup } from '../../../../../../../../redux/features/popups/popupsSlice';
import {createUseStyles} from "react-jss";
import { useEffect } from 'react';

let planner_catalog_project_info = {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: 18,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, .7)',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
}

let planner_catalog_project_wr = {
    height: 150,
    position: 'relative',
    '&:after':{
        content: "' '",
        top: '0%',
        left: '0%',
        width: 'calc(100% - 3px)',
        border: '1px solid #fff',
        height: 'calc(100% - 2px)',
        position: 'absolute',
        pointerEvents: 'none',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
    },
}
let planner_catalog_project_text = {
    fontSize: 12,
    position: 'absolute',
    left: 5,
    bottom: 5
}

const useStyle = createUseStyles({
    planer_catalog_cont: {
        borderLeft: '1px solid #cdcdcd',
        background: '#F1F1F1',
        height: '100%',
        display: 'grid',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 270,
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
    },
    planner_catalog_project_wrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff'
    },
    planner_catalog_project_wr: {
        extend: planner_catalog_project_wr,
        '&:after':{
            boxShadow: 'inset 0px 0px 0px 3px #9dd900',
            opacity: 0,
        },
        '&:hover':{
            '& $planner_catalog_project_info':{
                opacity: 1
            },
            '&:after':{
                opacity: 1
            }
        },
    },
    planner_catalog_project_wr_in_progress:{
        extend: planner_catalog_project_wr,
    },
    planner_catalog_project_wr_error:{
        extend: planner_catalog_project_wr,
        '&:after':{
            boxShadow: 'inset 0px 0px 0px 3px #ff3333',
        },
    },
    planner_catalog_project_img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    planner_catalog_project_info: {
        extend: planner_catalog_project_info,
        opacity: 0,
    },
    planner_catalog_project_info_in_progress:{
        extend: planner_catalog_project_info,
    },
    planner_catalog_project_info_error:{
        extend: planner_catalog_project_info,
    },
    planner_catalog_project_btn:{
        position:'relative',
        cursor: 'pointer',
        '&:hover line':{
            fill: '#ffd119',
            stroke: '#ffd119',
        },
        '&:hover rect':{
            fill: '#ffd119',
            stroke: '#ffd119',
        },
        '&:hover path:not(.path_not_anim)':{
            fill: '#ffd119',
            stroke: '#ffd119',
        },
        '&:hover':{
            '& $planner_catalog_project_btn_text':{
                opacity: 1
            },
        },
    },
    planner_catalog_project_btn_icon:{
        width: 26,
        height: 26,
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
        }
    },
    planner_catalog_project_btn_text:{
        position: 'absolute',
        top: 'calc(100% + 2px)',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: 14,
        color: '#ffd119',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: 0,
    },
    planner_catalog_project_text:{
        extend: planner_catalog_project_text,
        color: "rgb(253, 212, 27)",
    },
    planner_catalog_project_text_error:{
        extend: planner_catalog_project_text,
        color: "#ff3333",
    }
});

const ListRenders = props => {

    let activeViewMode
    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;
    const classes = useStyle();

    let [rendersArray, setRendersArray] = useState();

    let dispatch = useDispatch();

    let translation = useSelector(selectTranslation);

    let rendersShow = [];
    
    let openRender = async id => dispatch(setActivePopup({name: "confirm", type: "render_open", data: id}));

    let deleteRender = (id, projectId) => dispatch(setActivePopup({name: "confirm", type: "render_delete", data: {id, projectId}}));

    useEffect(async () => {
        currentDataPanel && currentDataPanel.forEach(item => {
            rendersShow.push(
                <div className={ item.status == "stored" ? classes.planner_catalog_project_wr : item.status == "error" || item.status == "blend_file" ? classes.planner_catalog_project_wr_error : classes.planner_catalog_project_wr_in_progress } key={item.render_id}>
                    <img src={R2D.URL.DOMAIN + item.preview} className={classes.planner_catalog_project_img}/>
                    <div className={item.status == "stored" ? classes.planner_catalog_project_info : item.status == "error" || item.status == "blend_file" ? classes.planner_catalog_project_info_error : classes.planner_catalog_project_info_in_progress}>
                        {
                            item.status == "stored" ?
                            <>
                                <div className={classes.planner_catalog_project_btn} onClick={e => openRender(item.render_id)}>
                                    <svg viewBox="0 0 485 485" className={classes.planner_catalog_project_btn_icon}>
                                        <path fill="#fff" d="M426.5,458h-368C51,458,45,464,45,471.5S51,485,58.5,485h368c7.5,0,13.5-6,13.5-13.5S434,458,426.5,458z"/>
                                        <path fill="#fff" d="M233,378.7c2.5,2.5,6,4,9.5,4s7-1.4,9.5-4l107.5-107.5c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0L256,336.5v-323
                                            C256,6,250,0,242.5,0S229,6,229,13.5v323l-84.4-84.4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L233,378.7z"/>
                                    </svg>
                                </div>
                            </>
                            :
                            item.status == "created" || item.status == "rendering" || item.status == "taken" || item.status == "start" ?
                                <div className={classes.planner_catalog_project_text}>
                                    {
                                        translation["TEXT_RENDER_IN_PROGRESS"]
                                    }
                                </div> :
                            item.status == "error" || item.status == "blend_file" ? 
                                <div className={classes.planner_catalog_project_text_error}>
                                    {
                                        translation["TEXT_RENDER_ERROR"]
                                    }
                                </div> :
                            item.status == "waiting" ? 
                                <div className={classes.planner_catalog_project_text}>
                                    {translation["TEXT_RENDER_WAITING"]}
                                </div>
                             :
                            null
                        }
                        
                    </div>
                </div>
            );
        });
        setRendersArray(rendersShow)
    }, [])
    
    return (
        <>
            <div className={classes.planer_catalog_cont} >
                <div className={classes.planner_catalog_project_wrap}>
                    { rendersArray }
                </div>
            </div>
        </>
    )
}

export default ListRenders;