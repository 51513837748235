import React, { useState } from 'react';
import {createUseStyles} from "react-jss";
import EditPlinthItem from "../../../../../../common/editPlinthItem/index.jsx";
import Checkbox from "../../../../../../common/checkbox/index.jsx";
import BlueButton from "../../../../../../common/buttons/bluebutton/index.jsx";
import ChangeSize from "../../../../../../common/changeSize/index.jsx";
import { user, planner } from '../../../../../../../../../init.js';
import EditMaterialsItem from '../../../../../../common/editMaterialItem/index.jsx';
import Catalog from '../../../general/catalog/Catalog.jsx';
import CatalogMaterials from '../../../general/catalog/CatalogMaterials.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { historyAdd, selectActiveViewModeData, updateMaterialId } from '../../../../../../../../../redux/features/viewMode/viewModeSlice.js';
import { selectTranslation } from '../../../../../../../../../redux/features/language/languageSlice.js';
import { selectMetrics } from '../../../../../../../../../redux/features/metrics/metricsSLice.js';
import { changeUndoRedo } from '../../../../../../../../../redux/features/topPanel/topPanelSlice.js';
import ChangePlinth from '../../../general/changePlinth/ChangePlinth.jsx';
import { selectIsLogged } from '../../../../../../../../../redux/features/user/userSlice.js';
import { motion } from 'framer-motion';
import ColorPicker from '../../../general/colorPicker/ColorPicker.jsx';

const useStyle = createUseStyles({
    planerWallsCont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planerWallsWrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',
        padding: 20,
    },
    planerWallsTitle:{
        fontSize: 13,
        textAlign: 'center',
        marginBottom: 15,
        color: '#505050',
    },
    planerChangeSizes:{
        marginBottom: 20,
        paddingRight: 80
    },
    planerWallsCurentMatWr:{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },

    divideWall:{
        height: 100,
        border: '1px solid #d9d9d9',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 31,
        padding: '10px 25px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        marginBottom: 20,
        '&:hover':{
            background: '#FFCC00',
            border: '1px solid #FFCC00',
        }
    },
    divideWallImg:{

    },
    divideWallText:{
        color: '#444444',
        fontSize: 13
    },
    divideWallGrid:{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 14,
        marginBottom: 20
    },
    divide_checkbox_wr:{
        marginBottom: 15
    }
});

const StateSelectedPlinth = () => {
    let isPro = user.isPro();
    let currObj = planner.mih()._scene.currentView3DObject;
    let constructionData = currObj.getConstructorElementData();

    let metrics = useSelector(selectMetrics);
    let isLogged = useSelector(selectIsLogged);

    let translation = useSelector(selectTranslation);
    let dispatch = useDispatch();

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataType = currentStateHistory[currentStateHistory.length - 1].type;
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    let [plinthHeight, setPlinthHeight] = useState(constructionData.h);
    let [plinthDepth, setPlinthDepth] = useState(constructionData.d);

    let [mouseDown, setMouseDown] = useState({status: false, action: null, type: null});

    let materialId = currentStateHistory[0].matId;

    let imagesSrc = constructionData.type == 'bottomPlinth' ? '/plinth/b' : '/molding/t';

    let onCopyMaterial = id => R2D.CopyPaste.copyMaterial(id);

    let onPasteMaterial = () => {
        let mat = R2D.CopyPaste.pasteMaterial();
        if (mat) {
            constructionData.setMaterial(mat);
            constructionData.dispatchUpdate();
            dispatch(updateMaterialId(mat));
            R2D.scene.history.saveState();
            dispatch(changeUndoRedo());
        }
    }

    let onClickPreview = async () => {
        let catalogMaterials = await user.loadRightPanelData(R2D.URL.CATALOG_MATERIALS_TREE_FOR_MODELS);
        dispatch(historyAdd({added: true, data: catalogMaterials.materials, type: "catalog"}));
    }

    let onSelectMaterial = id => {
        constructionData.setMaterial(id);
        constructionData.dispatchUpdate();
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    let onChangePreview = id => dispatch(updateMaterialId(id))

    let changeMoldingVisible = checked => {
        constructionData.exists = checked;
        constructionData.dispatchUpdate();
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }
    
    let onChangeSize = (mouseDown, value, type) => {
        setMouseDown({...mouseDown, type});
        constructionData[type] = value;
        constructionData.dispatchUpdate();
        planner.scene.rebuildAllPlinths();
        type == "h" ? setPlinthHeight(value) : setPlinthDepth(value);

        if(mouseDown.status == false) {
            planner.mih()._scene.history.saveState();
            dispatch(changeUndoRedo());
        }
    }

    let onClickPlinthChange = () => dispatch(historyAdd({added: true, type: "changePlinth"}));

    let onApplyToAllRoom = () => {
        planner.scene.setPlinthToRoom(constructionData);
        constructionData.dispatchUpdate();
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }
    const variants = {
        visible: { transform:  "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }

    const classes = useStyle();
    return (
        <>
            {
                currentDataType == "main" ?
                    <motion.div transition={{ ease: "linear", duration: .1 }} initial="hidden" animate="visible" exit={"exit"} variants={variants} className={classes.planerWallsCont}>
                        <div className={classes.planerWallsWrap}>
                            <div className={classes.planerWallsTitle}>{`${translation["LENGTH"]} ${planner.units.valueToString(constructionData.length)} ${R2D.DimensionSystem.getName()}`}</div>

                            <div className={classes.planerChangeSizes}>
                                <ChangeSize mouseDown={mouseDown} valueChangeType={"h"} changeValue={onChangeSize} value={plinthHeight} label={translation["HEIGHT"]}/>
                                <ChangeSize mouseDown={mouseDown} valueChangeType={"d"} changeValue={onChangeSize} value={plinthDepth} label={translation["DEPTH"]}/>
                            </div>

                            <div className={classes.divideWallGrid}>
                                <EditPlinthItem onClick={onClickPlinthChange} src={`/src_designer/images${imagesSrc + constructionData.shapeNum}.png`} lock={!isPro}/>
                                <EditMaterialsItem matId={materialId} onCopyPaste={{copy: onCopyMaterial, paste: onPasteMaterial}} onClick={onClickPreview}/>
                            </div>

                            <div className={classes.divide_checkbox_wr}>
                                <Checkbox name={"plinthVisible"} onChange={changeMoldingVisible} checked={constructionData.exists} label={translation["TEXT_SHOW"]}/>
                            </div>

                            <BlueButton label={translation["TEXT_APPLY_TO_ROOM"]} onClick={onApplyToAllRoom}/>

                        </div>
                    </motion.div>
                :
                currentDataType == "changePlinth" ? <ChangePlinth /> :
                currentDataType == "catalog" ? <Catalog /> :
                currentDataType == "colorpicker" ? <ColorPicker matId={materialId} materialSelect={onSelectMaterial} changePreviewId={onChangePreview}/> : 
                currentDataType == "material" ? <CatalogMaterials materialSelect={onSelectMaterial} changePreviewId={onChangePreview} matId={materialId} /> : null
            }
        </>
        
    );
};

export default StateSelectedPlinth;