import React from 'react';
import RegistrationPopup from './RegistrationPopup.jsx';
import LoginPopup from './LoginPopup.jsx';
import ForgotPassPopup from './ForgotPassPopup.jsx';
import { useSelector } from 'react-redux';
import { selectPopups } from '../../../../redux/features/popups/popupsSlice.js';
import ProfilePopup from './ProfilePopup.jsx';
import PayProPopup from './PayProPopup.jsx';
import SetProjectNamePopup from './SetProjectNamePopup.jsx';
import ConfirmPopup from './ConfirmPopup.jsx';
import AlertPopup from './AlertPopup.jsx';
import SharePopup from './SharePopup.jsx'
import SaveBeforePopup from './SaveBeforePopup.jsx';
import SetWallHeightPopup from './SetWallHeightPopup.jsx';
import CompleteMessage from './CompleteMessage.jsx';
import LoadingMessagePopup from './LoadingMessage.jsx';
import ImageUploadPopup from './ImageUploadPopup.jsx';
import AlignerWidthSetMetricsPopup from './AlignerWidthSetMetricsPopup.jsx';
import AlignerWidthSetPopup from './AlignerWidthSetPopup.jsx';
import UploadCustomPopup from './UploadCustomPopup/UploadCustomPopup.jsx';
import BuyCredits from './BuyCredits.jsx';
import RenderExample from './RenderExample.jsx';
import RenderDone from './RenderDone.jsx';
import StartPopup from './StartPopup.jsx';
import StartPopupProjects from './StartPopupProjects.jsx';

const Popups = props => {

    let activePopup = useSelector(selectPopups);

    return (
        <>
            { activePopup.login.status === true ? <LoginPopup /> : null }
            { activePopup.registration.status === true ? <RegistrationPopup /> : null }
            { activePopup.forgotPassword.status === true ? <ForgotPassPopup /> : null }
            { activePopup.profile.status === true ? <ProfilePopup /> : null }
            { activePopup.payPro.status === true ? <PayProPopup /> : null }
            { activePopup.setProjectName.status === true ? <SetProjectNamePopup /> : null }
            { activePopup.confirm.status === true ? <ConfirmPopup /> : null }
            { activePopup.alert.status === true ? <AlertPopup /> : null }
            { activePopup.share.status === true ? <SharePopup /> : null }
            { activePopup.saveBefore.status === true ? <SaveBeforePopup /> : null }
            { activePopup.setWallHeight.status === true ? <SetWallHeightPopup /> : null }
            { activePopup.completeMessage.status === true ? <CompleteMessage /> : null }
            { activePopup.loadingMessage.status === true ? <LoadingMessagePopup /> : null }
            { activePopup.uploadImage.status === true ? <ImageUploadPopup /> : null }
            { activePopup.alignerWidthSet.status === true ? <AlignerWidthSetPopup /> : null }
            { activePopup.alignerWidthSetMetrics.status === true ? <AlignerWidthSetMetricsPopup /> : null }
            { activePopup.uploadCustom.status === true ? <UploadCustomPopup /> : null }
            { activePopup.buyCredits.status === true ? <BuyCredits /> : null }
            { activePopup.renderExample.status === true ? <RenderExample /> : null }
            { activePopup.renderMade.status === true ? <RenderDone /> : null }
            {/* { activePopup.startPopup.status === true ? <StartPopup/> : null }
            { activePopup.preConfigRoomsPopup.status === true ? <StartPopupProjects/> : null } */}
        </>
    )
}

export default Popups;