import React, {useRef, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranslation } from '../../../../../../../../redux/features/language/languageSlice';
import { selectActiveViewModeData, setActiveViewMode, setActiveViewModeState, updateCurrentProductSize } from '../../../../../../../../redux/features/viewMode/viewModeSlice';
import { setActivePopup } from '../../../../../../../../redux/features/popups/popupsSlice';
import { planner, user } from '../../../../../../../../init';
import {createUseStyles} from "react-jss";
import { setActiveProjectName } from '../../../../../../../../redux/features/user/userSlice';
import { hideQuickPanelAll } from '../../../../../../../../redux/features/quickPanels/quickPanelsSlice';
import { changeImagePlanUpload } from '../../../../../../../../redux/features/constructor/constructorSlice';
import { updateCurrentProjectHash } from '../../../../../../../../redux/features/common/commonSlice';

const useStyle = createUseStyles({
    planer_catalog_cont: {
        borderLeft: '1px solid #cdcdcd',
        background: '#F1F1F1',
        height: '100%',
        display: 'grid',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 270,
        overflow:'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
    },
    roll_out_left:{
        transform: "translateX(-100%)"
    },
    roll_out_right:{
        transform: "translateX(100%)"
    },
    planner_catalog_project_wrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff'
    },

    planner_catalog_project_wr: {
        height: 150,
        position: 'relative',
        '&:after':{
            content: "' '",
            top: '0%',
            left: '0%',
            width: 'calc(100% - 3px)',
            border: '1px solid #fff',
            height: 'calc(100% - 2px)',
            position: 'absolute',
            boxShadow: 'inset 0px 0px 0px 3px #9dd900',
            pointerEvents: 'none',
            opacity: 0,
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        },
        '&:hover':{
            '& $planner_catalog_project_info':{
                opacity: 1
            },
            '& $planner_catalog_preview_info':{
                opacity: 1
            },
            '&:after':{
                opacity: 1
            }
        },
    },
    planner_catalog_project_img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    planner_catalog_project_info: {
        position: 'absolute',
        bottom: 29,
        left: 0,
        padding: '18px 14px 0',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, .7)',
        height: 'calc(100% - 29px)',
        alignItems: 'center',
        opacity: 0,
        display: 'flex',
        justifyContent: 'space-around',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planner_catalog_project_btn:{
        position:'relative',
        cursor: 'pointer',
        '&:hover line':{
            fill: '#ffd119',
            stroke: '#ffd119',
        },
        '&:hover rect':{
            fill: '#ffd119',
            stroke: '#ffd119',
        },
        '&:hover path:not(.path_not_anim)':{
            fill: '#ffd119',
            stroke: '#ffd119',
        },
        '&:hover':{
            '& $planner_catalog_project_btn_text':{
                opacity: 1
            },
        },
    },
    planner_catalog_project_btn_icon:{
        width: 23,
        height: 23,
        '& path':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
        }
    },
    planner_catalog_project_btn_text:{
        position: 'absolute',
        top: 'calc(100% + 2px)',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: 12,
        color: '#ffd119',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: 0,
    },
    planner_catalog_project_title:{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        minHeight: 29,
        backgroundColor: 'rgba(0, 0, 0, .7)',
        fontSize: 13,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 11px',
    },
    planner_catalog_preview_info:{
        position: 'absolute',
        top: 0,
        left:0,
        width: '100%',
        backgroundColor: 'rgba(129, 129, 129, 0.9)',
        height: '100%',
        alignItems: 'center',
        opacity:0,
        display: 'flex',
        justifyContent: 'space-around',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
    }
});

const ListProjects = props => {
    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;

    let translation = useSelector(selectTranslation);

    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    let dispatch = useDispatch();

    let deleteProject = item => {
        console.log("PROJECT DELETE: ", item);
        dispatch(setActivePopup({name: "confirm", type: "project_delete", data: item}));
    }

    let onProjectOpen = async item => {
        if (!planner.scene.wasChanged()) {
            
            dispatch(hideQuickPanelAll());
            let loadedProjectViewType = await planner.scene.loadProject(item.id);

            dispatch(setActiveProjectName(item.name));
            
            if(loadedProjectViewType == "2d" || loadedProjectViewType == "constr") dispatch(setActiveViewMode("view2d"));
            if(loadedProjectViewType == "3d") dispatch(setActiveViewMode("view3d"));
            if(loadedProjectViewType == "walk") dispatch(setActiveViewMode("viewWalk"));

            dispatch(setActiveViewModeState({state: "stateEditing"}));

            dispatch(changeImagePlanUpload(R2D.commonSceneHelper.drawingHelper.state != null ? R2D.commonSceneHelper.drawingHelper.state : null));

            let response = await user.projectHashLoad(R2D.controller.getProjectId());
            if(response.type == "error") dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
            else {
                dispatch(updateCurrentProjectHash(response));
                window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH + '/project/' + response);
            }
            
        } else {
            dispatch(setActivePopup({name: "saveBefore", type: "open", data: item}));
        }
    }

    let onProjectCopy = item => {
        dispatch(setActivePopup({name: "setProjectName", data: item, type: "copy"}));
    }

    let onProjectShare = async item => {
        let response = await user.projectHashLoad(item.id);
        if(response.type == "error") {
            dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
            return;
        }
        dispatch(setActivePopup({ name: "share", data: response}));
    }

    let projectsShow = [];
    const classes = useStyle();

    if(currentDataPanel) {
        currentDataPanel.forEach(item => {
            projectsShow.push(
                <div className={classes.planner_catalog_project_wr} key={ item.id }>
                        <img src={ R2D.URL.DOMAIN + item.preview } className={classes.planner_catalog_project_img}/>
                        <div className={classes.planner_catalog_project_info}>
                            <div className={classes.planner_catalog_project_btn} onClick={ e => onProjectOpen(item)}>
                                <svg viewBox="0 0 485 485" className={classes.planner_catalog_project_btn_icon}>
                                    <path fill="#fff" d="M426.5,458h-368C51,458,45,464,45,471.5S51,485,58.5,485h368c7.5,0,13.5-6,13.5-13.5S434,458,426.5,458z"/>
                                    <path fill="#fff" d="M233,378.7c2.5,2.5,6,4,9.5,4s7-1.4,9.5-4l107.5-107.5c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0L256,336.5v-323
                                        C256,6,250,0,242.5,0S229,6,229,13.5v323l-84.4-84.4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L233,378.7z"/>
                                </svg>
                                <div className={classes.planner_catalog_project_btn_text} >{ translation["BUTTON_OPEN_PLAN"] }</div>
                            </div>
                            <div className={classes.planner_catalog_project_btn} onClick={ e => onProjectCopy(item)}>
                                <svg viewBox="0 0 21 26" className={classes.planner_catalog_project_btn_icon}>
                                    <line y1="1" x2="15" y2="1" stroke="white" strokeWidth="2"/>
                                    <line y1="20" x2="15" y2="20" stroke="white" strokeWidth="2"/>
                                    <line x1="1" y1="1" x2="1" y2="20" stroke="white" strokeWidth="2"/>
                                    <line x1="14" y1="1" x2="14" y2="20" stroke="white" strokeWidth="2"/>
                                    <rect x="6" y="5" width="15" height="21" fill="white"/>
                                </svg>

                                <div className={classes.planner_catalog_project_btn_text}>{ translation["BUTTON_COPY_PLAN"] }</div>
                            </div>
                            <div className={classes.planner_catalog_project_btn} onClick={ e => deleteProject(item) }>
                                <svg viewBox="0 0 25 34" className={classes.planner_catalog_project_btn_icon}>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2 7.00006H22H23V32.4201C23 33.2921 22.296 34.0001 21.429 34.0001H3.571C2.704 34.0001 2 33.2921 2 32.4201V7.00006C2 7.00006 2 6.12706 2 7.00006Z" fill="#fff"/>
                                        <path className="path_not_anim" fillRule="evenodd" clipRule="evenodd" d="M18.5 9C19.328 9 20 9.672 20 10.5V27.5C20 28.328 19.328 29 18.5 29C17.672 29 17 28.328 17 27.5V10.5C17 9.672 17.672 9 18.5 9Z" fill="grey"/>
                                        <path className="path_not_anim" fillRule="evenodd" clipRule="evenodd" d="M12.5 9C13.328 9 14 9.672 14 10.5V27.5C14 28.328 13.328 29 12.5 29C11.672 29 11 28.328 11 27.5V10.5C11 9.672 11.672 9 12.5 9Z" fill="grey"/>
                                        <path className="path_not_anim" fillRule="evenodd" clipRule="evenodd" d="M6.5 9C7.328 9 8 9.672 8 10.5V27.5C8 28.328 7.328 29 6.5 29C5.672 29 5 28.328 5 27.5V10.5C5 9.672 5.672 9 6.5 9Z" fill="grey"/>
                                        <path d="M9 2L2 6H23L16 2L15.5528 1.10557C15.214 0.428005 14.5215 0 13.7639 0H11.2361C10.4785 0 9.786 0.428004 9.44721 1.10557L9 2Z" fill="#fff"/>
                                        <line y1="6.5" x2="26" y2="6.5" stroke="#ffd119"/>
                                        <line x1="10.5" y1="1.5" x2="14.5" y2="1.5" stroke="#BBBBBB" strokeLinecap="round"/>
                                </svg>
                                <div className={classes.planner_catalog_project_btn_text} >{ translation["BUTTON_DELETE_PLAN"] }</div>
                            </div>
                            <div className={classes.planner_catalog_project_btn} onClick={e => onProjectShare(item)}>
                                <svg viewBox="0 0 14 17" className={classes.planner_catalog_project_btn_icon}>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14 2.5C14 3.88071 12.8807 5 11.5 5C10.7669 5 10.1075 4.68445 9.65021 4.18177L4.79106 7.49789C4.92544 7.80468 5 8.14363 5 8.5C5 8.75212 4.96268 8.99552 4.89326 9.22498L9.85905 12.6139C10.2982 12.2315 10.872 12 11.5 12C12.8807 12 14 13.1193 14 14.5C14 15.8807 12.8807 17 11.5 17C10.1193 17 9 15.8807 9 14.5C9 14.1088 9.08984 13.7386 9.25002 13.4089L4.41389 10.1085C3.9553 10.6536 3.2681 11 2.5 11C1.11929 11 0 9.88071 0 8.5C0 7.11929 1.11929 6 2.5 6C3.16371 6 3.767 6.25863 4.21461 6.68062L9.13785 3.32075C9.04853 3.06367 9 2.7875 9 2.5C9 1.11929 10.1193 0 11.5 0C12.8807 0 14 1.11929 14 2.5Z" fill="white"></path>
                                </svg>
                                <div className={classes.planner_catalog_project_btn_text}>{ translation["BUTTON_SHARE_PLAN"] }</div>
                            </div>
                        </div>
                        <div className={classes.planner_catalog_project_title}>{ item.name }</div>
                    </div>
            )
        })
    }

    let activeAnimationClass = isAdded ? classes.roll_out_right : classes.roll_out_left;

    let rightPanelData = useRef(null);

    useEffect(async () => setTimeout(() => rightPanelData.current && rightPanelData.current.classList.remove(activeAnimationClass), 100), []);
    
    return (
            <div className={classes.planer_catalog_cont + ' ' + activeAnimationClass} ref={ rightPanelData }>
                <div className={classes.planner_catalog_project_wrap}>
                    { projectsShow }
                </div>
            </div>
    )
}

export default ListProjects;