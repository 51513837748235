import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguages, selectTranslation, languageChange } from '../../../../../redux/features/language/languageSlice.js';
import { setActiveViewModeState, selectActiveViewMode, historyUpdate, selectActiveViewModeState, setActiveViewMode } from '../../../../../redux/features/viewMode/viewModeSlice.js';
import { closeActivePopup, setActivePopup } from '../../../../../redux/features/popups/popupsSlice.js';
import { planner, user } from '../../../../../init';
import { selectUserProfileData, selectUserProjectActive, setActiveProjectName, userProfileDataLoad } from '../../../../../redux/features/user/userSlice.js';
import {createUseStyles} from 'react-jss';
import { changeUndoRedo, selectIsUndoRedoConstr, selectIsUndoRedoScene, selectIsUndoRedoTileConfig } from '../../../../../redux/features/topPanel/topPanelSlice.js';
import { changeMetricsActive, selectMetrics } from '../../../../../redux/features/metrics/metricsSLice.js';
import { hideQuickPanelAll } from '../../../../../redux/features/quickPanels/quickPanelsSlice.js';
import ButtonHover from './components/ButtonHover.jsx';
import Tip from '../../tips/Tip.jsx';
import { selectTips } from '../../../../../redux/features/tips/tipsSlice.js';
import { changeImagePlanUpload, selectIsImagePlanUploaded } from '../../../../../redux/features/constructor/constructorSlice.js';
import { changeActivePano, selectRightPanelStatus, updateCurrentProjectHash, updateRightPanelStatus } from '../../../../../redux/features/common/commonSlice.js';

const useStyles = createUseStyles({
    bar_btn_close: {
        width: '28px',
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        position: "relative",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '1px',
        '&:hover':{
            background: '#2f2f2f',
            '& path, & circle, & rect':{
                fill: '#ffd119',
            }
        },
        '@media screen and (max-width: 1280px)':{
            height: 27,
            zIndex: 1,
            pointerEvents: 'auto'
        }
    },
    bar_dropdown_wrap:{
        position: 'relative',
        height: '100%',
        minWidth: '36px',
        marginRight: '1px',
        '&:hover':{
            '& $bar_dropdown_option_wr':{
                transform: 'translateY(0%)'
            },
            '& path, & circle, & rect':{
                fill: '#ffd119',
            }
        },

    },
    bar_dropdown_wrap_lang:{
        '@media screen and (max-width: 1280px)':{
            height: 'auto',
            display: 'grid',
            gridTemplateColumns: '33% 67%',
            paddingLeft: 27,
            gridColumnStart: 1,
            gridColumnEnd: 4,
        }
    },
    bar_dropdown_option_wr_lang:{
        '@media screen and (max-width: 1280px)': {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
        }
    },
    bar_dropdown_active:{
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 10px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            color: '#ffd119'
        },
        '@media screen and (max-width: 1280px)':{
            height: '27px',
            justifyContent: 'flex-start',
        }
    },
    bar_dropdown_active_lang:{
        justifyContent: 'center',
    },
    bar_dropdown_option_wrap:{
        position: 'absolute',
        top: '100%',
        left: '0',
        width: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        zIndex: -1,
        '@media screen and (max-width: 1280px)':{
            position: 'relative',
            top: 0,
            zIndex: 0,
        }
    },
    bar_dropdown_option_wr:{
        paddingTop: '1px',
        pointerEvents: 'auto',
        transform: 'translateY(-100%)',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '@media screen and (max-width: 1280px)':{
            transform: 'translateY(0%)',
            paddingTop: 0
        }
    },
    bar_dropdown_option:{
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        height: '27px',
        marginBottom: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        color: '#ffffff',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            color: '#ffd119'
        },
        '@media screen and (max-width: 1280px)':{
            justifyContent: 'flex-start',
            padding: '0 10px',
        }
    },
    bar_dropdown_option_lang:{
        '@media screen and (max-width: 1280px)':{
            justifyContent: 'center',
        }
    },
    bar_login_btn:{
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        height: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        marginRight: '1px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            color: '#ffd119'
        }
    },
    bar_pro_btn:{
        height: '27px',
        userSelect: 'none',
        cursor: 'pointer'
    },
    bar_btn:{
        background: 'rgba(47, 47, 47, 0.7)',
        cursor: 'pointer',
        height: '27px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative",
        minWidth: '35px',
        marginRight: '1px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            '& $bar_btn_svg path':{
                fill: '#ffd119'
            }
        }
    },
    bar_btn_svg: {

    },
    bar_btn_not_active:{
        background: 'rgba(47, 47, 47, 0.7)',
        height: '27px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative",
        minWidth: '35px',
        marginRight: '1px',
        '& svg':{
            opacity: '0.3'
        }
    },
    bar_profil_icon:{
        width: 11,
        marginRight: 6,
        height: 13
    },
    hd_render_btn:{
        background: '#fcc749',
        cursor: 'pointer',
        height: '27px',
        display: 'flex',
        justifyContent: 'center',
        position: "relative",
        alignItems: 'center',
        minWidth: '35px',
        marginRight: '1px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#2f2f2f',
            '& path':{
                fill: '#ffd119'
            }
        },
        '@media screen and (max-width: 1280px)':{
            width: '100%',
        }
    },
    icon_hd_render:{

    },
    profilBarLogged:{
        '@media screen and (max-width: 1280px)':{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            marginBottom: 10
        }
    },
    bar_btnSaveBtn:{
        '@media screen and (max-width: 1280px)':{
            gridColumnStart: 3,
            gridRowStart: 4,
        }
    },
    bar_ShareBtn:{
        '@media screen and (max-width: 1280px)':{
            gridRowStart: 4,
        }
    },
    profilProject:{
        '@media screen and (max-width: 1280px)':{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            marginBottom: 10
        },
    },
    barMetricsBtn:{
        '@media screen and (max-width: 1280px)':{
            display: 'grid',
            gridTemplateColumns: 'auto',
            width: '100%',
            marginBottom: 10
        },
    },
    bar_dropdown_option_wr_metric:{
        '@media screen and (max-width: 1280px)':{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
        }
    },
    barMetricsActive:{
        '@media screen and (max-width: 1280px)':{
            display: 'none'
        }
    },
    bar_dropdown_option_active:{
        display: 'none',
        background: '#2f2f2f',
        '@media screen and (max-width: 1280px)':{
            display: 'flex'
        }
    },
    imagePlans:{
        minWidth: 135,
        '& $bar_dropdown_option':{
            justifyContent: 'left',
            padding: '0 10px'
        },
        '& $bar_dropdown_active':{
            justifyContent: 'left',
        }
    },
    imagePlanImg:{
        marginRight: 6
    }


});

const HideButton = props => {
    const classes = useStyles();

    return (
        <div className={classes.bar_btn_close} onClick={props.setPanelHidden}>
            <ButtonHover parentClass={classes.bar_btn_close} imgRotate={props.isHidden ? {transform: "rotate(180deg)"} : {transform: "rotate(0)"}} img={
                <svg width="9" height="15" className={classes.bar_btn_svg} viewBox="0 0 9 15" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.97282 0.679673L8.27496 0L0.5625 7.54323L1.26036 8.2229L1.26511 8.21825L8.30758 15L9 14.3151L1.96151 7.53713L8.97282 0.679673Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.97282 0.679673L8.27496 0L0.5625 7.54323L1.26036 8.2229L1.26511 8.21825L8.30758 15L9 14.3151L1.96151 7.53713L8.97282 0.679673Z" fill="white"/>
                </svg>

            } textHover={props.isHidden ? "HINT_BUTTON_SHOW_PANEL" : "HINT_BUTTON_HIDE_PANEL"} />
            
        </div>
    )
    
}

const LangButton = props => {
    
    const classes = useStyles();

    let languages = useSelector(selectLanguages);
    let dispatch = useDispatch();

    let onLangChange = code => {
        return new Promise((resolve, reject) => {
            dispatch(setActivePopup({name: "loadingMessage", data: "TEXT_WAIT"}))
            resolve(dispatch(languageChange(code)));
        }).then(result => {
            dispatch(closeActivePopup({name: "loadingMessage"}))
        })
    }

    let active = languages.find(i => i["current"] == 1);

    let elseElems = languages.map((item, index) => {
        if(item["current"] != 1) {
            return (
                <div className={classes.bar_dropdown_option+' '+ classes.bar_dropdown_option_lang} key={index} onClick={e => onLangChange(item["code"])}>
                    <img src={R2D.URL.DOMAIN + item["icon"]} />
                </div>
            )
        }
    })
    return(
        <div className={classes.bar_dropdown_wrap +' '+ classes.bar_dropdown_wrap_lang}>
            <div className={classes.bar_dropdown_active + ' ' + classes.bar_dropdown_active_lang} >
                <img src={R2D.URL.DOMAIN + active["icon"]} />
            </div>
            <div className={classes.bar_dropdown_option_wrap}>
                <div className={classes.bar_dropdown_option_wr +' '+ classes.bar_dropdown_option_wr_lang} id="bar_lang_option_wr">
                    {elseElems}
                </div>
            </div>
        </div>
    )
}

const LoginButton = props => {
    
    const classes = useStyles();

    let translation = useSelector(selectTranslation);
    
    let dispatch = useDispatch();
    
    return(
        <div className={classes.bar_login_btn} id="customLogin" onClick={() => dispatch(setActivePopup({name: "login"}))}>{translation["TEXT_BUTTON_SIGN_IN"]}</div> 
    )
}

const LoggedMenu = props => {
    let dispatch = useDispatch();

    let translation = useSelector(selectTranslation);
    const classes = useStyles();
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let activeViewMode = useSelector(selectActiveViewMode);
    let userData = useSelector(selectUserProfileData);

    let onLogout = e => dispatch(setActivePopup({name: "confirm", type: "logout"}));

    let onProfileButtonClick = e => {
        return new Promise((resolve, reject) => {
            resolve(dispatch(userProfileDataLoad()));
        }).then(result => {
            dispatch(setActivePopup({ name: "profile" }));
        })
    }

    let onRendersButtonClick = async e => {
        let response = await user.loadRenders();
        if(activeViewModeState == "stateRendersShow") dispatch(historyUpdate(response));
        else dispatch(setActiveViewModeState({state: "stateRendersShow", data: {added: true, type: "main", data: response}}));

        dispatch(hideQuickPanelAll());
        planner.mih().unsetActiveObjectProduct();
        planner.mih().changeState(planner.mih().stateMain);
    }

    return (
        <div id="profilBarLogged" className={classes.profilBarLogged}>
            <div className={classes.bar_dropdown_wrap}>
                <div className={classes.bar_dropdown_active}>
                    <svg className={classes.bar_profil_icon} width="64" height="87" viewBox="0 0 64 87" fill="none">
                        <path fill="#fff" d="M63.537 46.2786V82.2405C63.537 84.5038 61.7586 86.4436 59.3339 86.4436C56 86.4436 54.1308 84.6652 54.1308 82.2405L54.1297 57.4337C54.1297 55.8165 52.8362 54.3617 51.0577 54.3617C49.4406 54.3617 48.9857 55.6552 48.9857 57.4337V85.1922C48.9857 85.6771 48.6621 86.0008 48.1772 86.0008H15.3587C14.8737 86.0008 14.5501 85.6771 14.5501 85.1922V57.4337C14.5501 55.8165 14.2566 54.3617 12.4781 54.3617C10.861 54.3617 9.40613 55.6552 9.40613 57.4337V82.2405C9.40613 84.5038 8.5 86.4436 4.20307 86.4436C1.93978 86.4436 0 84.6652 0 82.2405V46.2786C0 39.9736 5.17394 34.7996 11.479 34.7996H51.8959C58.362 34.7996 63.5359 39.9736 63.5359 46.2786H63.537ZM31.8501 32.8185C40.9032 32.8185 48.3405 25.5438 48.3405 16.3281C48.3405 7.27504 40.9032 0 31.8501 0C22.797 0 15.3597 7.27475 15.3597 16.4904C15.3597 25.5435 22.797 32.8185 31.8501 32.8185Z"/>
                    </svg>
                    { user.data.name }
                </div>
                <div className={classes.bar_dropdown_option_wrap}>
                    <div className={classes.bar_dropdown_option_wr}>
                        <div className={classes.bar_dropdown_option} onClick={onProfileButtonClick}>{ translation["TEXT_PROFILE"] }</div>
                        {
                            userData.render == true && activeViewMode != "constr" ? 
                                <div className={classes.bar_dropdown_option} onClick={onRendersButtonClick}>{ translation["TEXT_MY_RENDERS"] }</div> 
                            :
                                null
                        }
                        <div className={classes.bar_dropdown_option} onClick={ onLogout }>{ translation["TEXT_BUTTON_SIGN_OUT"] }</div>
                    </div>
                </div>
            </div>
        </div>   
    )
}

const MetricsButton = props => {
    const classes = useStyles();

    let dispatch = useDispatch();

    let metrics = useSelector(selectMetrics);

    let onChangeDimension = dimension => {
        user.changeDimension(dimension);
        dispatch(changeMetricsActive(dimension));
        if(WC.WallsEditor._instance.state == WC.WallsEditor._instance.stateAlignDrawing) WC.WallsEditor._instance.state.draw()
    }

    let dimensionsData = [];

    metrics.dimensionsAll.forEach(item => {
        dimensionsData.push(
            <div key={item} className={"bar_dropdown_option js_set_metric " + (metrics.active == item ? classes.bar_dropdown_option_active : "")} onClick={e => onChangeDimension(item)}>{ item }</div>
        )
    })

    return (
        <div className={classes.bar_dropdown_wrap+' '+classes.barMetricsBtn}>
            <div className={classes.bar_dropdown_active+' '+classes.barMetricsActive}>{metrics.active}</div>
            <div className={classes.bar_dropdown_option_wrap}>
                <div className={classes.bar_dropdown_option_wr+' '+classes.bar_dropdown_option_wr_metric}>
                    { dimensionsData }
                </div>
            </div>
        </div>
    )
    
}

const Projects = props => {
    let translation = useSelector(selectTranslation);
    const classes = useStyles();
    let dispatch = useDispatch();
    let projectActive = useSelector(selectUserProjectActive);

    let projectsShow = async () => {
        if ( !user.isLogged()  ) {
            dispatch(setActivePopup({ name: "alert", type: "please_login", data: "TEXT_NEEDS_LOGIN" }));
            return;
        }
        let response = await user.loadProjects();

        if(response.type == "error") {
            dispatch(setActivePopup({name: "alert", type: response.message, data: response.data}))
        } else {
            dispatch(setActiveViewModeState({state: "stateProjectsShow", data: {data: response.list.reverse(), added: true}}));
            dispatch(hideQuickPanelAll());
            planner.mih().unsetActiveObjectProduct();
            planner.mih().changeState(planner.mih().stateMain);
        }
    }

    let onClickRename = e => {
        if ( !planner.scene.wasSaved() ) {
            dispatch(setActivePopup({name: "confirm", type: "project_save_before_rename"}))
        } else {
            dispatch(setActivePopup({name: "setProjectName", type: "rename"}));
        }
    }

    let onCreateNewScene = e => {
        if(planner.scene.wasChanged()) {
            dispatch(setActivePopup({name: "saveBefore", type: "createNew"}));
        } else {
            dispatch(hideQuickPanelAll());
            dispatch(setActiveViewModeState({state: "stateEditing"}));
            R2D.controller.createNewScene(true);
            dispatch(setActiveProjectName(translation["DEFAULT_SCENE_NAME"]));
            dispatch(changeImagePlanUpload(null));
            dispatch(changeActivePano({ id: 0, previewImgSrc: "", rotation: 0}));
            dispatch(updateCurrentProjectHash(""));
            window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH);
        }
        
    }

    let projectClear = e => planner.scene.wasChanged ? dispatch(setActivePopup({name: "confirm", type: "project_clear"})) : null;

    let buttonsSlide = useRef(null);
    let mainDiv = useRef(null);
    
    useEffect(() => {
        buttonsSlide.current.setAttribute("style", "width: auto");
        mainDiv.current.setAttribute("style", `min-width: ${buttonsSlide.current.offsetWidth + 20}px`);
        buttonsSlide.current.removeAttribute("style");
        return () => mainDiv.current ? mainDiv.current.removeAttribute("style") : null;
    });

    return (
        <div className={classes.bar_dropdown_wrap+' '+classes.profilProject} ref={ mainDiv }>
            <div className={classes.bar_dropdown_active}>{ projectActive.name || translation["DEFAULT_SCENE_NAME"] }</div>
            <div className={classes.bar_dropdown_option_wrap} ref={ buttonsSlide }>
                <div className={classes.bar_dropdown_option_wr}>
                    <div className={classes.bar_dropdown_option} onClick={onCreateNewScene}>{translation["TEXT_NEW_PLAN"]}</div>
                    <div className={classes.bar_dropdown_option} onClick={projectsShow}>{translation["TEXT_MY_PLANS"]}</div>
                    <div className={classes.bar_dropdown_option} onClick={projectClear}>{translation["BUTTON_CLEAR_SCENE"]}</div>
                    <div className={classes.bar_dropdown_option} onClick={onClickRename}>{translation["BUTTON_RENAME_SCENE"]}</div>
                </div>
            </div>
        </div>
    )
}

const ProVerButton = props => {
    let dispatch = useDispatch();
    const classes = useStyles();

    return (
        <div className={classes.bar_pro_btn} onClick={e => dispatch(setActivePopup({ name: "payPro" }))}>
            <img src="/src_designer/images/icon_pro_mini.svg"/>
        </div>
    )
}
let saveTipKey = null;

const SaveButton = props => {
    const classes = useStyles();
    let dispatch = useDispatch();

    let [showTipSaveProject, setTipSaveProject] = useState(false);

    let tips = useSelector(selectTips);
    var timeStart = useMemo(() => Date.now(), []);
                        
    let onClickSave = e => {
        if ( !user.isLogged()  ) {
            dispatch(setActivePopup({ name: "login" }));
            return;
        }
        else if ( !planner.scene.wasSaved() || R2D.controller.getProjectUserKey() != user.getKey()) {
            dispatch(setActivePopup({ name: "setProjectName", type: "create" }));
        } else {
            return new Promise((resolve, reject) => {
                dispatch(setActivePopup({name: "loadingMessage", data: "TEXT_WAIT"}));
                resolve(planner.scene.saveProject());
            }).then(result => {
                dispatch(closeActivePopup({name: "loadingMessage"}));
                dispatch(setActivePopup({name: "completeMessage", positionX: "left", data: "NOTIFY_SAVE_COMPLETE"}));
            })
        }
        
    }

    function onSaveProjectTipShow(e)
    {
        if (tips.saveProject.showed) return;

        saveTipKey = setTimeout(() =>
        {
            var timeFromLastHint = (Date.now() - tips.lastTriggerTime) / 1000;
            if (timeFromLastHint < 10) return;
            var time = (Date.now() - timeStart) / 1000;
            if (((R2D.scene.getSceneObjects().length >= 5 || R2D.viewConstructor.doneALot()) && time >= 30) ||
                ((R2D.scene.getSceneObjects().length >= 1 || R2D.viewConstructor.doneSomething()) && time >= 60))
            {
                setTipSaveProject(true);
            }
        }, 200);
    }

    useEffect(() => {
        planner.scene.addEventListener(planner.scene.TIPS_SAVE_PROJECT, onSaveProjectTipShow);
        return () => {
            planner.scene.removeEventListener(planner.scene.TIPS_SAVE_PROJECT, onSaveProjectTipShow);
            clearTimeout(saveTipKey);
        }
    }, [])

    return (
        <div className={classes.bar_btn + ' ' + classes.bar_btnSaveBtn} id="customSaveBtnNotLogged">
            <ButtonHover parentClass={classes.bar_btnSaveBtn} img={
                <svg width="13" className={classes.bar_btn_svg} height="13" viewBox="0 0 13 13" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 1.00006L10 1.00006V4.00006V5.00006L3 5.00006V4.00006V1.00006H1L1 12.0001L12 12.0001L12 2.50006L10.5 1.00006ZM12.9999 2.00006H13V13.0001H12H1H0V5.67436e-05L1 5.63065e-05L11 5.67436e-05L11 0L13 2L12.9999 2.00006ZM9 4.00006V1.00006H8V3.00006H7V1.00006H4V4.00006H9ZM10 8.00006V7.00006L3 7.00006V8.00006H10ZM10 9.00006V10.0001H3V9.00006H10Z" fill="white"/>
            </svg>
            } textHover={"HINT_BUTTON_SAVE"} onClick={() => onClickSave()}/>

            {
                showTipSaveProject && !tips.saveProject.showed ? <Tip name={"saveProject"} showTime={1000} arrow={{position: "left", direction: "right"}} text={"TUT_SAVE_PROJECT"} position={{x: 62, y: 125, from: "left"}}/> 
                : null
            }
        </div> 
    )
}

const SettingsButton = props => {

    let translations = useSelector(selectTranslation);

    let [settings, clickSettingsItem] = useState([
        { name: "snapToPoints", translation: "SNAP_TO_POINTS", active: WC.snapTool.pointsSnap },
        { name: "alignByPoints", translation: "ALIGN_BY_POINTS", active: WC.snapTool.pointsAlign },
        { name: "alignAxises", translation: "ALIGN_BY_ORTO", active: WC.snapTool.orthoAlign },
        { name: "alignMediana", translation: "ALIGN_BY_BISECTOR", active: WC.snapTool.bisectorAlign },
        { name: "showSizes", translation: "SHOW_SIZES", active: WC.wallsEditor.sizesVisible }
    ]);

    let settingsMenu = useRef(null);

    let settingsData = [];

    let onClickSettingsItem = itemName => {
        let newState = settings.map(i => {
            if(i.name == itemName) i.active = !i.active;
            return i;
        });
        
        clickSettingsItem([...newState]);
        user.settingsItemClick(itemName);
        
    }

    settings.forEach(i => {
        settingsData.push(
            <div onClick={e => onClickSettingsItem(i.name)} key={i.name} className={"bar_dropdown_option_setting" + (i.active ? " bar_dropdown_option_settings_active" : "")}>
                <svg width="13" viewBox="0 0 22 14" fill="none" className="bar_settings_dropdown_check">
                    <line x1="0.320092" y1="7.61589" x2="6.32009" y2="12.6159" strokeWidth="2px" stroke="white"/>
                    <line x1="21.3275" y1="0.377845" x2="6.32746" y2="13.3778" strokeWidth="2px" stroke="white"/>
                </svg>
                { translations[i.translation] }
            </div>
        )
    })

    let mouseEnter = e => e.currentTarget.setAttribute("style", "width: " + settingsMenu.current.offsetWidth + "px");
    let mouseLeave = e => e.currentTarget.removeAttribute("style");
    const classes = useStyles();

    useLayoutEffect(() => {
        settingsMenu.current.setAttribute("style", "width: auto");
        return () => settingsMenu.current.removeAttribute("style");
    })
    
    return (
        <div className="bar_settings_dropdown_wrap" onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}>
            <div className="bar_settings_dropdown_active">
            <svg  viewBox="0 0 50 50" width="16px">
                <path fill="#ffffff" d="M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z"/>
            </svg>
                <div className="bar_settings_dropdown_title">{translations["TEXT_SETTINGS"]}</div>
            </div>
            <div ref={settingsMenu} className={classes.bar_dropdown_option_wrap}>
                <div className="bar_dropdown_option_wr">
                    { settingsData }
                </div>
            </div>
        </div>
    )
}

const ShareButton = props => {
    const classes = useStyles();
    let dispatch = useDispatch();

    let onClickShare = async e => {
        if ( user.getId() == 0 ) {
            dispatch(setActivePopup({name: "login"}));
            return;
        }
        if(R2D.controller.getProjectUserKey() && R2D.controller.getProjectUserKey() != user.getKey()) {
            dispatch(setActivePopup({name: "alert", data: "TEXT_SHARE_PLAN_DIFF_USERS"}));
            return;
        } 
        let response = await user.projectHashLoad(R2D.controller.getProjectId());
        if(response.type == "error") dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
        else dispatch(setActivePopup({ name: "share" }));
    }

    return(
        <div className={classes.bar_btn + ' ' + classes.bar_ShareBtn}>
            <ButtonHover parentClass={classes.bar_ShareBtn} img={
                <svg width="14" height="17" className={classes.bar_btn_svg} viewBox="0 0 14 17" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 2.5C14 3.88071 12.8807 5 11.5 5C10.7669 5 10.1075 4.68445 9.65021 4.18177L4.79106 7.49789C4.92544 7.80468 5 8.14363 5 8.5C5 8.75212 4.96268 8.99552 4.89326 9.22498L9.85905 12.6139C10.2982 12.2315 10.872 12 11.5 12C12.8807 12 14 13.1193 14 14.5C14 15.8807 12.8807 17 11.5 17C10.1193 17 9 15.8807 9 14.5C9 14.1088 9.08984 13.7386 9.25002 13.4089L4.41389 10.1085C3.9553 10.6536 3.2681 11 2.5 11C1.11929 11 0 9.88071 0 8.5C0 7.11929 1.11929 6 2.5 6C3.16371 6 3.767 6.25863 4.21461 6.68062L9.13785 3.32075C9.04853 3.06367 9 2.7875 9 2.5C9 1.11929 10.1193 0 11.5 0C12.8807 0 14 1.11929 14 2.5Z" fill="white"/>
                </svg>
            } textHover={"HINT_BUTTON_SHARE"} onClick={() => onClickShare()}/>
        </div>
    )
}

const UndoRedoButtons = props => {
    const classes = useStyles();

    let constrUndoRedo = useSelector(selectIsUndoRedoConstr);
    let sceneUndoRedo = useSelector(selectIsUndoRedoScene);
    let tileConfigUndoRedo = useSelector(selectIsUndoRedoTileConfig)
    let activeViewMode = useSelector(selectActiveViewMode);
    let dispatch = useDispatch();
    let tileEditor = null;
    if(activeViewMode == "tileConfig") tileEditor = planner.scene.getConfigurator().getTileEditor();

    let [canUndo, setCanUndo] = useState(activeViewMode == "constr" ? constrUndoRedo.canUndo : activeViewMode == "tileConfig" ? tileConfigUndoRedo.canUndo : sceneUndoRedo.canUndo);
    let [canRedo, setCanRedo] = useState(activeViewMode == "constr" ? constrUndoRedo.canRedo : activeViewMode == "tileConfig" ? tileConfigUndoRedo.canRedo : sceneUndoRedo.canRedo);

    let [currentBtnClassUndo, setCurrentBtnClassUndo] = useState(canUndo ? classes.bar_btn : classes.bar_btn_not_active);
    let [currentBtnClassRedo, setCurrentBtnClassRedo] = useState(canRedo ? classes.bar_btn : classes.bar_btn_not_active);

    let onClickUndo = e => {

        if(activeViewMode == "constr") planner.constr.undo();
        else if(activeViewMode == "tileConfig") tileEditor.history.undo();
        else planner.scene.undo();

        dispatch(hideQuickPanelAll());
        dispatch(setActiveViewModeState({state: "stateEditing"}));
        dispatch(changeUndoRedo());
    }

    let onClickRedo = e => {
        if(activeViewMode == "constr") planner.constr.redo();
        else if(activeViewMode == "tileConfig") tileEditor.history.redo();
        else planner.scene.redo();

        dispatch(hideQuickPanelAll());
        dispatch(setActiveViewModeState({state: "stateEditing"}));
        dispatch(changeUndoRedo());
    }

    useEffect(() => {
        setCanUndo(activeViewMode == "constr" ? constrUndoRedo.canUndo : activeViewMode == "tileConfig" ? tileConfigUndoRedo.canUndo : sceneUndoRedo.canUndo);
        setCanRedo(activeViewMode == "constr" ? constrUndoRedo.canRedo : activeViewMode == "tileConfig" ? tileConfigUndoRedo.canRedo : sceneUndoRedo.canRedo);

        setCurrentBtnClassUndo(canUndo ? classes.bar_btn : classes.bar_btn_not_active);
        setCurrentBtnClassRedo(canRedo ? classes.bar_btn : classes.bar_btn_not_active)
    }, [activeViewMode, constrUndoRedo, tileConfigUndoRedo, sceneUndoRedo, canUndo, canRedo]);

    

    return (
        <>
            <div className={currentBtnClassUndo}>
                <ButtonHover parentClass={currentBtnClassUndo} img={
                    <svg width="12" height="15" className={classes.bar_btn_svg} viewBox="0 0 12 15" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.82163 0.827393L5.51949 1.50707L1.94819 5H12V6V14V15H4V14H11V6H1.87954L5.50886 9.49496L4.81643 10.1799L0.00680542 5.54833L0.0518246 5.5038L0.046056 5.49818L4.82163 0.827393Z" fill="white"/>
                    </svg>
                } textHover={"HINT_BUTTON_UNDO"} onClick={() => onClickUndo()}/>
            </div>
            <div className={currentBtnClassRedo}>
                <ButtonHover parentClass={currentBtnClassRedo} img={
                    <svg width="12" height="15" className={classes.bar_btn_svg} viewBox="0 0 12 15" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.17837 0.827393L6.48051 1.50707L10.0518 5H0V6V14V15H8V14H1V6H10.1205L6.49114 9.49496L7.18357 10.1799L11.9932 5.54833L11.9482 5.5038L11.9539 5.49818L7.17837 0.827393Z" fill="white"/>
                    </svg>
                } textHover={"HINT_BUTTON_REDO"} onClick={() => onClickRedo()}/>
            </div>
        </>
    )
}

const RealisticImageButton = props => {
    const classes = useStyles();

    let dispatch = useDispatch();

    let onClickRealImgBtn = e => {
        dispatch(hideQuickPanelAll());
        WC.WallsEditor._instance.changeState(WC.WallsEditor._instance.stateEditing);
        planner.scene.showRightPanelStatus();
        dispatch(setActiveViewModeState({state: "stateRenderMake", data: {type: "main", data: "", added: true}}));
        dispatch(updateRightPanelStatus(true));
    }

    return (
        <div className={classes.hd_render_btn} >
            <ButtonHover parentClass={classes.hd_render_btn} img={
                <svg xmlns="http://www.w3.org/2000/svg" className={classes.bar_btn_svg} width="26" height="19" viewBox="0 0 26 19" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.1093 2.52354H21.7603C22.7672 2.52354 23.3342 2.92379 24.0464 3.63616C24.7584 4.34853 25.1584 5.31483 25.1584 6.32242V14.6297V18.4286H21.3615H3.96826H0.171387L0.171387 14.6297V6.32242C0.171387 5.31483 0.704313 4.34853 1.41631 3.63616C2.12849 2.92378 2.56265 2.52357 3.56954 2.52357H7.22828C7.25285 2.45224 7.29468 2.38807 7.34976 2.33645V2.33662L9.47809 0.343893C9.67383 0.160263 10.054 0.00941842 10.3224 0.00941842L15.0182 1.365e-06C15.2866 -0.000520958 15.6673 0.148926 15.8625 0.332556L17.9805 2.31497C18.0413 2.37165 18.086 2.44367 18.1093 2.52354ZM5.8059 9.34575H4.85643V15.7128H5.8059V12.9016H11.2886V15.7128H12.2288V9.34575H11.2886V12.0452H5.8059V9.34575ZM19.3528 9.34575H14.0098V15.7128H19.3528C20.141 15.7128 20.6777 15.5638 20.9632 15.266C21.3852 14.8316 21.5962 13.9193 21.5962 12.5293C21.5962 11.133 21.3852 10.2176 20.9632 9.78325C20.6777 9.49158 20.141 9.34575 19.3528 9.34575ZM19.3435 14.8564H14.9592V10.2021H19.3435C19.8524 10.2021 20.1968 10.3604 20.3768 10.6769C20.5567 10.9934 20.6467 11.6108 20.6467 12.5293C20.6467 13.4539 20.5567 14.0745 20.3768 14.391C20.203 14.7012 19.8586 14.8564 19.3435 14.8564ZM12.5722 5.70606C10.9561 5.94627 9.48751 6.89159 9.13972 9.1023C9.10611 9.31593 8.87228 9.46627 8.61744 9.43809C8.3626 9.40992 8.18326 9.21391 8.21686 9.00029C8.61137 6.49264 10.3481 5.02123 12.4097 4.71481C14.4285 4.41474 16.6701 5.25198 18.0844 6.73634C18.2449 6.9047 18.2121 7.15019 18.0112 7.28467C17.8104 7.41914 17.5175 7.39167 17.3571 7.22332C16.1397 5.94569 14.2312 5.45948 12.5722 5.70606Z" fill="white"/>
                </svg>
            } textHover={"HINT_BUTTON_RENDER"} onClick={() => onClickRealImgBtn()}/>
        </div>

    )
}

const ImagePlanMenu = props => {
    let translation = useSelector(selectTranslation);
    const classes = useStyles();

    let activeViewMode = useSelector(selectActiveViewMode);
    let isImagePlanUploaded = useSelector(selectIsImagePlanUploaded);
    let dispatch = useDispatch();

    let imagePlanStates = [
            {type: 0, translation: "FLOORPLAN_OFF", drawType: WC.DRAWING_HIDE, img:'/src_designer/images/plan_hidden.svg'},
            {type: 1, translation: "FLOORPLAN_BELOW", drawType: WC.DRAWING_BELOW, img:'/src_designer/images/plan_down.svg'},
            {type: 2, translation: "FLOORPLAN_ABOVE", drawType: WC.DRAWING_ABOVE, img:'/src_designer/images/plan_up.svg'},
    ];
    
    let activeStateTranslation = null;
    let imagePlanStatesData = [];

    let onClickImagePlanStatesItem = newType => {

        imagePlanStates.forEach(i => i.type == newType ? WC.wallsEditor.drawingPosition = i.drawType : null);
        
        R2D.commonSceneHelper.drawingHelper.setState(newType);

        WC.wallsEditor.draw();

        dispatch(changeImagePlanUpload(newType));
    }

    imagePlanStates.forEach(item => {
        if(item.type == isImagePlanUploaded) activeStateTranslation = item.translation;
        imagePlanStatesData.push(
            <div key={item.type} className={classes.bar_dropdown_option + " js_set_metric " + (item.type == isImagePlanUploaded ? classes.bar_dropdown_option_active : "")} onClick={e => onClickImagePlanStatesItem(item.type)}>
                <img className={classes.imagePlanImg} src={item.img}/>
                { translation[item.translation] }
            </div>
        )
    })

    return (
        <div className={classes.bar_dropdown_wrap + ' ' + classes.imagePlans}>
        <div className={classes.bar_dropdown_active}>
            <img className={classes.imagePlanImg} src="/src_designer/images/plan_up.svg"/>
            { translation[activeStateTranslation] }
        </div>
        <div className={classes.bar_dropdown_option_wrap}>
            <div className={classes.bar_dropdown_option_wr}>
                { imagePlanStatesData }
            </div>
        </div>
    </div>
    )
}

export {HideButton, LangButton, LoginButton, LoggedMenu, 
        MetricsButton, Projects, ProVerButton, SaveButton, 
        SettingsButton, ShareButton, RealisticImageButton, 
        UndoRedoButtons, ImagePlanMenu}