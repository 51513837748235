import React from 'react';
import { useDispatch } from 'react-redux';
import { setActiveViewMode } from '../../../../../redux/features/viewMode/viewModeSlice';
import { changeUndoRedoScene } from '../../../../../redux/features/topPanel/topPanelSlice';
import { hideQuickPanelAll } from '../../../../../redux/features/quickPanels/quickPanelsSlice';
import { hideTip } from '../../../../../redux/features/tips/tipsSlice';
import LeftButton from '../btnComponent/LeftButton';

const ViewConstr = props => {
    let dispatch = useDispatch();

    let changeViewMode = e => {
        dispatch(changeUndoRedoScene({canUndo: false, canRedo: false}));
        dispatch(hideQuickPanelAll());
        dispatch(hideTip({name: "constructorMode"}));
        dispatch(setActiveViewMode("constr"));
    }

    return(
        <LeftButton img={
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <circle cx="2.5" cy="2.5" r="2.5" fill="white"/>
                <circle cx="2.5" cy="17.5" r="2.5" fill="white"/>
                <circle cx="17.5" cy="2.5" r="2.5" fill="white"/>
                <circle cx="17.5" cy="17.5" r="2.5" fill="white"/>
                <rect x="4" y="2" width="12" height="1" fill="white"/>
                <rect x="2" y="16" width="12" height="1" transform="rotate(-90 2 16)" fill="white"/>
                <rect x="17" y="16" width="12" height="1" transform="rotate(-90 17 16)" fill="white"/>
                <rect x="4" y="17" width="12" height="1" fill="white"/>
            </svg>
        } viewMode="constr" textHover={"HINT_BUTTON_CONSTRUCTOR"} onClick={changeViewMode}/>
    )
}

export default ViewConstr;