import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { user } from '../../../init'
import { setActivePopup } from '../popups/popupsSlice';

export const userCheckIsLogged = createAsyncThunk(
  'user/checkIsLogged',
  async (data, thunkAPI) => {
    let response = await user.checkIsLogged();
    if(response.type == "error") {
      thunkAPI.dispatch(setActivePopup({name: "alert", type: response.message, data: response.data}));
      return { logged: false };
    } else return response;
  } 
)

export const userProfileDataLoad = createAsyncThunk(
  'user/profile_data_load',
  async (data, thunkAPI) => {
      let response = await user.loadProfileData();
      if(response.type == "error") {
        thunkAPI.dispatch(setActivePopup({name: "alert", type: response.message, data: response.data}))
      }
      else return response;
  }
)

export const userLogout = createAsyncThunk(
    'user/logout',
    async (data, thunkAPI) => {
        return await user.logout();
    }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: { logged: false, profile: {}, projectActive: {name: null}},
  reducers: {
    saveLoggedStatus: (state, action) => {
      state.logged = action.payload;
    },
    setActiveProjectName: (state, action) => {
      state.projectActive.name = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(userCheckIsLogged.fulfilled, (state, action) => {
      state.logged = action.payload ? action.payload.logged : false;
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      return { ...state, logged: action.payload.logged, profile: {}};
    });
    builder.addCase(userProfileDataLoad.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
})

export const selectIsLogged = state => state.user.logged;
export const selectUserProfileData = state => state.user.profile;
export const selectUserProjectActive = state => state.user.projectActive;

export const { setActiveProjectName, saveLoggedStatus } = userSlice.actions; 

export default userSlice.reducer;