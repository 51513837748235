import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveViewMode, selectActiveViewMode } from '../../../../../redux/features/viewMode/viewModeSlice';
import { changeUndoRedoConstr } from '../../../../../redux/features/topPanel/topPanelSlice';
import { hideQuickPanelAll } from '../../../../../redux/features/quickPanels/quickPanelsSlice';
import LeftButton from '../btnComponent/LeftButton';

const ViewWalk = props => {
    let dispatch = useDispatch();
    let activeViewMode = useSelector(selectActiveViewMode);

    let changeViewMode = async e => {
        dispatch(changeUndoRedoConstr({canUndo: false, canRedo: false}));
        dispatch(hideQuickPanelAll());
        dispatch(setActiveViewMode("viewWalk"));
    }

    return (
        <LeftButton img={
            <svg width="15" height="21" viewBox="0 0 15 21" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.995 11.26C13.848 11.588 13.53 11.782 13.196 11.782C13.075 11.782 12.952 11.758 12.833 11.702L9.94002 10.365C9.73002 10.268 9.56502 10.089 9.48402 9.86902L9.00002 8.57202L8.37502 11.693L9.85202 14.081L10.391 14.812C10.438 14.873 10.476 14.941 10.505 15.015L12.258 19.47C12.437 19.928 12.218 20.447 11.768 20.629C11.662 20.673 11.552 20.692 11.443 20.692C11.096 20.692 10.767 20.481 10.628 20.132L8.99402 15.975L5.92002 12.851C5.92002 12.851 5.04302 12.139 5.30602 10.891C5.48202 10.356 5.83202 8.66302 6.09502 7.32702C6.09502 7.32702 6.11502 7.19702 6.15302 7.00202L4.15302 8.01802L2.52902 10.494C2.36002 10.752 2.08102 10.891 1.79802 10.891C1.63202 10.891 1.46302 10.842 1.31202 10.741C0.910016 10.468 0.801016 9.91602 1.07002 9.50502L2.82302 6.83202C2.90802 6.70302 3.02402 6.60002 3.16102 6.53002L6.66802 4.74802C6.78702 4.68702 6.91402 4.66302 7.03902 4.65902C7.04502 4.65902 7.05302 4.65402 7.05902 4.65402C7.32202 4.65402 8.81302 4.65402 8.81302 4.65402C8.81302 4.65402 8.81502 4.65702 8.81602 4.65902C9.16902 4.66002 9.50102 4.87502 9.63302 5.23002L10.998 8.89602L13.561 10.081C14.001 10.283 14.195 10.812 13.995 11.26ZM9.25102 4.00902C10.341 4.00902 11.224 3.11102 11.224 2.00402C11.224 0.896023 10.341 -0.000976562 9.25102 -0.000976562C8.16202 -0.000976562 7.27902 0.896023 7.27902 2.00402C7.27902 3.11102 8.16202 4.00902 9.25102 4.00902ZM5.04302 13.03L4.46202 15.896L1.97502 19.267C1.68402 19.658 1.76202 20.217 2.15002 20.512C2.30802 20.634 2.49302 20.692 2.67502 20.692C2.94202 20.692 3.20502 20.568 3.37702 20.335L5.92802 16.879C6.05102 16.76 6.14502 16.608 6.18302 16.415L6.79702 14.9L5.39402 13.475C5.30602 13.386 5.13102 13.207 5.04302 13.03Z" fill="white"/>
            </svg>
        } viewMode="viewWalk" textHover={"HINT_BUTTON_WALK"} onClick={changeViewMode}/>
    )
}

export default ViewWalk;