import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { user } from '../../../../../../init';
import { historyRemove, selectActiveViewModeData, selectActiveViewModeState, setActiveViewModeState } from '../../../../../../redux/features/viewMode/viewModeSlice';

const PrevButton = props => {

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let prevState = activeViewModeData.prevState;
    let prevStateData = activeViewModeData[prevState];
    let isAllowedChangeHistoryState = activeViewModeData[activeViewModeData.activeState].isAllowedChangeHistoryState;
    let activeViewModeState = useSelector(selectActiveViewModeState);

    let isPrevBtnActive = currentStateHistory && currentStateHistory.length > 1 || isAllowedChangeHistoryState ? true : false;

    let dispatch = useDispatch();

    let onPrevClick = () => {
        if(currentStateHistory.length < 2) {
            if(prevStateData.history.length > 0 || prevState == "stateEditing") {
                dispatch(historyRemove());
                dispatch(setActiveViewModeState({state: prevState}));
            }
        } else {
            dispatch(historyRemove());
        }
        
    }

    return (
        <div className={isPrevBtnActive ? "planer_catalog_btn" : "planer_catalog_btn_not_active planer_catalog_btn"} onClick={onPrevClick}>
            <svg width="13" height="15" viewBox="0 0 13 15" fill="none">
                <path opacity="0.99" fillRule="evenodd" clipRule="evenodd" d="M7.71246 0L8.41032 0.679673L1.94819 7H13V8H1.87968L8.4375 14.3151L7.74508 15L0.702613 8.21825L0.697857 8.2229L0 7.54323L7.71246 0Z" fill="white"/>
            </svg>
        </div>
    )
}

export default PrevButton;