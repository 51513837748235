import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { selectIsLogged, selectUserProfileData } from '../../../../redux/features/user/userSlice.js';
import { selectActiveViewMode, selectActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice.js';
import { createUseStyles } from 'react-jss';

import {HideButton, LangButton, LoginButton, MetricsButton, 
    ProVerButton, UndoRedoButtons, ShareButton, Projects, SaveButton, 
    SettingsButton, LoggedMenu, RealisticImageButton, ImagePlanMenu} from './buttons/Buttons.jsx';
import { selectIsImagePlanUploaded } from '../../../../redux/features/constructor/constructorSlice.js';

const useStyles = createUseStyles({
    panel_top:{
        position: 'absolute',
        left: '0',
        top: '0',
        display: 'flex',
        height: '27px',
        zIndex: '10',
        '@media screen and (max-width: 1280px)':{
            height: '100%',
            pointerEvents: 'none'
        }
    },
    bar_btns_wr: {
        'display':'grid',
        'gridTemplateColumns':'auto auto auto auto auto',
        'gridGap':'21px',
        'whiteSpace':'nowrap',
        '@media screen and (max-width: 1280px)':{
            background: '#F1F1F1',
            gridTemplateColumns:'auto',
            borderRight: '1px solid #cdcdcd',
            left: 0,
            position: 'absolute',
            top: 0,
            height: '100%',
            display: 'block',
            overflow: 'auto',
            pointerEvents: 'auto'
        }
    },
    bar_btns_flex:{
        'display': 'flex',

    },
    menuTop:{
        '@media screen and (max-width: 1280px)':{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            marginBottom: 10
        }
    },
    barUndoRedoBtns:{
        '@media screen and (max-width: 1280px)':{
            display: 'none'
        }
    }
});

export const TopPanel = props => {
    const classes = useStyles();
    let [isHidden, setPanelHidden] = useState(false);

    let activeViewMode = useSelector(selectActiveViewMode);
    let isLogged = useSelector(selectIsLogged);
    let activeViewModeState = useSelector(selectActiveViewModeState);
    let isImagePlanUploaded = useSelector(selectIsImagePlanUploaded);
    
    let userData = useSelector(selectUserProfileData);

    let onClickHiddenBtn = () => setPanelHidden(!isHidden);

    return(
            <div className={classes.panel_top}>
            <HideButton isHidden={isHidden} setPanelHidden={onClickHiddenBtn}/>
            {
                activeViewMode == "tileConfig" ?
                <div className={classes.bar_btns_wr}>
                    <div className={classes.bar_btns_flex}>
                        {
                            isHidden ? null : <MetricsButton />
                        }
                    </div>
                    <div className={classes.bar_btns_flex + ' ' + classes.barUndoRedoBtns}>
                        {
                            isHidden ? 
                            null : 
                            <UndoRedoButtons /> 
                        }
                    </div>
                </div>
                :
                <div className={classes.bar_btns_wr}>
                    <div className={classes.bar_btns_flex+' '+classes.menuTop}>
                        {
                            isHidden ? null : <LangButton />
                        }
                        {
                            isHidden ? null : isLogged ? <LoggedMenu /> : <LoginButton />
                        }
                        
                        {
                            isHidden ? null : activeViewMode != "constr" ? <ShareButton />  : null
                        }
                        {
                            isHidden ? null :
                            <>
                                <Projects />
                                <SaveButton />
                            </>
                        }
                        
                    </div>
                    <div className={classes.bar_btns_flex}>
                        {
                            isHidden ? null : <MetricsButton />
                        }
                        {
                            isImagePlanUploaded != null ? activeViewModeState != "stateAlignDrawing" ? <ImagePlanMenu /> : null : null
                        }
                        {
                            activeViewMode == "constr" ? activeViewModeState != "stateAlignDrawing" ? <SettingsButton /> : null : null
                        }
                    </div>
                    <div className={classes.bar_btns_flex + ' ' + classes.barUndoRedoBtns}>
                        {
                            activeViewMode == "constr" ? 
                                activeViewModeState != "stateAlignDrawing" ? 
                                    <UndoRedoButtons /> 
                                : null 
                                : isHidden ? 
                                null : 
                                activeViewModeState != "stateAlignDrawing" ? 
                                    <UndoRedoButtons /> : 
                                    null
                        }
                    </div>
                    {
                        isHidden ? null : isLogged && userData.render == true ? 
                        <div className={classes.bar_btns_flex}>
                            <RealisticImageButton />
                        </div> :
                        null
                    }
                    {
                        isHidden ? 
                        null : 
                        userData.plan == "pro" ? 
                        null :
                        <div className={classes.bar_btns_flex}>
                            <ProVerButton />
                        </div>
                    }
                </div>
            }
            
        </div>
    )
}

export default TopPanel;