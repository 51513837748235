import React from 'react';
import Copyright from './Copyright.jsx';
import Tips from './Tips.jsx';
import Terms from './Terms.jsx';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { selectActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice.js';

const useStyles = createUseStyles({
  panelBottom: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    bottom: 5,
    'WebkitUserSelect': 'none',
    'MsUserSelect': 'none',
    'userSelect': 'none',
  },
  panel_bottom_left: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingLeft: 5,
      '@media screen and (max-width: 1280px)':{
        display: 'none'
      }
  },
  copyright_container_element_text: {
    fontSize: 12,
    '& a': {
      color: 'rgb(47, 47, 47)'
    }
  }
});

const BottomPanel = props => {
  const classes = useStyles();
  let activeViewModeState = useSelector(selectActiveViewModeState);
  return (
    <>
      <div className={classes.panelBottom}>
          {
            activeViewModeState != "stateRenderMake" ?
            <>
                <div className={classes.panel_bottom_left}>
                  <Copyright/>
                  <Terms/>
                </div>
            </> : null
          }
        </div>
        <Tips/>
    </>
      
  );
};

export default BottomPanel;
