import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranslation } from '../../../../../../../../../redux/features/language/languageSlice';
import { setActiveViewModeState, selectActiveViewModeData } from '../../../../../../../../../redux/features/viewMode/viewModeSlice';
import {createUseStyles} from "react-jss";
import ChangeSize from "../../../../../../common/changeSize/index.jsx";
import { planner } from '../../../../../../../../../init';
import { changeWallsHeight, selectIsImagePlanUploaded, selectMaxWallsHeight, selectMinWallsHeight, selectWallsHeight } from '../../../../../../../../../redux/features/constructor/constructorSlice';
import { selectMetrics } from '../../../../../../../../../redux/features/metrics/metricsSLice';
import { setActivePopup } from '../../../../../../../../../redux/features/popups/popupsSlice';
import { motion } from 'framer-motion';

const useStyle = createUseStyles({
    planerConstructor: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planerWallsHeight:{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
        alignItems: 'center'
    },
    planerConstItemWrap:{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, calc(50% - 5px))',
        padding: '0 10px 20px',
        gridGap: 10
    },
    planerConstItem:{
        outline: '1px solid #d9d9d9',
        background: '#FFFFFF',
        height: 120,
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        justifyItems: 'center',
        padding: '6px 6px 4px',
        gridGap: 5,
        position: 'relative',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#FFCC00',
            '& $planerConstItemText':{
                opacity: 1
            }
        },
        '& img:not(.planer_catalog_prem_zamok, .planer_catalog_prem_add_img)':{
            maxWidth: '100%',
            maxHeight: '100%'
        },
        '@media screen and (max-width: 1280px)':{
            height: 80,
            paddingBottom: 20,
            '& img:not(.planer_catalog_prem_zamok, .planer_catalog_prem_add_img)':{
                maxWidth: '87%',
                maxHeight: '80%'
            },
        }
    },
    planerConstItemImg:{

    },
    planerConstItemText:{
        color: '#444444',
        fontSize: 12,
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        padding: '3px 2px',
        '@media screen and (max-width: 1280px)':{
            opacity: 1,
            fontSize: 10,
        },
    },
    roll_out_right:{
        transform: "translateX(100%)"
    },

});
const ListConstructor = props => {
    let translation = useSelector(selectTranslation);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let isImagePlanUploaded = useSelector(selectIsImagePlanUploaded);

    let activeState = activeViewModeData.activeState;
    let dispatch = useDispatch();
    let metrics = useSelector(selectMetrics);
    let wallsHeight = useSelector(selectWallsHeight);
    
    let minWallsHeight = useSelector(selectMinWallsHeight);
    let maxWallsHeight = useSelector(selectMaxWallsHeight);

    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    let [mouseDown, setMouseDown] = useState({status: false, action: null});
    
    let changeConstrState = newState => {
        
        planner.constr.finish();

        if(activeState == newState ) {
            dispatch(setActiveViewModeState({state: "stateEditing"}));
            document.removeEventListener("keydown", props.onKeyDownConstr)
        }
        else {
            document.addEventListener("keydown", props.onKeyDownConstr);
            planner.constr[newState]();
            dispatch(setActiveViewModeState({state: newState, data: {added: true, type: "main"}}));
        }
    }

    let onChangeSize = (mouseDown, value) => {
        setMouseDown({...mouseDown});
        if(!value || isNaN(value) || (value + "")[0] == '0') value = 0;

        if(value < minWallsHeight) {
            value = minWallsHeight;
        }
        if(value > maxWallsHeight) {
            value = maxWallsHeight;
        }

        dispatch(changeWallsHeight(value));
        planner.constr.setWallsHeight(value);
        
    }

    let onUploadPlan = async e => {
        let result = await planner.constr.uploadPlan();
        if(result.state == "stateAlignDrawing") {
            dispatch(setActivePopup({name: "uploadImage"}));
            dispatch(setActiveViewModeState({state: "stateAlignDrawing"}));
        }
    }

    let onImagePlanSettingsClick = e => {
        WC.WallsEditor._instance.changeState(WC.WallsEditor._instance.stateAlignDrawing);
        dispatch(setActiveViewModeState({state: "stateAlignDrawing"}));
    }

    const variants = {
        visible: { transform:  "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }

    useEffect(() => () => document.removeEventListener("keydown", props.onKeyDownConstr), []);

    const classes = useStyle();
    return (
        <>
            <motion.div transition={{ ease: "linear", duration: .1 }} initial="hidden" animate="visible" exit={"exit"} variants={variants} className={classes.planerConstructor }  >

                <div className={classes.planerWallsHeight} id="changerWallsHeight" >
                    <ChangeSize mouseDown={mouseDown} textAlign={'right'} changeValue={onChangeSize} value={wallsHeight} label={translation["TEXT_WALLS_HAIGHT"]}/>
                </div>

                <div className={classes.planerConstItemWrap}>
                    <div className={classes.planerConstItem} onClick={e => changeConstrState("stateMakeWall")}>
                        {
                            activeState == "stateMakeWall" ?
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/icon_close.svg"/>
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_DRAW_WALL_CLOSE"]}</div>
                            </>
                            :
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_wall.svg" />
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_DRAW_WALL"]}</div>
                            </>
                        }
                    </div>

                    <div className={classes.planerConstItem} onClick={e => changeConstrState("stateMakeRect")}>
                        {
                            activeState == "stateMakeRect" ?
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/icon_close.svg"/>
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_DRAW_ROOM_CLOSE"]}</div>
                            </>
                            :
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_room.svg" />
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_DRAW_ROOM"]}</div>
                            </>
                        }
                    </div>

                    <div className={classes.planerConstItem} onClick={e => changeConstrState("stateMakeRoom")}>
                        {
                            activeState == "stateMakeRoom" ?
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/icon_close.svg"/>
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_DRAW_FILL_CLOSE"]}</div>
                            </>
                            :
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_custom_shpare_wall.svg" />
                                <div className={classes.planerConstItemText} dangerouslySetInnerHTML={{__html: translation["TEXT_BUTTON_DRAW_FILL"]}}></div>
                            </>
                        }

                    </div>

                    <div className={classes.planerConstItem} onClick={e => changeConstrState("stateCutRoom")}>
                        {
                        activeState == "stateCutRoom" ? 
                        <>
                            <img className={classes.planerConstItemImg} src="/src_designer/images/icon_close.svg"/>
                            <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_CUT_FILL_CLOSE"]}</div>
                        </>
                        :
                        <>
                            <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_wall_cutout.svg" />
                            <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_CUT_FILL"]}</div>
                        </>
                        } 
                    </div>
            
                    <div className={classes.planerConstItem} onClick={e => changeConstrState("stateMakeCover")}>
                        {
                            activeState == "stateMakeCover" ? 
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/icon_close.svg"/>
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_DRAW_FLOOR_CLOSE"]}</div>
                            </>
                            :
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_floor.svg" />
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_DRAW_FLOOR"]}</div>
                            </>
                        }
                    </div>
                    
                    <div className={classes.planerConstItem} onClick={e => changeConstrState("stateCutCover")}>
                        {
                            activeState == "stateCutCover" ? 
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/icon_close.svg"/>
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_CUT_FLOOR_CLOSE"]}</div>
                            </>
                            :
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_floor_cutout.svg" />
                                <div className={classes.planerConstItemText}>{translation["TEXT_BUTTON_CUT_FLOOR"]}</div>
                            </>
                        }   
                    </div>

                    {
                        isImagePlanUploaded != null ?
                        <div className={classes.planerConstItem} onClick={onImagePlanSettingsClick}>
                            <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_edit_plan.svg" />
                            <div className={classes.planerConstItemText} dangerouslySetInnerHTML={{__html: translation["BUTTON_SETTING_PLAN"]}}></div>
                        </div>
                        :
                        <div className={classes.planerConstItem} onClick={onUploadPlan}>
                            <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_upload_floorplan.svg" />
                            <div className={classes.planerConstItemText}>{translation["BUTTON_UPLOAD_PLAN"]}</div>
                        </div>
                    }
                
                    
                    <div className={classes.planerConstItem} onClick={e => changeConstrState("stateSetWallHeight")}>
                        {
                            activeState == "stateSetWallHeight" ? 
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/icon_close.svg"/>
                                <div className={classes.planerConstItemText} dangerouslySetInnerHTML={{__html: translation["BUTTON_STOP_LOWER_CONTOUR"]}}></div>
                            </>
                            :
                            <>
                                <img className={classes.planerConstItemImg} src="/src_designer/images/draw_walls/icon_change_wall_height.svg" />
                                <div className={classes.planerConstItemText}>{translation["BUTTON_START_LOWER_CONTOUR"]}</div>
                            </>
                        }
                    </div>
                </div>
            </motion.div>
        </>    
    )
}

export default ListConstructor;