import React, { useState } from 'react';
import {createUseStyles} from "react-jss";

import iconDivideWall from './images/icon_divide.svg';
import Lock from '../../../../../../common/lock';
import Checkbox from '../../../../../../common/checkbox';
import EditMaterialsItem from '../../../../../../common/editMaterialItem';
import BlueButton from '../../../../../../common/buttons/bluebutton';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { user, planner } from '../../../../../../../../../init';
import { useDispatch } from 'react-redux';
import { historyAdd, selectActiveViewModeData, setActiveViewMode, updateMaterialId } from '../../../../../../../../../redux/features/viewMode/viewModeSlice';
import CatalogMaterials from '../../../general/catalog/CatalogMaterials';
import Catalog from '../../../general/catalog/Catalog';
import EditPlinthItem from '../../../../../../common/editPlinthItem';
import ChangePlinth from '../../../general/changePlinth/ChangePlinth';
import { selectIsLogged } from '../../../../../../../../../redux/features/user/userSlice';
import { hideQuickPanelAll } from '../../../../../../../../../redux/features/quickPanels/quickPanelsSlice';
import { selectTranslation } from '../../../../../../../../../redux/features/language/languageSlice';
import { changeUndoRedo } from '../../../../../../../../../redux/features/topPanel/topPanelSlice';
import ColorPicker from '../../../general/colorPicker/ColorPicker';

const useStyle = createUseStyles({
    planerWallsCont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planerWallsWrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',
        padding: 20,
    },
    planerWallsTitle:{
        fontSize: 13,
        textAlign: 'center',
        marginBottom: 15,
        color: '#505050',
    },
    planerWallsCurentMatWr:{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },

    divideWall:{
        height: 100,
        border: '1px solid #d9d9d9',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 31,
        padding: '10px 25px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        marginBottom: 20,
        '&:hover':{
            background: '#FFCC00',
            border: '1px solid #FFCC00',
        }
    },
    lockOn: {
        border: '1px solid #ca0707'
    },
    divideWallImg:{

    },
    divideWallText:{
        color: '#444444',
        fontSize: 13
    },
    divideWallGrid:{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 14,
        marginBottom: 20
    },
    divide_checkbox_wr:{
        marginBottom: 15
    }
});

const StateSelectedWall = () => {
    const classes = useStyle();

    let dispatch = useDispatch();

    let translation = useSelector(selectTranslation);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataType = currentStateHistory[currentStateHistory.length - 1].type;

    let isPro = user.isPro();
    let isLogged = useSelector(selectIsLogged);

    let currObj = planner.mih()._scene.currentView3DObject;
    let constructionData = currObj.getConstructorElementData();
    let topPlinth = constructionData.topPlinth;
    let bottomPlinth = constructionData.bottomPlinth;

    let materialIdWall = currentStateHistory[0].matId.wallMatId;
    let materialIdTop = currentStateHistory[0].matId.moldMatId;
    let materialIdBottom = currentStateHistory[0].matId.plinthMatId;

    let [selectedType, setSelectedType] = useState(null)

    let onCopyMaterial = id => R2D.CopyPaste.copyMaterial(id);

    let onPasteMaterial = type => {
        let mat = R2D.CopyPaste.pasteMaterial();
        if (mat) {
            switch(type) {
                case "wall": 
                    planner.scene.setMaterialOnCurrentConstr(mat);
                    dispatch(updateMaterialId({...currentStateHistory[0].matId, wallMatId: mat}))
                    break;
                case "top": 
                    topPlinth.setMaterial(mat);
                    topPlinth.dispatchUpdate();
                    dispatch(updateMaterialId({...currentStateHistory[0].matId, moldMatId: mat}))
                    break;

                case "bottom": 
                    bottomPlinth.setMaterial(mat);
                    bottomPlinth.dispatchUpdate();
                    dispatch(updateMaterialId({...currentStateHistory[0].matId, plinthMatId: mat}))
                    break;
            }
        }
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    let onClickPreview = async type => {
        let catalogMaterials = await user.loadRightPanelData(R2D.URL.CATALOG_MATERIALS_TREE_FOR_MODELS);
        dispatch(historyAdd({added: true, data: catalogMaterials.materials, type: "catalog"}));
        setSelectedType(type);
    }

    let onSelectMaterial = id => {
        switch(selectedType) {
            case "wall":
                planner.scene.setMaterialOnCurrentConstr(id);
                break;
            case "top":
                topPlinth.setMaterial(id);
                topPlinth.dispatchUpdate();
                break
            case "bottom":
                bottomPlinth.setMaterial(id);
                bottomPlinth.dispatchUpdate();
                break;
        }
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    let getSelectedId = () => {
        let selectedId = null;
        if(selectedType == "wall") selectedId = materialIdWall
        if(selectedType == "top") selectedId = materialIdTop
        if(selectedType == "bottom") selectedId = materialIdBottom
        return selectedId;
    }

    let onChangePreview = id => {
        if(selectedType == "wall") dispatch(updateMaterialId({...currentStateHistory[0].matId, wallMatId: id}))
        if(selectedType == "top") dispatch(updateMaterialId({...currentStateHistory[0].matId, moldMatId: id}))
        if(selectedType == "bottom") dispatch(updateMaterialId({...currentStateHistory[0].matId, plinthMatId: id}))
    }

    let changePlinthVisible = checked => {
        bottomPlinth.exists = checked;
        bottomPlinth.dispatchUpdate();
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    let changeMoldingVisible = checked => {
        topPlinth.exists = checked;
        topPlinth.dispatchUpdate();
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    let onApplyToAllRoom = () => {
        planner.scene.setPlinthToRoom(topPlinth);
        planner.scene.setPlinthToRoom(bottomPlinth);
        planner.scene.setWallToRoom(constructionData);
        constructionData.dispatchUpdate();
        R2D.scene.history.saveState();
        dispatch(changeUndoRedo());
    }

    let onClickWallSlice = e => {
        dispatch(hideQuickPanelAll());
        planner.scene.runConfigurator(constructionData);
        dispatch(setActiveViewMode('tileConfig'));
    }

    let onClickPlinthChange = plinthType => dispatch(historyAdd({added: true, data: plinthType, type: "changePlinth"}));

    return (
        <>
            {
                currentDataType == "main" ?
                    <div className={classes.planerWallsCont}>
                        <div className={classes.planerWallsWrap}>
                            <div className={classes.planerWallsTitle}>Площа 4.6 m²</div>
                            <div className={classes.planerWallsCurentMatWr}>
                                <EditMaterialsItem matId={materialIdWall} onCopyPaste={{copy: onCopyMaterial, paste: onPasteMaterial, index: "wall"}} onClick={e => onClickPreview("wall")}  lock={false}/>
                            </div>


                            <div onClick={onClickWallSlice} className={cx(classes.divideWall, {
                            [classes.lockOn]:!isPro})} >
                                <img className={classes.divideWallImg} src={iconDivideWall}/>
                                <div className={classes.divideWallText} >{ constructionData.configData ? translation["TILE_CONFIG_EDIT"] : translation["TILE_CONFIG_INIT"] }</div>
                                {
                                    isPro ? 
                                    null :
                                    <Lock/>
                                }
                                
                            </div>

                            <div className={classes.divide_checkbox_wr}>
                                <Checkbox name={"moldingVisible"} label={'Молдінг'} onChange={changeMoldingVisible} checked={topPlinth.exists}/>
                            </div>


                            <div className={classes.divideWallGrid}>
                                <EditPlinthItem onClick={e => onClickPlinthChange("molding")} src={'/src_designer/images/molding/t' + topPlinth.shapeNum + '.png'} lock={!isPro}/>
                                <EditMaterialsItem matId={materialIdTop} onCopyPaste={{copy: onCopyMaterial, paste: onPasteMaterial, index: "top"}} onClick={e => onClickPreview("top")}/>
                            </div>

                            <div className={classes.divide_checkbox_wr}>
                                <Checkbox name={"plinthVisible"} label={'Плінтус'} onChange={changePlinthVisible} checked={bottomPlinth.exists}/>
                            </div>


                            <div className={classes.divideWallGrid}>
                                <EditPlinthItem onClick={e => onClickPlinthChange("plinth")} src={'/src_designer/images/plinth/b' + bottomPlinth.shapeNum + '.png'} lock={!isPro}/>
                                <EditMaterialsItem matId={materialIdBottom} onCopyPaste={{copy: onCopyMaterial, paste: onPasteMaterial, index: "bottom"}} onClick={e => onClickPreview("bottom")} />
                            </div>

                            <BlueButton onClick={onApplyToAllRoom} label={'Застосувати до всієї кімнати'}/>

                        </div>
                    </div>
                    :
                    currentDataType == "changePlinth" ? <ChangePlinth /> :
                    currentDataType == "colorpicker" ? <ColorPicker matId={getSelectedId()} materialSelect={onSelectMaterial} changePreviewId={onChangePreview}/> : 
                    currentDataType == "catalog" ? <Catalog /> : 
                    currentDataType == "material" ? <CatalogMaterials materialSelect={onSelectMaterial} changePreviewId={onChangePreview} matId={getSelectedId} /> : null
                
            }
        </>
        
    );
};

export default StateSelectedWall;