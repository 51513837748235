import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import {createUseStyles} from "react-jss";
import { useDispatch } from 'react-redux';
import { planner } from '../../../../../init';
import { changeUndoRedo } from '../../../../../redux/features/topPanel/topPanelSlice';

const useStyle = createUseStyles({
    info_model_btn:{
        position: 'absolute',
        borderRadius: '50%',
        border: '1px solid #000',
        width: 32,
        height: 32,
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.25)',
        userSelect: 'none',
        //transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        '& img':{
            userSelect: "none",
            pointerEvents: "none"
        },
        '&:hover':{
            background: '#ffd119'
        }
    },
});

export const MoveRef = forwardRef(( { hide, show, parent, changePosition, positionBtn }, ref) => <Move hide={hide} show={show} parent={parent} positionBtn={positionBtn} changePosition={changePosition} referense={ref} />);


const Move = props => {

    let positionBtn = props.positionBtn;

    let pointDown = null;
    let pointPrev = null;
    let mouseDown = false;

    let restBtn = null;
    
    let dispatch = useDispatch();

    function startMove(event)
    {
        mouseDown = true;
        props.hide()

        pointDown = {
            x:event.clientX,
            y:event.clientY
        };

        pointPrev = {
            x:event.clientX,
            y:event.clientY
        };

        document.addEventListener('mousemove', move);
        document.addEventListener('mouseup', endMove);

        var viewportOffset = props.referense.current.getBoundingClientRect();
        var top = viewportOffset.top;

        planner.scene.startMoveCurrentMaterial(event.clientX, event.clientY);

        restBtn = (props.referense.current.offsetHeight - (event.clientY - top)) - positionBtn.y - 16;
    }

    function move(event)
    {
        if(!mouseDown) return;
        props.parent.current.style.left = event.clientX + 'px';
        props.parent.current.style.top = event.clientY + restBtn + "px";
        planner.scene.moveCurrentMaterial(event.clientX, event.clientY);
    }

    function endMove(event)
    {
        props.show();
        pointDown = null;
        pointPrev = null;
        document.removeEventListener('mousemove', move);
        document.removeEventListener('mouseup', endMove);
        props.changePosition({x: props.parent.current.style.left.replace('px', ''), y: props.parent.current.style.top.replace('px', '')});
        planner.scene.stopMoveCurrentMaterial();
        dispatch(changeUndoRedo());
        mouseDown = false;
    }

    const classes = useStyle();

    return (
        <div className={classes.info_model_btn} ref={props.referense} style={{top: positionBtn.y + 'px', left: positionBtn.x + 'px'}} onMouseDown={startMove}>
            <img src="/src_designer/images/icon_move_x_y.svg"/>
        </div>
    )
}

export default Move;