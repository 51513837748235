import React, { useEffect, useRef, useState } from 'react';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { user } from '../../../../init';
import { useDispatch } from 'react-redux';
import Checkbox from "../common/checkbox/index.jsx";
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import cx from 'classnames';
import { closeActivePopup, setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { selectUserProfileData } from '../../../../redux/features/user/userSlice';
import { hideTip } from '../../../../redux/features/tips/tipsSlice';

const useStyle = createUseStyles({
    tip:{
        fontSize: 17,
        textAlign: "left",
        border: "1px solid #469bf2",
        background: "#fff",
        padding: 20,
        borderRadius: 2,
        position: "absolute",
        transition: 'all .9s cubic-bezier(.19,1,.22,1)',
        opacity: 0,
        zIndex: 8,
        width: 285
    },
    tip_text:{
        minWidth: 200,
        color: "#469bf2",
        marginBottom: 20,
    },
    arrow:{
        position: "absolute",
    },
    arrow_top_right:{
        bottom: "100%",
        left: "calc(50% - 35px)",
        transform: "translateX(5%)"
    },
    arrow_bottom_right:{
        left: "calc(50% - 35px)",
        transform: "rotateY(180deg) rotate(180deg) translateX(5%)",
        top: "100%"
    },

    arrow_top_left:{
        left: "calc(50% - 150px)",
        bottom: "100%",
        transform: "rotateY(180deg)"
    },
    arrow_bottom_left:{
        left: "calc(50% - 150px)",
        transform: "rotateY(180deg) rotate(180deg) translateX(5%)",
        top: "100%"
    },
    arrow_left_right:{
        left: 'calc(-100% + 160px)',
        bottom: 'calc(100% - 25px)',
        transform: 'rotate(-90deg)'
    },

});
const Tip = props => {
    let dispatch = useDispatch();

    let translation = useSelector(selectTranslation);
    let userProfileData = useSelector(selectUserProfileData);

    const classes = useStyle();

    let changeTipsStatus = async tipsCheckboxStatus => {
        let dataObj = {tips: tipsCheckboxStatus};
        await user.setProfileData(dataObj);
        dispatch(closeActivePopup({ name: "profile" }));
        dispatch(setActivePopup({name: "completeMessage", data: "NOTIFY_TIPS_IN_PROFILE", positionX: "left"}));
    }

    let tip = useRef(null);
    
    let [tipPosition, setTipPosition] = useState(props.position);

    let onTipsHide = e => {
        tip.current ? tip.current.style.opacity = 0 : null;
        setTimeout(() => dispatch(hideTip({name: props.name})), 1000);
        document.removeEventListener("mouseup", onTipsHide);
    }

    useEffect(() => {
        setTimeout(() => document.addEventListener("mouseup", onTipsHide), 0);
        if(props.name == "panorams") setTipPosition({...tipPosition, y: tipPosition.y - tip.current.offsetHeight / 2});
        else if(props.name == "changeWallHeight") setTipPosition({...tipPosition, x: tipPosition.x - tip.current.offsetWidth + 30});
        else if(props.name == "saveProject") tipText.current.style.whiteSpace = "break-spaces";
        setTimeout(() => tip.current ? tip.current.style.opacity = 1 : null, props.showTime);
        return () => {
            document.removeEventListener("click", onTipsHide);
            tipText.current ? tipText.current.removeAttribute("style") : null;
        }
    }, []);
    
    let tipText = useRef(null);

    return (
        
        <div className={classes.tip} style={props.position.from == "right" ? {right: tipPosition.x, top: tipPosition.y} : {left: tipPosition.x, top: tipPosition.y}} ref={tip}>
            {
                props.arrow ?
                    <svg width="180" height="65" xmlns="http://www.w3.org/2000/svg" className={cx(classes.arrow, {
                    [classes.arrow_top_right]:props.arrow.position == "top" && props.arrow.direction == "right",
                    [classes.arrow_top_left]:props.arrow.position == "top" && props.arrow.direction == "left",
                    [classes.arrow_bottom_right]:props.arrow.position == "bottom" && props.arrow.direction == "right",
                    [classes.arrow_bottom_left]:props.arrow.position == "bottom" && props.arrow.direction == "left",
                    [classes.arrow_left_right]:props.arrow.position == "left" && props.arrow.direction == "right"})}>
                        
                        <path d="M 20 80 Q 20 20, 179 20" stroke="#469bf2" fill="transparent"></path>
                        <path d="M 179 20 L 169 25 M 179 20 L 169 15" stroke="#469bf2" fill="transparent"></path>
                    </svg> :
                    null
            }
            
            <div className={classes.tip_text} ref={tipText} dangerouslySetInnerHTML={{__html: translation[props.text]}}></div>
            
            <div className={classes.tips_checkbox}>
                <Checkbox name={"tips"} onChange={changeTipsStatus} label={translation["TEXT_SHOW_TIPS"]} checked={userProfileData.tips ? 'checked' : null}/>
            </div>
            
        </div>    

    );
};

export default Tip;