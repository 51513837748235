import { createSlice } from '@reduxjs/toolkit'

export const popupsSlice = createSlice({
  name: 'popups',
  initialState: {
    currentZindex: 2,
    login: { status: false, zIndex: 2, type: null, data: null},
    registration: { status: false, zIndex: 2}, 
    forgotPassword: { status: false, zIndex: 2},
    profile: { status: false, zIndex: 2},
    payPro: { status: false, zIndex: 2 },
    setProjectName: { status: false, type: null, data: null, zIndex: 2 },
    confirm: { status: false, type: null, data: null, zIndex: 2 },
    alert: { status: false, type: null, data: null, zIndex: 2 },
    share: { status: false, data: null, zIndex: 2 },
    saveBefore: { status: false, data: null, zIndex: 2 },
    setWallHeight: { status: false, data: null, zIndex: 2 },
    completeMessage: { status: false, data: null, zIndex: 2, positionX: null },
    loadingMessage: { status: false, data: null, zIndex: 2, type: null },
    uploadImage: { status: false, zIndex: 2 },
    alignerWidthSet: { status: false, zIndex: 2 },
    alignerWidthSetMetrics: { status: false, zIndex: 2, data: null },
    uploadCustom: { status: false, zIndex: 2, data: null },
    buyCredits: { status: false, zIndex: 2, data: null },
    renderExample: { status: false, zIndex: 2, data: null },
    renderMade: { status: false, zIndex: 2, data: null },
    startPopup: { status: true, zIndex: 2, data: null },
    preConfigRoomsPopup: { status: false, zIndex: 2, data: null }
  },
  reducers: {
    setActivePopup: (state, action) => {
      state.currentZindex += 1;
      state[action.payload.name].zIndex = state.currentZindex;
      state[action.payload.name].status = true;
      if(action.payload.type) state[action.payload.name].type = action.payload.type;
      if(action.payload.data) state[action.payload.name].data = action.payload.data;
      if(action.payload.positionX) state[action.payload.name].positionX = action.payload.positionX;
      
    },
    closeActivePopup: (state, action) => {
      state.currentZindex -= 1;
      state[action.payload.name].zIndex = 0;
      state[action.payload.name].status = false;
      state[action.payload.name].type = state[action.payload.name].data = null;
    },
    setPopupData: (state, action) => {
      state[action.payload.name].data = action.payload.data;
    }
  }
});

export const selectPopups = state => state.popups;
export const selectPopupsCurrentZindex = state => state.popups.currentZindex;

export const { setActivePopup, closeActivePopup, setPopupData } = popupsSlice.actions

export default popupsSlice.reducer;