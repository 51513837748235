import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { createUseStyles } from "react-jss";
import { selectTranslation } from '../../../../../../redux/features/language/languageSlice';
import { selectActiveViewMode } from '../../../../../../redux/features/viewMode/viewModeSlice';
import { useEffect } from 'react';
import { useState } from 'react';


const useStyles = createUseStyles({
    btn_hover:{
        position: "absolute",
        background: "#81e7ff",
        transition: "all 0s ease-in-out 1s",
        display: "none",
        fontSize: 12,
        width: "max-content",
        padding: "2px 7px",
        transition: "all .1s ease",
        userSelect: "none"
    },
    btn_overlay:{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0
    },
    image_div:{
        display: "flex"
    }
});

const ButtonHover = props => {
    const classes = useStyles();
    let activeViewMode = useSelector(selectActiveViewMode);
    let translation = useSelector(selectTranslation);
    let timeoutId = null;
    let mouseDown = false;

    let [text, setText] = useState(props.textHover);
    let [img, setImg] = useState(props.img);
    let [parentClass, setParentClass] = useState(props.parentClass);

    let mouseEnter = e => {
        if(!mouseDown) {
            let mainDiv = e.currentTarget.closest("." + parentClass);
            let hoverDiv = mainDiv.querySelector("." + classes.btn_hover);
            mainDiv.style.zIndex = 9;
            hoverDiv.style.left = e.clientX - mainDiv.getBoundingClientRect().left + 20 + "px";
            hoverDiv.style.top = e.clientY - mainDiv.getBoundingClientRect().top  + 10 + "px";
            timeoutId = setTimeout(() => hoverDiv.style.display = "block", 1000);
        }
    }

    let mouseMove = e => {
        if(!mouseDown) {
            let mainDiv = e.currentTarget.closest("." + parentClass);
            if(mainDiv) {
                let hoverDiv = mainDiv.querySelector("." + classes.btn_hover);
                hoverDiv.style.left = e.clientX - mainDiv.getBoundingClientRect().left + 20 + "px";
                hoverDiv.style.top = e.clientY - mainDiv.getBoundingClientRect().top  + 10 + "px";
            }
        }
    }

    let mouseLeave = e => {
        clearTimeout(timeoutId);
        let mainDiv = e.currentTarget.closest("." + parentClass);
        if(mainDiv) {
            let hoverDiv = mainDiv.querySelector("." + classes.btn_hover);
            hoverDiv.removeAttribute("style");
            mainDiv.removeAttribute("style");
        }
    }

    let mouseUpBtn = e => props.onClick && props.onClick();

    let onMouseUp = e => {
        mouseDown = false;
        document.removeEventListener("mouseup", onMouseUp);
    }

    let mouseDownBtn = e => {
        mouseDown = true;
        document.addEventListener("mouseup", onMouseUp);
        mouseLeave(e);
    }

    let buttonMain = useRef(null);
    let imgDiv = useRef(null);

    useEffect(() => setParentClass(props.parentClass), [props.parentClass]);

    useEffect(() => {
        buttonMain.current.addEventListener("mouseenter", mouseEnter);
        buttonMain.current.addEventListener("mousemove", mouseMove);
        buttonMain.current.addEventListener("mouseleave", mouseLeave);
        buttonMain.current.addEventListener("mousedown", mouseDownBtn);
        buttonMain.current.addEventListener("mouseup", mouseUpBtn);

        return () => {
            if(buttonMain.current) {
                buttonMain.current.removeEventListener("mouseenter", mouseEnter);
                buttonMain.current.removeEventListener("mousemove", mouseMove);
                buttonMain.current.removeEventListener("mouseleave", mouseLeave);
                buttonMain.current.removeEventListener("mousedown", mouseDownBtn);
                buttonMain.current.removeEventListener("mouseup", mouseUpBtn);
            }
        }
    }, [parentClass]);

    useEffect(() => setText(props.textHover), [props.textHover]);
    useEffect(() => setImg(props.img), [props.img]);
    useEffect(() => {
        props.imgRotate ? imgDiv.current.style.transform = props.imgRotate.transform : null
    }, [props.imgRotate])

    return(
        <>
            <div className={classes.image_div} ref={imgDiv}>
                { img }
            </div>
            
            <div className={classes.btn_hover}>{translation[text]}</div>
            <div className={classes.btn_overlay} ref={buttonMain}></div>
        </>
            
        
    )
}

export default ButtonHover;