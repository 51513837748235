import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveViewModeData, historyAdd } from '../../../../../../../../redux/features/viewMode/viewModeSlice';
import { createUseStyles } from "react-jss";
import { user } from '../../../../../../../../init';

const useStyle = createUseStyles({
    planer_catalog_cont:{
        borderLeft: '1px solid #cdcdcd',
        background: '#F1F1F1',
        height: '100%',
        display: 'grid',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '270px',
        overflow:'auto',
        transition: 'all 0.2s cubic-bezier(0.19, 1, 0.22, 1)',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    roll_out_left:{
        transform: "translateX(-100%)"
    },
    roll_out_right:{
        transform: "translateX(100%)"
    },
    planer_cat_item:{
        display: 'grid',
        gridTemplateColumns: '90px auto',
        alignItems: 'center',
        borderBottom: '1px solid #cdcdcd',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        cursor: 'pointer',
        minHeight: 38,
        '&:hover':{
            background: '#fff'
        },
        '@media screen and (max-width: 1280px)':{
            fontSize: 12
        }
    },
    planer_cat_item_img_wr:{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
});
const Catalog = props => {

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;

    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;

    let arrayList = [];

    let dispatch = useDispatch();

    let catalogClick = async data => {
        if(data.products_amount > 0) {
            dispatch(historyAdd({type: "material", matShowType: "bank", urlList: data.urlList, privateMaterialType: data.privateMaterialType, categoryId: data.id, materialType: data.name, data: null, added: true}));
        } else if(data.type == "colorpicker") {
            dispatch(historyAdd({added: true, categoryId: data.id, name: data.name, privateMaterialType: data.privateMaterialType, type: "colorpicker"}));
        }
        else dispatch(historyAdd({type: "catalog", data: data.nodes, added: true}))
    }

    const classes = useStyle();
    if(currentDataPanel) {
        currentDataPanel.forEach(e => {
            arrayList.push(
                <div className={classes.planer_cat_item} key={e.id} onClick={ event => catalogClick(e) }>
                    <div className={classes.planer_cat_item_img_wr}>
                        <img src={R2D.URL.DOMAIN + e.icon} className={classes.planer_cat_item_img}/>
                    </div>
                    <div className={classes.planer_cat_item_title}>{ e.name }</div>
                </div>
            )
        });
    }
    

    let activeAnimationClass = isAdded ? classes.roll_out_right : classes.roll_out_left;

    let rightPanelData = useRef(null);

    useEffect(() => setTimeout(() => rightPanelData.current && rightPanelData.current.classList.remove(activeAnimationClass), 100));

    return (
        <div key={Math.random()} ref={ rightPanelData } className={classes.planer_catalog_cont + ' ' + activeAnimationClass} >
            { arrayList }
        </div>
    )
}

export default Catalog;