import React from 'react';
import {createUseStyles} from "react-jss";
import iconCheck from './images/icon_check.svg';
const useStyle = createUseStyles({
    checkboxInputWr:{
        display: 'flex'
    },
    checkboxInput:{
        position: 'absolute',
        zIndex: '-1',
        opacity: 0,
        '&+label ':{
            display: 'inline-flex',
            alignItems: 'center',
            userSelect: 'none',
            fontSize: 14,
            color: '#505050',
            cursor: 'pointer',
            position: 'relative',
        },
        '&+label::before':{
            content: "''",
            display: 'inline-block',
            width: 14,
            height: 14,
            border: '1px solid #adb5bd',
            marginRight: 6,
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&+label:hover::before':{
            background: '#FFCC00'
        },
        '&+label::after':{
            width: 18,
            height: 17,
            content: "''",
            position: 'absolute',
            top: 0,
            left: 2,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        },
        '&:checked+label::after':{
            backgroundImage: 'url('+ iconCheck +')'
        }
    },
});

const Checkbox = props => {
    const classes = useStyle();
    
    return (
            <div className={classes.checkboxInputWr}>
                <input id={props.name} className={classes.checkboxInput} type="checkbox" onChange={e => props.onChange ? props.onChange(e.target.checked) : null} defaultChecked={props.checked} name={props.name} />
                <label htmlFor={props.name}>{props.label}</label>
            </div>
    );
};

export default Checkbox;