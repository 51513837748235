import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePopup, closeActivePopup, selectPopups, setPopupData } from '../../../../redux/features/popups/popupsSlice';
import { selectLanguages } from '../../../../redux/features/language/languageSlice';
import {createUseStyles} from "react-jss";
import { user } from '../../../../init';
import { useEffect } from 'react';
import { userProfileDataLoad } from '../../../../redux/features/user/userSlice';

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.6)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000',
        // height: '86%',
        // width: '95%',
        margin: 'auto',

},
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        position: 'relative',
        height: 680,
        boxSizing: "content-box",
        "& iframe":{
            border: "none"
        }
    },
    modal_pro_wr:{
        background: '#63bbf0',
        width: '100%',
        height: '100%'
    },
    planer_popup_close:{
        width: 14,
        height: 14,
        position: 'absolute',
        top: 0,
        right: 5,
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    modal_pro_wr_scroll:{
        height: '100%'
    },
    modal_pro_top:{
        padding: '32px 0 0',
        display: 'grid',
        gridTemplateColumns: '39% auto 39%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 75,
        zIndex: 1,
        position: 'relative'
    },
    modal_pro_top_bg: {
        position: 'absolute',
        top: 0,
        pointerEvents: 'none',
        left: 0,
        width: '100%',
        height: 'calc(100% + 152px)',
        zIndex: '-1'
    },
    modal_pro_title: {
        fontWeight: '500',
        color: '#fff',
        fontSize: 46,
        padding: '0 35px',
        marginBottom: 18
    },
    modal_pro_title_img_wr: {

    },
    modal_pro_title_img: {
        width: '100%',
        display: 'block'
    },
    modal_pro_scroll:{

    },
    main_bl_4_item_wr: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3,calc(33% - 19px))',
        gridGap: 35,
        padding: '0 161px',
    },

    main_bl_4_checkbox_wr: {
        marginTop: 21,
        display: 'flex',
        alignItems: 'center',
        padding: '0 161px',
        marginBottom: 84,
    },
    main_bl_4_checkbox_link:{
        color: '#505050',
        fontSize: 14,
        display: 'inline-block',
        textDecoration: 'underline',
        margin: '0 5px 0',
        '&:hover':{
            textDecoration: 'none',
        }
    },
    modal_pro_item_wrap:{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 33%)',
        justifyContent: 'center',
        gridGap: '112px 0',
        padding: '0 108px',
        paddingBottom: 111,
        paddingTop: 40
    },
    modal_pro_item_wr:{
        textAlign: 'center',
        position: 'relative'
    },
    modal_pro_item_title:{
        color: '#fff',
        fontSize: 23,
        height: 43,
        fontWeight: 300,
        textAlign: 'center',
        lineHeight: 1
    },
    modal_pro_item_img_wr:{
        height: 195,
        position: 'relative',
        zIndex: 1
    },
    modal_pro_item_shadow:{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '-1',
        pointerEvents: 'none',
    },
    modal_pro_item_img:{
        maxHeight: '100%'
    },
    modal_pro_item_btn_wrap:{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
    },
    modal_pro_item_btn_wr:{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover':{
            '& $modal_pro_item_btn':{
                backgroundColor: '#ffcc00',
                color: '#4c5d67'
            }
        }
    },
    modal_pro_item_btn:{
        color: '#fff',
        fontSize: 16,
        padding: '9px 16px',
        background: '#9dd900',
        lineHeight: '0.8',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',

    },
    modal_pro_item_btn_icon:{
        width: 48
    }
});

const PayProPopup = props => {

    let dispatch = useDispatch();

    let languages = useSelector(selectLanguages);

    let currLanguage = languages.find(i => i.current == 1);
    
    let popups = useSelector(selectPopups);

    let payProIframeSrc = popups.payPro.data;

    let token = R2D.Storage.load('r2d_token');

    let iframeSrc = payProIframeSrc || `/src_designer/html/payed_plans/plans.php?lang=${currLanguage.code}&token=${token}`;

    function receiveMessage(event)
    {
        let dataObj;

        if ( event.data.source && event.data.source.startsWith("react") || event.data && event.data.startsWith('/*framebus*/')) return;

        try
        {
            dataObj = JSON.parse(event.data);
        } catch ( error ) {
            console.error("Error parse JSON string!");
            console.log(event.data);
            return;
        }

        if (dataObj.action == 'buy')
        {
            let url = R2D.makeURL(R2D.URL.DOMAIN, R2D.URL.URL_PAY_FORM);
            url = url.includes('?') ? url + '&plan_recurring_id=' + dataObj.id : url + '?plan_recurring_id=' + dataObj.id
            user.isLogged() ? dispatch(setPopupData({name: "payPro", data: url})) : dispatch(setActivePopup({name: "login", type: "buy_plan", data: url}))
        }
        else if (dataObj.action == 'buy_finish')
        {
            window.removeEventListener('message', receiveMessage);

            showPayNotification();
            
        }
        else if (dataObj.action == 'open_terms')
        {
            window.open(R2D.URL.DOMAIN + dataObj.lang + '/terms_of_service/', '_blank');
        }
    }

    function showPayNotification() {

        dispatch(setPopupData({name: "payPro", data: `/src_designer/html/pay_notification/wait_${currLanguage.code}.html`}))

        window.addEventListener("message", receiveMessage, false);

        let checkInterval = setInterval(checkUserPlan, 2000);

        let checkNum = 0;

        async function checkUserPlan()
        {
            try {
                let response = await user.checkIsLogged();
                if(response.logged == true) {
                    if (user.isPro()) {
                        clearInterval(checkInterval);
                        dispatch(setPopupData({name: "payPro", data: `/src_designer/html/pay_notification/ok_${currLanguage.code}.html`}))
                        dispatch(userProfileDataLoad());
                    }
                    else {
                        checkNum += 1;
                        if (checkNum > 5) {
                            clearInterval(checkInterval);
                            dispatch(setPopupData({name: "payPro", data: `/src_designer/html/pay_notification/error_${currLanguage.code}.html`}))
                        }
                    }
                }
            } catch(e) {
                console.log("Error while user data load: ", e)
                clearInterval(checkInterval);
                dispatch(setPopupData({name: "payPro", data: `/src_designer/html/pay_notification/error_${currLanguage.code}.html`}))
            }
        }

        function receiveMessage(event)
        {
            let dataObj;

            if (event.data.source == "react-devtools-bridge" || event.data.startsWith('/*framebus*/')) return;

            try
            {
                dataObj = JSON.parse(event.data);
            } catch ( error ) {
                console.error("Error parse JSON string!");
                console.log(event.data);
                return;
            }

            if (dataObj.action == 'close')
            {
                clearInterval(checkInterval);
                window.removeEventListener("message", receiveMessage, false);
                dispatch(closeActivePopup({name: "payPro"}))
            }
        }  
    };

    const classes = useStyle();

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);
        return () => window.removeEventListener("message", receiveMessage);
    }, [])
    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show} style={{zIndex: popups.payPro.zIndex}}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <iframe src={iframeSrc} width={"1350px"} height={"680px"} data-type="window-iframe" id="dialog-iframe"></iframe>
                    <div className={classes.planer_popup_close} id="js_close_popup" onClick={e => dispatch(closeActivePopup({name:"payPro"}))}>
                        <svg className={classes.planer_popup_close_icon} viewBox="0 0 16.03 16.02" width="24px" height="24px">
                            <path fill="#444444" d="M1,0a1,1,0,0,0-.7,1.72L6.6,8,.31,14.3a1,1,0,0,0,1.39,1.44l0,0L8,9.42l6.29,6.29a1,1,0,1,0,1.44-1.39l0,0L9.43,8l6.29-6.29A1,1,0,1,0,14.31.3L8,6.6,1.72.3A1,1,0,0,0,1,0Z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayProPopup;