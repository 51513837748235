import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { historyScrollSave, historyUpdate, selectActiveViewModeData } from '../../../../../../../../../../redux/features/viewMode/viewModeSlice';
import { planner, user } from '../../../../../../../../../../init';
import {createUseStyles} from "react-jss";
import cx from 'classnames';
import { selectLanguages, selectTranslation } from '../../../../../../../../../../redux/features/language/languageSlice';
import { useDispatch } from 'react-redux';
import { setActivePopup } from '../../../../../../../../../../redux/features/popups/popupsSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { selectIsLogged } from '../../../../../../../../../../redux/features/user/userSlice';
import { motion } from 'framer-motion';

const useStyle = createUseStyles({
    planer_catalog_cont:{
        borderLeft: '1px solid #cdcdcd',
        background: '#F1F1F1',
        height: '100%',
        display: 'grid',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '270px',
        overflow:'auto',
        transition: 'all 0.2s cubic-bezier(0.19, 1, 0.22, 1)',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planer_catalog_elem:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '108px',
        width: '108px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        userSelect: 'none',
        marginTop: 10,
        '& img:not($planer_catalog_prem_zamok, $planer_catalog_prem_add_img)':{
            maxWidth: '100%',
            maxHeight: '100%'
        },
        '@media screen and (max-width: 1280px)':{
            height: 80
        }
    },
    planer_catalog_prem_zamok:{
        width: 16,
        height: 16,
        position: 'absolute',
        top: '-3px',
        right: '-6px',
        cursor: 'pointer',
        '& rect':{
            transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        },
        '&:hover':{
            '& rect':{
                fill: '#ffd119'
            }
        }
    },
    planer_catalog_elem_upload:{
        borderBottom: "1px solid rgb(178, 178, 178)",
        paddingBottom: 10,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: "wrap"
    },
    planer_catalog_elem_overlay:{
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%'
    },
    planer_catalog_title:{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '29px',
        backgroundColor: 'rgba(129, 129, 129, 0.9)',
        zIndex: '2',
        fontSize: '13px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '0 5px'
    },
    planer_catalog_elem_wrap:{
        paddingTop: '29px',
        height: '100%',
        overflow: 'auto',
        background: '#fff'
    },
    planer_catalog_elem_wr:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '10px'
    },
    planer_catalog_elem_add: {
        outline: '1px solid #d9d9d9',
        cursor: 'pointer',
        '&:hover':{
            background: '#fcc749',
            '& $planer_catalog_prem_add_text':{
                opacity: 1
            }
        },
    },
    planer_add_elem_lock:{
        border: '1px solid #ca0707',
        '&:hover':{
            border: '1px solid #cdcdcd',
        },
    },
    planer_catalog_prem_add_text:{
        fontSize: 10,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        transition: 'all 0.9s cubic-bezier(.19,1,.22,1)',
        opacity:0,
    },
    planer_catalog_elem_custom:{
        "&:hover $planer_catalog_elem_custom_delete": {
            opacity: 1
        }
    },
    planer_catalog_elem_custom_delete:{
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
        opacity: 0,
        zIndex: 9
    },
    planer_catalog_prem_add_img:{
        maxWidth: '60%',
        maxHeight: '60%'
    },
    product_hover:{
        padding: "10px 20px",
        textAlign: "center",
        border: "1px solid #000",
        position: "absolute",
        display: "inline",
        zIndex: 3,
        background: "#fff",
        pointerEvents: "none",
        '&:after': {
            content: "''",
            position: "absolute",
            left: "100%",
            top: "50%",
            transform: "translate(calc(-50% + 1px), -50%) rotate(-45deg)",
            width: 10,
            height: 10,
            background: "#fff",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
          },
        '& p':{
            marginBottom: 10,
            fontSize: 12
        }
    }
});
const ListModels = props => {

    let translation = useSelector(selectTranslation);
    let languages = useSelector(selectLanguages);
    
    let dispatch = useDispatch();

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentDataPanel = currentStateHistory[currentStateHistory.length - 1].data;
    let currentPanelStateHistory = currentStateHistory[currentStateHistory.length - 1];
    let isAdded = currentStateHistory[currentStateHistory.length - 1].added;
    let savedScroll = currentStateHistory[currentStateHistory.length - 1].lastSavedScroll;
    let mainDiv = useRef(null);
    if(mainDiv.current) mainDiv.current.scrollTop = savedScroll;

    let [modelsListState, setModelsListState] = useState(null);
    let [customModelsListState, setCustomModelsListState] = useState(null);


    let [hoverImages, setHoverImages] = useState([]);

    let isLogged = useSelector(selectIsLogged);

    let imageSizeCreator = null;

    let createProductImage = productContour => {
        return new Promise((resolve, reject) => {
            imageSizeCreator = new R2D.ProductHint.ImageSizeCreator();

            imageSizeCreator.addEventListener(Event.COMPLETE, imageSizeCreatorEventHandler);
            imageSizeCreator.addEventListener(Event.ERROR, imageSizeCreatorEventHandler);
            imageSizeCreator.create(productContour, R2D.ProductHint.Content.getPerson());

            function imageSizeCreatorEventHandler(e) {
                if ( imageSizeCreator == null ) return;

                let src = imageSizeCreator.getResultImageSrc();
                imageSizeCreator.removeEventListener(Event.COMPLETE, imageSizeCreatorEventHandler);
                imageSizeCreator.removeEventListener(Event.ERROR, imageSizeCreatorEventHandler);
                imageSizeCreator.dispose();
                imageSizeCreator = null;

                resolve(src);
            }
        })
    }
    
    const classes = useStyle();

    let mouseEnter = async (event, data) => {
        document.querySelector("." + classes.product_hover) && document.querySelector("." + classes.product_hover).remove()
        let imgSrc = null;
        hoverImages.forEach(i => i.id == data.id ? imgSrc = i.src : null);

        if(!imgSrc) {
            if(!data.source.body.contourCut) {
                console.error("Data for hover image not loaded");
                imgSrc = "";
            } else {
                imgSrc = await createProductImage(data.source.body.contourCut);
                if(imgSrc) {
                    setHoverImages(preview => {
                        let newArray = preview;
                        newArray.push({id: data.id, src: imgSrc});
                        return newArray;
                    });
                }
            }   
        }

        let product = event.target;
        let hoverWindow = document.createElement("div");
        hoverWindow.classList.add(classes.product_hover);
        let textHover = document.createElement("p");
        textHover.innerHTML = data.name;
        let imgHover = document.createElement("img");
        if(imgSrc) {
            imgHover = document.createElement("img");
            imgHover.src = imgSrc;
        } else {
            imgHover = document.createElement("div");
        }

        hoverWindow.append(textHover);
        hoverWindow.append(imgHover);
        document.querySelector("body").append(hoverWindow);
        hoverWindow.style.left = (product.getBoundingClientRect().left - hoverWindow.offsetWidth - 20) + "px";
        hoverWindow.style.top = (product.getBoundingClientRect().top + ((product.offsetHeight - hoverWindow.offsetHeight) / 2)) + "px";
    }

    let mouseLeave = () => document.querySelector("." + classes.product_hover) && document.querySelector("." + classes.product_hover).remove();

    let timer = null;
    let dragTimeout = null;

    let onObjectMove = e => planner.mih().state.mouseMove(e);

    let onObjectMouseUp = e => {
        planner.mih().state.mouseUp(e);
        document.removeEventListener("mousemove", onObjectMove);
        document.removeEventListener("mouseup", onObjectMouseUp);
    }

    let onMouseDownProduct = (e, id) => {
        document.addEventListener("mousemove", onObjectMove);
        document.addEventListener("mouseup", onObjectMouseUp);
        timer = Date.now();
        dragTimeout = setTimeout(() => e.nativeEvent.button == 0 ? planner.scene.dragProductFromCatalog(id) : null, 100)   
    }
    
    let onCustomMatAdd = e => user.isPro() ? dispatch(setActivePopup({name: "uploadCustom"})) : dispatch(setActivePopup({name: "payPro"}));

    let productMouseUp = e => Date.now() - timer < 100 && clearTimeout(dragTimeout);

    useEffect(async () => {
        let loadedData = await user.loadRightPanelData(currentStateHistory[currentStateHistory.length - 1].urlList, R2D.controller.getCountry());
        dispatch(historyUpdate(loadedData));
    }, [languages]);

    useEffect(() => {
        mainDiv.current.addEventListener("scroll", onScroll);
        setTimeout(() => Array.from(document.querySelectorAll("." + classes.planer_catalog_elem)).forEach(item => item.removeAttribute("style")), 300);
        return () => document.querySelector("." + classes.product_hover) && document.querySelector("." + classes.product_hover).remove();
    }, [modelsListState, customModelsListState]);

    useEffect(() => {
        let modelsList = [];
        let customModelsList = [];

        currentDataPanel && currentDataPanel.products.forEach(elem => {
            if(elem.material_owner) {
                customModelsList.push(
                    <div className={classes.planer_catalog_elem + " " + classes.planer_catalog_elem_custom} style={{pointerEvents: "none"}} key={elem.id} >
                        <LazyLoadImage src={R2D.URL.DOMAIN + elem.source.images.preview} placeholder={<div className='preloader-cube'></div>}/>
                        <LazyLoadImage src="/src_designer/images/icon_trash.svg" className={classes.planer_catalog_elem_custom_delete} onClick={e => dispatch(setActivePopup({name: "confirm", type: "delete_custom_material", data: elem.id}))} placeholder={<div className='preloader-cube'></div>}/>
                        <div className={classes.planer_catalog_elem_overlay} onMouseUp={productMouseUp} onMouseDown={e => onMouseDownProduct(e, elem.id)}></div>
                    </div>
                )
            } else {
                modelsList.push(
                    <div className={classes.planer_catalog_elem} style={{pointerEvents: "none"}} key={elem.id} onMouseLeave={mouseLeave} onMouseEnter={event => mouseEnter(event, elem)}>
                        <LazyLoadImage src={R2D.URL.DOMAIN + elem.source.images.preview} placeholder={<div className='preloader-cube'></div>}/>
                        <div className={classes.planer_catalog_elem_overlay} onMouseUp={productMouseUp} onMouseDown={e => onMouseDownProduct(e, elem.id)}></div>
                    </div>
                )
            }
        });
        setModelsListState(modelsList);
        setCustomModelsListState(customModelsList);
        
    }, [currentDataPanel]);

    const variants = {
        visible: { transform:  "translateX(0%)"},
        hidden: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
        exit: { transform:  isAdded ? "translateX(100%)" : "translateX(-100%)"},
    }
    
    let scrollTimer = -1;

    let onScroll = e => {
        if (scrollTimer != -1) clearTimeout(scrollTimer);
        scrollTimer = setTimeout(() => scrollFinished(), 500);
    }

    let scrollFinished = () => dispatch(historyScrollSave(mainDiv.current ? mainDiv.current.scrollTop : 0));

    return (
        <motion.div transition={{ ease: "easeOut", duration: .1, delay: .2 }} initial="hidden" animate="visible" exit={"exit"}  variants={variants} className={classes.planer_catalog_cont} >

            <div className={classes.planer_catalog_title}>{ currentDataPanel && currentDataPanel.data && currentDataPanel.data.name }</div>

            <div className={classes.planer_catalog_elem_wrap} ref={mainDiv}>
                <div className={classes.planer_catalog_elem_wr} >

                    {
                        currentDataPanel ?
                            customModelsListState && customModelsListState.length > 0 ?
                            <>
                                <div className={classes.planer_catalog_elem_upload}>
                                    <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                                        <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                                        { user.data.plan != "pro" ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                                        <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                                    </div>
                                    { customModelsListState }
                                </div> 
                                { modelsListState }
                            </>
                             :
                             currentPanelStateHistory.privateMaterialType ?
                             <>
                                <div className={cx(classes.planer_catalog_elem, classes.planer_catalog_elem_add, {[classes.planer_add_elem_lock]:!user.isPro()})} onClick={onCustomMatAdd}>
                                    <img src="/src_designer/images/icon_add.svg" className={classes.planer_catalog_prem_add_img}/>
                                    { user.data.plan != "pro" ? <img src="/src_designer/images/icon_zamok_red_lock.svg" className={classes.planer_catalog_prem_zamok}/> : null }
                                    <div className={classes.planer_catalog_prem_add_text}>{ translation["TEXT_ADD"] }</div>
                                </div>
                                { modelsListState }
                             </>
                            :
                            <>
                                { modelsListState }
                            </>
                        :
                        null
                    }
                </div>
            </div>

        </motion.div>
    )
}

export default ListModels;