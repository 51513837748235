import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActivePopup, selectPopups, setActivePopup } from '../../../../redux/features/popups/popupsSlice';
import { setActiveProjectName } from '../../../../redux/features/user/userSlice';
import { selectTranslation } from '../../../../redux/features/language/languageSlice';
import {createUseStyles} from "react-jss";
import { user, planner } from '../../../../init';
import { selectActiveViewMode, setActiveViewMode, setActiveViewModeState } from '../../../../redux/features/viewMode/viewModeSlice';
import { hideQuickPanelAll } from '../../../../redux/features/quickPanels/quickPanelsSlice';
import { changeImagePlanUpload } from '../../../../redux/features/constructor/constructorSlice';
import { changeActivePano, updateCurrentProjectHash } from '../../../../redux/features/common/commonSlice';

const useStyle = createUseStyles({
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        color: '#000'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        padding: '30px 30px',
        position: 'relative'
    },
    planer_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },
    text:{
        color: 'rgb(47, 47, 47)',
        margin: '0 10px 15px',
        fontSize: 15,
        textAlign: 'center'
    },
    planer_popup_bottom:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    planer_btn_done:{
        background: '#828282',
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '12px',
        padding: '0 20px',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        margin: '0 10px',
        minWidth: '94px',
        border: 'none',
        outline: 'rgb(217, 217, 217) solid 1px',
        '&:hover': {
            background: '#ffd119'
        }
    },

});

const SaveBeforePopup = props => {

    let dispatch = useDispatch();
    let translation = useSelector(selectTranslation);
    let popups = useSelector(selectPopups);
    let activeViewMode = useSelector(selectActiveViewMode);

    let projectToSave = popups.saveBefore.data;

    let popupType = popups.saveBefore.type;

    let onClickOk = null;
    let onClickDoNotSave = null

    if(popupType == "open") {
        onClickOk = e => {
            dispatch(closeActivePopup({name: "saveBefore"}));
            if (!planner.scene.wasSaved()) dispatch(setActivePopup({name: "setProjectName", type: "createBeforeOpen", data: projectToSave}));
            else {
                return new Promise((resolve, reject) => {
                    let project = {createNew: false};
                    if(R2D.controller.getProjectUserKey() != user.getKey()) {
                        project.createNew = true;
                    }
                    resolve(planner.scene.saveProject(project));
                }).then(async result => {
                    dispatch(hideQuickPanelAll());
                    
                    let loadedProjectViewType = await planner.scene.loadProject(projectToSave.id);

                    dispatch(setActiveProjectName(projectToSave.name));
                
                    if(loadedProjectViewType == "2d" || loadedProjectViewType == "constr") dispatch(setActiveViewMode("view2d"));
                    if(loadedProjectViewType == "3d") dispatch(setActiveViewMode("view3d"));
                    if(loadedProjectViewType == "walk") dispatch(setActiveViewMode("viewWalk"));
                    dispatch(changeImagePlanUpload(R2D.commonSceneHelper.drawingHelper.state != null ? R2D.commonSceneHelper.drawingHelper.state : null));
                    let response = await user.projectHashLoad(R2D.controller.getProjectId());
                    if(response.type == "error") dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
                    else {
                        dispatch(updateCurrentProjectHash(response));
                        window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH + '/project/' + response);
                    }
                })
            }
        }
        onClickDoNotSave = async e => {
            dispatch(hideQuickPanelAll());
            dispatch(closeActivePopup({name: "saveBefore"}));
            let loadedProjectViewType = await planner.scene.loadProject(projectToSave.id);

            dispatch(setActiveProjectName(projectToSave.name));
                
            if(loadedProjectViewType == "2d" || loadedProjectViewType == "constr") dispatch(setActiveViewMode("view2d"));
            if(loadedProjectViewType == "3d") dispatch(setActiveViewMode("view3d"));
            if(loadedProjectViewType == "walk") dispatch(setActiveViewMode("viewWalk"));
            
            dispatch(changeImagePlanUpload(R2D.commonSceneHelper.drawingHelper.state != null ? R2D.commonSceneHelper.drawingHelper.state : null));

            let response = await user.projectHashLoad(R2D.controller.getProjectId());
            if(response.type == "error") dispatch(setActivePopup({ name: "alert", type: response.message, data: response.data }));
            else {
                dispatch(updateCurrentProjectHash(response));
                window.history.replaceState(null, null, R2D.URL.REACT_BASEPATH + '/project/' + response);
            }
        }
    }

    if(popupType == "createNew") {
        onClickOk = e => {
            dispatch(closeActivePopup({name: "saveBefore"}));
            user.isLogged() ?
            dispatch(setActivePopup({name: "setProjectName", type: "saveBeforeCreate"})) :
            dispatch(setActivePopup({name: "login"}));
        }
    
        onClickDoNotSave = e => {
            dispatch(hideQuickPanelAll());
            dispatch(setActiveViewModeState({state: "stateEditing"}));
            dispatch(closeActivePopup({name: "saveBefore"}));
            R2D.controller.createNewScene(true);
            dispatch(setActiveProjectName(translation["DEFAULT_SCENE_NAME"]));
            dispatch(changeImagePlanUpload(null));
            dispatch(changeActivePano({ id: 0, previewImgSrc: "", rotation: 0}));
        }
    }
    

    const classes = useStyle();
    return (
        <div className={classes.planer_popup_wrap +' '+ classes.planer_popup_wrap_show}>
            <div className={classes.planer_popup_wr}>
                <div className={classes.planer_popup_cont}>
                    <div className="planer_popup_login_wrap">
                        <img src="/src_designer/images/gradient_left_top.png" className={classes.shadow_planer_pop_top_left} />
                        <img src="/src_designer/images/gradient_right_bottom.png" className={classes.shadow_planer_pop_bot_right} />
                        
                        <div className={classes.text}>
                            <p dangerouslySetInnerHTML={{__html: translation["TEXT_SAVE_BEFORE_LOAD_PLAN"]}}></p>
                        </div>
                        <div className={classes.planer_popup_bottom}>
                            <span className={classes.planer_btn_done} onClick={e => {onClickOk()}}>{ translation["BUTTON_OK"] }</span>
                            <span className={classes.planer_btn_done} onClick={e => {onClickDoNotSave()}}>{ translation["BUTTON_DO_NOT_SAVE"] }</span>
                            <span className={classes.planer_btn_done} onClick={e => dispatch(closeActivePopup({name: "saveBefore"}))}>{ translation["BUTTON_CANCEL"] }</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaveBeforePopup;