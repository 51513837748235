import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {createUseStyles} from "react-jss";
import { selectTranslation } from '../../../../../../redux/features/language/languageSlice';
import ChangeSize from '../../../common/changeSize';
import LockSize from '../../../common/lockSize';
import { selectLockState } from '../../../../../../redux/features/common/commonSlice';

const useStyle = createUseStyles({

    planerChangeSizesWrap:{
        display: 'grid',
        gridTemplateColumns: 'auto 27px',
        gridGap: 24,
        marginBottom: 20,
        paddingRight: 10
    },
    planerChangeSizes:{

    },
    changeSizesLockWr:{
        position: 'relative',
        display: 'grid',
        paddingTop: 13,
        height: '100%'
    },
    changeSizesLockLine1:{
        width: '100%',
        height: 11,
        borderTop: '1px solid',
        borderRight: '1px solid',
        marginBottom: 7
},
    changeSizesLockLine2:{
        width: '100%',
        height: 11,
        borderBottom: '1px solid #444444',
        borderRight: '1px solid #444444'
    },
    lock_main:{
        position: 'absolute',
        '& svg': {
            width: 16,
            cursor: 'pointer'
        }
    },
    
    planer_popup_wrap:{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0%',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '101',
        WebkitPerspective: '800px',
        perspective: '800px',
        minHeight: '550px',
        overflow: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.2)'
    },
    planer_popup_wrap_show:{
        top: '0',
        opacity: '1',
        pointerEvents: 'auto',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    planer_popup_wr:{
        width: 'auto',
        height: 'auto'
    },
    planer_popup_cont:{
        color: '#000',
        backgroundColor: '#f1f1f1',
        border: '1px solid #2f2f2f',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    planer_popup_top: {
        position: 'relative',
        zIndex: 9,
        width: 500,
        height: 220,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        background: '#fff',
    },

    planer_popup_top_text:{
        fontSize: '18px',
        color: '#444444',
        whiteSpace: 'nowrap'
    },

    planer_popup_close:{
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: '2',
        '& path': {
            transition: 'all 0.9s cubic-bezier(.19,1,.22,1)'
        },
        '&:hover':{
            '& path':{
                fill: '#ffd119'
            }
        }
    },
    planer_popup_close_icon:{
        width: '100%'
    },
    shadow_planer_pop_top_left:{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none'
    },
    shadow_planer_pop_bot_right:{
        position: 'absolute',
        bottom: '0',
        right: '0',
        pointerEvents: 'none'
    },

    planer_popup_bottom:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        fontSize: 13,
        color: 'rgb(47, 47, 47)',
    },
    planer_popup_bottom_size:{
        display: "flex",
        alignItems: "center"

    },
    planer_popup_bottom_size_btn:{
        marginRight: 40,
        marginBottom: 0,
        gridGap: 14,
        minWidth: 200
    },
    planerChangeSizes:{
        display: 'grid',
        gridGap: 10
    },
    green_outline: {
        outline: 'solid 2px #bae51f',
        outlineOffset: '-4px'
    },
    planer_popup_bottom_size_apply:{
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        height: 25,
        margin: '0 10px',
        display: 'flex',
        outline: 'rgb(217, 217, 217) solid 1px',
        padding: '0 20px',
        fontSize: 12,
        minWidth: 94,
        background: '#828282',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover':{
            background: '#ffd119',
        }
    }
});
const MaterialSize = props => {
    let translation = useSelector(selectTranslation);

    let lockState = useSelector(selectLockState);

    let imageWidth = props.imgWidth;
    let imageHeight = props.imgHeight;

    let ratio = imageWidth / imageHeight;

    let [mouseDown, setMouseDown] = useState({status: false, action: null, type: null});

    const classes = useStyle();

    let onChangeValue = (mouseDown, value, type) => {
        setMouseDown({...mouseDown, type});
        switch(type) {
            case "width":
                if(lockState) imageHeight = value / ratio;
                props.changeSize(value, imageHeight);
                break;
            case "height":
                if(lockState) imageWidth = value * ratio;
                props.changeSize(imageWidth, value);
                break;
        }
    }

    return (
        <>
            <div className={classes.planerChangeSizesWrap + ' ' + classes.planer_popup_bottom_size_btn}>
                <div className={classes.planerChangeSizes}>
                    <ChangeSize mouseDown={mouseDown} valueChangeType={"width"} changeValue={onChangeValue} value={props.imgWidth} label={translation["WIDTH"]} />
                    <ChangeSize mouseDown={mouseDown} valueChangeType={"height"} changeValue={onChangeValue} value={props.imgHeight} label={translation["HEIGHT"]} />
                </div>
                <div>
                    <div className={classes.changeSizesLockWr}>
                        <div className={classes.changeSizesLockLine1}></div>
                        <div className={classes.changeSizesLockLine2}></div>

                        <LockSize lockState={lockState} hoverPosition={{right: 20, top: 0}} right={'-8px'} top={"23px"}/>

                    </div>
                </div>
            </div>
            <button className={classes.planer_popup_bottom_size_apply} onClick={props.onApply}>{translation["APPLY"]}</button>
        </>
    )
}

export default MaterialSize;