import React from 'react';
import HomeButton from './buttons/HomeButton.jsx';
import PrevButton from './buttons/PrevButton.jsx';
import HideButton from './buttons/HideButton.jsx';
import {createUseStyles} from "react-jss";
import { useSelector } from 'react-redux';
import { selectActiveViewModeData, selectActiveViewModeState } from '../../../../../redux/features/viewMode/viewModeSlice.js';
import { selectRightPanelStatus } from '../../../../../redux/features/common/commonSlice.js';

const useStyle = createUseStyles({
    planer_catalog_top_wr:{
        position: 'relative',
        background: '#2f2f2f',
        height: '29px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    planer_catalog_navigation:{
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    }
});
const Navigation = props => {
    const classes = useStyle();
    let activeViewModeState = useSelector(selectActiveViewModeState);

    let activeViewModeData = useSelector(selectActiveViewModeData);
    let currentStateHistory = activeViewModeData[activeViewModeData.activeState].history;
    let currentStateData = currentStateHistory[currentStateHistory.length - 1];
    let rightPanelStatus = useSelector(selectRightPanelStatus);

    return (
        <>
            <div className={classes.planer_catalog_top_wr}>
                {
                    !rightPanelStatus ? 
                    null :
                    <div className={classes.planer_catalog_navigation}>
                        {
                            activeViewModeState == "stateRenderMake" ?
                            null :
                            <HomeButton/>
                        }
                        {
                            activeViewModeState == "stateRenderMake" && currentStateData && currentStateData.type == "main" ?
                            null :
                            <PrevButton />
                        } 
                    </div>
                }
                {
                    activeViewModeState == "stateRenderMake" ?
                    null :
                    <HideButton />
                }
            </div>
            
        </>
    )
}

export default Navigation;