import React from 'react';
import { planner } from '../../../../../init';
import { selectActiveViewMode } from '../../../../../redux/features/viewMode/viewModeSlice';
import { useSelector } from 'react-redux';
import LeftButton from '../btnComponent/LeftButton';

const ZoomInOut = props => {

    let activeViewMode = useSelector(selectActiveViewMode);
    let tileConfigurator = null;
    let tileEditor = null;
    if(activeViewMode == 'tileConfig') {
        tileConfigurator = planner.scene.getConfigurator();
        tileEditor = tileConfigurator.getTileEditor();
    }
    
    let zoomIn = e => {
        activeViewMode == 'tileConfig' ?
        tileEditor.zoom(tileEditor.canvasWidth / 2, tileEditor.canvasHeight / 2, 2) :
        planner.zoomIn();
    }

    let zoomOut = e => {
        activeViewMode == 'tileConfig' ?
        tileEditor.zoom(tileEditor.canvasWidth / 2, tileEditor.canvasHeight / 2, -2) :
        planner.zoomOut();
    }

    return(
        <div>
            <LeftButton img={
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <rect x="8" width="1" height="17" fill="white"/>
                    <rect x="17" y="8" width="1" height="17" transform="rotate(90 17 8)" fill="white"/>
                </svg>
            } textHover={"HINT_BUTTON_ZOOM_PLUS"} onClick={zoomIn}/>

            <LeftButton img={
                <svg width="17" height="1" viewBox="0 0 17 1" fill="none" >
                    <rect x="17" width="1" height="17" transform="rotate(90 17 0)" fill="white"/>
                </svg>
            } textHover={"HINT_BUTTON_ZOOM_MINUS"} onClick={zoomOut}/>
        </div>
        
    )
}

export default ZoomInOut;