import ReactDOM from 'react-dom';
import React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Main from './jsx/Main.jsx';
import { planner, user } from '../init';
import { store } from "../redux/store/store";
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { historyAdd } from '../redux/features/viewMode/viewModeSlice.js';
import { userCheckIsLogged } from '../redux/features/user/userSlice.js';
import { languagesInit, translationInit } from '../redux/features/language/languageSlice.js';

typeof SENTRY_DSN !== 'undefined' && SENTRY_DSN && Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    debug: process.env.NODE_ENV === "development",
    enabled: process.env.NODE_ENV !== "development",
    environment: SENTRY_ENV,
    tracesSampleRate: 1.0,
})

async function init() {
    
    await planner.init();
    
    await store.dispatch(languagesInit());

    await store.dispatch(translationInit())
    await store.dispatch(userCheckIsLogged())
    let start2D3DData = await user.loadRightPanelData(R2D.URL.CATALOG_TREE);
    store.dispatch(historyAdd({type: "catalog", lastSavedScroll: 0, data: start2D3DData.tree, added: true}));

    ReactDOM.render(
        <React.StrictMode>
            <Router basename={R2D.URL.REACT_BASEPATH}>
                <Routes>
                    <Route path="/" element={
                        <Provider store={store}>
                            <Main />
                        </Provider>
                    } />
                    <Route path="/project/*" element={
                        <Provider store={store}>
                            <Main />
                        </Provider>
                    } />
                </Routes>
            </Router>
        </React.StrictMode>,
        document.getElementById("app")
    );
}

init();
