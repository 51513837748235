import { createSlice } from '@reduxjs/toolkit'
import { planner } from '../../../init'

export const viewModeSlice = createSlice({
  name: 'viewMode',
  initialState: {
    active: "view3d",
    "view3d": {
      activeState: "stateEditing",
      prevState: null,
      stateEditing: { history: [] },
      stateRendersShow: { history: [] , isAllowedChangeHistoryState: true},
      stateProjectsShow: { history: [] , isAllowedChangeHistoryState: true},
      stateRenderMake: { history: [], isAllowedChangeHistoryState: true},
      stateSelectedConstr: { history: [] },
      stateSelectedProduct: { history: [] },
      stateSelectedWall: { history: [] },
      stateSelectedPlinth: { history: [] },
      stateSelectedGroup: { history: [] }
    },
    "view2d": {
      activeState: "stateEditing",
      prevState: null,
      stateEditing: { history: [] },
      stateRendersShow: { history: [] , isAllowedChangeHistoryState: true},
      stateProjectsShow: { history: [] , isAllowedChangeHistoryState: true},
      stateRenderMake: { history: [], isAllowedChangeHistoryState: true},
      stateSelectedConstr: { history: [] },
      stateSelectedProduct: { history: [] },
      stateSelectedGroup: { history: [] }
    },
    "constr": {
      activeState: "stateEditing",
      prevState: null,
      stateEditing: { history: [{added: true, type: "main"}]},
      stateMakeWall: { history: [] },
      stateMakeRect: { history: []},
      stateMakeRoom: { history: []},
      stateCutRoom: { history: []},
      stateMakeCover: { history: []},
      stateCutCover: { history: []},
      stateMakeArea: { history: [] },
      stateSetWallHeight: { history: []},
      stateRendersShow: { history: [], isAllowedChangeHistoryState: true},
      stateProjectsShow: { history: [], isAllowedChangeHistoryState: true},
      stateRenderMake: { history: [], isAllowedChangeHistoryState: true},
      stateSelectedCover: { history: [] },
      stateSelectedArea: { history: [] },
      stateAlignDrawing: { history: [] },
      stateSelectedRuler: { history: [] }
    },
    "viewWalk": {
      activeState: "stateEditing",
      prevState: null,
      stateEditing: { history: [{type: "main", added: true, data: null}], lastSavedScroll: 0 },
      stateRendersShow: { history: [] , isAllowedChangeHistoryState: true},
      stateProjectsShow: { history: [] , isAllowedChangeHistoryState: true},
      stateRenderMake: { history: [], isAllowedChangeHistoryState: true},
    },
    'tileConfig': {
      activeState: "stateEditing",
      prevState: null,
      stateEditing: { history: [] },
      stateMakingTiles: { history: [] },
      stateSelecting: { history: [] },
      stateSelected: { history: [] },
      stateDraggingCanvas: { history: [] },
      stateDraggingDelimiter: { history: [] },
      stateSelectedDelimiter: { history: [] },
      stateShiftingArea: { history: [] },
      stateDraggingMaterial: { history: [] },
    }
  },
  reducers: {
    setActiveViewMode: (state, action) => {
      let newMode = action.payload;

      if(state.active == newMode) return;
      
      if(state[state.active].activeState == "stateProjectsShow") state[state.active][state[state.active].activeState].history = [];

      if(newMode != 'tileConfig') {
        planner[newMode].activate();
        state[state.active].activeState = "stateEditing";
        state[state.active].prevState = null;
      }

      let newStateData = action.payload.data;

      state.active = newMode;

      let activeViewModeState = "stateEditing";

      if(newStateData) state[newMode][activeViewModeState].history.push(newStateData);

      if(action.payload.state && state[newMode].prevState == null) {
        state[newMode].prevState = "stateEditing";
      }

      if(newMode == "view2d") state[newMode]["stateEditing"].history = state["view3d"]["stateEditing"].history;
      else if(newMode == "view3d") state[newMode]["stateEditing"].history = state["view2d"]["stateEditing"].history.length > 0 ? state["view2d"]["stateEditing"].history : state["view3d"]["stateEditing"].history;
      
    },
    setActiveViewModeState(state, action) {
      let newState = action.payload.state;
      let newStateData = action.payload.data;

      let activeViewMode = state.active;
      let activeViewModeState = state[activeViewMode].activeState;

      if(newStateData && state[state.active][newState].history.length < 1) state[state.active][newState].history.push(newStateData);
      if(newState == activeViewModeState) return;

      if(state[activeViewMode][activeViewModeState].history.length > 0)
        state[activeViewMode][activeViewModeState].history[state[activeViewMode][activeViewModeState].history.length - 1].added = false;
        

      state[state.active].prevState = state[state.active].activeState;

      if(newState == "stateEditing" || newState == "stateSelectedGroup" && state[state.active].activeState == "stateSelectedProduct") {
        state[state.active].prevState = null;
        state[activeViewMode][activeViewModeState].history = [];
      }

      if(state[state.active].activeState == "stateRenderMake") {
        planner.mih()._currentViewer.cameraMoveY(150);
        planner.mih()._currentViewer.cameraMinHeightResetToDef();
        planner.mih()._currentViewer.changeIsRenderStateActive(false);
        planner.mih()._currentViewer.showCursor();
        planner[activeViewMode].activate();
        state[state.active].prevState = "stateEditing";
      }

      state[state.active].activeState = newState;

      if(state[state.active].activeState == "stateProjectsShow" || state[state.active].activeState == "stateRendersShow" || state[state.active].activeState == "stateRenderMake") state[state.active].prevState = "stateEditing";
      
    },
    updateMaterialId(state, action) {
      let activeViewMode = state.active;
      let activeViewModeState = state[activeViewMode].activeState;
      state[activeViewMode][activeViewModeState].history[0].matId = action.payload;
    },
    historyAdd(state, action) {
      let activeViewMode = state.active;
      
      if(state[activeViewMode][state[activeViewMode].activeState].history.length > 0)
        state[activeViewMode][state[activeViewMode].activeState].history[state[activeViewMode][state[activeViewMode].activeState].history.length - 1].added = false;
      
      state[activeViewMode][state[activeViewMode].activeState].history.push(action.payload);
    },
    historyRemove(state, action) {
      let activeViewMode = state.active;
      state[activeViewMode][state[activeViewMode].activeState].history.pop();
    },
    historyUpdate(state, action) {
      let activeViewMode = state.active;
      state[activeViewMode][state[activeViewMode].activeState].history[state[activeViewMode][state[activeViewMode].activeState].history.length - 1].data = action.payload;
    },
    historyClear(state, action) {
      let activeViewMode = state.active;
      state[activeViewMode][action.payload.state].history = [];
    },
    historyScrollSave(state, action) {
      let activeViewMode = state.active;
      state[activeViewMode][state[activeViewMode].activeState].history[state[activeViewMode][state[activeViewMode].activeState].history.length - 1].lastSavedScroll = action.payload;
    },
    updateCurrentProductSize(state, action) {
      let activeViewMode = state.active;
      state[activeViewMode][state[activeViewMode].activeState].history[0].data = action.payload;
    },
  },
});

export const selectActiveViewMode = state => state.viewMode.active;
export const selectActiveViewModeState = state => state.viewMode[state.viewMode.active].activeState;
export const selectActiveViewModeData = state => state.viewMode[state.viewMode.active];

export const { 
  setActiveViewMode, setActiveViewModeState, historyAdd, historyRemove, 
  historyClear, updateMaterialId, historyUpdate, 
  updateCurrentProductSize, historyScrollSave } = viewModeSlice.actions

export default viewModeSlice.reducer;