import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {createUseStyles} from "react-jss";
import { planner } from '../../../../../../../../../init';
import { changeUndoRedo } from '../../../../../../../../../redux/features/topPanel/topPanelSlice';
import { setActiveViewMode, setActiveViewModeState } from '../../../../../../../../../redux/features/viewMode/viewModeSlice';

const useStyle = createUseStyles({
    planerWallsCont: {
        top: 0,
        left: 0,
        width: 270,
        height: '100%',
        display: 'grid',
        position: 'absolute',
        background: '#F1F1F1',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        borderLeft: '1px solid #cdcdcd',
        '@media screen and (max-width: 1280px)':{
            width: 200
        }
    },
    planerWallsWrap: {
        height: '100%',
        overflow: 'auto',
        background: '#fff',
        padding: '20px 10px',
    },
    divideWallGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 14,
        marginBottom: 20
    },
    planerConstItemText:{
        color: '#444444',
        fontSize: 12,
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        padding: '3px 2px',
        '@media screen and (max-width: 1280px)':{
            opacity: 1
        }
    },
    item:{
        outline: '1px solid #d9d9d9',
        background: '#FFFFFF',
        height: 120,
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        justifyItems: 'center',
        padding: '6px 6px 4px',
        gridGap: 5,
        position: 'relative',
        transition: 'all 0.9s cubic-bezier(0.19, 1, 0.22, 1)',
        '&:hover':{
            background: '#FFCC00',
            '& $planerConstItemText':{
                opacity: 1
            }
        },
        '@media screen and (max-width: 1280px)':{
            height: 80
        }
    },
});

const StateEditing = props => {

    let rightPanelData = useRef(null);

    let dispatch = useDispatch();

    let tileConfigurator = planner.scene.getConfigurator();

    let [wasChanged, setWasChanged] = useState(false);

    useEffect(() => {
        setTimeout(() => rightPanelData.current && rightPanelData.current.classList.remove(classes.roll_out_right), 100);
        return () => document.removeEventListener("keydown", props.onKeyDownConstr)
    }, []);

    let onSplitVert = () => {
        tileConfigurator.splitVertListener();
        dispatch(changeUndoRedo());
        setWasChanged(true);
    }

    let onSplitHor = () => {
        tileConfigurator.splitHorListener();
        dispatch(changeUndoRedo());
        setWasChanged(true);
    }

    let onClear = () => {
        tileConfigurator.clearListener();
        dispatch(changeUndoRedo());
        setWasChanged(false);
    }

    let onSave = () => {
        var dw = planner.mih()._scene.currentView3DObject.getConstructorElementData();
        var configData = tileConfigurator.getData();
        if (configData.areas.length == 1) {
            dw.configData = null;
        }
        else {
            dw.configData = configData;
        }

        dw.changed = true;
        dw.build3D();
        dw.dispatchUpdate();
        R2D.scene.currentPartNum = wasChanged ? 1 : -1;
        R2D.scene.history.saveState();

        tileConfigurator.destroy();
        tileConfigurator = null;

        dispatch(setActiveViewMode('view3d'));
    }

    const classes = useStyle();

    return (
        <div className={classes.planerWallsCont} ref={rightPanelData}>
            <div className={classes.planerWallsWrap}>
                <div className={classes.divideWallGrid}>
                    <div className={classes.item} onClick={onSave}>
                        <img src="/src_designer/images/icon_save_grey.svg"/>
                        <p className={classes.planerConstItemText} >Save</p>
                    </div>
                    <div className={classes.item} onClick={onClear}>
                        <img src="/src_designer/images/icon_clear.svg"/>
                        <p className={classes.planerConstItemText}>Clear</p>
                    </div>
                    <div className={classes.item} onClick={onSplitVert}>
                        <img src="/src_designer/images/icon_wall_y.svg"/>
                        <p className={classes.planerConstItemText}>Split vertical</p>
                    </div>
                    <div className={classes.item} onClick={onSplitHor}>
                        <img src="/src_designer/images/icon_wall_x.svg"/>
                        <p className={classes.planerConstItemText}>Split horizontal</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default StateEditing;